import {
  GalleryIcon,
  VideoCameraIcon,
  ImageIcon,
  MovieIcon,
} from '@workspace/4Z1.uikit.react';
import { AttachmentTypes } from './utils';

interface AttachmentIconType {
  readonly icon: JSX.Element;
  readonly placeholderComponent: JSX.Element;
}

export const attachmentIcons: Record<AttachmentTypes, AttachmentIconType> = {
  [AttachmentTypes.Photo]: {
    icon: <GalleryIcon.Small />,
    placeholderComponent: <ImageIcon />,
  },
  [AttachmentTypes.Video]: {
    icon: <VideoCameraIcon.Small />,
    placeholderComponent: <MovieIcon />,
  },
} as const;
