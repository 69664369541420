export default {
  'massload.BACK_TO_UPLOAD': 'To upload data',
  'massload.File Name': 'File Name',
  'massload.Created At': 'Created At',
  'massload.Type': 'File type',
  'massload.Actions': 'Actions',
  'massload.DownloadServiceLink': 'Download agent',
  'massload.NoService': 'No agent',
  'massload.spaceinfo': 'Disk space',
  'massload.loading.telemetry': 'Loading telemetry',
  'massload.loading.stop': 'Stop loading',
  'massload.loading.remove': 'Remove from list',
  'massload.loading': 'Loading: {progress}%',
  'massload.dndFiles': 'Drag and drop files here',
  'massload.dndFilesOnButton': 'Drag and drop files here or click the button',
  'massload.dndFilesOr': 'Drag and drop files or',
  'massload.upload': 'upload',
  'massload.supportedFormats': 'Supported formats: ',
  'massload.selectFile': 'Select file',
  'massload.goToUploads': 'Go to downloads',

  'massload.error.fileAlreadyExists': 'File already exists',
  'massload.error.fileTypeNotSupported': 'File type not supported',
  'massload.error.fileTransferFailed': 'File transfer to operational storage failed',
  'massload.error.notEnoughSpace': 'Not enough free space in storage',
  'massload.error.fileGroupNotAdded': 'File group not added',
  'massload.error.spaceCheckError': 'Error checking available space for upload',
  'massload.error.postProcessingFailed': 'Post-processing failed',
  'massload.error.fileInfoNotFound': 'File information not found',
  'massload.error.bearerTokenRequired': 'Bearer user token required',
  'massload.error.fileInfoNotUpdated': 'File information not updated',
  'massload.error.fileArchResultNotUpdated': 'File arch result information was not updated',
  'massload.error.internalError': 'Internal server error',
  'massload.loadingWithBytes': 'Loading {progress}% • {bytes}',
  'massload.error.unknownError': 'Unknown error',
  'massload.error.networkError': 'Network error',
  'massload.error.sequenceContainsNoElements': 'No files were found. Check that the device is connected or contact the administrator',

  'massload.error.Error': 'Error',
  'massload.error.Terminated': 'Terminated',
  'massload.error.Finished': 'Finished',
  'massload.error.Uploading': 'Uploading',
  'massload.error.Stored': 'Stored',
  'massload.error.CreateUpload': 'Create upload',
  'massload.error.Rejected': 'Rejected',

  'massload.status.Processing': 'Processing',
  'massload.status.Moved': 'Moved to storage',
  'massload.status.Loaded': 'Loaded',
};
