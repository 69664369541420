import type { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { AssetCardProps } from '@/widgets';
import { AssetCapability } from '@/.graphql/graphql';
import { CompassIcon, MessageIcon, Tooltip } from '@workspace/4Z1.uikit.react';

interface Capability {
  readonly [AssetCapability: string]: {
    readonly icon: () => ReactNode,
    readonly message: string,
    readonly defaultMessage: string
  }
}

const INDICATORS: Capability = {
  [AssetCapability.CanSendGps]: {
    icon: CompassIcon.Small,
    message: 'online.assetCard.canSendGPS',
    defaultMessage: 'Can send GPS',
  },
  [AssetCapability.CanReceiveCommands]: {
    icon: MessageIcon.Small,
    message: 'online.assetCard.canExecuteCommands',
    defaultMessage: 'Can execute commands',
  },
};

export const AssetCapabilities: FC<AssetCardProps> = ({asset}) => {
  const intl = useIntl();

  return (
      asset.assetCapabilities.map((capability: string) => {
        const indicator = INDICATORS[capability];

        if (indicator === undefined) {
          return ;
        }

        return <Tooltip
          key={capability}
          className='assetCardTooltip assetCardTooltipInfo'
          title={intl.formatMessage({id: indicator.message})}
        >
          <indicator.icon/>
        </Tooltip>
      })
  )
}
