import { type FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { CopyIcon } from '@workspace/4Z1.uikit.react';
import { message } from 'antd';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { ContextMenuStore } from '../../model/ContextMenu.store';
import { implicitContextMenuCheck } from '../../model/implicitContextMenuCheck';
import { Item } from '../Item';
import { useCookies } from 'react-cookie';
import { transformCoordFormat } from '@/components/Map/transformCoordinates';

interface Props {
  readonly store?: ContextMenuStore;
  readonly className?: string;
}

export const CopyCoordinates: FC<Props> = observer(({
  store = implicitContextMenuCheck(),
  className,
}) => {
  const intl = useIntl();
  const [coordinates, setCoordinates] = useState<string>('');
  const [cookies] = useCookies(['reprojection']);

  useEffect(() => {
    if (store.coordinates !== undefined) {
      const { lat, lon } = store.coordinates;
      const transformedCoordinates = transformCoordFormat([lon, lat], cookies.reprojection);
      setCoordinates(transformedCoordinates);
    }
  }, [store.coordinates, cookies.reprojection]);

  const copyText = () => {
    navigator.clipboard.writeText(coordinates);
    // TODO избавиться от компонента antd - message, когда будет готов новый дизайн
    message.success(
      intl.formatMessage({
        id: 'contextmenu.copied',
        defaultMessage: 'Copied',
      }),
    );
  }

  return (
    <Item
      className={classNames('coordinates listItem listItem-reverse', className)}
      action={() => copyText()}
    >
      <div className='image'>
        <CopyIcon.Small/>
      </div>
      {coordinates}
    </Item>
  )
});