import { type FC } from 'react';
import { List } from '@workspace/4Z1.uikit.react';
import { observer } from 'mobx-react';
import { CONTEXT_MENU_WIDTH } from '../model/constants';
import { MenuItem } from '../model/interfaces';
import { ContextMenuStore } from '../model/ContextMenu.store';

interface Props {
  store: ContextMenuStore;
}

export const MenuItems: FC<Props> = observer(({store}) => {

  return (
    <List
      activeParam='render'
      onClick={(item: MenuItem) => item.action?.(store)}
      width={CONTEXT_MENU_WIDTH}
      dataSource={store.items}
      renderItem={(item: MenuItem) => {

        if (item.render !== undefined) {
          return item.render;
        }

        if (item.title !== undefined) {
          return <List.Item title={item.title} />
        }
      }}
    />
  )
})
