export default {
  'photo.start': 'Взлет',
  'photo.end': 'Посадка',
  'photo.noPreviousUploadFound': 'Перетащите сюда JPG-файлы или нажмите на кнопку.',
  'photo.telemetryRequired': 'Для загрузки фотографий, наличие файла телеметрии обязательно.',
  'photo.gallary': 'Галерея архива фото',
  'photo.gallary.download': 'Скачать фотографии',
  'photo.empty.photos': 'Фотографии по данному запросу не найдены',
  'photo.compare': 'Сравнить',
  'photo.compare.reload': 'Reload',
};
