export enum FtpStatus {
  Unknown = 0,
  InProgress = 1,
  Uploaded = 2,
  Failed = 3,
}

export interface FlightStatusResponse {
  readonly ftp_start?: FtpStart;
}

interface FtpStart {
  readonly ftp_status: number;
  readonly id: string;
  readonly project_id: string;
}

export interface CreatedFileResponse {
  readonly createdFileName: string;
}

export interface FlightResponse {
  readonly companyCode: string;
  readonly viewShot: string;
  readonly numberRoute: string;
  readonly numberFlight: number;
  readonly numberTeam: string;
  readonly cameraModel: string;
  readonly focus: string;
  readonly status: number;
  readonly dir: string;
  readonly flight_name: string;
}

export interface SendFtpRequestParams {
  readonly id?: string;
  readonly userLogin: string;
  readonly viewShot: string;
  readonly numberRoute: string;
  readonly numberFlight: string;
  readonly numberTeam: string;
  readonly cameraModel: string;
  readonly focus: string;
  readonly file?: any;
}

export type FtpErrorKeys =
  | 'userlogin'
  | 'viewshot'
  | 'flightdate'
  | 'numberroute'
  | 'numberflight'
  | 'numberteam'
  | 'cameramodel'
  | 'focus'
  | 'inputdir';
