import HoldPosition from '@/components/HoldPosition';
import { getOlMap, implicitMapCheck, MapPluginProps } from '@/shared/map';
import { Portal } from '@/shared/utils';
import { FC, RefObject, useMemo } from 'react';

interface Props extends MapPluginProps {
  readonly target: RefObject<Element>;
}

/**
 * Компонент является адаптацией старого компонента HoldPosition для удобного использования с новой картой
 * 
 * Подробнее см. в комментарии в HoldPosition.tsx
*/
export const HoldScalePlugin: FC<Props> = ({ map = implicitMapCheck(), target }) => {
  const olMap = useMemo(() => {
    return getOlMap(map);
  }, [map]);

  return (
    <Portal target={target}>
      <HoldPosition rightPosition map={olMap} />
    </Portal>
  );
};
