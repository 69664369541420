const LOCAL_STORAGE_KEY = 'demo';
const VALUE_OFF = 'off';

/**
 * Включает редизайн.
 * В последствии при окончательном переходе на редизайн - может быть выпилен.
 *
 * Сейчас - редизайн включен по-умолчанию. Но его можно выключить значением 'off' в ключе demo
 */
export const isDemoMode = (): boolean => {
  const value = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (value === null) {
    return true;
  }
  return value !== VALUE_OFF;
}
