import { useStore } from "@/shared/store/context";
import { useEffect } from "react";
import { Location } from "@/shared/model";


export interface MapNavigateFn {
  (loc: Location): void;
}

export const useMapNavigation = (navigate: MapNavigateFn, id: string) => {
  const { mapNavigation } = useStore();

  useEffect(() => {
    const mapLocation = mapNavigation.navigations;

    if (mapLocation.get(id) !== undefined) {
      navigate(mapLocation.get(id));
      mapNavigation.done(id);
    }
  }, [mapNavigation, mapNavigation.navigations]);
};
