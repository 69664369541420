import './styles.scss';

import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, SearchIcon } from '@workspace/4Z1.uikit.react';
import { Tooltip } from '@workspace/4Z1.uikit.react';
import { AssetCardProps } from '@/widgets';
import { useIntl } from "react-intl";

/**
 * Ссылка на объект в AssetCard
 * @param asset
 */
export const OpenDetailsPage: FC<AssetCardProps> = ({asset}) => {
  const intl = useIntl();

  return (
    <Tooltip className='assetCardTooltip' title={intl.formatMessage({id: 'online.assetCard.openDetailsPage'})}>
      <Link to={{ pathname: `/admin/assets/${asset.id}` }} className='openDetailsPageLink'>
        <Button
          onlyIcon={true}
          fullWidth={true}
          size={'small'}
          type={'badge'}
          icon={<SearchIcon.Small />}
        />
      </Link>
    </Tooltip>
  )
}
