import { useAccess } from '@umijs/max';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { UserLoginPath, CurrentUserKey, CurrentOptionsKey } from "@/services/UrqlClientProvider";

const useUserMenu = () => {
    const [isActiveUser, setIsActiveUser] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const intl = useIntl();
    const access = useAccess();

    useEffect(() => {
        const user = localStorage.getItem(CurrentUserKey);
        if (user) {
            try {
                const parsedUser = JSON.parse(user);
                setCurrentUser(parsedUser);
            } catch (error) {
                console.error(error);
            }
        }
    }, []);

    const onMenuClick = (e: { key: string; }) => {
        const { key } = e;
        if (key === '/user/logout') {
            localStorage.removeItem(CurrentUserKey);
            localStorage.removeItem(CurrentOptionsKey);
            window.location.assign(UserLoginPath);
        } else {
            window.location.assign(key);
        }
    };

    const menuEntries = [
        { key: "/user/update", label: intl.formatMessage({ id: "menu.Profile" }) },
        (access['zalaroute/create'] && access['canAdmin']) && { key: "/admin/route", label: intl.formatMessage({ id: "menu.Routes" }) },
        (access['zalatile/create'] && access['canAdmin']) && { key: "/admin/tile", label: intl.formatMessage({ id: "menu.Tiles" }) },
        (access['logger'] && access['canAdmin']) && { key: "/admin/statistic", label: intl.formatMessage({ id: "menu.Statistics" }) },
        access['canAdmin'] && { key: "/admin/useraccess", label: intl.formatMessage({ id: "menu.UserAccess" }) },
        access['canAdmin'] && { key: "/admin/assets", label: intl.formatMessage({ id: "menu.Assets" }) },
        access['canAdmin'] && { key: "/admin/dtoe", label: intl.formatMessage({ id: "menu.Download" }) },
        { key: "/user/logout", label: intl.formatMessage({ id: "menu.logout" }) },
    ].filter(Boolean);

    return {
        isActiveUser,
        setIsActiveUser,
        menuEntries,
        onMenuClick,
        currentUser,
    };
};

export default useUserMenu;