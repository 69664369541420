import { type FC, RefObject, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { DataSourceItem, Search } from '@workspace/4Z1.uikit.react';
import { Portal } from '@/shared/utils';
import { implicitMapCheck, MapPluginProps } from '@/shared/map';
import { GeoSearchStore } from '@/features/geosearch/model';
import { SearchPluginStore } from '../model/SearchPlugin.store';

export const LAYER_NAME = 'searchLayer';

interface Props extends MapPluginProps {
  target: RefObject<Element>;
}

export const SearchPlugin: FC<Props> = observer(({
    map = implicitMapCheck(),
    target
  }) => {
  const intl = useIntl();
  const [storePlugin] = useState(() => new SearchPluginStore());
  const [storeGeoSearch] = useState(() => new GeoSearchStore());

  useEffect(() => {
    storeGeoSearch.setQuery('');

    return () => {
      map.removeLayerByName(LAYER_NAME);
    }
  }, []);

  useEffect(() => {
    map.clearLayer(LAYER_NAME);
    addMarker();
  }, [storePlugin.searchMarker]);

  useEffect(() => {
    storePlugin.setResults(storeGeoSearch.results)
  }, [storeGeoSearch.results]);

  const addMarker = () => {
    if (storePlugin.searchMarker === undefined) {
      return;
    }
    map.addMarker(storePlugin.searchMarker);
    map.setCenter(storePlugin.searchMarker.location);
  }

  const handleSelectedItem = (item: DataSourceItem) => {
    storePlugin.selectItem(item);
    storeGeoSearch.selectItem(item.id, item.title);
  }

  return(
    <Portal target={target}>
      <Search.Select
        options={storePlugin.results}
        onSearchChange={(value: string) => storeGeoSearch.setQuery(value)}
        onSelectedItem={(item: DataSourceItem) => handleSelectedItem(item)}
        onClear={() => storePlugin.reset()}
        emptyMessage={intl.formatMessage({ id: 'map.SearchEmptyMessage' })}
        imageEnd={true}
        labelTooltip={intl.formatMessage({ id: 'map.Search' })}
      />
    </Portal>
  )
})
