import {
  Attachment,
  AttachmentUrlParams,
} from '@/entities/attachment';
import { FeaturesType, MEDIA_FEATURE_TYPES } from '@/entities/features';
import { MapEngine } from '@/shared/map/model/interfaces';
import { isNil } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { MapBrowserEvent, Overlay } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { FeatureLike } from 'ol/Feature';
import { toLonLat } from 'ol/proj';
import { Cookies } from 'react-cookie';

export interface DropdownItem {
  readonly name: string;
  readonly link: string;
  readonly openInNewTab: boolean;
}

const ANIMATION_DURATION = 250;

const OVERLAY_CONFIG = {
  id: 'main_overlay',
  autoPan: { animation: { duration: ANIMATION_DURATION } },
};

export class ClusterMenuStore {
  public dropdownItems: DropdownItem[] = [];

  constructor(
    private readonly map: MapEngine,
    public overlay: Overlay = new Overlay(OVERLAY_CONFIG),
  ) {
    makeAutoObservable(this);
  }

  public mount(): void {
    this.map.addOverlay(this.overlay);
    this.map.onMapClick(this.handleMapClick);
  }

  private handleMapClick = (event: MapBrowserEvent<UIEvent>): void => {
    const items = this.collectDropdownItems(event);

    this.updateOverlay([...items], event.coordinate);
  };

  private collectDropdownItems(
    event: MapBrowserEvent<UIEvent>,
  ): readonly DropdownItem[] {
    const items: DropdownItem[] = [];

    this.map.forEachFeatureAtPixel(event.pixel, (feature) => {
      if (this.isRelevantFeature(feature)) {
        const cluster = feature.get('features');

        const dropdownItems = cluster
          ? this.createFromCluster(event, cluster)
          : this.createFromSingleFeature(event, feature);

        items.push(...dropdownItems);
      }
    });

    return items;
  }

  private createFromCluster(
    event: MapBrowserEvent<UIEvent>,
    cluster: readonly FeatureLike[],
  ): readonly DropdownItem[] {
    return cluster
      .map((feature) => this.createDropdownItem(event, feature))
      .filter((item) => !isNil(item?.name));
  }

  private parseViewTime(
    event: MapBrowserEvent<UIEvent>,
    feature: FeatureLike,
  ): number | undefined {
    const featureType: FeaturesType | undefined = feature.get('type');

    if (featureType !== FeaturesType.TRACK) return;

    const geometry = feature.getGeometry();

    if (isNil(geometry)) return;

    const point: number[] = geometry.getClosestPoint(
      event.map.getCoordinateFromPixel(event.pixel),
    );

    /**
     * Получаем время просмотра видео (в секундах)
     *
     * @description - [lon, lat, currentTimeTrack]
     */
    const [, , currentTimeTrack] = point;

    return currentTimeTrack;
  }

  private createFromSingleFeature(
    event: MapBrowserEvent<UIEvent>,
    feature: FeatureLike,
  ): readonly DropdownItem[] {
    const name: string | undefined = feature.get('name');
    const link: string | undefined = feature.get('link');
    const withDropdown: boolean | undefined = feature.get('withDropdown');

    if (name && (link || withDropdown)) {
      return [this.createDropdownItem(event, feature)];
    }

    return [];
  }

  private isRelevantFeature(feature: FeatureLike | undefined): boolean {
    return feature?.get('type') !== FeaturesType.ONLINE;
  }

  private createDropdownItem(
    event: MapBrowserEvent<UIEvent>,
    feature: FeatureLike,
  ): DropdownItem {
    const singleTab: boolean = feature.get('singleTab') ?? false;
    const link = this.getFeatureLink(event, feature);
    const cookies = new Cookies();

    return {
      name: feature.get('name'),
      link,

      /**
       * TODO - Открытие ссылки в новом табе происходит сейчас на любой странице
       *
       * Необходимо переработать логику так, чтобы учитывался и путь страницы (должно работать только для страницы полёта)
       */
      openInNewTab: !singleTab && cookies.get('blank') === 'true',
    };
  }

  private getFeatureLink(
    event: MapBrowserEvent<UIEvent>,
    feature: FeatureLike,
  ): string {
    const featureType = feature.get('type');
    const baseLink = feature.get('link');

    if (featureType === FeaturesType.TRACK) {
      const attachment: Attachment | undefined = feature.get('attachment');
      const viewTime: number | undefined = this.parseViewTime(event, feature);

      const url = new URLSearchParams(window.location.search);

      if (!isNil(attachment)) {
        url.set(AttachmentUrlParams.Id, attachment.id);
        url.set(AttachmentUrlParams.Type, attachment.type);
      }

      if (!isNil(viewTime)) {
        url.set(AttachmentUrlParams.Viewtime, viewTime.toString());
      }

      /**
       * TODO - Переработать
       *
       * Таким образом формируется ссылка при нажатии на трек видео
       * Не очень хочется вообще взаимодействовать на этом уровне с урлом
       *
       * Возможно, здесь стоит генерировать только параметры ссылки, а остальное уже передать
       * под ответственность react-router-dom в компоненте, вызывая при клике по ссылке метод setParams
       */
      return `${window.location.pathname}?${url.toString()}`;
    }

    if (MEDIA_FEATURE_TYPES.includes(featureType)) {
      return `${baseLink}?coord=${toLonLat(event.coordinate).toReversed()}`;
    }

    return baseLink;
  }

  private updateOverlay(
    items: DropdownItem[],
    coordinate: Coordinate | undefined,
  ): void {
    if (items.length > 0) {
      this.dropdownItems = items;
      this.overlay.setPosition(coordinate);

      return;
    }

    this.clearOverlay();
  }

  private clearOverlay(): void {
    this.dropdownItems = [];
    this.overlay.setPosition(undefined);
  }

  public unmount(): void {
    this.map.offMapClick(this.handleMapClick);
    this.map.removeOverlay(this.overlay);
  }
}
