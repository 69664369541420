import { Locale, Languages } from '../model';

export const LOCALES: Record<Languages, Readonly<Locale>> = {
  [Languages.RU]: {
    code: Languages.RU,
    label: 'Русский',
  },
  [Languages.EN]: {
    code: Languages.EN,
    label: 'English',
  },
} as const;
