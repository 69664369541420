import { type FC } from 'react';
import { AimIcon, Button } from '@workspace/4Z1.uikit.react';
import { Tooltip } from '@workspace/4Z1.uikit.react';
import { AssetCardProps } from '@/widgets';
import { useIntl } from 'react-intl';
import { dep, diInject } from '@/HOC';
import { DiKeys } from '@/shared/di/global';

interface Props extends AssetCardProps {
  readonly assetOnMap: (assetId: string) => void;
}

/**
 * Компонент центрирующий карту по объекту AssetCard
 * @param asset
 * @param assetOnMap
 */
const ShowOnMap: FC<Props> = ({asset, assetOnMap}) => {
  const intl = useIntl();

  return (
    <Tooltip
      className='assetCardTooltip'
      title={intl.formatMessage({id: 'online.assetCard.showOnMap'})}
    >
      <Button
        type={'badge'}
        size={'small'}
        onlyIcon={true}
        fullWidth={true}
        onClick={() => assetOnMap?.(asset.id)}
        icon={<AimIcon.Small/>}
      />
    </Tooltip>
  )
};

export default diInject(ShowOnMap, {
  assetOnMap: dep(DiKeys.assetOnMap),
});
