import { dep, diInject } from '@/HOC';
import { DiKeys } from '@/shared/di/global';
import { Button, CloseIcon, CompareIcon, DropDown, SaveIcon, Skeleton, } from '@workspace/4Z1.uikit.react';
import { DropdownViewModel, InputOption, OrtophotoStore } from '../model/ortophoto.store';
import { FC, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { FlightContentBar, FlightContentBarButton } from '@/widgets/FlightContentBar';

interface Props {
  readonly store: OrtophotoStore;
}

const OrtophotoDropdown = ({ viewModel }: { viewModel: DropdownViewModel }): ReactElement => {
  if (!viewModel.visible) return <></>;

  if (viewModel.loading) {
    return <Skeleton loading stretch className='ortophoto-map__dropdown' />;
  }

  return (
    <DropDown
      label={viewModel.value}
      absolute
      multiselect={false}
      resettable={false}
      options={viewModel.options}
      className='ortophoto-map__dropdown'
      onChange={(ortophoto: InputOption['value']) => viewModel.onSelect(ortophoto)}
    />
  );
};

const OrtophotoActionsPanel: FC<Props> = ({ store }) => {
  const intl = useIntl();

  const end: FlightContentBarButton[] = [
    {
      type: 'accent',
      disabled: store.actionsView.disabled,
      label: intl.formatMessage({ id: 'button.Save' }),
      icon: <SaveIcon.Small />,
      iconPosition: 'left',
      onClick: store.actionsView.onChangesSave,
    },
    {
      type: 'badge',
      disabled: store.actionsView.disabled,
      label: intl.formatMessage({ id: 'button.Cancel' }),
      icon: <CloseIcon.Small />,
      iconPosition: 'left',
      onClick: store.actionsView.onChangesRevert,
    },
  ]

  return (
    <FlightContentBar
      startContent={
        store.hasOrtophoto &&
        <>
          <Button
            label={intl.formatMessage({id: 'photo.compare'})}
            icon={<CompareIcon.Small/>}
            iconPosition={'left'}
            type={'badge'}
            onClick={store.actionsView.onCompareButtonClick}
          />
          <OrtophotoDropdown viewModel={store.dropdownView}/>
        </>
      }
      endButtons={end}
      acceptFiles={store.acceptFiles}
      uploadType={store.uploadType}
    />
  );
};

export default diInject(OrtophotoActionsPanel, {
  store: dep(DiKeys.ortophotoStore),
});
