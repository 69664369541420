import { Component, Key } from 'react';
import { injectIntl } from 'react-intl';
import { withCookies } from 'react-cookie';
import request from 'umi-request';

import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { BaseLayerOptions } from 'ol-layerswitcher';
import { Feature, Map, TileCache } from 'ol';
import { GeoJSON } from 'ol/format';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import { Coordinate } from 'ol/coordinate';
import { fromLonLat } from 'ol/proj';
import { Point } from 'ol/geom';

class TRAFFIC_AIR extends Component<any, any> {
  cache    = new TileCache();
  map: Map = this.props.map;
  layer    = new VectorLayer();
  source   = new VectorSource();
  tag = 'air';
  urlTiles = '0,0,6000?';
  icon_plane = '/icons/planes.png';
  airTrafficFetchInterval: NodeJS.Timer | null = null;

  ICON_AIR_MAPPING: any = {
    0: { x: 48 * 12, y: 0, width: 20, height: 20, mask: false },
    15: { x: 48 * 13, y: 0, width: 20, height: 20, mask: false },
    30: { x: 48 * 14, y: 0, width: 20, height: 20, mask: false },
    45: { x: 48 * 15, y: 0, width: 20, height: 20, mask: false },
    60: { x: 48 * 16, y: 0, width: 20, height: 20, mask: false },
    75: { x: 48 * 17, y: 0, width: 20, height: 20, mask: false },
    90: { x: 48 * 18, y: 0, width: 20, height: 20, mask: false },
    105: { x: 48 * 19, y: 0, width: 20, height: 20, mask: false },
    120: { x: 48 * 20, y: 0, width: 20, height: 20, mask: false },
    135: { x: 48 * 21, y: 0, width: 20, height: 20, mask: false },
    150: { x: 48 * 22, y: 0, width: 20, height: 20, mask: false },
    165: { x: 48 * 23, y: 0, width: 20, height: 20, mask: false },
    180: { x: 48 * 0, y: 0, width: 20, height: 20, mask: false },
    195: { x: 48 * 1, y: 0, width: 20, height: 20, mask: false },
    210: { x: 48 * 2, y: 0, width: 20, height: 20, mask: false },
    225: { x: 48 * 3, y: 0, width: 20, height: 20, mask: false },
    240: { x: 48 * 4, y: 0, width: 20, height: 20, mask: false },
    255: { x: 48 * 5, y: 0, width: 20, height: 20, mask: false },
    270: { x: 48 * 6, y: 0, width: 20, height: 20, mask: false },
    285: { x: 48 * 7, y: 0, width: 20, height: 20, mask: false },
    300: { x: 48 * 8, y: 0, width: 20, height: 20, mask: false },
    315: { x: 48 * 9, y: 0, width: 20, height: 20, mask: false },
    330: { x: 48 * 10, y: 0, width: 20, height: 20, mask: false },
    345: { x: 48 * 11, y: 0, width: 20, height: 20, mask: false },
    360: { x: 48 * 12, y: 0, width: 20, height: 20, mask: false },
  };

  constructor(props: any) {
    super(props);

    this.state = {
      ID: '',
    };

    const { intl } = this.props;
    this.layer = new VectorLayer({
      name: 'traffic_air',
      className: 'traffic_air',
      title: intl.formatMessage({
        id: 'layer.traffic.air',
        defaultMessage: 'Air',
      }),
      source: this.source,
      visible: this.props.allCookies.layers.traffic_air ? true : false,
      zIndex: 10,
      minZoom: this.props.minZoom,
      maxZoom: this.props.maxZoom,
    } as BaseLayerOptions);
  }

  componentDidMount() {
    this.source.setLoader(this.loader);
    this.props.addLayer(this.layer);

    this.airTrafficFetchInterval = setInterval(() => {
      this.loader();
    }, 10 * 1000);
  }

  componentDidUpdate() {
    if (this.props.feature.get('type') === this.tag && this.props.feature.getId() !== this.state.ID){
      const id   = this.props.feature.getId();
      const info = this.props.feature.get('info');

      this.setHTML(info);

      this.setState({ID: id});
    }
  }

  componentWillUnmount() {
    clearInterval(this.airTrafficFetchInterval!);
    this.map.removeLayer(this.layer);
  }

  setHTML = (props: any) => {
    let html =
      `Рейс: <b>${props.Name}</b> Страна: <b>${props.Registration.Name}</b><br/>
      <b>Высота:</b> ${props.Altitude.Meter | 0} м<br/>
      <b>Скорость:</b> ${props.Speed.KilometerPerHour | 0} км/ч<br/>
      <b>Из:</b> ${props.From.IATA}<br/>
      <b>В:</b> ${props.To.IATA}`;
    
    this.props.setHtml(html);
  }

  loader = () => {
    const url = this.props.urlApi+'/'+this.tag+'/'+this.urlTiles;
    request(url, {
      method: 'get',
      //headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        this.setSource(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setSource = (data: any) => {
    if (data.length)
    {
      this.source.clear();
      data.map((row: any) => {
        const dir = Math.min(360, Math.floor(row.Direction / 15) * 15);
        const iconXY = this.ICON_AIR_MAPPING[dir];
        
        var coord = fromLonLat([row.Position.Longitude, row.Position.Latitude]);
        var plane = new Feature({
          geometry: new Point(coord),
        });
        plane.setId(row.ID);
        plane.set('type', this.tag);
        plane.set('typeClick', 'traffic');
        plane.set('info', row);
        plane.setStyle(
          new Style({
            image: new Icon({
              src: this.icon_plane,
              //rotation: Math.min(360, Math.floor(row.Direction / 15) * 15),
              size: [20,20],
              offset: [iconXY.x, iconXY.y],
            }),
          }),
        );
        
        this.source.addFeature(plane);
      });
    }
  }

  render() {
    return (
      <></>
    );
  }
}

export default injectIntl(withCookies(TRAFFIC_AIR));
