import { UploadHistoryFile, UploadHistoryFileStatuses, UploadHistoryService } from '@/entities/uploadHistory';
import { State, StateHandler } from '@/shared/utils';
import RequestHandler from '@/shared/utils/requestHandler';
import { logger } from '@workspace/4Z1.ts.utils';
import { inject, injectable } from 'inversify';
import { isEmpty } from 'lodash';
import { makeAutoObservable } from 'mobx';

const log = logger('DownloadHistory');

export interface DownloadHistoryStoreState {
  readonly downloadHistory: readonly UploadHistoryFile[];
}

export interface DownloadHistoryStore {
  readonly state: State<DownloadHistoryStoreState, unknown>;
  readonly mount: (flightId: string) => void;
  readonly unmount: () => void;
}

@injectable()
export class DownloadHistoryStoreImpl implements DownloadHistoryStore {
  /** Статичные переменные класса */
  private readonly POLLING_INTERVAL_MS = 5000;

  /** Переменные класса */
  private files: readonly UploadHistoryFile[] = [];
  private pollingTimeout: NodeJS.Timeout | undefined = undefined;

  /** Подключение сервисов */
  private readonly uploadHistoryService: UploadHistoryService;

  constructor(
    @inject(UploadHistoryService.diKey) uploadHistoryService: UploadHistoryService,
    private readonly requestHandler = new RequestHandler<readonly UploadHistoryFile[]>(),
    private readonly pollingHandler = new RequestHandler<readonly UploadHistoryFile[]>(),
  ) {
    makeAutoObservable(this);
    this.uploadHistoryService = uploadHistoryService;
  }

  public get state(): State<DownloadHistoryStoreState, unknown> {
    const { isLoading, error } = this.requestHandler;

    if (isLoading) {
      return StateHandler.loading();
    }

    if (error) {
      return StateHandler.error(error ?? 'An error occured during component rendering');
    }

    if (!isEmpty(this.files)) {
      return StateHandler.ready({
        downloadHistory: this.files,
      });
    }

    log.error('An unknown component state met ::', this.files, error);

    return StateHandler.error('Couldn`t resolve the state of component');
  }

  public mount(flightId: string): void {
    this.fetchAndPullFiles(flightId);

    log.debug('Successfully mounted');
  }

  public unmount(): void {
    clearInterval(this.pollingTimeout);

    log.debug('Successfully unmounted');
  }

  private async fetchUploadHistoryFiles(
    flightId: string,
    withPolling: boolean = false,
  ): Promise<readonly UploadHistoryFile[]> {
    const handler = withPolling ? this.pollingHandler : this.requestHandler;

    const files = await handler
      .handleRequest(() => this.uploadHistoryService.fetchFilesByFlightId(flightId))
      .then(files => {
        log.debug('Files have been retrieved ::', files);

        return files;
      });

    this.files = [...files];

    return files;
  }

  private async pullUploadHistoryFiles(flightId: string): Promise<void> {
    if (this.pollingTimeout) {
      log.debug('Clearing existing polling timeout');
      clearTimeout(this.pollingTimeout);
    }

    if (!this.shouldPollFiles) {
      log.debug('Loading files were not found. Polling stopped');
      return;
    }

    const pollingTimeout = setTimeout(() => this.fetchAndPullFiles(flightId, true), this.POLLING_INTERVAL_MS);

    this.pollingTimeout = pollingTimeout;
  }

  private async fetchAndPullFiles(flightId: string, withPolling: boolean = false): Promise<void> {
    this.fetchUploadHistoryFiles(flightId, withPolling).finally(() => this.pullUploadHistoryFiles(flightId));
  }

  private get shouldPollFiles(): boolean {
    return this.files.some(file => file.status === UploadHistoryFileStatuses.Loading);
  }
}
