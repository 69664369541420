import './styles.scss';

import { type FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { LayersPanelStore } from '../model/LayersPanel.store';
import { getPathPreviewMap } from '../model/getPathImageMap';
import { useCookies } from 'react-cookie';
import BaseLayer from 'ol/layer/Base';
import { useVisibleLayer } from '@/shared/ui/ol_map/useVisibleLayer';
import { Button, CloseIcon } from '@workspace/4Z1.uikit.react';

interface Props {
  store: LayersPanelStore;
}

export const LayersPanelMaps: FC<Props> = observer(({store}) => {
  const intl = useIntl();
  const [cookies] = useCookies(['layers']);
  const {setVisibleLayer} = useVisibleLayer();

  useEffect(() => {
    store.setActiveMapId(cookies.layers.maps ? cookies.layers.maps : store.activeMapId);
  }, [store.activeMapId]);

  const selectMap = (item: BaseLayer) => {
    store.selectMap(item);
    setVisibleLayer(item);
  }

  return (
    <div className='layersPanelMaps'>

      <div className='layersPanelMapsTitle'>
        {intl.formatMessage({id: 'map.layersPanel.maps'})}
        <Button
          size={'small'}
          type={'badge'}
          onlyIcon={true}
          icon={<CloseIcon/>}
          onClick={() => store.show = false}
        />
      </div>

      <div className='layersPanelMapsList'>
        {store.maps?.map(item =>

          <div className='layersPanelMapsListItem' onClick={() => selectMap(item)}>

            {/* TODO встраивать актуальные картинки */}
            <img
              className={classNames('layersPanelMapsListItemImage', {active: item.get('map_id') === store.activeMapId})}
              src={getPathPreviewMap(item.get('map_id'))}
              alt='previe-map'
            />

            <div className='layersPanelMapsListItemName'>
              {item?.get('title')}
            </div>
          </div>
        )}
      </div>

      <div className='divider'></div>

    </div>
  )
})
