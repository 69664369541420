import { OrtophotoDocument, OrtophotoQuery, Ortophoto as RawGraphqlOrtophoto } from '@/.graphql/graphql';
import { GraphQlApi } from '@/shared/api/graphql';
import { ApiQueryBuilder, ComparisonOperator } from '@workspace/4Z1.ts.utils';
import { Ortophoto, OrtophotoComparee } from '../model/interface';
import { isNil } from 'lodash';
import { createRestApi } from '@/shared/api/rest';
import urlJoin from 'url-join';
import { OrtophotoMeta } from '../model/interface';
import { AxiosResponse } from 'axios';
import { emulateDownload } from '@/shared/utils/downloadFile';

enum OrtophotoStatus {
  Deleted = 0,
  Active = 1,
  Inactive = 2,
  Atwork = 3,
}

const defaultPrefixFormatter = (value?: number): string => (value ? `(${value})` : ``);

export const formatOrtophotoName = (ortophoto: OrtophotoMeta, prefixFormatter = defaultPrefixFormatter): string => {
  if (isNil(ortophoto)) return ``;

  return `${prefixFormatter(ortophoto.flightId)} ${ortophoto.name}`;
};

export interface OrtophotoApi {
  readonly fetchOrtophotoByFlightId: (flightId: string) => Promise<Ortophoto | undefined>;
  readonly fetchOrtophotoComparees: (ortophotoId: string) => Promise<readonly OrtophotoComparee[]>;
  readonly fetchOrtophotoById: (ortophotoId: string) => Promise<Ortophoto | undefined>;
  readonly downloadOrtophoto: (ortophotoId: string) => Promise<void>;
  readonly updateOrtophoto: (ortophotoId: string, updatedOrtophoto: Partial<Ortophoto>) => Promise<void>;
  readonly deleteOrtophoto: (ortophotoId: string) => Promise<void>;
}

class OrtophotoApiImpl implements OrtophotoApi {
  private static readonly API_BASE = API;

  private static readonly UPDATE_ORTOPHOTO_NAME = 'name';
  private static readonly DOWNLOAD_ORTOPHOTO = 'download';

  private readonly clientGraphql = new GraphQlApi();
  private readonly clientRest = createRestApi();

  public async deleteOrtophoto(ortophotoId: string): Promise<void> {
    this.clientRest.delete(this.path(ortophotoId));
  }

  public async fetchOrtophotoByFlightId(flightId: string): Promise<Ortophoto | undefined> {
    const queryBuilder = new ApiQueryBuilder();

    queryBuilder.addFilter('flightId', [{ operator: ComparisonOperator.EQUALS, value: flightId }]);
    queryBuilder.addFilter('status', [{ operator: ComparisonOperator.EQUALS, value: OrtophotoStatus.Active }]);

    const rawOrtophoto = await this.clientRest
      .post<AxiosResponse<readonly OrtophotoMeta[]>>(this.path(), queryBuilder.buildQuery())
      .then(response => response?.data?.at(0));

    if (isNil(rawOrtophoto)) {
      return;
    }

    return this.fetchOrtophotoById(rawOrtophoto.id.toString());
  }

  public async fetchOrtophotoComparees(ortophotoId: string): Promise<readonly OrtophotoComparee[]> {
    const queryBuilder = new ApiQueryBuilder();

    queryBuilder.setPagination(0, 10);
    queryBuilder.addFilter('flightId', [{ operator: ComparisonOperator.GREATER_THAN, value: 0 }]);
    queryBuilder.addFilter('status', [{ operator: ComparisonOperator.EQUALS, value: OrtophotoStatus.Active }]);

    const ortophotos = await this.clientRest
      .post<AxiosResponse<readonly OrtophotoMeta[]>>(this.path(), queryBuilder.buildQuery())
      .then(data => data.data);

    return transformOrtophotosToComparees(ortophotos);
  }

  public async updateOrtophoto(ortophotoId: string, updatedOrtophoto: Partial<Ortophoto>): Promise<void> {
    return this.clientRest.put<void>(
      this.path(OrtophotoApiImpl.UPDATE_ORTOPHOTO_NAME),
      {},
      { id: ortophotoId, name: updatedOrtophoto.name },
    );
  }

  public async fetchOrtophotoById(ortophotoId: string): Promise<Ortophoto | undefined> {
    return this.clientGraphql
      .query<OrtophotoQuery>(OrtophotoDocument, { id: ortophotoId })
      .then(data => data.ofp as RawGraphqlOrtophoto)
      .then(ortophoto => transformRawGraphqlOrtophoto(ortophoto));
  }

  private path(...methods: string[]): string {
    return urlJoin(OrtophotoApiImpl.API_BASE, 'ofp', 'OrtoPhoto', ...methods);
  }

  public async downloadOrtophoto(ortophotoId: string): Promise<void> {
    return emulateDownload(this.path(OrtophotoApiImpl.DOWNLOAD_ORTOPHOTO, ortophotoId))
  };
}

export const createOrtophotoApi = (): OrtophotoApi => {
  return new OrtophotoApiImpl();
};

const transformRawGraphqlOrtophoto = (rawOrtophoto?: RawGraphqlOrtophoto | null): Ortophoto | undefined => {
  if (isNil(rawOrtophoto)) return;

  return {
    id: rawOrtophoto.id,
    geoserver: rawOrtophoto.geoserver ?? '',
    name: rawOrtophoto.name,
    polygon: {
      geom: rawOrtophoto.polygon?.geom ?? '',
      epsg: rawOrtophoto.polygon?.epsg ?? '',
    },
  };
};

const transformOrtophotosToComparees = (ortophotos: readonly OrtophotoMeta[]): readonly OrtophotoComparee[] => {
  return ortophotos.map(ortophoto => {
    const ortophotoName = formatOrtophotoName(ortophoto);

    return {
      // label: flight.name,
      label: formatOrtophotoName(ortophoto),
      value: { ...ortophoto, name: ortophotoName, id: ortophoto.id.toString() },
    };
  });
};
