export const getLocalizationId = (errorCode: string) => {
    const localizationMap = {
      'ERR_409_FILE_ALREADY_EXISTS': 'massload.error.fileAlreadyExists',
      'ERR_415_FILE_TYPE_NOT_SUPPORTED': 'massload.error.fileTypeNotSupported',
      'ERR_500_FILE_TRANSFER_FAILED': 'massload.error.fileTransferFailed',
      'ERR_507_NOT_ENOUGH_SPACE': 'massload.error.notEnoughSpace',
      'ERR_500_FILE_GROUP_NOT_ADDED': 'massload.error.fileGroupNotAdded',
      'ERR_500_SPACE_CHECK_ERROR': 'massload.error.spaceCheckError',
      'ERR_500_POST_PROCESSING_FAILED': 'massload.error.postProcessingFailed',
      'ERR_404_FILE_INFO_NOT_FOUND': 'massload.error.fileInfoNotFound',
      'ERR_401_BEARER_TOKEN_REQUIRED': 'massload.error.bearerTokenRequired',
      'ERR_500_FILE_INFO_NOT_UPDATED': 'massload.error.fileInfoNotUpdated',
      'ERR_500_FILE_ARCH_RESULT_NOT_UPDATED': 'massload.error.fileArchResultNotUpdated',
      'ERR_500_INTERNAL_ERROR': 'massload.error.internalError',
      'ERR_500_UNKNOWN_ERROR': 'massload.error.unknownError',
      'ERR_400_NETWORK_ERROR': 'massload.error.networkError',
      'ERR_409_DUPLICATE_FILES': 'massload.error.filesAlreadyExists',
      'ERR_500_SEQUENCE_CONTAINS_NO_ELEMENTS': 'massload.error.sequenceContainsNoElements',
    };
  
    return localizationMap[errorCode] || 'error.unknownError';
  };