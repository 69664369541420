export default [
  {
    path: '/',
    component: '../layouts/BlankLayout',
    flatMenu: true,
    headerRender: false,
    routes: [
      {
        path: '/login',
        component: './User/login',
        ghost: true,
        menuRender: false,
        menuHeaderRender: false,
      },
      {
        path: '/flight/:flightID',
        component: './FlightPage',
        exact: true,
      },
      {
        path: '/flightID/:flightID',
        component: './FlightCard',
        exact: true,
      },
      {
        path: '/flight/:flightID/*',
        redirect: '/flight/:flightID',
      },
      {
        path: '/home',
        component: 'index',
        name: 'home',
        icon: 'HomeIcon', //Имена иконок брать по названию компонента в 4Z1.uikit.react/src/assets/icons
        exact: true,
      },
      {
        path: '/downloads/:id', //Костыль! надо что-то думать с роутингом umi
        exact: true,
        component: './DownloadPage',
        hideInMenu: true,
        visibleNewMenu: true, //Костыль! Указываем для нового сайдбара если нет такого элемента в старом
        name: 'downloads',
        icon: 'CloudUploadIcon',
        access: 'zalamassload',
      },
      {
        path: '/flights', //Костыль! надо что-то думать с роутингом umi
        exact: true,
        component: './FlightPage',
        hideInMenu: true,
        visibleNewMenu: true, //Костыль! Указываем для нового сайдбара если нет такого элемента в старом
        name: 'flights',
        aliases: ['flight'],
        icon: 'GlobalIcon',
      },
      {
        path: '/flights_old',
        name: 'flights',
        icon: 'GlobalIcon',
        component: './Flight/index',
        exact: true,
        hideInMenu: true,
        aliases: ['flight'],
        access: 'zalaflight',
      },
      {
        path: '/photos/:flightId',
        name: 'photos',
        component: './Photo',
        hideInMenu: true,
        access: 'zalaimagetile',
      },
      {
        path: '/photos/gallery/:flightId',
        name: 'photos',
        component: './Photo/Gallery',
        hideInMenu: true,
        access: 'zalaimagetile/download',
      },
      {
        path: '/photos/compare/:flightId/:photoId',
        name: 'photos',
        component: './Photo/Compare',
        hideInMenu: true,
        access: 'zalaimagetile',
      },
      {
        path: '/videos/:videoId',
        name: 'videos',
        component: './Video',
        hideInMenu: true,
        access: 'zalavideo',
      },
      {
        path: '/265',
        component: './Video/265',
        exact: true,
        access: 'zalavideo',
      },
      {
        path: '/heatmap',
        name: 'Heatmap',
        component: './Heatmap/index',
        hideInMenu: true,
        access: 'zalaviolation',
      },
      {
        path: '/violations',
        name: 'violations',
        icon: 'AlertIcon',
        component: './Violation/index',
        exact: true,
        access: 'zalaviolation',
        aliases: ['heatmap'],
      },
      {
        path: '/violations/view/:vltId',
        component: './Violation/view',
        hideInMenu: true,
        name: 'vlt_view',
        access: 'zalaviolation',
      },
      {
        path: '/violations/list',
        component: './Violation/list/index',
        hideInMenu: true,
        name: 'vlt_list',
        access: 'zalaviolation',
      },
      {
        path: '/onlines',
        component: './Online/index',
        hideInMenu: true,
        name: 'online',
        access: 'zalaonline',
      },
      {
        path: '/o/onlines',
        component: './Online/index',

        hideInMenu: true,
        name: 'online',
        access: 'zalaonline',
      },
      {
        path: '/onlines/VideoDashboard',
        name: 'online-dashboard',
        icon: 'OnlineBroadcastIcon',
        component: './Online/Dashboard/index',
        access: 'zalaonline',
      },
      {
        path: '/ofptiff',
        component: './OFP/ofptiff',
        exact: true,
        access: 'zalaortophoto',
      },
      {
        path: '/ofps',
        name: 'ofps',
        icon: 'GalleryIcon',
        component: './OFP',
        exact: true,
        access: 'zalaortophoto',
      },
      {
        path: '/ofps/compare/:mainID/:compareID',
        component: './OFP/compare',
        hideInMenu: true,
        name: 'ofp_compare',
        exact: true,
        access: 'zalaortophoto',
      },
      {
        path: '/ofps/cdreports',
        component: './OFP/compare_cd',
        hideInMenu: true,
        name: 'ofp_compare_cd',
        exact: true,
        access: 'zalaortophoto/cdreport',
      },
      {
        path: '/ofps/swipe/:mainID/:compareID',
        component: './OFP/swipe',
        hideInMenu: true,
        name: 'ofp_swipe',
        exact: true,
        access: 'zalaortophoto',
      },
      {
        path: '/gazes',
        name: 'gazes',
        icon: 'HeatMapIcon',
        component: './Gaz',
        access: 'zalagaz',
      },
      {
        path: '/reports',
        name: 'reports',
        icon: 'UnorderedListIcon',
        component: './Report',
        access: 'zalareport',
      },
      {
        path: '/user/update',
        component: './User/update',
        access: 'user/update',
      },
      {
        path: '/orders',
        name: 'orders',
        icon: 'FileExclamationIcon',
        component: './Order',
        access: 'zalaorder',
      },
      {
        path: '/orders/create',
        component: './Order/create',
        access: 'zalaorder/create',
      },
      {
        path: '/orders/view/:orderID',
        component: './Order/view',
      },
      {
        path: '/ftp/:flightID',
        component: './Ftp',
      },
      {
        path: '/admin/massload',
        component: './Admin/massload',
        access: 'zalamassload',
        exact: true,
      },
      {
        path: '/admin/massload/arhive',
        component: './Admin/massload/arhive',
        access: 'zalamassload',
        exact: true,
      },
      {
        path: '/admin/statistic',
        component: './Admin/statistic',
        access: 'canAdmin',
        exact: true,
      },
      {
        path: '/admin/statistic/:userID',
        component: './Admin/statistic/view',
        access: 'canAdmin',
        exact: true,
      },
      {
        path: '/admin/route',
        component: './Admin/route',
        access: 'zalaroute/create',
        exact: true,
      },
      {
        path: '/admin/tile',
        component: './Admin/tile',
        access: 'zalatile/create',
        exact: true,
      },
      {
        path: '/admin/cdreport',
        component: './Admin/cdreport',
        access: 'canAdmin',
        exact: true,
      },
      {
        path: '/admin/telemetry',
        component: './Admin/telemetry',
        access: 'canAdmin',
        exact: true,
      },
      {
        path: '/admin/flight',
        component: './Admin/flight',
        access: 'canAdmin',
        exact: true,
      },
      {
        path: '/admin/flight/:flightID',
        component: './Admin/flight/view',
        access: 'canAdmin',
        exact: true,
      },
      {
        path: '/admin/ofp',
        component: './Admin/ofp',
        access: 'canAdmin',
        exact: true,
      },
      {
        path: '/admin/useraccess',
        component: './Admin/useraccess',
        access: 'canAdmin',
        exact: true,
      },
      {
        path: '/admin/useraccess/:userID',
        component: './Admin/useraccess/view',
        access: 'canAdmin',
        exact: true,
      },
      {
        path: '/admin/assets',
        name: 'Assets',
        component: './Admin/asset',
        exact: true,
        hideInMenu: true,
        access: 'canAdmin',
      },
      {
        path: '/admin/assets/:assetId',
        component: './Admin/asset/Details',
        exact: true,
        access: 'canAdmin',
      },
      {
        path: '/admin/assets/edit/:assetId',
        component: './Admin/asset/Update',
        exact: true,
        access: 'canAdmin',
      },
      {
        path: '/admin/dtoe/create',
        component: './Admin/dtoe/create',
        exact: true,
        access: 'dtoe',
      },
      {
        path: '/admin/dtoe/upload',
        component: './Admin/dtoe/upload',
        exact: true,
        access: 'dtoe',
      },
      {
        path: '/admin/dtoe',
        component: './Admin/dtoe',
        exact: true,
        access: 'dtoe',
      },
      {
        path: '/',
        redirect: '/home',
      },
      {
        path: '*',
        component: './Exception/404',
      },
    ],
  },
];
