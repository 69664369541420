import { MapEngine } from '@/shared/map/model/interfaces';
import { makeAutoObservable } from 'mobx';
import {
  DrawMeasureType,
  LAYER_NAME,
  modifyStyle,
  generateStyle,
} from './MeasureStyle';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Draw, Modify } from 'ol/interaction';


export class MeasureStore {

  private draw!: Draw;
  private _type: DrawMeasureType | undefined;

  private source = new VectorSource();
  private modify = new Modify({
    source: this.source,
    style: modifyStyle
  });
  private vector = new VectorLayer({
    source: this.source,
    style: function (feature) {
      return generateStyle(feature);
    },
  });

  constructor(
    private readonly map: MapEngine,
  ) {
    makeAutoObservable(this);
    this.vector.set('name', LAYER_NAME);
  }

  get type() {
    return this._type;
  }

  startDraw(type: DrawMeasureType) {
    if(type === this.type) {
      this.endDraw();
      return;
    }

    // Если был переключен режим изменения мер на карте, то нужно очистить интерактивность
    // чтобы стили работали корректно
    if(type !== this.type && this.draw) {
      this.map.removeInteraction(this.draw);
    }

    this._type = type;

    this.draw = new Draw({
      source: this.source,
      type: this._type,
      style: (feature) => generateStyle(feature, this._type),
    });

    this.map.addLayer(this.vector);

    this.modify.setActive(true);
    this.map.addInteraction(this.draw);
    this.map.addInteraction(this.modify);
  }

  endDraw() {
    this.modify.setActive(false);
    this.map.removeInteraction(this.draw);
    this.map.removeInteraction(this.modify);
    this.source.clear();
    this._type = undefined;
    this.map.removeLayerByName(LAYER_NAME);
  }
}
