import { makeAutoObservable } from "mobx";
import { MapMarker } from "@/entities/map/model/MapMarker";

export const LAYER_WITH_SEARCH_MARKER = 'searchMarker';

export class SearchMarkerStore {

  public searchMarker: MapMarker | undefined;

  constructor() {
    makeAutoObservable(this);
  }

}
