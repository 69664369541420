import { FtpErrorKeys } from '@/entities/ftp/model/interfaces';
import { FormType, FormValues } from './interfaces';
import { isUploadToFtpCouldBeStarted } from '@/entities/ftp';

/**
 * Получаем локалии ошибок по ключам полей формы
 */
export const FtpLocalizedMessages: Readonly<
  Record<keyof FormValues, Record<string, string>>
> = {
  userLogin: {
    '422-1': 'ftp.error.userlogin.not-found',

    // Пример добавления более специфичной ошибки
    // { '422-1-1': 'ftp.error.userlogin.specific-error' },
  },
  file: {
    '422-3': 'ftp.error.inputDir.no-telemetry-found',
  },
  viewShot: {
    '422-6': 'ftp.error.viewShot.incorrect-type',
  },
  numberRoute: {
    '422-8': 'ftp.error.numberRoute.incorrect-route',
  },
  numberFlight: {
    '422-9': 'ftp.error.numberFlight.incorrect-flight-number',
  },
  numberTeam: {
    '422-10': 'ftp.error.numberTeam.incorrect-team-number',
  },
  cameraModel: {
    '422-11': 'ftp.error.cameraModel.incorrect-camera-model',
  },
  focus: {
    '422-12': 'ftp.error.focus.incorrect-focal-length',
  },
} as const;

/**
 * Преобразовываем ключи формы к ключам ошибок FTP-запроса
 *
 * Необходимо для получения ошибок из ответа сервера по ключам формы
 * @example userLogin -> userlogin
 */
export const FormErrorKeys: Readonly<Record<keyof FormValues, FtpErrorKeys>> = {
  userLogin: 'userlogin',
  viewShot: 'viewshot',
  numberRoute: 'numberroute',
  numberFlight: 'numberflight',
  numberTeam: 'numberteam',
  cameraModel: 'cameramodel',
  focus: 'focus',
  file: 'inputdir',
} as const;

export const getStatus = (status: number | undefined): FormType => {
  return isUploadToFtpCouldBeStarted(status)
    ? FormType.DataUpload
    : FormType.Disabled;
};
