import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { AssetCardProps } from '@/widgets';
import { Asset, Assets, AssetTypes, VideoStreamingAssetsService } from '@/entities/assets/model';
import { Button, Tooltip, VideoCameraIcon } from '@workspace/4Z1.uikit.react';
import { dep, diInject } from '@/HOC';
import { DiKeys } from '@/shared/di/global';

interface Props extends AssetCardProps {
  readonly streams?: VideoStreamingAssetsService;
  readonly clickOnAssetIcon?: (asset: Asset) => void;
}

const ShowVideoStream: FC<Props> = ({asset, streams, clickOnAssetIcon}) => {
  const intl = useIntl();

  const canShowVideoStream = Boolean(streams?.getStreamByPublicId(asset.publicAssetId));

  if (!Assets.withVideoStream(asset)) {
    return <></>
  }

  return (
    Assets.withVideoStream(asset) && canShowVideoStream &&
    <Tooltip className='assetCardTooltip' title={
      asset.assetType === AssetTypes.Uav
        ? intl.formatMessage({id: 'online.assetCard.showVideoStreamFromGCS'})
        : intl.formatMessage({id: 'online.assetCard.showVideoStream'})
    }>
      <Button
        type={'badge'}
        size={'small'}
        fullWidth={true}
        icon={<VideoCameraIcon.Small/>}
        onlyIcon={true}
        className='fullWidth'
        onClick={() => clickOnAssetIcon?.(asset)}/>
    </Tooltip>
  )
}

export default diInject(ShowVideoStream, {
  streams: dep(VideoStreamingAssetsService),
  clickOnAssetIcon: dep(DiKeys.showVideoStream),
});
