import { type FC } from 'react';
import { getOlMap, implicitMapCheck, MapPluginProps } from '@/shared/map';
import { useCookies } from 'react-cookie';
import { useAccess } from 'umi';
import { useVisibleLayer } from '@/shared/ui/ol_map/useVisibleLayer';

import T_VPRF from '@/components/Map/Layers/VPRF';

export const AirSpaceLayer: FC<MapPluginProps> = ({map = implicitMapCheck()}) => {
  const access = useAccess();
  const [cookies] = useCookies(['layers', 'reprojection']);
  const { setVisibleLayer } = useVisibleLayer();

  return access['vprf'] && <T_VPRF map={getOlMap(map)} initTiles={cookies.layers} onSetVisible={setVisibleLayer} />
}
