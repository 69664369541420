import { type FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { implicitMapCheck, MapPluginProps } from '@/shared/map';
import { ClusterMenuStore, DropdownItem } from '../model/clusterMenu.store';
import '@/demo.scss';
import { List } from 'antd';
import { Link } from '@umijs/max';

export const ClusterMenuOverlay: FC<MapPluginProps> = observer(
  ({ map = implicitMapCheck() }) => {
    const menuElem = useRef<HTMLDivElement>(null);
    const [store] = useState(() => new ClusterMenuStore(map));

    useEffect(() => {
      if (menuElem.current) {
        store.overlay.setElement(menuElem.current);
        store.mount();
      }

      return () => store.unmount();
    }, [store]);

    return (
      <div id="popup" className="ol-popup" ref={menuElem}>
        <div id="popup-content">
          {store.dropdownItems && (
            <List
              dataSource={store.dropdownItems}
              renderItem={(item: DropdownItem) => (
                <List.Item>
                  <Link target={item.openInNewTab ? '_blank' : '_self'} to={item.link}>
                    {item.name}
                  </Link>
                </List.Item>
              )}
            />
          )}
        </div>
      </div>
    );
  },
);
