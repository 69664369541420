import { makeAutoObservable } from 'mobx';
import { injectable } from 'inversify';
import { UploadHistoryFile } from './interfaces';
import { createUploadHistoryFileApi, type UploadHistoryApi } from '../api/uploadHistoryFile.api';

@injectable()
export class UploadHistoryService {
  public static readonly diKey = Symbol.for('UploadHistoryService');

  constructor(private readonly api: UploadHistoryApi = createUploadHistoryFileApi()) {
    makeAutoObservable(this);
  }

  public async fetchFilesByFlightId(flightId: string): Promise<readonly UploadHistoryFile[]> {
    return this.api.fetchFiles(flightId);
  }
}
