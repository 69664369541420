export default {
  'gaz.title': 'Газоанализатор',
  'gaz.name': 'Газ',
  'gas.noPreviousUploadFound': 'Телеметрия газоанализатора ещё не загружена. Перетащите сюда TLM-файл или нажмите на кнопку',
  'gaz.radius size': 'Размер',
  'gaz.enableThermalMap': 'Включить тепловую карту',
  'gaz.enableNumericDisplay': 'Включить числовое отображение',
  'gaz.blur size': 'Сглаживание',
  'gaz.threshold': 'Фильтр',
  'gaz.All_gazes': 'Все за период',
  'gaz.Cluster': 'Числовая',
  'gaz.Heatmap': 'Тепловая',
};
