import { type FC } from 'react';
import { useCookies } from 'react-cookie';
import { getOlMap, implicitMapCheck, type MapPluginProps } from '@/shared/map';
import { SK42Grid } from '@/components/Map/Layers/sk42grid';

export const SK42GridLayer: FC<MapPluginProps> = ({map = implicitMapCheck()}) => {
  const [cookies] = useCookies(['layers', 'reprojection']);

  return cookies.reprojection == 'pulkovo1942' && <SK42Grid map={getOlMap(map)} />
}
