import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { AssetCardProps } from '@/widgets';
import { Text } from "@workspace/4Z1.uikit.react";

/**
 * Проект объекта в AssetCard
 * @param asset
 */
export const Project: FC<AssetCardProps> = ({asset}) => {
  const intl = useIntl();

  if (asset.project === undefined) {
    return <></>;
  }

  return (
      <Text.Ellipsis>
        {asset.project} <br/>
        {intl.formatMessage({id: 'online.assetCard.hardwareID'})}: {asset.publicAssetId}
      </Text.Ellipsis>
  )
}
