import { GraphQlApi, REQUEST_POLICY_NETWORK } from '@/shared/api/graphql';
import { Flight_FtpDocument, SentToFtpDocument } from '@/.graphql/graphql';
import {
  FlightResponse,
  FlightStatusResponse,
  CreatedFileResponse,
  SendFtpRequestParams,
} from '../model/interfaces';
import urlJoin from 'url-join';
import { createRestApi } from '@/shared/api/rest';

interface FtpFlightData {
  readonly flight_ftp: FlightResponse;
}

export interface FtpApi {
  flightFtp(flightId: string): Promise<FtpFlightData>;
  sendFtp(params: SendFtpRequestParams): Promise<FlightStatusResponse>;
  createTml(params: SendFtpRequestParams): Promise<CreatedFileResponse>;
}

export class FtpApi implements FtpApi {
  constructor(
    private readonly clientGraphQl = new GraphQlApi(),
    private readonly clientRestApi = createRestApi(true),
  ) {}

  public async flightFtp(flightId: string): Promise<FtpFlightData> {
    return this.clientGraphQl.query<FtpFlightData>(
      Flight_FtpDocument,
      {
        id: flightId,
      },
      REQUEST_POLICY_NETWORK,
    );
  }

  public async sendFtp(
    params: SendFtpRequestParams,
  ): Promise<FlightStatusResponse> {
    return this.clientGraphQl.mutation<FlightStatusResponse>(
      SentToFtpDocument,
      params,
      REQUEST_POLICY_NETWORK,
    );
  }

  public async createTml(
    params: SendFtpRequestParams,
  ): Promise<CreatedFileResponse> {
    const formData = new FormData();
    if (params.file) {
      params.file.fileList.forEach((file) =>
        formData.append('file', file.originFileObj),
      );
    }

    Object.keys(params).map(
      (key) =>
        key !== 'file' &&
        formData.append(key, params[key as keyof SendFtpRequestParams]),
    );

    return this.clientRestApi.post<CreatedFileResponse>(
      urlJoin([API, '/flight/create-tlm']),
      formData,
    );
  }
}

export const createFtpApi = (): FtpApi => {
  return new FtpApi();
};
