import './index.css';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { CloseSquareOutlined } from '@ant-design/icons';

import { GroupLayerOptions } from 'ol-layerswitcher';
import LayerGroup from 'ol/layer/Group';
import { Feature, Map, Overlay } from 'ol';

import TRAFFIC_AIR from './air';
import { fromLonLat } from 'ol/proj';

/**
 * @deprecated Новый слой  расположен в @/features/map/layers/TrafficLayer/ui/TrafficLayer.tsx
 */
class T_Traffic extends Component<any, any> {
  map: Map = this.props.map;
  minZoom  = 1;
  maxZoom  = 21;
  group_traffic = new LayerGroup();

  urlApi = TRAFFIC;
  //urlTiles = '?tiles=1:1:3,2:1:3,1:2:3,2:2:3,0:0:3,1:0:3,2:0:3,3:0:3,0:1:3,3:1:3,0:2:3,3:2:3,0:3:3,1:3:3,2:3:3,3:3:3,4:2:3,5:2:3,6:2:3,4:1:3,5:1:3,6:1:3,7:1:3,7:2:3,4:3:3,5:3:3,6:3:3,7:3:3';
  //urlInfo  = '/info/zone?index';

  popup: any = React.createRef();
  overlay    = new Overlay({
    id: 'traffic_overlay',
    autoPan: {
      animation: {
        duration: 250
      }
    }
  });

  constructor(props: any) {
    super(props);

    this.state = {
      coord: [0,0],
      feature: new Feature(),
    };

    const { intl } = this.props;

    this.group_traffic = new LayerGroup({
      title: intl.formatMessage({
        id: 'layer.traffic',
        defaultMessage: 'Traffic',
      }),
      fold: 'close',
      name: 'traffic',
      className: 'traffic',
      layers: [],
    } as GroupLayerOptions);
  }

  componentDidMount() {
    this.overlay.setElement(this.popup.current);
    this.props.map.addLayer(this.group_traffic);
    this.props.map.addOverlay(this.overlay);

    this.map.on('click', (e) => {
      let coord = undefined;
      this.map.forEachFeatureAtPixel(e.pixel, (feature: any) => {
        if (feature && feature.get('info') && feature.get('typeClick') == 'traffic'){
          const info = feature.get('info');
          coord = fromLonLat([info.Position.Longitude, info.Position.Latitude]);
          this.setState({feature: feature});
        }
      });

      this.overlay.setPosition(coord);
    });

  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
    this.props.map.removeLayer(this.group_traffic);
    this.props.map.removeOverlay(this.overlay);
  }

  setHtml = (html: string) => {
    //this.overlay.setPosition(this.state.coord);
    const content = document.getElementById('traffic_popup-content');
    if (content) content.innerHTML = html;
  };

  addLayer = (layer: any) => {
    const layers = this.group_traffic.getLayers();
    layers.push(layer);
    this.group_traffic.setLayers(layers);
  }

  popupClose = () => {
    this.overlay.setPosition(undefined);
    return false;
  };

  render() {

    return (
      <>

        <TRAFFIC_AIR map={this.map} urlApi={this.urlApi} minZoom={this.minZoom} maxZoom={this.maxZoom}
          feature={this.state.feature}
          setHtml={this.setHtml}
          addLayer={this.addLayer}
        />

        <div id="traffic_popup" className="ol-popup" ref={this.popup} >
          <div className="ol-popup-closer">
            <a onClick={() => this.popupClose()} id="popup-closer"><CloseSquareOutlined /></a>
          </div>
          <div id="traffic_popup-content"></div>
        </div>

      </>

    );
  }
}

export default injectIntl(T_Traffic);
