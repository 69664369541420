import { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'umi';
import { Modal, Form, Input, Divider, message, Button, Slider} from 'antd';
import { useCookies } from 'react-cookie';
import { useMarkCreateMutation, useMarkUpdateMutation, useMarkDeleteMutation } from '@/.graphql/graphql';
import { transformCoordFormat, transformCoordToArray, transformCoord_4326 } from '../transformCoordinates';
import { dataMarkTypes } from '@/components/MarkTypes';
import { TypeMark } from '../Layers/Mark/mark';

import { useMarkQuery } from '@/.graphql/graphql';
import { useStore } from '@/shared/store/context';

const MarkModal = (props: any) => {
  const { modalOpen, coord, markID, deleteMark } = props;
  const { ether } = useStore();
  const [cookies] = useCookies(['reprojection', 'blank']);
  const intl = useIntl();
  const [form] = Form.useForm();
  const [createResult, CreateMark] = useMarkCreateMutation();
  const [updateResult, UpdateMark] = useMarkUpdateMutation();
  const [deleteResult, DeleteMark] = useMarkDeleteMutation();

  const mark_types = dataMarkTypes();
  const [queryMark] = useMarkQuery({ variables: { id: markID }, pause: markID === undefined });

  const defaultTitle: string = ` (${coord})`;

  const [loading, setLoading] = useState(false);
  const [markTitle, setMarkTitle] = useState(defaultTitle);


  const modalCancel = () => {
    props.onModalChange({flag: false, markID: undefined});
    setMarkTitle(defaultTitle);
    form.resetFields();
  };

  const modalOK = async () => {
    const row: TypeMark = (await form.validateFields());
    row.mark_type_id = mark_types[0].id;

    setLoading(true);
    if (coord && !markID) {
      row.coord = JSON.stringify(transformCoord_4326(transformCoordToArray(coord), cookies.reprojection));
      CreateMark(row);
    }
    if (coord && markID && !deleteMark) {
      row.id = markID;
      UpdateMark(row);
    }
    if (markID && deleteMark) {
      DeleteMark({id: markID});
    }
  };

  function refreshMutation() {
    setLoading(false);
    setMarkTitle(defaultTitle);
    form.resetFields();
    props.onModalChange({flag: false, markID: undefined, deleteMark: false});

    ether.refreshMarx();
  }

  useEffect(() => {
    if (queryMark.data?.mark?.id) {
      form.setFieldsValue({
        name: queryMark.data.mark.name,
        mark_type_id: mark_types[0].id,//selected,
        color_radius: queryMark.data.mark.color_radius,
        radius: queryMark.data.mark.radius || 0
      });
      const _coord = transformCoordFormat(queryMark.data.mark.center.coordinates, cookies.reprojection);
      setMarkTitle(` (${_coord}) - ${queryMark.data.mark.name}`);
    }
  }, [queryMark.data]);

  useEffect(() => {
    if (createResult.data?.mark.create) {
      message.success(intl.formatMessage({id: 'mark.context.create.success'}));
      refreshMutation();
    }
  }, [createResult]);

  useEffect(() => {
    if (updateResult.data?.mark.update) {
      message.success(intl.formatMessage({id: 'mark.context.update.success'}));
      refreshMutation();
    }
  }, [updateResult]);

  useEffect(() => {
    if (deleteResult.data?.mark.delete === true || deleteResult.data?.mark.delete === false) {
      message.success(intl.formatMessage({id: 'mark.context.delete.success'}));
      refreshMutation();
    }
  }, [deleteResult]);

  useEffect(() => {
    if (coord) {
      setMarkTitle(defaultTitle);
    }
  }, [coord]);

  useEffect(() => {
    return () => {
    };
  }, []);

  return (
    <Modal
      centered
      title={intl.formatMessage({id: 'mark.Title'}) + markTitle}
      width={600}
      open={modalOpen}
      onOk={modalOK}
      onCancel={modalCancel}
      footer={[
        <Button key="back" onClick={modalCancel}>
          <FormattedMessage id="mark.context.button.Cancel" />
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={modalOK}>
          {markID
          ? (deleteMark
            ? <FormattedMessage id="mark.context.button.DELETE" />
            : <FormattedMessage id="mark.context.button.EDIT" />
            )
          : <FormattedMessage id="mark.context.button.OK" />
          }
        </Button>,
      ]}
    >
      {
        deleteMark
        ? <FormattedMessage id="mark.context.delete.confirm" />
        : <Form
            form={form}
            name="new_mark"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={{
              name: '',
              mark_type_id: '1',
              color_radius: '#ffffff',
              radius: 0
            }}
          >

            <Form.Item
              name="name"
              label={<FormattedMessage id="mark.name" />}
              rules={[{ required: true, message: <FormattedMessage id="mark.context.create.name.required" /> }]}
            >
              <Input />
            </Form.Item>
            <Divider />

            <Form.Item
              name="radius"
              label={<FormattedMessage id="mark.radius" />}
            >
              <Slider min={0} max={100} />
            </Form.Item>
            <Divider />

            <Form.Item
              name="color_radius"
              label={<FormattedMessage id="mark.color_radius" />}
            >
              <Input type="color" />
            </Form.Item>

          </Form>
      }


    </Modal>
  );
};

export default MarkModal;
