import { useAppContext } from '@/shared/store/context';
import { Coordinates, Location } from '@workspace/4Z1.ts.utils';

/**
 * @deprecated
 * Временный костыль, так как на беке сейчас хранится обычный массив, без указания lat, lon.
 * */
export const getBaseCenter = (): Coordinates => {
  const { appContext } = useAppContext();
  const { center } = appContext.getCurrentOptions();
  if (center) {
    // Чтобы не сломать поведение карты у пользователей используем toReversed.
    // Когда данные о координатах будут сохраняться в формате lat lon, метод нужно будет переделать
    const coordinates = Location.parse(center.toReversed());

    if (coordinates !== undefined) {
      return coordinates;
    }
  }

  return { lon: 0, lat: 0 };
}
