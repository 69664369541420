import { LicenseStatuses } from '@/entities/userOptions/interface';
import { LocalStorageKeys } from '@/shared/model';
import { getLocalStorageItems } from '@/shared/utils/localStorage';
import { License } from '@workspace/4Z1.uikit.react';
import { isNil } from 'lodash';
import { FC } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'react-intl';

const LicenseStates: Readonly<Record<number, string>> = {
  [LicenseStatuses.Active]: 'def',
  [LicenseStatuses.Inactive]: 'critical',
  [LicenseStatuses.Deleted]: 'critical',
  [LicenseStatuses.Invalid]: 'critical',
  [LicenseStatuses.Empty]: 'critical',
} as const;

export const AppLicense: FC = () => {
  const intl = useIntl();
  const [cookies] = useCookies(['show_license']);
  const user = getLocalStorageItems(LocalStorageKeys.CurrentUser);

  if (
    isNil(cookies.show_license) ||
    isNil(user?.version) ||
    isNil(user?.license?.status)
  ) {
    return;
  }

  return (
    <License
      state={LicenseStates[user.license.status]}
      label={intl.formatMessage(
        { id: 'license.active' },
        { version: user.version, message: user?.license?.message },
      )}
    />
  );
};
