import { resolveUrl } from '@workspace/4Z1.ts.utils';
import { defaultTo } from 'lodash';

const baseOrigin: string = defaultTo(DOMAIN_DEV, window.location.origin);

/**
 * Объединяет переданный URL с базовым доменом, используя функцию `resolveUrl` для формирования абсолютного URL.
 * Если передан относительный URL, он будет преобразован в абсолютный на основе `baseUrl`.
 * Если URL уже абсолютный, возвращается он как есть.
 *
 * @param {string} url - Входной URL, который может быть как абсолютным, так и относительным.
 * @param {string} [baseUrl=baseOrigin] - Базовый URL (по умолчанию используется путь текущей страницы, либо переменная DOMAIN_DEV).
 * @returns {string | undefined} - Абсолютный URL или `undefined`, если входные данные некорректны.
 */
export const resolveLinkWithDomain = (url: string, baseUrl: string = baseOrigin): string => {
  return resolveUrl(baseUrl, url) ?? url;
};
