import { Coordinate, format } from 'ol/coordinate';
import { Projection, addProjection, addCoordinateTransforms } from 'ol/proj';
import proj4 from 'proj4';

const PULKOVO_1942_GEODETIC_CRS_DEFINITION = '+proj=longlat +ellps=krass +towgs84=23.57,-140.95,-79.8,0.0,0.35,0.79,-0.22 +no_defs +type=crs';
const WebMercator_CRS_DEFINITION = '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs';

export const format_1942 = (coord: any) => {
  return format(coord, 'x: {y}, y: {x}')
}

export const projection_1942_3857 = new Projection({
  code: 'pulkovo1942_3857',
  units: 'm'
});
addProjection(projection_1942_3857);

addCoordinateTransforms(
  'EPSG:3857',
  projection_1942_3857,
  (coordinate: Coordinate) => {
    return WMToSk42(coordinate);
  },
  (coordinate: Coordinate) => {
    return sk42ToWM(coordinate);
  }
);

const getWgs84GeoZone = (x: number): number => {
  return Math.floor(x * 0.000001);
}

const getPulkovo1942GeoZone = (coordinate: Coordinate): number => {
  const dst = proj4<Coordinate>(WebMercator_CRS_DEFINITION, PULKOVO_1942_GEODETIC_CRS_DEFINITION, coordinate);
  return Math.floor((6.0 + dst[0]) / 6);
}

const getPulkovo1942CRSDefinition = (zone: number): string => {
  return `+proj=tmerc +lat_0=0 +lon_0=${zone * 6 - 3} +k=1 +x_0=${zone}500000 +y_0=0 +ellps=krass +towgs84=23.57,-140.95,-79.8,0.0,0.35,0.79,-0.22 +no_defs +type=crs`;
}

// [x, y] -> {x: lon, y: lat}
export const sk42ToWM = (coordinate: number[]): Coordinate => {
  const zone = getWgs84GeoZone(coordinate[0]);
  const dst = getPulkovo1942CRSDefinition(zone);
  return proj4<Coordinate>(dst, WebMercator_CRS_DEFINITION, coordinate);
}

// [lon, lat] -> {x, y}
export const WMToSk42 = (coordinate: number[], fixedZone: number | null = null): Coordinate => {
  const zone = fixedZone ?? getPulkovo1942GeoZone(coordinate);
  const dst = getPulkovo1942CRSDefinition(zone);
  return proj4<Coordinate>(WebMercator_CRS_DEFINITION, dst, coordinate);
}
