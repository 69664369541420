export default {
  'gaz.title': 'Gas analyzer',
  'gaz.name': 'Gas',
  'gas.noPreviousUploadFound': 'The telemetry of the gas analyzer is not loaded yet. Drag and drop the TLM file here or click on the button',
  'gaz.radius size': 'radius size',
  'gaz.enableThermalMap': 'Enable thermal map',
  'gaz.enableNumericDisplay': 'enable numeric display',
  'gaz.blur size': 'blur size',
  'gaz.threshold': 'threshold',
  'gaz.All_gazes': 'All for the period',
  'gaz.Cluster': 'Cluster',
  'gaz.Heatmap': 'Heatmap',
};
