import { Location } from "@/shared/model";
import { v4 as generateId } from 'uuid';

export enum MapMarkersTypes {
  Default = 'default',
}

export interface MapMarker {
  readonly location: Location;
  readonly type: MapMarkersTypes;
  readonly layerId: string;
  readonly id: string;
}

export function createMarkerWithRandomId(marker: Omit<MapMarker, 'id'>): MapMarker {
  return {
    ...marker,
    id: generateId(),
  }
}
