import { Asset } from '@/entities/assets/model';
import { GraphQlApi, REQUEST_POLICY_NETWORK } from '@/shared/api/graphql';
import { AssetStateTimeRangeDocument } from '@/.graphql/graphql';
import moment from 'moment';
import { Coordinates } from '@workspace/4Z1.ts.utils';
import { TrackPoint } from '../model/types';

export interface AssetTrackApi {
  getTrackForLastHours(asset: Asset, lastHours: number): Promise<readonly TrackPoint[]>;
}


export const createAssetTrackApi = () => {
  return new AssetTrackApiGraphQl();
};


class AssetTrackApiGraphQl implements AssetTrackApi {

  constructor(
    private readonly client = new GraphQlApi(),
  ) { }

  getTrackForLastHours(asset: Asset, lastHours: number): Promise<readonly TrackPoint[]> {

    const vars = {
      publicAssetId: asset.publicAssetId,
      from: GraphQlApi.timestampToApi(moment().subtract(lastHours, 'hours').toDate().getTime()),
      // раньше было 3608802899 - видимо какой-то далекий момент
      // переделал на час вперед
      to: GraphQlApi.timestampToApi(moment().add(1, 'hours').toDate().getTime()),
    };
    return this.client
      .query(AssetStateTimeRangeDocument, vars, REQUEST_POLICY_NETWORK)
      .then(response => response.assetStateTimeRange.map(item => {
        return {
          timestamp: GraphQlApi.timestampFromApi(item.timestamp),
          date: item,
          location: item.location as Coordinates, // хм, тут парсить не надо - и так подходит. но возможно стоит обработать наличие
        };
      }));
  }

}
