import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import LayerGroup from 'ol/layer/Group';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';

import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';

import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import {get as getProjection} from 'ol/proj';
import {getTopLeft, getWidth} from 'ol/extent';
import {getRenderPixel} from 'ol/render.js';

enum ExtentPaddingSizes {
  Top = 50,
  Right = 0,
  Bottom = 0,
  Left = 0,
}

const EXTENT_PADDINGS: readonly ExtentPaddingSizes[] = [
  ExtentPaddingSizes.Top,
  ExtentPaddingSizes.Right,
  ExtentPaddingSizes.Bottom,
  ExtentPaddingSizes.Left,
] as const;

/**
 * @deprecated
 * 
 * Использовать в новых компонентах - запрещено, вместо этого используй features/map/ortophotoLayer
 * 
 * Компонент подлежит рефакторингу, а именно:
 *  1. Необходимо избавиться от any
 *  2. Перенести слой в features/map
 *  3. Избавиться от магических чисел
 */
export const OFP = (props: any) => {
  const { map, data, map2, data_compare, cdreport, swipe } = props;
  const [cookies] = useCookies(['hold_position']);

  useEffect(() => {
    if (data) {
      const ofp = setOfp(data);
      if (ofp) {
        var group_ofp = new LayerGroup({
          title: data.name,
          name: 'ofp',
          className: 'ofp',
          layers: [ofp],
        } as GroupLayerOptions);

        const geometry =
          cdreport && cdreport.polygon
            ? cdreport.polygon.geom
            : data.polygon.geom;
        const epsg =
          cdreport && cdreport.polygon
            ? cdreport.polygon.epsg
            : data.polygon.epsg;
        const polygon = new VectorSource({
          features: new GeoJSON().readFeatures(geometry, {
            dataProjection: epsg,
            featureProjection: 'EPSG:3857',
          }),
        });
        const extent = polygon.getExtent();

        map.addLayer(group_ofp);
        map.getView().setMinZoom(0);

        if(!cookies.hold_position || !props.holdposition) {
          setTimeout(() => {
            map.getView().fit(extent, { padding: [...EXTENT_PADDINGS] });
          })
        }

        if (data_compare) {
          const ofp_compare = setOfp(data_compare);

          var group_ofp_compare = new LayerGroup({
            title: data_compare.name,
            name: 'ofp_compare',
            className: 'ofp_compare',
            layers: [ofp_compare],
          } as GroupLayerOptions);

          if (ofp_compare && swipe) 
          {
            map.addLayer(group_ofp_compare);
            map.getView().setMinZoom(0);
            
            ofp_compare.on('prerender', function (event: any) {
              const ctx = event.context;
              const mapSize = map.getSize();
              const width = mapSize[0] * (swipe.value / 100);
              const tl = getRenderPixel(event, [width, 0]);
              const tr = getRenderPixel(event, [mapSize[0], 0]);
              const bl = getRenderPixel(event, [width, mapSize[1]]);
              const br = getRenderPixel(event, mapSize);
            
              ctx.save();
              ctx.beginPath();
              ctx.moveTo(tl[0], tl[1]);
              ctx.lineTo(bl[0], bl[1]);
              ctx.lineTo(br[0], br[1]);
              ctx.lineTo(tr[0], tr[1]);
              ctx.closePath();
              ctx.clip();
            });
            
            ofp_compare.on('postrender', function (event: any) {
              const ctx = event.context;
              ctx.restore();
            });
            
            swipe.addEventListener('input', function () {
              map.render();
            });
          }else{
            map2.addLayer(group_ofp_compare);
            map2.getView().setMinZoom(0);
          }
        }
      }
    }

    return () => {
      map
        .getLayers()
        .getArray()
        .map((layer: any) => {
          if (layer.className_ == 'ofp') map.removeLayer(layer);
        });
      if (data_compare && map2) {
        map2
          .getLayers()
          .getArray()
          .map((layer: any) => {
            if (layer.className_ == 'ofp_compare') map2.removeLayer(layer);
          });
      }
    };
  }, [data, data_compare, cdreport]);

  function setOfp(data: any){
    var ofp;
    const name = 'o-'+data.id;
    const projection = getProjection('EPSG:3857');
    if (data.url) {
      ofp = new TileLayer({
        name: name,
        visible: true,
        zIndex: 1,
        source: new XYZ({
          url: data.url,
        }),
      } as BaseLayerOptions);
    }else if (data.geoserver && projection) {
      const geoserverParams = JSON.parse(data.geoserver);
      const projectionExtent = projection.getExtent();
      const size = getWidth(projectionExtent) / 256;
      const resolutions = new Array(21);
      const matrixIds = new Array(21);
      for (let z = 0; z < 21; ++z) {
        // generate resolutions and matrixIds arrays for this WMTS
        resolutions[z] = size / Math.pow(2, z);
        matrixIds[z] = z;
      }

      ofp = new TileLayer({
        name: name,
        visible: true,
        zIndex: 1,
        source: new WMTS({
          url: geoserverParams.url,
          layer: geoserverParams.layer,
          matrixSet: 'WebMercatorQuad',
          format: 'image/png',
          projection: projection,
          tileGrid: new WMTSTileGrid({
            origin: getTopLeft(projectionExtent),
            resolutions: resolutions,
            matrixIds: matrixIds,
          }),
          style: 'raster',
          wrapX: true,
        }),
      } as BaseLayerOptions);
    }

    return ofp;
  }

  return <></>;
};
