import { makeAutoObservable } from 'mobx';
import { PtzActions } from '../model/interfaces';
import { PtzActionsApi } from '../api/ptzActions.api';
import { prepareCommand } from '../model/prepareCommand';
import { logger } from '@workspace/4Z1.ts.utils';

const  WEBSOCKET_COMMAND = 'SubscribeToDeviceState';
const WEBSOCKET_HANDLER = 'ptzcamerastate';
const log = logger('SOVA_ACTIONS');

interface PointCameraParams {
    /** Относительное смещение направления поворота камеры в процентах */
    readonly x: number;
    readonly y: number;
}

export class PtzActionsStore {

  private listenerCount= 0;

  constructor(
    private _api: PtzActionsApi = new PtzActionsApi()
  ) {
    makeAutoObservable(this);
  }

  connect(id: string): void {
    this.listenerCount++;
    this._api.connect(this.callbackAfterConnected.bind(this, id));
  }

  subscribe(command: string, id: string) {
    this._api.subscribe(command, id);
  }

  disconnect(): void {
    this.listenerCount--;
    if (this.listenerCount === 0) {
      this._api.disconnect();
      this._api.off(WEBSOCKET_HANDLER);
    }
  }

  sendCommand(Uuid: string, command: PtzActions) {
    const commandParams = prepareCommand(Uuid, command);
    log.debug('Отправили команду: ', commandParams);
    this._api.sendCommand(commandParams);
  }

  pointCamera(deviceId: string, params: PointCameraParams) {
    this._api.pointCamera(deviceId, params);
  }

  callbackAfterConnected(id: string) {
    this.subscribe(WEBSOCKET_COMMAND, id);
    this._api.on(WEBSOCKET_HANDLER, (message: string) => log.info('HANDLER' , message));
  }
}
