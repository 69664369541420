import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withCookies } from 'react-cookie';
import request from 'umi-request';

import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { BaseLayerOptions } from 'ol-layerswitcher';
import { Map, TileCache } from 'ol';
import { GeoJSON } from 'ol/format';
import { Fill, Stroke, Style } from 'ol/style';
import { Coordinate } from 'ol/coordinate';

class VPRF_CTRZ extends Component<any, any> {
  cache    = new TileCache();
  map: Map = this.props.map;
  layer    = new VectorLayer();
  source   = new VectorSource();
  tag = 'CTRZ';

  constructor(props: any) {
    super(props);

    this.state = {
      coord: [],
    };

    const { intl } = this.props;
    this.layer = new VectorLayer({
      name: 'zones',
      className: 'zones',
      title: intl.formatMessage({
        id: 'layer.vprf.zones',
        defaultMessage: 'Zones and areas',
      }),
      source: this.source,
      visible: this.props.allCookies.layers.zones ? true : false,
      zIndex: 2,
      minZoom: this.props.minZoom,
      maxZoom: this.props.maxZoom,
    } as BaseLayerOptions);
  }

  componentDidMount() {
    this.source.setLoader(this.loader);
    this.props.addLayer(this.layer);
  }

  componentDidUpdate() {
    if (this.props.feature.get('type') === this.tag && this.props.feature.get('coord') !== this.state.coord){
      const id    = this.props.feature.getId();
      const coord = this.props.feature.get('coord');

      this.showPopup(coord, id);

      this.setState({coord: coord});
    }
  }

  componentWillUnmount() {
    this.map.removeLayer(this.layer);
  }

  showPopup = (coord: number[], id: any) => {
    this.cache.containsKey(id)
      ? this.setHTML(coord, this.cache.get(id)) 
      : this.getInfo(coord, id);
  }

  getInfo = (coord: number[], id: any) => {
    const url = this.props.urlApi+this.props.urlInfo+`[${this.tag}]=`+id;
    request(url, {
      method: 'get',
      //headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.length) {
          this.cache.set(id, response[0]);
          this.setHTML(coord, response[0]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setHTML = (coord: number[], props: any) => {
    let html =
      `${props.index || ''} <b>${props.name}</b><br/><br/>
       Высота:<br/>
       ${props.alts?.str}: ${props.alts?.range[0]}м - ${props.alts?.range[1]}м<br/><br/>`;
  
    if (Array.isArray(props.freq)) {
      html += 'Частота: <br/>';
      props.freq.forEach((f: any) => html += `${f?.title}<br/>`);
    } else if (props.freq) {
      html += `Частота:<br/>${props.freq}<br/>`;
    }
  
    if (props.phone) {
      html += `<br/><b>Телефон:</b> ${props.phone}<br/>`;
    }
    
    this.props.setHtml(coord, html);
  }

  loader = () => {
    const url = this.props.urlApi+'/'+this.tag+this.props.urlTiles;
    request(url, {
      method: 'get',
      //headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        this.setSource(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setSource = (data: any) => {
    if (data.length)
    {
      data.map((row: any) => {
        if (row.path.length) {
          const geom: Coordinate[] = [];
          row.path[0].map((coors: number[]) => {
            geom.push([coors[1], coors[0]]); 
          });
          const GeoJson = {
            "type": "Polygon",
            "coordinates": [geom],
            "crs": {"type": "name","properties": {"name": "EPSG:4326"}}
          };
          const feature = new GeoJSON().readFeature(GeoJson, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          });
          feature.setId(row.id);
          feature.set('type', row.type);
          feature.set('typeClick', 'vprf');
          feature.setStyle(
          [
            new Style({
              fill: new Fill({
                color: 'transparent',
              }),
              stroke: new Stroke({
                width: 2,
                color: 'rgba(255,255,255,1)',
              }),
            }),
            new Style({
              fill: new Fill({
                color: 'transparent',
              }),
              stroke: new Stroke({
                width: 1,
                color: 'rgba(0,0,0,1)',
              }),
            })
          ]
          );
          
          this.source.addFeature(feature);
        }
      });
      this.layer.setSource(this.source);
    }
  }

  render() {
    return (
      <></>
    );
  }
}

export default injectIntl(withCookies(VPRF_CTRZ));
