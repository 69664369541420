import { makeAutoObservable } from 'mobx';

import { fromLonLat } from 'ol/proj';
import { Coordinate } from 'ol/coordinate';
import { Asset } from '@/entities/assets/model';
import { injectable } from 'inversify';

@injectable()
export class AssetDirectionStore {
  public static readonly diKey = Symbol.for('AssetDirectionStore');

  private _visible: boolean = false;
  private _asset: Asset | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  public get visible(): boolean {
    return this._visible;
  }

  public get asset(): Asset | undefined {
    return this._asset;
  }

  public showAssetCompass(asset: Asset): void {
    this._asset = asset;
    this._visible = true;
  }

  public closeCompass(): void {
    this._visible = false;
  }

  public get coordinates(): Coordinate | undefined {
    const location = this.asset?.state?.location;

    if (location?.lat === undefined || location?.lon === undefined) {
      this.closeCompass();
      return undefined;
    }

    const { lat, lon } = location;

    return fromLonLat([lon, lat]);
  }

  public get course(): number {
    return this.asset?.state?.location?.course;
  }
}
