import { PtzActions, type PtzActionsList } from './interfaces';
import {
  DownFilledIcon,
  LeftFilledIcon,
  RightFilledIcon,
  UpFilledIcon,
  ZoomInIcon,
  ZoomOutIcon
} from '@workspace/4Z1.uikit.react';

export const ptzActionsList: PtzActionsList[] = [
  {icon: <ZoomOutIcon.Small/>, type: PtzActions.ZoomOut},
  {icon: <ZoomInIcon.Small/>, type: PtzActions.ZoomIn},
  {icon: <LeftFilledIcon.Small/>, type: PtzActions.Left},
  {icon: <UpFilledIcon.Small/>, type: PtzActions.Up},
  {icon: <DownFilledIcon.Small/>, type: PtzActions.Down},
  {icon: <RightFilledIcon.Small/>, type: PtzActions.Right},
];
