import { WebsocketApi } from '@/shared/api/websocket';
import urlJoin from 'url-join';
import { isDev } from '@/utils/utils';
import { prepareBaseParams } from '../model/prepareCommand';

const IS_DEV = isDev();

// Если порт указан, конкатенируем порт с хостом, если нет, берем только хост
const PTZ_PATH = PTZ_WEBSOCKETS_PORT ? `${PTZ_WEBSOCKETS_HOST}:${PTZ_WEBSOCKETS_PORT}` : PTZ_WEBSOCKETS_HOST
const PTZ_CONNECTION_METHOD = '/ptzCameraControl';

enum PTZ_COMMANDS {
  Controls = 'SendCommand',
  CenterOnPoint = 'MoveToScreenPoint'
}

// Если порт и хост не указаны и запущен dev, то стучимся к тестовому беку.
const WS_PATH = (PTZ_PATH === '' || PTZ_PATH === ':') && IS_DEV ? 'https://owl.eds.loc:52001' : PTZ_PATH

interface PointCameraRequestParams {
    readonly x: number;
    readonly y: number;
}

export class PtzActionsApi {
  private _ws: WebsocketApi;

  constructor() {
    this._ws = new WebsocketApi(urlJoin(WS_PATH, PTZ_CONNECTION_METHOD));
  }

  connect(callbackAfterConnected: () => void): void {
    return this._ws.start(callbackAfterConnected);
  }

  disconnect(): void {
    return this._ws.stop();
  }

  subscribe(command: string, id: string): void {
    return this._ws.sendNoAnswer(command, id);
  }

  sendCommand(command: any): void {
    return this._ws.send(PTZ_COMMANDS.Controls, command);
  }

  pointCamera(deviceId: string, params: PointCameraRequestParams): void {
    const { x, y } = params;

    return this._ws.send(PTZ_COMMANDS.CenterOnPoint, {
      ...prepareBaseParams(deviceId),
      Point: { X: x, Y: y },
    });
  }

  on(method: string, callback: (message: any) => void) {
    this._ws.on(method, callback);
  }

  off(method: string) {
    this._ws.off(method);
  }
}
