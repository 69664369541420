import { FC, useCallback, useEffect } from 'react';
import { useIntl } from 'umi';
import LayerGroup from 'ol/layer/Group';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import { LineString, Point } from 'ol/geom';
import {
  implicitMapCheck,
  Layers,
  mapCoordinates,
  MapPluginProps,
  useLayersVisibility,
} from '@/shared/map';
import {
  getTrackLineStyle,
  getWaypointStyle,
  TrackSeekCallback,
  WaypointType,
} from '@/entities/assetTrack';
import { MapBrowserEvent } from 'ol';
import { compact, isEmpty, isNil } from 'lodash';
import { VideoAttachment } from '@/entities/attachment';
import { logger } from '@workspace/4Z1.ts.utils';
import { FeaturesType } from '@/entities/features';

interface Props extends MapPluginProps {
  // TODO - Добавить прокидывание стора?
  readonly trackVideos: readonly VideoAttachment[];
  readonly onVideoTrackSeek: TrackSeekCallback;
}

const log = logger('TRACK');

const layerNameForAttachment = (attachment: VideoAttachment): string =>
  `video-track-${attachment.id}`;

/**
 * Слой для отображения трека видео
 *
 * Новый слой для отображения трека видео для нового компонента карты.
 * Использовать вместо trackpoint_video
 *
 * @see Map/layers/trackpoint_video
 */
export const VideoTrackLayer: FC<Props> = ({
  map = implicitMapCheck(),
  trackVideos,
  onVideoTrackSeek,
}) => {
  const intl = useIntl();
  const [videosLayer] = useLayersVisibility([Layers.Videos]);

  const pointerMoveCallback = useCallback(
    (event: MapBrowserEvent<UIEvent>) => {
      const hasFeatureAtPixel = map.hasFeatureAtPixel(event.pixel);

      event.map.getViewport().style.cursor = hasFeatureAtPixel ? 'pointer' : '';
    },
    [map],
  );

  useEffect(() => {
    // TODO - Вынести в стор, когда будет готов DI для карты
    map.onPointerMove(pointerMoveCallback);

    return () => {
      map.offPointerMove(pointerMoveCallback);
    };
  }, [onVideoTrackSeek, pointerMoveCallback, map]);

  const _title = intl.formatMessage({
    id: 'module.video',
    defaultMessage: 'Video',
  });

  function setPoint(
    geom: number[],
    type: WaypointType.Start | WaypointType.End = WaypointType.Start,
  ) {
    const feature: Feature = new Feature({
      geometry: new Point(geom),
    });

    const text =
      type === WaypointType.Start
        ? {
            id: 'video.start',
            defaultMessage: 'Start',
          }
        : {
            id: 'video.end',
            defaultMessage: 'End',
          };

    feature.setStyle(getWaypointStyle(type));
    feature.set('name', intl.formatMessage(text));

    return feature;
  }

  const createVideoLayer = (video: VideoAttachment) => {
    const layerName = layerNameForAttachment(video);

    if (isEmpty(video.timing) || isNil(video.timing)) {
      log.error('Cannot create video layer for timings of unknown', video);

      return;
    }

    const geom = video.timing.map((item) => {
      const coordinates = mapCoordinates(item.droneCoordinates);

      if (isNil(coordinates)) {
        log.error(
          'Cannot render waypoint for coordinates of unknown',
          video,
          item,
        );
        return;
      }

      /**
       * Передаём координаты точки видео и передаем секунды (тайм-код точки на видео) в качестве третьего параметра
       *
       * Передача секунд необходима для удобного получения времени при клике по треку
       */
      return [...coordinates, item.sec];
    });

    const line: Feature = new Feature({
      geometry: new LineString(compact(geom), 'XYM'),
      id: video.id,
    });

    const trackGeometry = line.getGeometry();

    if (isNil(trackGeometry)) {
      log.error(
        'Cannot render video layer with an incorrect geometry',
        video,
        trackGeometry,
      );
      return;
    }

    line.set('attachment', video);
    line.set('name', video.name);
    line.set('singleTab', true);
    line.set('type', FeaturesType.TRACK);
    line.set('withDropdown', true);
    
    line.setStyle(getTrackLineStyle());

    const startPoint = setPoint(
      trackGeometry.getFirstCoordinate(),
      WaypointType.Start,
    );
    const endPoint = setPoint(
      trackGeometry.getLastCoordinate(),
      WaypointType.End,
    );

    const source = new VectorSource();

    source.addFeatures([line, startPoint, endPoint]);

    return new VectorLayer({
      source: source,
      visible: videosLayer,
      zIndex: 3,
      className: layerName,
      name: layerName,
    } as BaseLayerOptions);
  };

  useEffect(() => {
    if (!trackVideos.length) {
      map.removeLayerByName(Layers.Videos);

      return;
    }

    if (map.getLayerByName(Layers.Videos)) return;

    const videoTrackLayers = trackVideos.map((video) =>
      createVideoLayer(video),
    );

    const videoPointsGroup = new LayerGroup({
      title: _title,
      name: Layers.Videos,
      className: Layers.Videos,
      layers: videoTrackLayers,
    } as GroupLayerOptions);

    map.addLayer(videoPointsGroup);
  }, [trackVideos]);

  useEffect(() => {
    return () => {
      map.removeLayerByName(Layers.Videos);
    };
  }, []);

  return <></>;
};
