import { makeAutoObservable } from 'mobx';
import { DataSourceItem } from '@workspace/4Z1.uikit.react';
import { MapMarker } from '@/shared/map';
import { SearchResultItem } from '@/features/geosearch';
import { resultsMapping, searchMarkerMapping } from './dataMapping';

export class SearchPluginStore {
  private _searchMarker: MapMarker | undefined;
  private _results: SearchResultItem[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get searchMarker(): MapMarker | undefined {
    return this._searchMarker;
  }

  get results(): SearchResultItem[] {
    return this._results;
  }

  selectItem(item: DataSourceItem): void {
    this._searchMarker = searchMarkerMapping(item);
  }

  setResults(results: readonly SearchResultItem[]): void {
    this._results = resultsMapping(results);
  }

  reset(): void {
    this._searchMarker = undefined;
  }
}
