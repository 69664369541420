import { Component } from "react";

import KML from 'ol/format/KML';
import {Vector as VectorSource, Cluster} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
import {Style, Circle, Fill, Text, Stroke} from 'ol/style';
import {transformExtent} from 'ol/proj';

/** 
 * @deprecated
 * 
 * Компонент подлежит рефакторингу, а именно:
 *  1. Логику по отображению тултипов необходимо вынести в tooltipOverlay
 *  2. Избавиться от any
 *  3. Логику по вычислению размеров кластера изолировать и покрыть тестами
 *  4. Использовать декларативный подход при рефакторинге и переписать на функциональный компонент
 *  5. Должен быть вынесен в features/map
 * 
 * Использовать разрешается только для поддержки старого функционала
 */
class ClusterMap extends Component <any, any>
{
  cluster: any;
  source: any;
  mapDiv: any = document.getElementById('map');
  popup_container: any = document.getElementById('popup');
  popup_content: any = document.getElementById('popup-content');
  
  constructor(props: any) {
    super(props);

    this.state = {
    };

    this.source = new VectorSource({
      url: this.props.data.file,
      format: new KML({
        extractStyles: false
      })
    });
    this.props.map.listeners_.click = [];
    this.props.map.listeners_.pointermove = [];

    this.styleFunction = this.styleFunction.bind(this);

    this.cluster = new VectorLayer({
      source: new Cluster({
        distance: 30,
        source: this.source,
      }),
      zIndex: 5,
      style: this.styleFunction,
    });
  }

  styleFunction(feature: any, resolution: any) {
    let currentResolution, maxConcentration = 0;
    if (resolution != currentResolution) {
      maxConcentration = this.calculateClusterInfo();
      currentResolution = resolution;
    }
    let style;
    let concentration = feature.get('name');
    if(concentration > 100) {
      style = new Style({
        image: new Circle({
          radius: 20,
          fill: new Fill({
            color: [255, 153, 0, Math.min(0.8, 0.2 + concentration / maxConcentration)],
          }),
        }),
        text: new Text({
          text: concentration.toString(),
          fill: new Fill({
            color: '#fff',
          }),
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.6)',
            width: 3,
          }),
        }),
      });
    }
    
    return style;
  }

  calculateClusterInfo() {
    let maxConcentration = 0;
    const features = this.cluster.getSource().getFeatures();
    for (var i = features.length - 1; i >= 0; --i) {
      let feature = features[i];
      var originalFeatures = feature.get('features');
      let concentration = 0;
      let j, jj;
      for (j = 0, jj = originalFeatures.length; j < jj; ++j) {
        concentration = Math.max(originalFeatures[j].get('name'), concentration);
      }
      maxConcentration = Math.max(maxConcentration, concentration);
      feature.set('name', concentration);
    }

    return maxConcentration;
  }
 
  componentDidMount() {
    const shouldCenterOnRender = this.props.shouldCenterOnRender ?? true;

    this.props.map.addLayer(this.cluster);
    //this.props.map.getView().setCenter(fromLonLat(this.props.data.gaz_track.first_coord));
    shouldCenterOnRender && this.props.map.getView().fit(transformExtent(this.props.data.gaz_track.bbox, 'EPSG:4326', 'EPSG:3857'), {padding: [20,20,20,20]});

    this.props.map.on('pointermove', (e: any) => {
      let pixel = e.pixel;
		  let hit = this.props.map.hasFeatureAtPixel(pixel);
		  this.props.map.getTargetElement().style.cursor = hit ? 'pointer' : '';
    });

    this.props.map.on('singleclick', (e: any) => {
      let pixel = e.pixel;
      let hit = this.props.map.hasFeatureAtPixel(pixel);

      /**
       * Вынести определение тултипов в отдельный плагин tooltipOverlay.ts
      */
      if(hit && this.popup_content){
        let feature = this.props.map.forEachFeatureAtPixel(e.pixel, function(feature: any) { return feature; });
        if (feature && feature.get('name')) {
          var concentration = feature.get('name');        
          this.popup_content.innerHTML = 'Концентрация: ' + concentration + ' ppm×m';
          this.popup_container.style.display = "block";
          let overlay = this.props.map.getOverlayById('main_overlay');
          overlay.setPosition(e.coordinate);
        }
      }
    });
  }

  componentDidUpdate() {
    this.source.setUrl(this.props.data.file);
    this.cluster.getSource().getSource().refresh();
    //this.props.map.getView().setCenter(fromLonLat(this.props.data.gaz_track.first_coord));
    this.props.shouldCenterOnRender && this.props.map.getView().fit(transformExtent(this.props.data?.gaz_track?.bbox, 'EPSG:4326', 'EPSG:3857'), {padding: [20,20,20,20]});
  }
  
  componentWillUnmount() {
    this.props.map.removeLayer(this.cluster);
  }

  render() {

    return (
      <></>
    );
  }
}

export default ClusterMap;
