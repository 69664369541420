export default {
  'login.submit': 'Войти',
  'login.username.label': 'Логин',
  'login.username.required': 'Необходимо заполнить «Логин»',
  'login.password.label': 'Пароль',
  'login.password.required': 'Необходимо заполнить «Пароль»',
  'login.rememberMe': 'Запомнить меня',
  'login.username.password.error': 'Неверные логин или пароль',
  'login.username.password.success': 'Успешная авторизация',
  'login.accountLogin.errorMessage': 'Ошибка Login',
  'login.input.enter-login': 'Введите логин',
  'login.input.enter-password': 'Введите пароль',
}
