import './styles.scss';

import React, { ReactNode, useCallback, useEffect } from 'react';
import Dropzone from '@/components/Dropzone/Dropzone';
import { UploaderStore } from '../store/uploadFiles.store';
import { dep, diInject } from '@/HOC';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { MoveIcon } from '@workspace/4Z1.uikit.react';

interface Props {
  readonly uploaderStore: UploaderStore;
  readonly flightId?: string;
  readonly fileType?: string;
  readonly onLoadStart?: () => void;
  readonly icon: ReactNode;
  readonly title: string;
  readonly text?: string
  readonly disabled?: boolean;
  readonly bigIcon?: boolean;
  readonly updateInfo?: boolean;
  readonly disabledBgType?: boolean;
  readonly visibleAcceptText?: boolean;
  readonly type?: 'button' | 'text' | 'update';
  readonly accept: string[]
}

const UploadFilesFlight = ({ 
    uploaderStore, 
    flightId, 
    fileType, 
    onLoadStart,
    icon,
    title,
    text,
    accept,
    disabled,
    bigIcon = false,
    updateInfo,
    disabledBgType = false,
    visibleAcceptText = false,
    type = 'button',
  }: Props) => {
  const intl = useIntl();

  const handleFilesChange = useCallback((incomingFiles: File[]) => {
    if (incomingFiles.length === 0) {
      return;
    }
    uploaderStore.onFilesSelected(incomingFiles, flightId, fileType);
  }, [uploaderStore]);

  useEffect(() => {
    uploaderStore.mount();

    return () => {
      uploaderStore.unmount();
    };
  }, []);

  useEffect(()=>{
    if(uploaderStore.uploadsTotalPercentage !== 0){
      onLoadStart?.();
    }
  },[uploaderStore.uploadsTotalPercentage])

  return (
    <div className={classNames('uploadFilesFlightContainer', {'absoluteDropzoneContainer':updateInfo})}>
      <Dropzone
        type={type}
        icon={
          <div className={classNames('iconDropzoneFlight', {'bigIcon': bigIcon}, {'absoluteDropzone':updateInfo})}>
            {type === 'update' ? <MoveIcon.Large/> : icon}
          </div>
        }
        title={intl.formatMessage({ id: title })}
        text={text && intl.formatMessage({ id: text })}
        onChange={handleFilesChange}
        accept={accept}
        directory={false}
        className='dropzoneFlight'
        disabledBgType={updateInfo ? 'opacity' : 'transparent'}
        disabled={disabled || uploaderStore.uploadsTotalPercentage !== 0}
        buttonType='accent'
        visibleAcceptText={visibleAcceptText}
        buttonText={intl.formatMessage({ id:'button.CreateUpload' })}
      />
    </div>
  );
};

export default diInject(UploadFilesFlight, {
  uploaderStore: dep(UploaderStore),
});