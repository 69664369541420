import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'umi';
import LayerGroup from 'ol/layer/Group';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import Feature from 'ol/Feature';
import { fromLonLat } from 'ol/proj';
import { Style, Stroke, Circle, Fill, Icon, RegularShape } from 'ol/style';
import { Geometry, LineString, Point } from 'ol/geom';

/** 
 * @deprecated - Старый компонент отображения трека полёта по видео.
 * Используется для поддержки функционала старой страницы видео, в остальных случаях использование запрещено
 * 
 * Вместо этого компонента используй feature/map/layers/VideoTrackLayer
 * 
 * @see feature/map/layers/VideoTrackLayer
*/
export const TrackPointVideo = (props: any) => {
  const { map, data, video_div, centerMap } = props;
  const intl = useIntl();
  const [cookies] = useCookies(['layers']);
  const icon_drone = props.data.icon_drone;
  const source_drone = new VectorSource();

  const _title = intl.formatMessage({
    id: 'module.video',
    defaultMessage: 'Video',
  });
  const redPointStyle = new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({ color: '#dc3545' }),
    }),
  });

  function setPoint(geom: number[], type: string = 'start') {
    const feature: Feature = new Feature({
      geometry: new Point(geom),
    });
    feature.setStyle(redPointStyle);
    feature.set('name', intl.formatMessage({
      id: type == 'start' ? 'video.start' : 'video.end',
      defaultMessage: type == 'start' ? 'Start' : 'End'
    }));

    return feature;
  }

  useEffect(() => {
    if (data) {
      removeLayer('videos');
      removeLayer('drone');
      
      const geom = data.timing.map((item: any) => { return fromLonLat([item.lon, item.lat, item.sec]); });
      const line: Feature = new Feature({
        geometry: new LineString(geom, "XYM"),
      });
      line.setStyle(
        new Style({
          stroke: new Stroke({ color: 'rgb(62,44,193,.8)', width: 3 }),
        }),
      );

      const line_geom: any = line.getGeometry();

      const startPoint = setPoint(line_geom.getFirstCoordinate(), 'start');
      const endPoint   = setPoint(line_geom.getLastCoordinate(), 'end');
      
      const source = new VectorSource({
        features: [line, startPoint, endPoint],
      });

      if(props.searchArea?.type){
        var searchArea = new GeoJSON().readFeature(props.searchArea, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        });
        source.addFeature(searchArea);
      }

      const layer = new VectorLayer({
        source: source,
        visible: cookies.layers.videos ? true : false,
        zIndex: 2,
      } as BaseLayerOptions);

      const group_video_points = new LayerGroup({
        title: _title,
        //fold: 'close',
        name: 'videos',
        className: 'videos',
        layers: [layer],
      } as GroupLayerOptions);
      map.addLayer(group_video_points);

      const drone = new VectorLayer({
        source: source_drone,
        className: 'drone',
        zIndex: 10,
      });
      map.addLayer(drone);

      setDrone(data.timing[0], source_drone);
      video_div.currentTime = data.second;
      video_div.centerMap = centerMap;

      video_div.ontimeupdate = () => {
        var sec = Math.ceil(video_div.currentTime);
        var row = data.timing.find((row: any) => row.sec === sec);
        if (row) setDrone(row, source_drone);
      };
    }
  }, [data]);

  useEffect(() => {
    video_div.centerMap = centerMap;
  }, [centerMap]);

  function removeLayer(layer_name: string) {
    map.getLayers().getArray().map((layer: any) => {
      if (layer.className_ === layer_name) map.removeLayer(layer);
    });
  };

  useEffect(() => {
    return () => {
      removeLayer('videos');
      removeLayer('drone');
    };
  }, []);

  function setDrone(row: any, source: VectorSource<Geometry>) {
    if (row.lon && row.lat) {
      source.clear();

      const droneCoordinates = fromLonLat([row.lon, row.lat]);
      const droneFeature = new Feature({
        geometry: new Point(droneCoordinates),
      });

      droneFeature.setStyle(
        new Style({
          image: new Icon({
            src: icon_drone,
            rotation: (row.dir * Math.PI) / 180,
          }),
        }),
      );
      source.addFeature(droneFeature);
      if(video_div.centerMap) map.getView().animate({ center: droneCoordinates, duration: 100 });


      /**
       * Координата точки, в которую направлена камера
      */
      const cameraCoordinates = fromLonLat([row.c_lon, row.c_lat]);
      const cameraFeature = new Feature({
        geometry: new Point(cameraCoordinates),
      });

      cameraFeature.setStyle(
        new Style({
          image: new RegularShape({
            points: 4,
            radius: 20,
            stroke: new Stroke({ color: 'red', width: 2 }),
          }),
        }),
      );
      source.addFeature(cameraFeature);
    }
  }

  return <></>;
};
