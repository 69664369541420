import { useCookies } from "react-cookie";
import BaseLayer from "ol/layer/Base";

// TODO пока не совсем понятна структура по FSD, поэтому пока этот метод лежит тут
export const useVisibleLayer = () => {
  const [cookies, setCookie] = useCookies(['layers', 'reprojection']);
  const mainLgroups = ['flights', 'videos', 'violations', 'onlines_global', 'marks', 'sk42grid', 'zviolations'];

  const setVisibleLayer = (lyr: BaseLayer) => {
    if (lyr.get('name') || lyr.get('map_id'))
    {
      const layers = cookies.layers;
      if (lyr.get('map_id')) {
        if (lyr.getVisible()) layers['maps'] = lyr.get('map_id');
      } else {
        if (lyr.get('active')
          || mainLgroups.includes(lyr.get('name'))
          || !['routes', 'tiles', 'ofps', 'vprf', 'traffic'].includes(lyr.get('name'))
        ) {
          layers[lyr.get('name')] = lyr.getVisible() ? 1 : 0;
        }
      }
      setCookie('layers', layers, {path: '/', maxAge: 3600*24*365});
    }
  }
  return {setVisibleLayer};
}
