import './styles.scss';

import {
  Children,
  cloneElement,
  CSSProperties,
  FC,
  isValidElement,
  ReactElement,
  ReactNode,
  useEffect,
  useState
} from 'react';
import { Coordinates } from '@workspace/4Z1.ts.utils';
import { MapCore } from '../model/MapCore';
import { MapEngine } from '../model/interfaces';

export interface MapProps {
  readonly id?: string;
  readonly style?: CSSProperties;
  readonly children?: ReactNode;
  readonly center?: Coordinates;
  readonly sharedViewMapId?: string;
  readonly animateCenterMove?: boolean;
}

/**
 * Компонент карты.
 * Документация ./docs/Map.md
 */
export const Map: FC<MapProps> = (
  {
    id = 'map',
    style,
    children,
    center,
    sharedViewMapId,
    animateCenterMove = false,
  }) => {

  const [map] = useState<MapCore>(() => new MapCore(id, sharedViewMapId));

  useEffect(() => {
    map.init();

    return () => {
      map.unmount();
    }
  }, [map]);

  useEffect(() => {
    if (center === undefined) {
      return;
    }

    map.setCenter(center, animateCenterMove);
  }, [map, center]);

  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child as ReactElement<{map: MapEngine}>, {map});
    }
    return child;
  });

  return (
    <div id={id}
         className='MapEngine'
         style={style}>
      {childrenWithProps}
    </div>
  )
}
