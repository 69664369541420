import { Asset } from '@/entities/assets/model';
import {
  AssetDirectionStore,
  DirectionStore,
} from '@/features/assetCard/ChangeDirection/model';
import { dep, diInject } from '@/HOC';
import { DiKeys } from '@/shared/di/global';
import { useIntl } from 'react-intl';
import { Compass } from '@workspace/4Z1.uikit.react';
import { message } from 'antd';
import { useState } from 'react';

interface Props {
  readonly assetDirectionStore: AssetDirectionStore;
  readonly onAssetDirectionChange: (asset: Asset, direction: number) => void;
}

const AssetDirectionPicker: React.FC<Props> = ({
  assetDirectionStore,
  onAssetDirectionChange,
}) => {
  const [directionStore] = useState(() => new DirectionStore());
  const intl = useIntl();

  const handleShowError = () => {
    message.error(
      intl.formatMessage({
        id: 'change-dir.submit.fail',
        defaultMessage: 'Try again!',
      }),
    );
  };

  const handleShowSuccess = () => {
    message.success(
      intl.formatMessage({
        id: 'change-dir.submit.success',
        defaultMessage: 'Successfully changed!',
      }),
    );
  };

  const onCompassCheck = (angle: number) => {
    directionStore
      .updateDirection({
        asset: assetDirectionStore.asset as Asset,
        direction: angle,
      })
      .then(() => {
        onAssetDirectionChange(assetDirectionStore.asset as Asset, angle);
        handleShowSuccess();
      })
      .finally(() => assetDirectionStore.closeCompass())
      .catch(handleShowError);
  };

  return (
    <div>
      {assetDirectionStore.visible && assetDirectionStore.asset && (
        <Compass
          initialAngle={assetDirectionStore.course}
          onClose={() => assetDirectionStore.closeCompass()}
          onCheck={onCompassCheck}
        />
      )}
    </div>
  );
};

export default diInject(AssetDirectionPicker, {
  assetDirectionStore: dep(AssetDirectionStore.diKey),
  onAssetDirectionChange: dep(DiKeys.onAssetDirectionChange),
});
