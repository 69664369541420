import { RouteItem } from '../model/types';

const checkForActive = (route: RouteItem): boolean => {
  const [currentLocation, currentRoute] = [location.pathname, route.path].map(
    (item) => item.split('/')[1],
  );

  const withCurrentRouteActive = currentLocation === currentRoute;

  if (withCurrentRouteActive) return true;

  const withActiveAliases = route.aliases?.some(
    (alias) => alias === currentLocation,
  );

  /**
   * На случай, если роут неактивен и не имеет активных алиасов - возвращаем false
   */
  return withActiveAliases ?? false;
};

const menuFromUmiRoutes = (
  routes: RouteItem[],
  access: { [key: string]: boolean },
): RouteItem[] => {
  const filteredRoutes = routes
    .filter((route: RouteItem) => {
      const hasPermission = route.access ? access[route.access] : true;

      if (hasPermission && route.visibleNewMenu && route.icon) {
        return true;
      }

      if (!hasPermission || route.hideInMenu || !route.icon) {
        return false;
      }

      return !!route.name && typeof route.name === 'string';
    })
    .map((route) => {
      return { ...route, active: checkForActive(route) };
    });

  return filteredRoutes;
};

export default menuFromUmiRoutes;
