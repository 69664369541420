import { ReactNode } from 'react';

export interface PtzBaseParams {
  /** Уникальный идентификатор устройства */
  readonly CameraUuid: string;
  /** Уникальный идентификатор команды */
  readonly CommandUuid: string;
}

export interface PtzCommandParams extends PtzBaseParams {
  /** Поворот в вертикальной плоскости */
  PitchDeg?: number;
  /** Поворот в горизонтальной плоскости */
  YawDeg?: number;
  /** Скорость перемещения в вертикальной плоскости */
  PitchRateDegS?: number;
  /** Скорость перемещения в горизонтальной плоскости */
  YawRateDegS?: number;
  /** Зум */
  Zoom?: number;
  /** Скорость зуммирования */
  ZoomRateDegS?: number;
  /** Признак, что нужно команду выполнить в абсолютных величинах */
  IsAbsolute?: boolean;
}

export interface PtzPointCameraParams extends PtzBaseParams {
  /** Относительное смещение направления поворота камеры в процентах */
  readonly x: number;
  readonly y: number;
}

export interface PtzActionsList {
  icon: ReactNode;
  type: PtzActions;
}

export enum PtzActions {
  Up = 'up',
  Down = 'down',
  Left = 'left',
  Right = 'right',
  ZoomIn = 'zoomIn',
  ZoomOut = 'zoomOut',
}
