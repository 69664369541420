import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl, useModel } from 'umi';
import LayerGroup from 'ol/layer/Group';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import Feature from 'ol/Feature';
import { Style, Stroke } from 'ol/style';

// @deprecated packages/app/src/features/map/layers/flightLayer/ui/FlightLayer.tsx
export const Flights = (props: any) => {
  const { map, data } = props;
  const intl = useIntl();
  const { initialState } = useModel('@@initialState');
  const flightOptions = initialState?.currentOptions?.flight;
  const [cookies] = useCookies(['layers']);

  const _title = intl.formatMessage({
    id: 'module.flights',
    defaultMessage: 'Flights',
  });

  useEffect(() => {
    map
      .getLayers()
      .getArray()
      .map((layer: any) => {
        if (layer.className_ == 'flights') map.removeLayer(layer);
      });
    if (data && data.length) {
      const source = new VectorSource();
      data.map((flight: any, feature: Feature) => {
        if (flight.geometry) {
          var type = flight.type == 2 && flightOptions?.photos ? 'photos' : 'videos';
          var feature = new GeoJSON().readFeature(flight.geometry.geom, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          });
          feature.set('className', 'layer-flight-' + flight.flight_id);
          feature.set('name', flight.flight_name);
          if (flight.type != 4)
            feature.set('link', '/' + type + '/' + flight.flight_id);
          if(type) feature.set('type', type);
          feature.setStyle(
            new Style({
              stroke: new Stroke({ color: flight.color, width: 3 }),
            }),
          );
          source.addFeature(feature);
        }
      });

      const layer = new VectorLayer({
        source: source,
        visible: cookies.layers.flights ? true : false,
        zIndex: 5,
      } as BaseLayerOptions);

      const group_flights = new LayerGroup({
        title: _title,
        //fold: 'close',
        name: 'flights',
        className: 'flights',
        layers: [layer],
      } as GroupLayerOptions);

      map.addLayer(group_flights);
    }
  }, [data]);

  return <></>;
};
