import { makeAutoObservable } from 'mobx';
import { Location } from '@/shared/model';

export class MapNavigationStore {

  private _locations: Map<string, Location> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  public get navigations(): Map<string, Location> {
    return this._locations;
  }

  public navigateTo(id: string, location: Location) {
    this._locations = new Map([...this._locations.entries(),[id, location]]);
  }

  public done(id: string) {
    this._locations.delete(id);
  }
}
