import _ from 'lodash';
import { makeAutoObservable } from 'mobx';
import { Geometry } from 'ol/geom';
import { DateRange } from '@workspace/4Z1.ts.utils';
import { SearchOnAreaItem } from '../model/interfaces';
import { createSearchOnAreaApi } from '../api';

export class FlightsByDateAndAreaStore {
  public area!: Geometry;
  public flightsSearchOnArea: SearchOnAreaItem[] = [];

  constructor(
    private readonly api = createSearchOnAreaApi(),
  ) {
    makeAutoObservable(this);
  }

  querySearch(dateRange: DateRange) {
    if (_.isNil(dateRange.startDate) || _.isNil(dateRange.endDate) || _.isNil(this.area)) {
      return;
    }

    this.api.searchOnMapArea(this.area, dateRange)
      .then((data: SearchOnAreaItem[]) => this.flightsSearchOnArea = data);
  }
}
