import { format } from 'ol/coordinate';
import { Coordinates } from '@workspace/4Z1.ts.utils';

/** Кол-во символов после точки в координатах */
const FRACTION = 5;

/** Шаблон расположения координат */
const TEMPLATE = '{x} {y}';

export const coordinatesToString = (coordinates: Coordinates | undefined) => {
  if (coordinates === undefined) {
    return '';
  }

  return format([coordinates.lat, coordinates.lon], TEMPLATE,  FRACTION);
}
