export default {
  'flight.name': 'Наименование',
  'flight.routes': 'Все маршруты',
  'flight.users': 'Все пользователи',
  'flight.showAll': 'Показать все',
  'flight.edit': 'Редактировать полёт',
  'flight.NewFlight': 'Новый полёт',
  'flight.types': 'Типы',

  'flight.violations': 'Проишествия',
  'flight.list.empty': 'На этой странице сейчас пусто',
  'flight.list.suggest': 'Вы можете загрузить вложения самостоятельно',

  'flight.delete.confirm.title': 'Внимание',
  'flight.delete.confirm.body': 'После удаления полета все данные полёта будут утеряны без возможности восстановления. Вы действительно хотите удалить полёт?',
  'flight.flights_delete_error':
    'Произошла ошибка во время удаления полёта. Попробуйте ещё раз!',
  'flight.readMore': 'Подробнее',
  'flight.redirect': 'Перейти',
  'flight.downloadKmlTooltip': 'Скачать KML',
  'flight.missingKmlTooltip': 'KML отсутствует',
  'flight.redirectToPage': 'Перейти на страницу полёта',

  'flight.photo-viewer.title': 'Фотография {title}',
  'flight.video-viewer.title': 'Видео {title}',

  'flight.flights_update_success': 'Данные успешно обновлены',
  'flight.flights_update_error': 'Ошибка обновления',
  'flight.empty_new_flight': 'Новый полёт не может быть пустым',

  'flight.NO_FLIGHT': 'Полёт не найден',
  'flight.Edit_Name_Flight': 'Редактирование название полёта',
  'flight.flights_delete_success': 'Полёт успешно удалён',
  'flight.returnToMap': 'Вернуться к карте',

  'flight.general': 'Общее',
  'flight.trackFlight': 'Трек полёта',
  'flight.crew': 'Экипаж',
  'flight.dateStart': 'Дата начала',
  'flight.dateEnd': 'Дата завершения',
  'flight.timeStart': 'Время начала',
  'flight.timeEnd': 'Время завершения',
  'flight.complex': 'Комплекс',
  'flight.comand': 'Команда',
  'flight.flightName': 'Название полёта',
  'flight.flightNumber': 'Номер полёта',
  'flight.enterNumber': 'Введите номер',
  'flight.chooseRoute': 'Выберите маршрут',
  'flight.enterFIO': 'Введите ФИО',
  'flight.board': 'Борт',
  'flight.chooseBoard': 'Выберите борт',
  'flight.create': 'Создать полёт',
  'flight.telemetry': 'Телеметрия',
  'flight.DragDropTLM': 'Перетащите сюда TLM-файл или нажмите на кнопку',


  'flight.load.tlm': 'Загрузить телеметрию (фото)',
  'flight.load.tlm.gas': 'Загрузить телеметрию (газ)',
  'flight.load.photo': 'Загрузить фото',
  'flight.load.video': 'Загрузить видео',
  'flight.load.incidents': 'Загрузить происшествие',
  'flight.load.ofp': 'Загрузить ортофотопланы',

  'flight.attachments.delete-success': 'Выбранные вложения успешно удалены!',
  'flight.photo.delete': 'Вы действительно хотите удалить {count} фото?',
  'flight.video.delete': 'Вы действительно хотите удалить {count} видео?',
};
