import lineIntersect from '@turf/line-intersect';
import { along, lineString } from '@turf/turf';
import { Coordinate, format } from 'ol/coordinate';
import { Extent, boundingExtent, getBottomLeft, getBottomRight, getCenter, getTopLeft, getTopRight } from 'ol/extent';
import { Projection, addProjection, addCoordinateTransforms, fromLonLat, toLonLat, transformExtent } from 'ol/proj';
import proj4 from 'proj4';

const PULKOVO_1942_GEODETIC_CRS_DEFINITION = '+proj=longlat +ellps=krass +towgs84=23.57,-140.95,-79.8,0.0,0.35,0.79,-0.22 +no_defs +type=crs';
const WGS84_4326_CRS_DEFINITION = '+proj=longlat +datum=WGS84 +no_defs +type=crs';

export const projection_1942 = new Projection({
  code: 'pulkovo1942',
  units: 'm'
});
addProjection(projection_1942);

addCoordinateTransforms(
  'EPSG:4326',
  projection_1942,
  (coordinate: Coordinate) => {
    return wgs84ToSk42(coordinate);
  },
  (coordinate: Coordinate) => {
    return wgs84ToSk42(coordinate);
  }
);

const getWgs84GeoZone = (x: number): number => {
  return Math.floor(x * 0.000001);
}

const getPulkovo1942GeoZone = (coordinate: Coordinate): number => {
  const dst = proj4<Coordinate>(WGS84_4326_CRS_DEFINITION, PULKOVO_1942_GEODETIC_CRS_DEFINITION, coordinate);
  return Math.floor((6.0 + dst[0]) / 6);
}

const getPulkovo1942CRSDefinition = (zone: number): string => {
  return `+proj=tmerc +lat_0=0 +lon_0=${zone * 6 - 3} +k=1 +x_0=${zone}500000 +y_0=0 +ellps=krass +towgs84=23.57,-140.95,-79.8,0.0,0.35,0.79,-0.22 +no_defs +type=crs`;
}

/**
 * @deprecated Вычисление SK42 координат теперь происходит через класс Location из workspace/4Z1.ts.utils
 * @see workspace/4Z1.ts.utils
 */
// [x, y] -> [lon, lat]
export const sk42ToWgs84 = (coordinate: number[]): Coordinate => {
  const zone = getWgs84GeoZone(coordinate[0]);
  const dst = getPulkovo1942CRSDefinition(zone);
  return proj4<Coordinate>(dst, WGS84_4326_CRS_DEFINITION, coordinate);
}


/**
 * @deprecated Вычисление WGS координат теперь происходит через класс Location из workspace/4Z1.ts.utils
 * @see workspace/4Z1.ts.utils
 */
// [lon, lat] -> [x, y]
export const wgs84ToSk42 = (coordinate: number[], fixedZone: number | null = null): Coordinate => {
  const zone = fixedZone ?? getPulkovo1942GeoZone(coordinate);
  const dst = getPulkovo1942CRSDefinition(zone);
  return proj4<Coordinate>(WGS84_4326_CRS_DEFINITION, dst, coordinate);
}

const getSk42ZoneGridPoints = (distance: number, extent: Extent): {
  points: Coordinate[][],
  labelsLon: string[],
  labelsLat: string[],
  zone: number
} => {
  const points: Coordinate[][] = [];
  const labelsLon: string[] = [];
  const labelsLat: string[] = [];

  const viewportCenter: Coordinate = getCenter(extent);

  const zone = getPulkovo1942GeoZone(viewportCenter);

  const sk42viewportNW = wgs84ToSk42(getTopLeft(extent));
  const sk42viewportSE = wgs84ToSk42(getBottomRight(extent));

  const wgs84ZoneCenterLon = zone * 6 - 3;
  const wgs84ZoneCenterLat = Math.floor(viewportCenter[1] / distance);

  const sk42ZoneCenter = wgs84ToSk42([wgs84ZoneCenterLon, wgs84ZoneCenterLat]);
  sk42ZoneCenter[0] = Math.floor(sk42ZoneCenter[0] / distance) * distance;
  sk42ZoneCenter[1] = Math.floor(sk42ZoneCenter[1] / distance) * distance;

  let wgs84ZoneLeftLon = 6 * (zone - 1);
  let wgs84ZoneRightLon = 6 * zone;

  const sk42ZoneLeft = wgs84ToSk42([wgs84ZoneLeftLon, wgs84ZoneCenterLat]);
  const sk42ZoneRight = wgs84ToSk42([wgs84ZoneRightLon, wgs84ZoneCenterLat]);

  let y1 = Math.ceil(sk42ZoneCenter[1] - Math.floor((sk42ZoneCenter[1] - sk42viewportSE[1]) / distance) * distance);
  let y2 = Math.ceil(sk42ZoneCenter[1] - Math.floor((sk42ZoneCenter[1] - sk42viewportNW[1]) / distance) * distance);
  let x1 = Math.ceil(sk42ZoneCenter[0] - Math.floor((sk42ZoneCenter[0] - sk42viewportNW[0]) / distance) * distance);
  let x2 = Math.ceil(sk42ZoneCenter[0] - Math.floor((sk42ZoneCenter[0] - sk42viewportSE[0]) / distance) * distance);

  if (zone <= 30) {
    y1 -= (3 * distance);
    y2 += (3 * distance);
    x1 -= (3 * distance);
    x2 += (3 * distance);
  }
  else {
    y1 += (3 * distance);
    y2 -= (3 * distance);
    x1 += (3 * distance);
    x2 -= (3 * distance);
  }

  const yMin = Math.min(y1, y2);
  const yMax = Math.max(y1, y2);

  let xMin = Math.min(x1, x2);
  let xMax = Math.max(x1, x2);

  xMin = Math.max(xMin, Math.floor(sk42ZoneLeft[0]));
  xMax = Math.min(xMax, Math.floor(sk42ZoneRight[0]));

  let i = 0;
  let j = 0;
  for (let y = yMin; y <= yMax; y += distance) {
    points[i] = [];
    for (let x = xMin; x <= xMax; x += distance) {
      const pointWgs84 = sk42ToWgs84([x, y]);
      points[i][j] = pointWgs84;
      labelsLon[j] = String(Math.floor(x / 1000) % 100);

      j++;
    }

    labelsLat[i] = String(Math.floor(y / 1000) % 100);
    i++;
    j = 0;
  }

  return {
    points,
    labelsLon,
    labelsLat,
    zone
  };
}

export type gridLines = {
  grid: number[][],
  leftLabels: { coordinate: number[], text: string }[],
  rightLabels: { coordinate: number[], text: string }[],
  topLabels: { coordinate: number[], text: string }[],
  bottomLabels: { coordinate: number[], text: string }[],
  zone: number
};

export const getSk42ZoneGridLines = (distance: number, extent: number[]): gridLines => {
  const grid: number[][] = [];
  const leftLabels: { coordinate: number[], text: string }[] = [];
  const rightLabels: { coordinate: number[], text: string }[] = [];
  const topLabels: { coordinate: number[], text: string }[] = [];
  const bottomLabels: { coordinate: number[], text: string }[] = [];

  const { points, labelsLon, labelsLat, zone } = getSk42ZoneGridPoints(distance, extent);

  const dimensions = [points.length, points[0].length];

  const leftViewportVertical = lineString([getTopLeft(extent), getBottomLeft(extent)]);
  const rightViewportVertical = lineString([getTopRight(extent), getBottomRight(extent)]);
  const topViewportHorizontal = lineString([getTopLeft(extent), getTopRight(extent)]);
  const bottomViewportHorizontal = lineString([getBottomLeft(extent), getBottomRight(extent)]);

  for (let i = 0; i < dimensions[0]; i++) {
    for (let j = 0; j < dimensions[1] - 1; j++) {
      if (points[i][j] && points[i][j + 1]) {
        grid.push([points[i][j][0], points[i][j][1], points[i][j + 1][0], points[i][j + 1][1]]);

        const line = lineString([[points[i][j][0], points[i][j][1]], [points[i][j + 1][0], points[i][j + 1][1]]]);
        const intersectLeft = lineIntersect(line, leftViewportVertical);
        const intersectRight = lineIntersect(line, rightViewportVertical);

        if (intersectLeft.features.length > 0) {
          leftLabels.push({ coordinate: intersectLeft.features[0].geometry.coordinates, text: labelsLat[i] });
        }

        if (intersectRight.features.length > 0) {
          rightLabels.push({ coordinate: intersectRight.features[0].geometry.coordinates, text: labelsLat[i] });
        }
      }
    }
  }

  for (let j = 0; j < dimensions[1]; j++) {
    for (let i = 0; i < dimensions[0] - 1; i++) {
      if (points[i][j] && points[i + 1][j]) {
        grid.push([points[i][j][0], points[i][j][1], points[i + 1][j][0], points[i + 1][j][1]]);

        const line = lineString([[points[i][j][0], points[i][j][1]], [points[i + 1][j][0], points[i + 1][j][1]]]);
        const intersectTop = lineIntersect(line, topViewportHorizontal);
        const intersectBottom = lineIntersect(line, bottomViewportHorizontal);

        if (intersectTop.features.length > 0) {
          topLabels.push({ coordinate: intersectTop.features[0].geometry.coordinates, text: labelsLon[j] });
        }

        if (intersectBottom.features.length > 0) {
          bottomLabels.push({ coordinate: intersectBottom.features[0].geometry.coordinates, text: labelsLon[j] });
        }
      }
    }
  }

  return {
    grid,
    leftLabels,
    rightLabels,
    topLabels,
    bottomLabels,
    zone
  };
}

export type Snail = {
  horizontal1Left: number[],
  horizontal1Right: number[],
  horizontal2Left: number[],
  horizontal2Right: number[],
  vertical1Bottom: number[],
  vertical1Top: number[],
  vertical2Bottom: number[],
  vertical2Top: number[],
  bbox: Extent,
}

export const getSnail = (distance: number, coordinate: number[], zone: number): Snail => {
  const pointSk42 = wgs84ToSk42(coordinate, zone);

  const leftSk42 = Math.floor(pointSk42[0] / distance) * distance;
  const rightSk42 = (Math.floor(pointSk42[0] / distance) + 1) * distance;
  const bottomSk42 = Math.floor(pointSk42[1] / distance) * distance;
  const topSk42 = (Math.floor(pointSk42[1] / distance) + 1) * distance;

  const nw = sk42ToWgs84([leftSk42, topSk42]);
  const sw = sk42ToWgs84([leftSk42, bottomSk42]);
  const ne = sk42ToWgs84([rightSk42, topSk42]);
  const se = sk42ToWgs84([rightSk42, bottomSk42]);

  const bbox = boundingExtent([nw, sw, ne, se]);

  const lineVerticalLeft = lineString([[nw[0], nw[1]], [sw[0], sw[1]]]);
  const lineVerticalRight = lineString([[ne[0], ne[1]], [se[0], se[1]]]);
  const lineHorizontalTop = lineString([[nw[0], nw[1]], [ne[0], ne[1]]]);
  const lineHorizontalBottom = lineString([[sw[0], sw[1]], [se[0], se[1]]]);

  const horizontal1Left = along(lineVerticalLeft, 0.333).geometry.coordinates;
  const horizontal1Right = along(lineVerticalRight, 0.333).geometry.coordinates;
  const horizontal2Left = along(lineVerticalLeft, 0.666).geometry.coordinates;
  const horizontal2Right = along(lineVerticalRight, 0.666).geometry.coordinates;

  const vertical1Bottom = along(lineHorizontalBottom, 0.333).geometry.coordinates;
  const vertical1Top = along(lineHorizontalTop, 0.333).geometry.coordinates;
  const vertical2Bottom = along(lineHorizontalBottom, 0.666).geometry.coordinates;
  const vertical2Top = along(lineHorizontalTop, 0.666).geometry.coordinates;

  return {
    horizontal1Left,
    horizontal1Right,
    horizontal2Left,
    horizontal2Right,
    vertical1Bottom,
    vertical1Top,
    vertical2Bottom,
    vertical2Top,
    bbox
  }

}
