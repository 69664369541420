import { FC, useEffect, useState } from 'react';
import { implicitMapCheck } from '@/shared/map';
import VectorLayer from 'ol/layer/Vector';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import LayerGroup from 'ol/layer/Group';
import VectorSource from 'ol/source/Vector';
import { useIntl } from 'react-intl';
import { AssetSubsetFragment, AssetTypes } from '@/.graphql/graphql';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { getAssetIconDynamic, getAssetIconStatic, getAssetIconUav } from '@/shared/utils/getAssetIcon';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { MapPluginProps } from '@/shared/map/model/interfaces';
import CircleStyle from "ol/style/Circle";

interface Props extends MapPluginProps {
  assetsList?: AssetSubsetFragment[];
  onClick?: (featureData: AssetSubsetFragment) => void;
}

const LAYER_NAME = 'online';
const FEATURE_NAME = 'onlineData';

// TODO частичный копипаст кода из файла onlines_global.tsx
export const OnlineLayer: FC<Props> = ({
    map = implicitMapCheck(),
    assetsList = [],
    onClick
  }) => {
  const intl = useIntl();
  let onClickFeature = undefined;

  const [source] = useState(new VectorSource());
  const _title = intl.formatMessage({
    id: 'online.layers',
    defaultMessage: 'Onlines',
  });

  const getCenter = (item: AssetSubsetFragment) => {
    return (item.state.location?.lon && item.state.location?.lat)
      ? [item.state.location?.lon, item.state.location?.lat]
      : [];
  }
  function styleUAV(item: AssetSubsetFragment) {
    const IconText: string = `
      ${item.name}
    `;

    const src = getAssetIconUav(item, {canShowVideoStream: false})

    const styles = [
      new Style({
        image: new Icon({
          src,
          rotation: item.state.location?.course ? (item.state.location?.course * Math.PI) / 180 : 0,
        }),
        text: new Text({
          text: IconText,
          offsetX: -8,
          offsetY: 25,
          scale: 1.2,
          fill: new Fill({
            color: 'black',
          }),
          stroke: new Stroke({
            color: 'white',
            width: 3,
          }),
        }),
      })
    ];
    if (!item.state.uavState?.uplinkAvailable && item.state.uavState?.downlinkAvailable) {
      styles.push(
        new Style({
          image: new CircleStyle({
            radius: 30,
            stroke: new Stroke({
              color: 'red',
              width: 2,
              lineDash: [10, 10]
            }),
          }),
        })
      );
    }
    if (!item.state.uavState?.uplinkAvailable && !item.state.uavState?.downlinkAvailable) {
      styles.push(
        new Style({
          image: new CircleStyle({
            radius: 30,
            stroke: new Stroke({
              color: 'red',
              width: 2,
            }),
          }),
        })
      );
    }

    return styles;
  }

  function styleDynamic(item: AssetSubsetFragment) {
    let icon = '';
    const IconText: string = `
      ${item.name}
    `;

    const src = getAssetIconDynamic(item);

    const styles = [
      new Style({
        image: new Icon({
          src,
          rotation: item.state.location?.course ? (item.state.location?.course * Math.PI) / 180 : 0,
        }),
        text: new Text({
          text: IconText,
          offsetX: -8,
          offsetY: 25,
          scale: 1.2,
          fill: new Fill({
            color: 'black',
          }),
          stroke: new Stroke({
            color: 'white',
            width: 3,
          }),
        }),
      })
    ];

    return styles;
  }

  function styleStatic(item: AssetSubsetFragment) {
    const IconText: string = `
      ${item.name}
    `;

    const src = getAssetIconStatic(item);

    const styles = [
      new Style({
        image: new Icon({
          src,
          //rotation: item.state.location?.course ? (item.state.location?.course * Math.PI) / 180 : 0,
        }),
        text: new Text({
          text: IconText,
          offsetX: -8,
          offsetY: 25,
          scale: 1.2,
          fill: new Fill({
            color: 'black',
          }),
          stroke: new Stroke({
            color: 'white',
            width: 3,
          }),
        }),
      })
    ];

    return styles;
  }

  function setStyle(item: AssetSubsetFragment) {
    switch (item.assetType) {
      case AssetTypes.Uav:
        return styleUAV(item);
      case AssetTypes.Gcs:
        return styleStatic(item);
      case AssetTypes.Beacon:
        return styleStatic(item);
      case AssetTypes.GeoGorizontService:
        return styleStatic(item);
      case AssetTypes.Car:
        return styleDynamic(item);
      case AssetTypes.Ptz:
        return styleDynamic(item);
      default:
        return new Style();
    }
  }

  useEffect(() => {
    source.clear();

    assetsList.map((item: AssetSubsetFragment) => {
      const center: number[] = getCenter(item);
      if (center.length) {
        const feature: Feature = new Feature(new Point(fromLonLat(center)));
        feature.set('className', 'layer-onlines_global-' + item.id);
        feature.set('type', 'onlines_global');
        feature.set(FEATURE_NAME, item);
        feature.setStyle(setStyle(item));

        source.addFeature(feature);
      }
    });
  }, [assetsList]);

  useEffect(() => {
    if (onClick !== undefined) {
      // Подписка на клик по иконке
      onClickFeature = map.onClickFeature(FEATURE_NAME, (feature) => onClick(feature.get(FEATURE_NAME)));
    }


    const layer = new VectorLayer({
      source: source,
      visible: true,
      zIndex: 10,
    } as BaseLayerOptions);

    const groupOnline = new LayerGroup({
      title: _title,
      name: LAYER_NAME,
      layers: [layer],
    } as GroupLayerOptions);

    map.addLayer(groupOnline);

    return () => {
      source.clear();
      if (onClickFeature !== undefined) {
        map.offMapClick(onClickFeature);
      }
      map.removeLayerByName(LAYER_NAME);
    };
  }, []);

  return <></>
}
