import { type FC } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { AssetCardProps } from '@/widgets';
import { Assets } from '@/entities/assets/model';
import { useCookies } from 'react-cookie';

/**
 * Информация о расположении объекта в AssetCard
 * @param asset
 */
export const Location: FC<AssetCardProps> = ({asset}) => {
  const intl = useIntl();

  const [cookies] = useCookies(['reprojection']);

  if (_.isNil(asset.state.location)) {
    return <></>;
  }

  return (
    <div>
      {/* Скорость объекта */}
      {
        Assets.withSpeedInfo(asset) &&
        <div className='fullWidth'>
          <div className='ellipsis'>
            {intl.formatMessage({id: 'online.assetCard.speed'})}: {Assets.speed(asset)}
            {intl.formatMessage({id: 'online.assetCard.kmh'})}
          </div>
        </div>
      }

      {/* Курс объекта */}
      {
        Assets.withCourseInfo(asset) &&
        <div>
          {intl.formatMessage({id: 'online.assetCard.course'})}: {Assets.course(asset)}&deg;
        </div>
      }

      {/* Координаты объекта */}
      {intl.formatMessage({id: 'online.assetCard.location'})}: {Assets.location(asset, cookies.reprojection)}


      {/* Высота расположения над землей объекта */}
      {
        Assets.withAltitudeInfo(asset) &&
        <div>
          {intl.formatMessage({id: 'online.assetCard.altitude'})}: {Assets.uavElevation(asset)}
        </div>
      }

      {/* Ближайшее место объекта */}
      {
        asset.nearestPlace &&
        <div>
          {intl.formatMessage({id: 'online.assetCard.locality'})}: {asset.nearestPlace}
        </div>
      }

    </div>
  )
}
