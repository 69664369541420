import { CurrentUserKey } from '@/services/UrqlClientProvider';

export const getAuthToken = (): string | undefined =>  {
  const currentUserJson = localStorage.getItem(CurrentUserKey);
  if(currentUserJson) {
    const user = JSON.parse(currentUserJson);
    return user.token;
  }
  return undefined;
}
