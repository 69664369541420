import { useState, useEffect, RefObject } from 'react';

const useFullScreen = (elementRef: RefObject<HTMLElement>) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const toggleFullScreen = () => {
    if (!elementRef.current) return;

    if (!fullScreen) {
      elementRef.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      setFullScreen(document.fullscreenElement !== null);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  return { fullScreen, toggleFullScreen };
};

export default useFullScreen;
