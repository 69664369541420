export default {
  'photo.start': 'Start',
  'photo.end': 'End',
  'photo.gallary': 'Gallery archive photo',
  'photo.gallary.download': 'Download photos',
  'photo.empty.photos': 'No photos in area',
  'photo.compare': 'Compare',
  'photo.noPreviousUploadFound': `The photos haven't been uploaded yet. Drag and drop JPG files here or click on the button`,
  'photo.telemetryRequired': 'To upload photos, you must have a telemetry file',
  'photo.compare.reload': 'Reload',
};
