import { GraphQlApi, REQUEST_POLICY_NETWORK } from '@/shared/api/graphql';
import { SearchOnMapDocument, SearchOnMapQuery } from '@/.graphql/graphql';
import { Geometry } from 'ol/geom';
import { SearchOnAreaItem } from '../model/interfaces';
import { DateRange } from '@workspace/4Z1.ts.utils';

export interface SearchOnAreaApi {
  searchOnMapArea(area: Geometry, dateRange: DateRange): Promise<SearchOnAreaItem[]>;
}

export const createSearchOnAreaApi = () => {
  return new SearchOnAreaApiImpl();
}

class SearchOnAreaApiImpl implements SearchOnAreaApi {
  constructor(
    private readonly clientGraphQl = new GraphQlApi(),
  ) {}

  public searchOnMapArea(area: Geometry, dateRange: DateRange): Promise<SearchOnAreaItem[]> {

    const params = {
      geom: JSON.stringify(area),
      date_range: GraphQlApi.formatDateRange(dateRange),
    }
    return this.clientGraphQl
      .query(SearchOnMapDocument, params, REQUEST_POLICY_NETWORK)
      .then(data => this.formattingDataFromGraphQl(data as SearchOnMapQuery));
  }

  private formattingDataFromGraphQl(data: SearchOnMapQuery): SearchOnAreaItem[] {
    return data?.flight_search_on_area.map(item => {
      return {
        ...item,
        flightId: item.flight_id,
        title: item.flight_name,
      }
    }) ?? [];
  }
}
