import { Asset, AssetTypes } from './types';
import { transformCoordFormat } from "@/components/Map/transformCoordinates";
import _ from 'lodash';

// 5 minutes
const ACTIVE_ASSET_TIMEOUT = 5 * 60;
// 60 minutes
const SOFTWARE_ACTIVE_ASSET_TIMEOUT = 60 * 60;

const STREAMING_TYPES: readonly AssetTypes[] = [AssetTypes.Gcs, AssetTypes.Ptz, AssetTypes.Uav];


export const Assets = {

  streamingAssetTypes: STREAMING_TYPES,

  /**
   * Координаты ассета
   */
  location: (asset: Asset, reprojection: string): string => {
    const location = asset?.state?.location;

    if (_.isNil(location)) {
      return '';
    }

    const {lon, lat} = location;

    if (_.isNil(lon) || _.isNil(lat)) {
      return '';
    }

    return transformCoordFormat([lon, lat], reprojection);
  },

  /**
   * Скорость ассета
   */
  speed: (asset: Asset): number => {
    if (_.isNil(asset?.state?.location?.speed)) {
      return 0;
    }

    return Math.round(asset.state.location.speed * 60 * 60 / 1000);
  },

  /**
   * Курс ассета
   */
  course: (asset: Asset) => {
    if (_.isNil(asset?.state?.location?.course)) {
      return 0;
    }

    return Math.round(asset.state.location.course);
  },

  /**
   * Последнее обновление данных ассета
   */
  lastUpdated: (asset: Asset) => {
    if (_.isNil(asset?.state?.timestamp)) {
      return undefined;
    }

    return new Date(asset.state.timestamp * 1000).toLocaleString();
  },

  /**
   * Считает высоту расположения ассета над землей.
   */
  uavElevation: (asset: Asset): number => {
    const location = asset?.state?.location;
    if (_.isNil(location)) {
      return 0;
    }

    const { amsl, elevation } = location;
    if (amsl === undefined || elevation === undefined) {
      return 0;
    }

    return Math.max(amsl - elevation, 0);
  },



  /**
   * Проверяет МОЖЕТ ли ассет стримить
   * @param asset
   */
  couldStream: (asset: Asset): boolean => {
    return Assets.streamingAssetTypes.includes(asset.assetType) ||
      asset.assetCapabilities.includes('CAN_STREAM_VIDEO');
  },

  canUpdateCoordinates: (asset: Asset) => {
    if (asset.assetType === AssetTypes.Afu) {
        return true;
    }
    
    return Assets.isActive(asset) && asset.assetType === AssetTypes.Ptz;
  },

  // Нужно разбить логику
  // Вернет true, даже если инвертирована всего лишь одна ось
  withInvertedControls: (asset: Asset): boolean => {
    const config = asset.state?.pTZCameraState?.movingConfig

    return config?.reversePanDirection || config?.reverseTiltDirection;
  },

  canBeInverted: (asset: Asset): boolean => {
    return asset.assetType === AssetTypes.Ptz
  },

  withCrosshair: (asset: Asset) => {
    return asset.assetType === AssetTypes.Ptz
  },

  canPointCamera: (asset: Asset) => {
    return asset.assetType === AssetTypes.Ptz
  },

  // TODO - Стоит создать отдельный набор пермишенов для камеры
  canSetDirection: (asset: Asset): boolean => {
    return asset.assetType === AssetTypes.Ptz
  },

  forPztActions: (asset: Asset): boolean => {
    return asset.assetType === AssetTypes.Ptz
  },

  hasKnownFieldOfView: (asset: Asset): boolean => {
    return asset.assetType === AssetTypes.Ptz;
  },

  withSpeedInfo: (asset: Asset): boolean => {
    return asset.assetType !== AssetTypes.GeoGraphics
      && asset.assetType !== AssetTypes.Gcs
      && asset.assetType !== AssetTypes.Ptz
      && asset.assetType !== AssetTypes.Afu
  },

  withCourseInfo: (asset: Asset): boolean => {
    return asset.assetType !== AssetTypes.GeoGraphics
      && asset.assetType !== AssetTypes.Gcs 
      && asset.assetType !== AssetTypes.Afu
  },

  withAltitudeInfo: (asset: Asset): boolean => {
    return asset.assetType !== AssetTypes.GeoGraphics
      && asset.assetType !== AssetTypes.Gcs
      && asset.assetType !== AssetTypes.Ptz
      && asset.assetType !== AssetTypes.Afu
  },

  withNearestPlace: (asset: Asset): boolean => {
    return asset.assetType !== AssetTypes.Uav
  },

  withVideoStream: (asset: Asset): boolean => {
    return asset.assetType === AssetTypes.Gcs
      || asset.assetType === AssetTypes.Ptz
      || asset.assetType === AssetTypes.Uav;
  },

  withPtzActions: (asset: Asset): boolean => {
    return Assets.canUpdateCertificate(asset) || Assets.canUpdateCoordinates(asset)
  },

  isSoftware: (asset: Asset): boolean => {
    const { assetType } = asset;
    return (
      assetType === AssetTypes.MobileTranslation
      || assetType === AssetTypes.MobileGeoGraphics
      || assetType === AssetTypes.GeoGraphics
      || assetType === AssetTypes.GeoGorizontService
      || assetType === AssetTypes.GeoGorizontMobile
      || assetType === AssetTypes.GeoGorizontDesktop
      || assetType === AssetTypes.GeoDynamics
    );
  },

  canUpdateCertificate(asset: Asset): boolean{
    return asset.assetType === AssetTypes.Ptz
  },

  isActive: (asset: Asset): boolean => {
    const timestamp = asset.state.timestamp;
    const timeout = Assets.isSoftware(asset) ? SOFTWARE_ACTIVE_ASSET_TIMEOUT : ACTIVE_ASSET_TIMEOUT;

    const timeNow = Date.now() / 1000;
    const difference = timeNow - (timestamp ?? 0);

    return difference <= timeout;
  },

  isUav: (asset: Asset): boolean => {
    return asset.assetType === AssetTypes.Uav;
  },

  isGcs: (asset: Asset): boolean => {
    return asset.assetType === AssetTypes.Gcs;
  },

  getConnectedGCS: (asset: Asset, candidates: readonly Asset[]): Asset | undefined => {
    if (asset.assetType === AssetTypes.Gcs) {
      return asset;
    }

    const candidate = candidates.find(gcs => gcs.state.gcsState?.activeUavAssetId === asset.publicAssetId);

    if (candidate === undefined || !Assets.isActive(candidate)) {
      return undefined;
    }

    return candidate;
  },

  /**
   * Возвращает asset, если его тип AssetTypes.Uav,
   * или, если передан AssetTypes.Gcs - ищет среди кандидатов UAV, являющийся активным для этого GCS.
   * Во всех остальных случаях и если среди кандидатов ни одного подходящего ассета - вернет undefined
   */
  getActiveUav: (asset: Asset, candidates: readonly Asset[]): Asset | undefined => {
    if (asset.assetType === AssetTypes.Uav) {
      return asset;
    }

    if (asset.assetType !== AssetTypes.Gcs || !asset.state.gcsState?.activeUavAssetId) {
      return undefined;
    }

    return candidates.find(a => a.publicAssetId === asset.state.gcsState?.activeUavAssetId);
  },

  getNorthDirection: (asset: Asset): number => {
    const direction = asset.state?.pTZCameraState?.movingConfig?.northDirection

    if(direction === undefined) {
      throw new Error('North direction is required')
    }

    return direction
  },

  getVideoAspectRatio: (asset: Asset): number => {
    switch (asset.assetType) {
      case AssetTypes.Ptz: {
        const information = asset.state?.pTZCameraState?.info?.information;

        const width = information?.horizontalResolutionPx;
        const height = information?.verticalResolutionPx;

        return width / height
      }

      default:
        throw new Error('Cannot get aspect ratio for a restricted assetType');
    }
  },

  getCourse: (asset: Asset): number => {
    const course = asset.state?.location?.course

    if(course === undefined) {
      throw new Error('Course is required')
    }

    return course;
  },

  hasConnectionIssues: (asset: Asset): boolean | undefined => {
    const candidate = asset.state.uavState;
    
    if (!candidate) return undefined;
    const { downlinkAvailable, uplinkAvailable } = candidate;
  
    return !downlinkAvailable || !uplinkAvailable;
  },

  isStaticAsset: (assetType: AssetTypes): boolean => {
    const staticAssetTypes = [
      AssetTypes.Gcs,
      AssetTypes.Beacon,
      AssetTypes.Car,
      AssetTypes.GeoGorizontService,
      AssetTypes.Afu
    ];

    return staticAssetTypes.includes(assetType);
  },

  hasMovementTrack: (asset: Asset): boolean => {
    return [AssetTypes.Uav, AssetTypes.Car].includes(asset.assetType);
  }

} as const;
