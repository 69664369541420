import { Component } from 'react';
import {
  Circle as CircleStyle,
  Fill,
  RegularShape,
  Stroke,
  Style,
  Text,
} from 'ol/style';
import { Draw } from 'ol/interaction';
import {LineString, Point} from 'ol/geom';
import {Vector as VectorSource} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
import {getArea, getLength} from 'ol/sphere';
import { DrawEvent } from 'ol/interaction/Draw';
import { projection_1942_3857 } from '../sk42_3857';

/**
 * @deprecated
 * 
 * Использовать в новых слоя запрещено
 * 
 * @see features/map/layers/artilleryTriangleLayer
 */
class ArtilleryTriangle extends Component <any, any>
{
  draw: any;
  source_AT: VectorSource<any>;
  addInteraction: any;
  constructor(props: any) {
    super(props);

    this.state = {
      btnAT: props.btnAT,
    }

    const style = new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 1)',
      }),
      stroke: new Stroke({
        color: 'rgba(255, 0, 0, 1)',
        width: 3,
      }),
      image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 1)',
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, 1)',
        }),
      }),
    });

    const segmentStyle = new Style({
      text: new Text({
        font: '14px Calibri,sans-serif',
        fill: new Fill({
          color: 'rgba(255, 255, 255, 1)',
        }),
        backgroundFill: new Fill({
          color: 'rgba(0, 0, 0, 1)',
        }),
        padding: [2, 2, 2, 2],
        textBaseline: 'bottom',
        offsetY: -12,
      }),
      image: new RegularShape({
        radius: 6,
        points: 3,
        angle: Math.PI,
        displacement: [0, 8],
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0.4)',
        }),
      }),
    });

    const segmentStyles = [segmentStyle];

    const formatLength = function (line: any) {
      const length = getLength(line);
      let output;
      if (length > 100) {
        output = Math.round((length / 1000) * 100) / 100 + ' km';
      } else {
        output = Math.round(length * 100) / 100 + ' m';
      }
      return output;
    };

    const formatArea = function (polygon: any) {
      const area = getArea(polygon);
      let output;
      if (area > 10000) {
        output = Math.round((area / 1000000) * 100) / 100 + ' km\xB2';
      } else {
        output = Math.round(area * 100) / 100 + ' m\xB2';
      }
      return output;
    };

    const styleFunction = (feature: any) => {
      const styles = [style];
      const geometry = feature.getGeometry();
      const type = geometry.getType();

      if (type === 'LineString') {
        const line = geometry;
        if (line) {
          let count = 0;
          line.forEachSegment(function (a: any, b: any) {
            const segment = new LineString([a, b]);
            const label = formatLength(segment);
            if (segmentStyles.length - 1 < count) {
              segmentStyles.push(segmentStyle.clone());
            }
            const segmentPoint = new Point(segment.getCoordinateAt(0.5));
            segmentStyles[count].setGeometry(segmentPoint);
            segmentStyles[count].getText().setText(label);
            styles.push(segmentStyles[count]);
            count++;
          });
        }
        
      }

      return styles;
    }

    this.source_AT = new VectorSource();

    const measure = new VectorLayer({
      source: this.source_AT,
      className: 'measureAT',
      zIndex: 10,
      style: function (feature) {
        return styleFunction(feature);
      },
    });
    this.props.map.addLayer(measure);

    this.addInteraction = () => {
      this.draw = new Draw({
        source: this.source_AT,
        type: 'LineString',
        maxPoints: 2,
        style: function (feature) {
          return styleFunction(feature);
        },
      });
      this.props.map.addInteraction(this.draw);

      this.draw.on('drawend', (evt: DrawEvent) => {
        const projection = (this.props.cookies.reprojection == 'pulkovo1942') ? projection_1942_3857 : 'EPSG:3857';
        const feature: any = evt.feature;
        const geometry = feature.getGeometry().transform('EPSG:3857', projection);
        const first = geometry.getFirstCoordinate();
        const last = geometry.getLastCoordinate();
        const line = new LineString([first, last, [last[0], first[1]], first]);
        feature.setGeometry(line.transform(projection, 'EPSG:3857'));
      });
    }
  }
  
  componentDidMount() {
  }

  componentDidUpdate() {
    if (this.props.btnAT !== this.state.btnAT) {
      if (this.props.btnAT) {
        this.addInteraction();
      }else{
        this.source_AT.clear();
        this.props.map.removeInteraction(this.draw);
      }
      this.setState({ btnAT: this.props.btnAT });
    }
  }

  componentWillUnmount() {
    this.source_AT.clear();
    this.props.map.removeInteraction(this.draw);
  }

  render() { return (<></>) }
};

export default ArtilleryTriangle;
