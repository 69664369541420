import './styles.scss';

import { type FC, useEffect, useState } from 'react';
import { implicitMapCheck } from '@/shared/map';
import { MapPluginProps } from '@/shared/map/model/interfaces';
import { MousePosition } from 'ol/control';
import { transformCoordFormat } from '@/components/Map/transformCoordinates';
import { Coordinate } from 'ol/coordinate';
import { useCookies } from 'react-cookie';

const PROJECTION = 'EPSG:4326';

export const CursorCoordinatesPanel: FC<MapPluginProps> = ({map = implicitMapCheck()}) => {
  const [cookies] = useCookies(['reprojection', 'blank']);

  const coordinateFormat = (coordinates: number[] | Coordinate | undefined) => coordinates
      ? transformCoordFormat(coordinates, cookies.reprojection)
      : '';

  const [mousePosition] = useState(() => new MousePosition({
    coordinateFormat: ((coordinates: number[] | undefined) => coordinateFormat(coordinates)),
    projection: PROJECTION,
    className: 'mouse-position-info',
  }))

  useEffect(() => {
    map.addControls(mousePosition);

    // Необходиый хак, т.к. библиотека OpenLayer автоматически подставляет в обертку div -> &nbsp;
    // Из-за чего отображается пустая плашка в которой должны быть координаты.
    mousePosition.element.innerHTML = '';
  }, [map]);

  return <></>;
}
