export default {
  'online.layers': 'Онлайн метки',
  'online.height': 'Высота',
  'online.wind_ms': 'Ветер',
  'online.wind_dir': 'Направление ветра',
  'online.metr': 'м',
  'online.metr_seconds': 'м/с',

  'online.MultiStream': 'Мультистрим',

  'online.assetsFilter.onlyActive': 'Показывать только активные объекты',
  'online.assetsFilter.onlyStreaming':
    'Показывать только объекты с видеотрансляцией',
  'online.assetsFilter.onlyRevoked':
    'Показывать только "Отозванные" устройства',
  'online.assetsFilter.assetType': 'Тип объекта',

  'online.assetTypes.allAssetTypes': 'Все типы',
  'online.assetTypes.car.shortname': 'Автомобиль',
  'online.assetTypes.car.fullname': 'Автомобиль',
  'online.assetTypes.uav.shortname': 'БВС',
  'online.assetTypes.uav.fullname': 'Беспилотное воздушное судно (БВС)',
  'online.assetTypes.gcs.shortname': 'НСУ',
  'online.assetTypes.gcs.fullname': 'Наземная станция управления (НСУ)',
  'online.assetTypes.incident.shortname': 'Метка',
  'online.assetTypes.incident.fullname': 'Метка',
  'online.assetTypes.beacon.shortname': 'Маяк',
  'online.assetTypes.beacon.fullname': 'Маяк',
  'online.assetTypes.geographics.shortname': 'ГеоГоризонт',
  'online.assetTypes.geographics.fullname': 'ГеоГоризонт',
  'online.assetTypes.localServers': '4Z1',
  'online.assetTypes.ptz.fullname': 'Камера',
  'online.assetTypes.afu.fullname': 'АФУ',

  'online.assetCard.connected': 'Подключен',
  'online.assetCard.disconnected': 'Отключен',
  'online.assetCard.canSendGPS': 'Отправка GPS',
  'online.assetCard.canStreamVideo': 'Показ видео',
  'online.assetCard.canExecuteCommands': 'Выполнение команд',
  'online.assetCard.hardwareID': 'Идентификатор',
  'online.assetCard.warnings': 'Предупреждения',
  'online.assetCard.gcs': 'НСУ',
  'online.assetCard.state': 'Положение',
  'online.assetCard.inAir': 'В воздухе',
  'online.assetCard.grounded': 'На земле',
  'online.assetCard.mode': 'Режим',
  'online.assetCard.engineTemp': 'Температура',
  'online.assetCard.activeUAV': 'Активное БВС',
  'online.assetCard.сonnectedUAVs': 'Подключенные БВС',
  'online.assetCard.speed': 'Скорость',
  'online.assetCard.kmh': 'км/ч',
  'online.assetCard.course': 'Курс',
  'online.assetCard.location': 'Координаты',
  'online.assetCard.altitude': 'Высота',
  'online.assetCard.lastUpdated': 'Обновлено',
  'online.assetCard.last1h': '1 час',
  'online.assetCard.last8h': '8 часов',
  'online.assetCard.last24h': '24 часа',
  'online.assetCard.showTrack': 'Показать маршрут',
  'online.assetCard.showVideoStream': 'Показать видео',
  'online.assetCard.changeCoordinates': 'Изменить координаты',
  'online.assetCard.coordinates': 'Координаты',
  'online.assetCard.tooltip.coordinates': 'Изменить координаты',
  'online.assetCard.showVideoStreamFromGCS': 'Показать видео с НСУ',
  'online.assetCard.openDetailsPage': 'Подробная информация',
  'online.assetCard.showOnMap': 'Показать на карте',
  'online.assetCard.device.revoke': 'Отозвать',
  'online.assetCard.device.enable': 'Восстановить',
  'online.assetCard.tooltip.revoke': 'Отозвать сертификат',
  'online.assetCard.tooltip.enable': 'Восстановить сертификат',
  'online.assetCard.locality': 'Населённый пункт',

  'online.multiVideoPlayers.actions.zoomIn': 'Приблизить',
  'online.multiVideoPlayers.actions.zoomOut': 'Уменьшить',
  'online.fieldOfView': 'Зоны видимости камер',
  'online.fieldOfViewToggle.show': 'Отобразить область видимости камеры',
  'online.fieldOfViewToggle.hide': 'Скрыть область видимости камеры',

  'online.assetCard.diagnosticFlag.0': 'Отказ магнитометра',
  'online.assetCard.diagnosticFlag.1': 'Нет связи с приёмником СНС',
  'online.assetCard.diagnosticFlag.2': 'Прогрев САУ',
  'online.assetCard.diagnosticFlag.3': 'Провал по высоте',
  'online.assetCard.diagnosticFlag.4': 'Критическая скорость или ошибка ПВД >6',
  'online.assetCard.diagnosticFlag.5': 'Нет координат от СНС',
  'online.assetCard.diagnosticFlag.6': 'Вероятность обледенения',
  'online.assetCard.diagnosticFlag.7': 'Потеря тяги',
  'online.assetCard.diagnosticFlag.8': 'Не подключён отцеп',
  'online.assetCard.diagnosticFlag.9': 'Отсутствует АС (инверсный)',
  'online.assetCard.diagnosticFlag.10': 'Отказ двигательной установки',
  'online.assetCard.diagnosticFlag.11': 'Нет связи с двигательной установкой',
  'online.assetCard.diagnosticFlag.12': 'Перегрузка сервоприводов',
  'online.assetCard.diagnosticFlag.13': 'Запись в тегру',
  'online.assetCard.diagnosticFlag.14': 'Совпадение сетевых адресов',
  'online.assetCard.diagnosticFlag.15': 'Отсутствие цепи взвод',
  'online.assetCard.diagnosticFlag.16': 'Отсутствие цепи подрыв',
  'online.assetCard.diagnosticFlag.17': 'Контроль ПЗ для БСС',
  'online.assetCard.diagnosticFlag.18': 'Не прошли предполётные проверки',
  'online.assetCard.diagnosticFlag.19': 'Внешнее воздействие',
  'online.assetCard.diagnosticFlag.20': 'Самолет в полёте',

};
