import { useMarktypesQuery } from '@/.graphql/graphql';

export type TypeMarkType = {
    id: string,
    name: string,
    image: string,
};

export const dataMarkTypes = (): TypeMarkType[] | [] => {
    const [query] = useMarktypesQuery();
    return query.data?.mark_types || [];
};
