import { DiKeys } from '@/shared/di/global';
import { inject, injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';
import { type UserOptions } from '@/entities/userOptions/interface';

@injectable()
export class PageLayoutStore {
  public static readonly diKey = Symbol.for('PageLayoutStore');

  constructor(
    @inject(DiKeys.userOptions)
    private userOptions: UserOptions,
  ) {
    makeAutoObservable(this);
  }

  public get shouldRenderHeader(): boolean {
    return this.userOptions.isLoggedIn;
  }
}
