import { FC, PropsWithChildren } from 'react';
import { ModalPopup } from '@workspace/4Z1.uikit.react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import './styles.scss';

interface Props extends PropsWithChildren {
  readonly confirmText: string;
  readonly onConfirm: () => void;
  readonly onCancel: () => void;
  readonly title: string;
  readonly isOpen: boolean;
  readonly className?: string;
}

export const ActionConfirmModal: FC<Props> = ({
  onConfirm,
  onCancel,
  title,
  confirmText,
  isOpen,
  children,
  className,
}) => {
  const intl = useIntl();

  return (
    <ModalPopup
      isOpen={isOpen}
      title={title}
      buttons={[
        {
          label: confirmText,
          type: 'badge',
          onClick: onConfirm,
        },
        {
          label: intl.formatMessage({ id: 'button.Abort' }),
          type: 'accent',
          onClick: onCancel,
        },
      ]}
      onClose={onCancel}
      className={classNames('actionConfirmModal', className)}
    >
      {children}
    </ModalPopup>
  );
};
