import { Base } from './Base';
import { Onlines } from './Onlines';
import {  SimpleMapCard } from './SimpleMapCard';

/** Библиотека шаблонов карт */
let Maps = {
  Base: Base,
  Onlines: Onlines,
  SimpleMapCard: SimpleMapCard,
}

export { Maps }
