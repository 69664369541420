import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'react-intl';
import { useModel } from 'umi';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer, Group as LayerGroup, } from 'ol/layer';
import GeoJSON from 'ol/format/GeoJSON';
import Feature from 'ol/Feature';
import { Style, Icon, Stroke, Fill } from 'ol/style';

import { dataMarkTypes } from '@/components/MarkTypes';
import { useMarksQuery } from '@/.graphql/graphql';
import { circular } from 'ol/geom/Polygon';
import { useStore } from "@/shared/store/context";
import { observer } from "mobx-react";
import { LayersFlags } from '@/shared/map';

export type TypeMark = {
  id: string,
  name: string,
  coord: string,
  mark_type_id: string,
  radius: number,
  color_radius: string,
}

const hex2rgba = (hex: any, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x: any) => parseInt(x, 16));
  return [r, g, b, alpha];
};

/**
 * @deprecated
 */
export const Marks = observer((props: any) => {
  const { map } = props;
  const intl = useIntl();
  const { ether } = useStore();
  const [cookies] = useCookies(['layers']);
  const { initialState, setInitialState } = useModel('@@initialState');
  const initOptions = initialState?.currentOptions;
  const date_range = initOptions?.date_range?.main || [];

  const mark_types = dataMarkTypes();
  const [query, refreshMarks] = useMarksQuery({
    variables: {
      date_range: date_range?.join(' - '),
    },
  });
  const data = query.data?.marks;

  const defautlScale: number = .7;
  const _title = intl.formatMessage({ id: 'module.marks' });
  const f_title = intl.formatMessage({ id: 'mark.Title' });

  function singleStyle(mark: any, scale: number = defautlScale) {
    const type = mark_types.find((item: any) => item.id == mark.mark_type_id);
    return new Style({
      image: new Icon({
        src: 'data:image/svg+xml;utf8,' + type?.image,
        size: [50,50],
        scale: scale,
      })
    });
  }

  useEffect(() => {
    removeLayer('marks');
    if (data && data.length && mark_types.length) {
      const source = new VectorSource();
      data.map((mark: any, feature: Feature) => {
        var feature = new GeoJSON().readFeature(mark.center.geom, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        });
        feature.setId(mark.id);
        feature.set('className', 'layer-mark-' + mark.id);
        feature.set('name', f_title + ' - ' + mark.name);
        feature.set('type', 'mark');
        feature.setStyle(singleStyle(mark));

        const circleFeature = new Feature();
        if (mark.radius > 0) {
          const circle = circular(mark.center.coordinates, mark.radius * 1000);
          circleFeature.setGeometry(circle.transform('EPSG:4326', 'EPSG:3857'));
          circleFeature.setStyle(
            new Style({
              fill: new Fill({
                color: 'transparent',
              }),
              stroke: new Stroke({
                color: hex2rgba(mark.color_radius),
                width: 2,
              }),
            }),
          );
        }
        source.addFeatures([feature, circleFeature]);
      });

      const layer = new VectorLayer({
        source: source,
        visible: cookies.layers.marks ? true : false,
        zIndex: 7,
      } as BaseLayerOptions);

      var group_marks = new LayerGroup({
        title: _title,
        name: 'marks',
        className: 'marks',
        visible: cookies.layers.marks ? true : false,
        layers: [layer],
      } as GroupLayerOptions);

      group_marks.set(LayersFlags.DisableChildrenVisibilityChange, true);

      map.addLayer(group_marks);
    }
  }, [data, mark_types]);

  function getLayer(layer_name: string) {
    return map.getLayers().getArray().find((layer: any) => layer.className_ == layer_name);
  }

  function removeLayer(layer_name: string) {
    var layer = getLayer(layer_name);
    if (layer) map.removeLayer(layer);
  }

  useEffect(() => {
    refreshMarks({ requestPolicy: 'network-only' });
  }, [ether.marx]);

  useEffect(() => {
    return () => {
      //removeLayer('marks');
    };
  }, []);

  return <></>;
});
