import './styles.scss';

import { type FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useOutsideClick from '@workspace/4Z1.uikit.react/src/shared/hooks/useOutsideClick';
import { MapPluginProps } from '@/shared/map/model/interfaces';
import { coordinatesToString, implicitMapCheck } from '@/shared/map';
import MarkModal from '@/components/Map/ContexMenu/modalMark';
import VltModal from '@/components/Map/ContexMenu/modalVlt';
import { MenuItems } from './MenuItems';
import { MenuItem } from '../model/interfaces';
import { ContextMenuStore } from '../model/ContextMenu.store';

interface Props extends MapPluginProps {
  items: MenuItem[];
}

/**
 * Еще примеры добавления кнопки в контекстное меню, удалить комментарий при выполнении задачи: https://jira.eds.loc/browse/REDR-797
 *
 * {render: <ModalMark/>}
 *
 * {render: (store) => Boolean(store.markId) ? <RemoveMark /> : undefined},
 *
 * {title: 'Еще один способ создании кнопки', action: () => alert('action'), isEnabled: (store) => Boolean(store.markId)},
 *
 * {render: <RemoveMark />, isEnabled: (store: ContextMenuStore) => Boolean(store.markId) },
 *
 * {cmiRemoveMark(()} (showDelete - логика по отображению кнопки)
 */
export const ContextMenu: FC<Props> = observer(({
  map = implicitMapCheck(),
  items,
}) => {

  const [store] = useState(() => new ContextMenuStore(map));

  const contextMenuCallback = useCallback((event: MouseEvent) => {
    event.preventDefault();
    store.showContextMenu(event, items);
  }, [store]);

  useEffect(() => {
    map.container.addEventListener('contextmenu', contextMenuCallback);

    return () => {
      map.container.removeEventListener('contextmenu', contextMenuCallback);
    }
  }, [map]);

  const ref = useOutsideClick(() => {
    store.hideContextMenu();
  });

  return (
    <>
      {store.show &&
        <div className='contextMenu' ref={ref} style={{...store.position}}>
          <MenuItems store={store}/>
        </div>
      }
      {/*
        TODO Старый код модальных окон.
          После их редизайна, они буду располагаться в компонентах фич,
          отвечающих за их логику отображения контекстного меню
      */}
      <MarkModal
        markID={store.markId}
        deleteMark={store.deleteMark}
        coord={coordinatesToString(store.coordinates)}
        modalOpen={store.markModalOpen}
        onModalChange={() => {
          store.markModalOpen = false;
          store.deleteMark = false;
          store.markId = undefined
        }}
      />
      <VltModal
        coord={coordinatesToString(store.coordinates)}
        modalOpen={store.vltModalOpen}
        onModalChange={() => {
          store.vltModalOpen = false
        }}
      />
    </>
  )
})
