export default {
  'login.submit': 'Submit',
  'login.username.label': 'Username',
  'login.username.required': '«Username» required',
  'login.password.label': 'Password',
  'login.password.required': '«Password» required',
  'login.rememberMe': 'Remember me',
  'login.username.password.error': 'Incorrect login or password',
  'login.username.password.success': 'Successful authorization',
  'login.accountLogin.errorMessage': 'Error Login',
  'login.input.enter-login': 'Enter login',
  'login.input.enter-password': 'Enter password',
}
