import { cloneElement } from 'react';
import { makeAutoObservable } from 'mobx';
import { MapEngine } from '@/shared/map/model/interfaces';
import { Coordinates } from '@workspace/4Z1.ts.utils';
import { MenuItem } from './interfaces';

type Event = MouseEvent | undefined;

export class ContextMenuStore {

  /** Флаг отображения контекстного меню */
  show: boolean = false;
  /** Элементы контекстного меню */
  items: MenuItem[] = [];

  // region
  // TODO параметры для модальных окон со старыми компонентами.
  //  Избавиться от них, когда будут реализованы новые модальные окна
  /** Флаг открытия модального окна Маркера */
  markModalOpen: boolean = false;
  /** Флаг состояния удаления для модального окна Маркера */
  deleteMark: boolean = false;
  /** Идентификатор маркера модального окна Маркера */
  markId: string | number | undefined;
  /** Флаг открытия модального окна Происшествия */
  vltModalOpen: boolean = false;

  /** Событие клика по карте */
  private _event: Event;
  /** Позиционирование контекстного меню */
  private _position = { top: 0, left: 0 };
  /** Координаты в которых был произведен клик по карте */
  private _coordinates: Coordinates | undefined = undefined;
  // endregion

  constructor(private readonly map: MapEngine) {
    makeAutoObservable(this);
  }

  get position() {
    return this._position;
  }

  get event() {
    return this._event;
  }

  get coordinates() {
    return this._coordinates;
  }

  public hideContextMenu() {
    this._event = undefined;
    this.show = false;
  }

  public showContextMenu(event: Event, items: MenuItem[] = []): void {
    this.show = true;
    this._event = event;

    if (event !== undefined) {
      this.setPosition(event);
      this.setCoordinates(event);
      this.setMark(event);
      this.addItems(items);
    }
  }

  private setCoordinates(event: MouseEvent) {
    this._coordinates = this.map.getCoordinatesByUIEvent(event);
  }

  private addItems(items: MenuItem[]) {
    this.items = [];

    items.forEach((item: MenuItem) => {
      // Если у элемента меню есть метод isEnabled и его значение false, то элемент не показываем
      if (item?.isEnabled && !item.isEnabled(this)) return;

      // Добавляем элемент с шаблоном компонента
      if (item.render) {
        if (typeof item.render === 'function') {
          const template = item.render(this);
          if (template !== undefined) {
            this.items.push({render: cloneElement(template, {store: this})})
          }
        } else {
          this.items.push({render: cloneElement(item.render, {store: this})})
        }
      }

      // Добавляем элемент с заголовком и действием
      if (item.title) {
        this.items.push(item);
      }
    })
  }

  private setPosition(event: MouseEvent) {
    this._position = {top: event.layerY, left: event.layerX};
  }

  private setMark(event: MouseEvent) {
    const hasMark = this.map.getFeatureByUIEvent(event, 'type', 'mark');

    if (hasMark) {
      this.markId = hasMark.getId();
    } else {
      this.markId = undefined;
    }
  }
}
