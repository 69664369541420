import { makeAutoObservable } from 'mobx';

/**
 *  Ether - эфир.
 *  Стор для эфемерных вещей, которые призваны упростить жизнь на переходный период — когда у нас еще много старого кода,
 *  который должен работать не хуже нового.
 *
 *  По идее все вещи расположенные тут являются костылями и должны быть пересмотрены в пользу вещей не являющимися костылями.
 */
export class EtherStore {

  constructor() {
    makeAutoObservable(this);
  }

  // region управление рефрешем меток
  private _marx = 0;

  public get marx(): number {
    return this._marx;
  }

  public refreshMarx(): void {
    this._marx += 1;
  }

  // endregion

  // region управление рефрешем происшествий (violations - стар, incidents - нов)
  private _incidents = 0;

  public get incidents(): number {
    return this._incidents;
  }

  public refreshIncidents(): void {
    this._incidents += 1;
  }

  // endregion
}
