import '../styles.scss';

import React, { FC, useRef } from 'react';
import { Map, MAP_LAYERS } from '@/shared/map';
import { CursorCoordinatesPanel, Measure, SearchOnArea, ZoomPanel, ZoomPosition } from '@/features/map';
import { LayersPanel, LayersPanelPosition } from '@/features/map/LayersPanel';
import { FlightsByDateAndArea } from '@/widgets/FlightsByDateAndArea';
import { SinglePointLayer } from '@/features/map/layers';
import { Coordinates } from '@workspace/4Z1.ts.utils';

interface Props {
  readonly point?: Coordinates;
}

export const SimpleMapCard: FC<Props> = ({point}) => {
  const mapTools = useRef<HTMLInputElement>(null);

  return (
    <>
      <div className='simpleMapCard'>
        <div ref={mapTools} className='map-tools'></div>
        <Map>

          <ZoomPanel position={ZoomPosition.BaseLayoutTopRight}/>

          <SearchOnArea
            target={mapTools}
            widgetResult={<FlightsByDateAndArea />}
            pluginName={'searchOnArea'}
          />

          <Measure
            ruleTarget={mapTools}
            areaTarget={mapTools}
            clearTarget={mapTools}
            pluginName={'measure'}
          />

          <LayersPanel
            position={LayersPanelPosition.TopRight}
            target={mapTools}
            layerSortingTemplate={[...MAP_LAYERS]}
          />

          <SinglePointLayer coordinates={point} />

          <CursorCoordinatesPanel />

        </Map>
      </div>
    </>
  )
}
