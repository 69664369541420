import { type FC } from 'react';
import { AssetCardProps } from '@/widgets';
import { Text } from "@workspace/4Z1.uikit.react";

/**
 * Комментарий к объекту в AssetCard
 * @param asset
 */
export const Comment: FC<AssetCardProps> = ({asset}) => {

  if (!asset.comment) {
    return <></>;
  }

  return<Text.Auto> {asset.comment} </Text.Auto>
}
