import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Exception } from '@workspace/4Z1.uikit.react';
import { BlankLayout } from '@/layouts';
import { PublicPageLayout } from '@/layouts';
import './styles.scss';

const ForbiddenPage: FC = () => {
  const intl = useIntl();

  return (
    <BlankLayout>
      <PublicPageLayout headerFixed>
        <div className="exception">
          <Exception.Forbidden>
            {intl.formatMessage({ id: '403' })}
          </Exception.Forbidden>
        </div>
      </PublicPageLayout>
    </BlankLayout>
  );
};

export default ForbiddenPage;
