import './styles.scss';
import { FC } from 'react';
import { FileItem } from '../../model/interface';
import FileProgress from '@/components/FileProgress/FileProgress';
import { Button, ClockIcon, DeleteIcon, DoneIcon, FileBadge, Tooltip } from '@workspace/4Z1.uikit.react';
import classNames from 'classnames';
import { UploadingFileStates } from '@/entities/fileUpload';
import { formatBytes } from '@workspace/4Z1.ts.utils';
import { useIntl } from 'react-intl';

interface Props {
    readonly file: FileItem;
    readonly size?: 'small' | 'big';
}

const UploadFileListItem: FC<Props> = ({file, size}) => {
  const intl = useIntl()

  return (
    <div className={classNames('uploadFileListItemContainer', size)}>
        <FileBadge fileName={file.fileName} progress={file.progress} />
        <div className='uploadFileInfoContainer'>
            {file.fileName && (
                <div className='uploadFileTitleContainer'>
                    <p className='uploadFileName'>{file.fileName}</p>
                    {
                        (() => {
                            switch (file.state) {
                                case UploadingFileStates.Completed:
                                    return <div className='uploadCheck'><DoneIcon /></div>;
                                case UploadingFileStates.Queued:
                                    return <div className='uploadQueued'><ClockIcon /></div>
                                default:
                                    return undefined;
                            }
                        })()
                    }
                </div>
            )}
            <FileProgress progress={file.progress} startDate={`${file.startDate}`} fileSize={`${formatBytes(file.size ?? 0)}`}/>
            {file.error && <p className='uploadErrorText'>{intl.formatMessage({ id: file.error })}</p>}
        </div>
        <div className='uploadFileButtonContainer'>
            {file.onClose && (
                <Tooltip theme='light' text={file.closeButtonTooltip} direction='top-center'>
                    <Button type='badge' onlyIcon onClick={file.onClose} icon={<DeleteIcon/>} />
                </Tooltip>
            )}
        </div>
    </div>
  );
};

export default UploadFileListItem;
