import { useState, useCallback, useRef } from 'react';

interface FullscreenAPI {
  readonly isFullscreen: boolean;
  /**
   * Входит в полноэкранный режим для указанного элемента или всего документа.
   * @param {HTMLElement | null} [element] - Элемент, который будет развернут на весь экран. По умолчанию document.documentElement.
   */
  readonly enterFullscreen: (element?: HTMLElement | null) => void;

  /**
   * Выходит из полноэкранного режима.
   */
  readonly exitFullscreen: () => void;

  /**
   * Переключает состояние полноэкранного режима.
   * @param {HTMLElement | null} [element] - Элемент для входа в полноэкранный режим, если в данный момент режим выключен.
   */
  readonly toggleFullscreen: (element?: HTMLElement | null) => void;
}

interface FullscreenElement extends HTMLElement {
  readonly webkitRequestFullscreen?: () => Promise<void>;
  readonly mozRequestFullScreen?: () => Promise<void>;
  readonly msRequestFullscreen?: () => Promise<void>;
}

interface FullscreenDocument extends Document {
  readonly webkitExitFullscreen?: () => Promise<void>;
  readonly mozCancelFullScreen?: () => Promise<void>;
  readonly msExitFullscreen?: () => Promise<void>;
}

/**
 * Хук для управления полноэкранным режимом.
 * Позволяет входить, выходить и переключать полноэкранный режим для элементов DOM.
 * @returns {FullscreenAPI} Объект с состоянием полноэкранного режима и методами управления.
 */
export const useFullscreen = (): FullscreenAPI => {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const elementRef = useRef<HTMLElement | null>(null);

  const enterFullscreen = useCallback((element?: HTMLElement | null) => {
    const target = (element || elementRef.current || document.documentElement) as FullscreenElement;

    if (target.requestFullscreen) {
      target.requestFullscreen().then(() => setIsFullscreen(true));
    } else if (target.webkitRequestFullscreen) {
      target.webkitRequestFullscreen().then(() => setIsFullscreen(true));
    } else if (target.mozRequestFullScreen) {
      target.mozRequestFullScreen().then(() => setIsFullscreen(true));
    } else if (target.msRequestFullscreen) {
      target.msRequestFullscreen().then(() => setIsFullscreen(true));
    }
  }, []);

  const exitFullscreen = useCallback(() => {
    const doc = document as FullscreenDocument;

    if (doc.exitFullscreen) {
      doc.exitFullscreen().then(() => setIsFullscreen(false));
    } else if (doc.webkitExitFullscreen) {
      doc.webkitExitFullscreen().then(() => setIsFullscreen(false));
    } else if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen().then(() => setIsFullscreen(false));
    } else if (doc.msExitFullscreen) {
      doc.msExitFullscreen().then(() => setIsFullscreen(false));
    }
  }, []);

  const toggleFullscreen = useCallback(
    (element?: HTMLElement | null) => {
      if (isFullscreen) {
        exitFullscreen();
      } else {
        enterFullscreen(element);
      }
    },
    [isFullscreen, enterFullscreen, exitFullscreen],
  );

  return {
    isFullscreen,
    enterFullscreen,
    exitFullscreen,
    toggleFullscreen,
  };
};
