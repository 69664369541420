import './styles.scss';

import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FileList } from '@/components/FileList';
import Dropzone from '@/components/Dropzone/Dropzone';
import { UploaderStore } from '../store/uploadFiles.store';
import { dep, diInject } from '@/HOC';
import { useIntl } from 'react-intl';
import { Button, ClipIcon, Skeleton, UsbIcon } from '@workspace/4Z1.uikit.react';
import { useAccess } from '@umijs/max';

interface Props {
  readonly uploaderStore: UploaderStore;
}

const UploadFiles = ({ uploaderStore }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const access = useAccess();

  useEffect(() => {
    navigate('/downloads/upload', { replace: true });
  }, []);

  const handleFilesChange = useCallback((incomingFiles: File[]) => {
    if (incomingFiles.length === 0) {
      return;
    }
    uploaderStore.onFilesSelected(incomingFiles);
  }, [uploaderStore]);

  useEffect(() => {
    uploaderStore.mount();

    return () => {
      uploaderStore.unmount();
    };
  }, []);

  const additionalButtons = useMemo(() => (
    <>
      { 
        (access['zalaflight/ftp']) && 
        (access['canAdmin']) && 
        <Button
          type='badge'
          size='small'
          icon={ <ClipIcon.Small/> }
          iconPosition='left'
          onClick={(e: { stopPropagation: () => void; })=>{
            e.stopPropagation();
            navigate('/admin/telemetry')
          }}
          label={intl.formatMessage({ id: 'massload.loading.telemetry' })}
        />
      }
      <Button
        type='badge'
        size='small'
        icon={<UsbIcon.Small />}
        iconPosition='left'
        disabled={!uploaderStore.isUsbUploadEnabled}
        onClick={(e: { stopPropagation: () => void; }) => {
          e.stopPropagation();
          uploaderStore.onUsbUploadClicked();
        }}
        label={intl.formatMessage({ id: 'button.UploadUSB' })}
      />
    </>
  ), [access, intl, navigate, uploaderStore.isUsbUploadEnabled]);

  return (
    <div className='uploadFilesContainer'>
      <Dropzone
        size={'big'}
        type={'button'}
        onChange={handleFilesChange}
        additionalButtons={additionalButtons}
      />
      {uploaderStore.sessions.map((session, index) => (
        <div key={index} className='fileList'>
          <FileList
            title={session.title && intl.formatMessage({id: session.title})}
            errorMessage={session?.error ? intl.formatMessage({ id: session?.error }) : undefined}
            files={session.files.map(file => ({
              ...file,
              closeButtonTooltip: intl.formatMessage({ id: 'massload.loading.remove' })
            }))}
            badgeCount={session.badgeCount}
            onCloseList={session.onClose}
          />
        </div>
      ))}
    </div>
  );
};

export default diInject(UploadFiles, {
  uploaderStore: dep(UploaderStore),
});