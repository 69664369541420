import { isNil } from 'lodash';
import { UploadHistoryFile, UploadHistoryFileStatuses, UploadStatus } from '@/entities/uploadHistory';
import { logger } from '@workspace/4Z1.ts.utils';
import { createRestApi } from '@/shared/api/rest';
import urlJoin from 'url-join';
import { ZalaType } from '@/entities/zalaType';
import { mockDataList } from './utils';

const log = logger('Api:UploadHistoryFile');

export interface UploadHistoryApi {
  readonly fetchFiles: (flightId: string) => Promise<readonly UploadHistoryFile[]>;
}

interface FilesResponse {
  readonly createdAt: string;
  readonly fileName: string;
  readonly fileSize: number;
  readonly fileType: string;
  readonly finishedAt: string;
  readonly flightId: string;
  readonly id: number;
  readonly offset: number;
  readonly status: UploadStatus;
  readonly username: string;
  readonly zalaFileType: ZalaType;
}

class UploadHistoryImpl implements UploadHistoryApi {
  private static readonly API_BASE = API;

  private static readonly FILES = '/files/search';

  private static readonly PATHS = {
    searchFiles: urlJoin(UploadHistoryImpl.API_BASE, UploadHistoryImpl.FILES),
  } as const;

  private readonly client = createRestApi();

  async fetchFiles(flightId: string): Promise<readonly UploadHistoryFile[]> {
    const response = await this.client.post<readonly FilesResponse[]>(UploadHistoryImpl.PATHS.searchFiles, {
      /**
       * TODO - Реализовать утилиту для удобной фильтрации/сортировки по многим полям
       *
       * @example filters.getQuery() // возвращает структуру данных для передачи апишке
       */
      filters: [
        {
          field: 'flightId',
          logic: 'and',
          filterValues: [
            {
              operator: 'eq',
              value: flightId,
            },
          ],
        },
      ],
      sorts: [
        {
          field: 'createdAt',
          isDescending: true,
        },
      ],
    });

    const rawFiles = resolveRawFiles(response?.data);

    return transformUploadHistoryFiles(rawFiles);
  }
}

export const createUploadHistoryFileApi = (): UploadHistoryApi => {
  return new UploadHistoryImpl();
};

const FILE_STATUSES_MAP: Partial<Record<UploadStatus, UploadHistoryFileStatuses>> = {
  [UploadStatus.Error]: UploadHistoryFileStatuses.Error,
  [UploadStatus.Finished]: UploadHistoryFileStatuses.Loaded,
  [UploadStatus.CreateUpload]: UploadHistoryFileStatuses.Loading,
  [UploadStatus.Processing]: UploadHistoryFileStatuses.Processing,
  [UploadStatus.Uploading]: UploadHistoryFileStatuses.Loading,

  // TODO - Уточнить, каким должно быть отображение каждого статуса
  [UploadStatus.Archived]: UploadHistoryFileStatuses.Loaded,
  [UploadStatus.Removed]: UploadHistoryFileStatuses.Error,
  [UploadStatus.Rejected]: UploadHistoryFileStatuses.Error,
  [UploadStatus.Stored]: UploadHistoryFileStatuses.Loaded,
  [UploadStatus.Unknown]: UploadHistoryFileStatuses.Error,
  [UploadStatus.Uploaded]: UploadHistoryFileStatuses.Error,
  [UploadStatus.Terminated]: UploadHistoryFileStatuses.Error,
} as const;

const transformUploadHistoryFiles = (files: readonly FilesResponse[]): readonly UploadHistoryFile[] => {
  return files.map(file => ({
    id: file.id.toString(),
    type: file.fileType,
    fileName: file.fileName,
    fileSize: file.fileSize,
    progress: file.offset,
    uploadedSize: file.offset,
    zalaType: file.zalaFileType,
    status: parseFileStatus(file.status),
    createdAt: file.createdAt,
  }));
};

const parseFileStatus = (status: UploadStatus): UploadHistoryFileStatuses => {
  /**
   * TODO - Необходимо определить поведение функции, если тип статуса не был найден
   *
   * На данный момент проверка ?? status является костылем, чтобы отображать пустую строчку вместо статуса
   * В будущем необходимо обрабатывать все возможные статусы
   */
  const mappedStatus = FILE_STATUSES_MAP[status] ?? status;

  if (isNil(mappedStatus)) {
    log.error('An unknown status met during status parsing :: ', status);
  }

  return mappedStatus;
};

/**
 * @dev-only
 * Возвращает файлы из предоставленного массива необработанных файлов истории загрузки (rawFiles)
 * Эта функция используется в режиме разработки для тестирования с использованием локальных настроек из `localStorage`.
 * 
 * Если ключ dev__dhistMocks указан в localStorage, то вернет моковые данные, иначе вернет сырые данные без обработки
 */
const resolveRawFiles = (rawFiles: readonly FilesResponse[]): readonly FilesResponse[] => {
  const devLocalStorageKeys = {
    replaceWithMocks: 'dev__dhistMocks',
  } as const;

  const shouldReplaceWithMocks: string | undefined =
    localStorage.getItem(devLocalStorageKeys.replaceWithMocks) ?? undefined;

  if (shouldReplaceWithMocks) {
    log.debug('Received dev__dhistMocks ::', shouldReplaceWithMocks);
    log.debug('Forcing resolver to return mockData', mockDataList);
    return mockDataList;
  }

  return rawFiles;
};
