import { injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';
import { FlightWorkflowStatus } from './interfaces';
import { Flight } from '../api/flight.api';
import { createFlightStatusesApi } from '../api/flightStatuses.api';
import { isEmpty } from 'lodash';

export interface FlightWorkflowStatusForFlight extends FlightWorkflowStatus {
  readonly condition: 'wait' | 'complete';
}

@injectable()
export class FlightWorkflowService {

    public static readonly diKey = Symbol.for('FlightWorkflowService');

    private values: readonly FlightWorkflowStatus[] = [];

    constructor(
      private readonly api = createFlightStatusesApi(),
    ) {
       makeAutoObservable(this);
    };
    
    /**
     * Принудительный (без учета кэширования) запрос списка статусов полета. 
     * После успешного запроса - данные закэшируются до следующего явного вызова этого метода.
     */
    public fetchStatuses(): Promise<readonly FlightWorkflowStatus[]> {
      return this.api.fetchStatuses().then(data => {
        this.values = [...data];
        return this.values;
      });
    }

    public get statusList(): readonly FlightWorkflowStatus[] {
      this.checkCache();
      return this.values;
    }

    public getStatusForFlight(flight: Flight): readonly FlightWorkflowStatusForFlight[] {
      this.checkCache();
      return this.values.map(status => ({ ...status, condition: status.id <= flight.workflowStatus ? 'complete' : 'wait'}));
    }

    public checkCache() {
      if (isEmpty(this.values)) {
        this.fetchStatuses();
      }
    }
}