import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withCookies } from 'react-cookie';
import request from 'umi-request';

import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { BaseLayerOptions } from 'ol-layerswitcher';
import { Map, TileCache } from 'ol';
import { GeoJSON } from 'ol/format';
import { Fill, Stroke, Style } from 'ol/style';
import { Coordinate } from 'ol/coordinate';

class VPRF_DZ extends Component<any, any> {
  cache    = new TileCache();
  map: Map = this.props.map;
  layer    = new VectorLayer();
  source   = new VectorSource();
  tag = 'DZ';
  _tiles   = '8:4:4,9:4:4,10:4:4,11:4:4,12:4:4,13:4:4,8:5:4,9:5:4,10:5:4,11:5:4,12:5:4,13:5:4,7:3:4,8:3:4,9:3:4,10:3:4,11:3:4,12:3:4,13:3:4,14:3:4,7:4:4,14:4:4,7:5:4,14:5:4,7:6:4,8:6:4,9:6:4,10:6:4,11:6:4,12:6:4,13:6:4,14:6:4';

  constructor(props: any) {
    super(props);

    this.state = {
      coord: [],
    };

    const { intl } = this.props;
    this.layer = new VectorLayer({
      name: 'dz',
      className: 'dz',
      title: intl.formatMessage({
        id: 'layer.vprf.dz',
        defaultMessage: 'Danger zones',
      }),
      source: this.source,
      visible: this.props.allCookies.layers.dz ? true : false,
      zIndex: 2,
      minZoom: this.props.minZoom,
      maxZoom: this.props.maxZoom,
    } as BaseLayerOptions);
  }

  componentDidMount() {
    this.source.setLoader(this.loader);
    this.props.addLayer(this.layer);
  }

  componentDidUpdate() {
    if (this.props.feature.get('type') === this.tag && this.props.feature.get('coord') !== this.state.coord){
      const id    = this.props.feature.getId();
      const coord = this.props.feature.get('coord');

      this.showPopup(coord, id);

      this.setState({coord: coord});
    }
  }

  componentWillUnmount() {
    this.map.removeLayer(this.layer);
  }

  showPopup = (coord: number[], id: any) => {
    this.cache.containsKey(id)
      ? this.setHTML(coord, this.cache.get(id)) 
      : this.getInfo(coord, id);
  }

  getInfo = (coord: number[], id: any) => {
    const url = this.props.urlApi+this.props.urlInfo+`[${this.tag}]=`+id;
    request(url, {
      method: 'get',
      //headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.length) {
          this.cache.set(id, response[0]);
          this.setHTML(coord, response[0]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setHTML = (coord: number[], props: any) => {
    let html = 
      `Опасная зона ${props.index || ''} <b>${props.name}</b><br/><br/>
      <b>Описание:</b> ${props.description?.replaceAll('\n', '<br/>')}<br/>
      <b>Вертикальные границы:</b> ${props.alts?.str}: ${props.alts?.range[0]} м - ${props.alts?.range[1]} м<br/>
      <b>Расписание:</b> ${props.schedule?.str?.replaceAll('\n', '<br/>')}<br/>`
    
    this.props.setHtml(coord, html);
  }

  loader = () => {
    const url = this.props.urlApi+'/'+this.tag+this.props.urlTiles+this._tiles;
    request(url, {
      method: 'get',
      //headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        this.setSource(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setSource = (data: any) => {
    if (data.length)
    {
      data.map((row: any) => {
        if (row.path.length) {
          const geom: Coordinate[] = [];
          row.path[0].map((coors: number[]) => {
            geom.push([coors[1], coors[0]]); 
          });
          const GeoJson = {
            "type": "Polygon",
            "coordinates": [geom],
            "crs": {"type": "name","properties": {"name": "EPSG:4326"}}
          };
          const feature = new GeoJSON().readFeature(GeoJson, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          });
          feature.setId(row.id);
          feature.set('type', row.type);
          feature.set('typeClick', 'vprf');
          feature.setStyle(
          [
            new Style({
              fill: new Fill({
                color: 'transparent',
              }),
              stroke: new Stroke({
                width: 2,
                color: 'rgba(255, 255, 0, 1)',
              }),
            })
          ]
          );
          
          this.source.addFeature(feature);
        }
      });
      this.layer.setSource(this.source);
    }
  }

  render() {
    return (
      <></>
    );
  }
}

export default injectIntl(withCookies(VPRF_DZ));
