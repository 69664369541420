import { AssetsService } from './Assets.service';
import { inject, injectable } from 'inversify';
import { Asset, type AssetListFilter, AssetTypes } from '@/entities/assets/model/types';
import { VideoStream } from '@/.graphql/graphql';
import _ from 'lodash';
import { Assets } from '@/entities/assets/model/Assets';
import { logger } from '@workspace/4Z1.ts.utils';
import { DiKeys } from '@/shared/di/global';

const log = logger('VSAST:SRV');

@injectable()
export class VideoStreamingAssetsService extends AssetsService {
  public static readonly diKey = Symbol.for('VideoStreamingAssetsService');

  private videoAssets: Asset[] = [];

  // TODO private set?
  videoStreams: VideoStream[] = [];

  constructor(
    @inject(DiKeys.assetFilter) filter: AssetListFilter,
  ) {
    super(filter);
    log.debug('constructd', filter);
  }


  protected fetch() {
    super.fetch();

    this.api.videoStreamingAssets()
      .then(result => {
        log.debug('VSA data updated', result);
        this.videoAssets = result;
      })
      .catch(error => {
        log.error('failed to fetch VSA data', error);
      });

    this.api.videoStreams()
      .then(result => {
        log.debug('Video streams data updated', result);
        this.videoStreams = result;
      })
      .catch(error => {
        log.error('failed to fetch Video streams data', error);
      });
  }

  public get onlyVideoStreams() {
    return this.list.filter(item => this.getStreamByPublicId(item.publicAssetId));
  }

  public connectedGcs(assetOrPublicId: Asset | string): Asset | undefined {
    const ast = _.isString(assetOrPublicId)
      ? this.findAssetByPublicId(assetOrPublicId)
      : assetOrPublicId as Asset;
    if (ast === undefined) {
      return undefined;
    }

    return Assets.getConnectedGCS(ast, this.list) ?? Assets.getConnectedGCS(ast, this.videoAssets);
  }

  /**
   * Возвращает непостредственно стримящий ассет.
   * Для UAV - вернет его GCS
   */
  public getRealStreamingAsset(assetOrPublicId: Asset | string): Asset | undefined {
    const asset = _.isString(assetOrPublicId)
      ? this.findAssetByPublicId(assetOrPublicId)
      : assetOrPublicId as Asset;


    if (asset === undefined ) {
      return undefined;
    }

    if (asset.assetType === AssetTypes.Gcs || asset.assetType === AssetTypes.Ptz) {
      return asset;
    }

    if (asset.assetType === AssetTypes.Uav) {
      return Assets.getConnectedGCS(asset, this._list)
        ?? Assets.getConnectedGCS(asset, this.videoAssets);
    }

    if (Assets.couldStream(asset)) {
      return asset;
    }
    return undefined;
  }


  /**
   * Возвращает либо сам стримящий ассет, либо связанный с ним (для GCS сначала будет искать активный UAV, и только потом вернет сам GCS)
   */
  public getStreamingAssetOrRelated(assetOrPublicId: Asset | string): Asset | undefined {
    const asset = _.isString(assetOrPublicId)
      ? this.findAssetByPublicId(assetOrPublicId)
      : assetOrPublicId as Asset;

    if (asset === undefined || !Assets.couldStream(asset)) {
      return undefined;
    }

    if (asset.assetType === AssetTypes.Gcs || asset.assetType === AssetTypes.Uav) {
      // получаем любой из возможных ассетов с приоритетами:
      // - тот что есть в списке
      // - uav, а потом gcs
      return Assets.getActiveUav(asset, this._list)
        ?? Assets.getConnectedGCS(asset, this._list)
        ?? Assets.getActiveUav(asset, this.videoAssets)
        ?? Assets.getConnectedGCS(asset, this.videoAssets);
    }

    return asset;
  }

  activeAsset(asset: Asset): Asset | undefined {
    if (asset.assetType === AssetTypes.Ptz) {
      return this.findAssetByPublicId(asset.publicAssetId);
    }

    if (asset.assetType === AssetTypes.Gcs) {
      const activeUavId: string | undefined = asset.state.gcsState?.activeUavAssetId;
      if (activeUavId === undefined) {
        return undefined;
      }

      return this.findAssetByPublicId(activeUavId);
    }

    log.warn('can not find active asset for:', asset.assetType, asset.publicAssetId);
    return undefined; // TODO уточнить что тут нужно сделать
  }

  protected findAssetByPublicId(id: string): Asset | undefined {
    return super.findAssetByPublicId(id) ?? this.videoAssets.find(a => a.publicAssetId === id);
  }

  public getStreamByPublicId(publicAssetId: string): VideoStream | undefined {
    const streamingAsset = this.getRealStreamingAsset(publicAssetId)

    if(!streamingAsset) return undefined

    return this.videoStreams.find(
      (asset) => asset.publicAssetId === streamingAsset?.publicAssetId,
    );
  }
}
