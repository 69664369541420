import { FC } from "react";
import { observer } from "mobx-react";
import { MapMarkersHandler, useMapMarkers } from "@/entities/map/hooks/useMapMarkers";

interface Props {
  readonly handler: MapMarkersHandler;
}


/**
 * Удобный способ вызова хука useMapMarkers из классовых компонент.
 */
export const MapMarkers: FC<Props> = observer(({ handler }: Props) => {
  useMapMarkers(handler);
  return (<></>);
});
