import { Icon, Style } from 'ol/style';

/**
 * Получение стилей для маркера
 */
export const defineStyle = (markerType: MapMarkersTypes) => {
  switch (markerType) {
    case MapMarkersTypes.Default:
      return MARKER_STYLE_DEFAULT;
  }
}

/**
 * Типы маркеров на карте
 */
export enum MapMarkersTypes {
  Default = 'default',
}

const MARKER_STYLE_DEFAULT = new Style({
  image: new Icon({
    src: '/icons/search_mark.svg',
    scale: .8
  }),
});
