import { useStore } from "@/shared/store/context";
import { useEffect } from "react";
import { LAYER_WITH_SEARCH_MARKER, MapMarker } from "@/entities/map";

export interface MapMarkersHandler {
  clearLayer(layerId: string): void;
  addMarker(marker: MapMarker): void;
}

// это лишь заготовка для компонента управления маркерами на карте
// по задумке - здесь можно добавить различные источники маркеров и отправлять их в единый хендлер
// но сейчас реализован только один сценарий - для маркеров геопоиска.

export const useMapMarkers = (handler: MapMarkersHandler) => {
  const { searchMarkerStore: store } = useStore();

  useEffect(() => {
    const marker = store.searchMarker;

    handler.clearLayer(LAYER_WITH_SEARCH_MARKER);

    if (marker !== undefined) {
      handler.addMarker(marker);
    }
  }, [store, store.searchMarker]);

}
