import { AssetDirectionStore } from '@/features/assetCard/ChangeDirection/model';
import { AssetDirectionRange } from '@/features/assetCard/ChangeDirection';
import { dep, diInject } from '@/HOC';
import { implicitMapCheck, MapPluginProps } from '@/shared/map';
import { Overlay } from 'ol';
import { useEffect, useMemo, useRef } from 'react';

interface Props extends MapPluginProps {
  readonly assetDirectionStore: AssetDirectionStore;
}

const AssetDirectionRangeLayer: React.FC<Props> = ({
  map = implicitMapCheck(),
  assetDirectionStore,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const overlay = useMemo(() => {
    return new Overlay({
      id: 'compass_overlay',
      element: ref.current ?? undefined,
      positioning: 'center-center',
      stopEvent: true,
    });
  }, [ref.current]);

  useEffect(() => {
    map.addOverlay(overlay);

    return () => {
      map.removeOverlay(overlay);
    };
  }, [overlay, map]);

  useEffect(() => {
    overlay.setPosition(
      assetDirectionStore.visible
        ? assetDirectionStore?.coordinates
        : undefined,
    );
  }, [assetDirectionStore?.coordinates, overlay, assetDirectionStore.visible]);

  return (
    <div ref={ref}>
      <AssetDirectionRange />
    </div>
  );
};

export default diInject(AssetDirectionRangeLayer, {
  assetDirectionStore: dep(AssetDirectionStore.diKey),
});
