import { CurrentOptions, CurrentUser } from '@/app';
import { Languages, LocalStorageKeys } from '../model';
import { Loggers } from '@/debug/loggers';

const logger = Loggers.sova;

interface LocalStorageSchema {
  [LocalStorageKeys.ClientLocale]: Languages;
  [LocalStorageKeys.CurrentOptions]: CurrentOptions;
  [LocalStorageKeys.CurrentUser]: CurrentUser;
  [LocalStorageKeys.HiddenFOV]: string[];
  [LocalStorageKeys.UserAvatarColor]: string;
  [LocalStorageKeys.Demo]: unknown;
}

class LocalStorage {
  public static getItems<T extends keyof LocalStorageSchema>(
    key: T,
  ): LocalStorageSchema[T] | undefined {
    try {
      const data = localStorage.getItem(key as T);
      if (!data) return undefined;

      logger.debug(
        `getting item - ${data} for key - ${key} from local storage`,
      );

      return JSON.parse(data);
    } catch (error) {
      logger.error(`failed getting item for key - ${key} from local storage`);

      throw new Error(`Failed to retrieve items from storage by key - ${key}`);
    }
  }

  public static setItems<T extends keyof LocalStorageSchema>(
    key: T,
    items: LocalStorageSchema[T],
  ): void {
    try {
      logger.debug(`setting item - ${items} for key - ${key} to local storage`);
      
      localStorage.setItem(key, JSON.stringify(items));
    } catch (error) {
      logger.error(`failed setting item for key - ${key} to local storage`);

      throw new Error(`Failed to set items in storage by key - ${key}`);
    }
  }

  public static clearItems<T extends keyof LocalStorageSchema>(key: T): void {
    try {
      logger.debug(`clearing items for key - ${key} from local storage`);

      localStorage.removeItem(key);
    } catch (error) {
      logger.error(`faield to clear items for key - ${key} from local storage`);

      throw new Error(`Failed to clear items from storage by key - ${key}`);
    }
  }
}

export const setLocalStorageItems = <T extends keyof LocalStorageSchema>(
  key: T,
  items: LocalStorageSchema[T],
): void => {
  return LocalStorage.setItems(key, items);
};

export const getLocalStorageItems = <T extends keyof LocalStorageSchema>(
  key: T,
): LocalStorageSchema[T] | undefined => {
  return LocalStorage.getItems(key);
};

export const clearLocalStorageItems = <T extends keyof LocalStorageSchema>(
  key: T,
): void => {
  return LocalStorage.clearItems(key);
};
