import { makeAutoObservable } from 'mobx';
import { FormValues, StatusSubmit } from './interfaces';

import { injectable } from 'inversify';
import { FormErrorKeys, FtpLocalizedMessages } from './utils';
import { ApiError } from '@/shared/errors';
import { isNil } from 'lodash';

export interface FtpState {
  readonly status: StatusSubmit;
}

@injectable()
export class FormHelper {
  public static readonly diKey = Symbol.for('FormHelper');

  private error: ApiError | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  public getFieldErrorLocale(key: keyof FormValues): undefined | string {
    if (isNil(this.error)) return;
    
    const ftpErrorKey = FormErrorKeys[key];
    
    if (!ftpErrorKey) {
      throw new Error(`Cannot find error-handler for a key ${key}`);
    }

    const error = this.error.getErrorsByPointer(ftpErrorKey).at(0);
    const locale = FtpLocalizedMessages[key];

    if (isNil(error) || isNil(locale)) return;

    return locale[error.code];
  }

  public get hasErrors(): boolean {
    return this.error !== undefined;
  }

  public resetError(): void {
    this.error = undefined;
  }

  public setError<T>(error: T): void {
    if (!(error instanceof ApiError)) return;

    this.error = error;
  }

  public fieldHasError(key: keyof FormValues): boolean {
    return (this.getFieldErrorLocale(key)?.length ?? 0) > 0;
  }
}
