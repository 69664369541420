import { useVltTypesQuery } from '@/.graphql/graphql';

export type TypeVltType = {
    id: string,
    name: string,
    image: string,
    color: string,
};

export const dataVltTypes = (): TypeVltType[] | [] => {
    const [query] = useVltTypesQuery();
    return query.data?.vlt_types || [];
};
