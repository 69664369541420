import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { useIntl } from 'react-intl';
import { IconMap, VisibleArea } from '@workspace/4Z1.uikit.react';
import { dep, diInject } from '@/HOC';
import { Overlay } from 'ol';
import {
  mapCoordinates,
  implicitMapCheck,
  MapPluginProps,
  useLayersVisibility,
  FIELD_OF_VIEW_LAYER
} from '@/shared/map';
import { Asset, Assets, VideoStreamingAssetsService } from '@/entities/assets/model';
import { getAssetIcon } from '@/components/Map/IconOverlay/utils/getAsseticons';
import { FieldOfViewLayerStore } from '@/entities/map/stores/FieldOfViewLayer.store';
import { DiKeys } from '@/shared/di/global';

interface Props extends MapPluginProps {
  readonly items: Asset[];
  readonly showLayer?: boolean;
  readonly showVideoStream?: (asset: Asset) => void;
  readonly selectedAssetOnMap?: (asset: Asset) => void;
  readonly streams?: VideoStreamingAssetsService;
  readonly fieldOfViewLayerStore: FieldOfViewLayerStore;
  readonly showName?: boolean;
  readonly activeItem?: Asset | undefined;
}

/** Префикс Asset идентификатора для иконки */
const ASSET_ICON_ID_PREFIX = 'asset-icon-overlay';

/** Наименования событий, которые приводят к изменениям */
const MAP_EVENTS_LEADS_TO_UPDATE = ['change:resolution', 'change:center'];

/** Метод формирования идентификатора overlay для Asset */
const getAssetOverlayId = (id: string) => `${ASSET_ICON_ID_PREFIX}-${id}`;

const AssetsIcons: FC<Props> = ({
  map = implicitMapCheck(),
  items,
  showLayer,
  showVideoStream,
  streams,
  fieldOfViewLayerStore,
  showName = true,
  selectedAssetOnMap,
  activeItem,
}) => {
  const intl = useIntl();

  /** Идентификатор выбранного Asset на карте */
  const rootMapRef = useRef<Map<HTMLElement, Root>>(new Map());
  /** Идентификатор выбранного Asset на карте */
  const [activeId, setActiveId] = useState<string | undefined>(undefined);
  /** Флаг включения слоя "Область видимости" */
  const [fieldOfViewLayer] = useLayersVisibility([FIELD_OF_VIEW_LAYER])

  const createAssetOverlay = (item: Asset): Overlay => {
    const element = document.createElement('div');
    element.className = 'icon-overlay';

    return new Overlay({
      id: getAssetOverlayId(item.id),
      element: element,
      positioning: 'center-center',
      stopEvent: false,
    });
  };

  useEffect(() => {
    setActiveId(activeItem ? activeItem.id : undefined);
  }, [activeItem]);

  const getOrCreateOverlay = (item: Asset, overlayId: string): Overlay => {
    let overlay = map.getOverlayById(overlayId);

    // явно проверяем на null - т.к. openLayer возвращает null
    if (overlay === null) {
      overlay = createAssetOverlay(item);
      map.addOverlay(overlay);
    }
    return overlay;
  }

  const getCurrentOverlays = () => new Set<string>(
    map
      .getOverlays()
      .getArray()
      .map((overlay: Overlay) => overlay.getId())
      .filter((overlayId: string) =>
        overlayId.startsWith(ASSET_ICON_ID_PREFIX)
      ),
  );

  const updateOverlays = useCallback(() => {
    if (!showLayer) {
      map.clearOverlaysList(getCurrentOverlays());
      return;
    }

    const currentOverlays = getCurrentOverlays();

    items.forEach((item: Asset) => {
      const overlayId = getAssetOverlayId(item.id);
      currentOverlays.delete(overlayId);

      const angleOfView = item?.state?.pTZCameraState?.angleOfView;
      const windDirection = item?.state?.environment?.windDirection;
      const infoLocation = item?.state?.location;

      const center = mapCoordinates(item.state.location);

      if (center === undefined) {
        return;
      }

      const overlay = getOrCreateOverlay(item, overlayId);

      const assetIcon = getAssetIcon(item);
      const overlayElement = overlay.getElement();

      const root = rootMapRef.current.get(overlayElement) || createRoot(overlayElement);
      if (!rootMapRef.current.has(overlayElement)) {
        rootMapRef.current.set(overlayElement, root);
      }

      const handlerClick = () => {
        showVideoStream?.(item);
        selectedAssetOnMap?.(item);
      }

      root.render(
        <>
          <IconMap
            key={item.id}
            course={item?.state.location?.course}
            view={map.view}
            icon={typeof assetIcon === 'string' ? <img src={assetIcon} alt={item.name}/> : assetIcon}
            name={showName ? item.name : ''}
            zoom={map.zoom}
            altitude={infoLocation?.amsl ? `${infoLocation.amsl} ${intl.formatMessage({id: 'online.metr'})}` : undefined}
            windSpeed={windDirection ? `${windDirection} ${intl.formatMessage({id: 'online.metr_seconds'})}` : undefined}
            nearestPlace={item.nearestPlace}
            staticIcon={Assets.isStaticAsset(item.assetType)}
            isActive={activeId === item.id}
            setActive={() => {
              setActiveId(prevActiveId => (prevActiveId === item.id ? undefined : item.id));
            }}
            onClick={() => handlerClick()}
            isVideoStream={Boolean(streams?.getStreamByPublicId(item.publicAssetId))}
            uavIssues={Assets.hasConnectionIssues(item)}
          />
          {
            fieldOfViewLayer && !fieldOfViewLayerStore.canShow(item) &&
            <VisibleArea
              viewingDistance={angleOfView?.doriDetection}
              viewingAngle={angleOfView?.aovhInRad}
              resolution={map.view.getResolution()}
              course={item?.state.location?.course}
            />
          }

        </>
      );

      overlay.setPosition(center);
    });

    // Очистка от элементов которые не пришли в оверлей
    currentOverlays.forEach(overlayId => {

      const overlay = map.getOverlayById(overlayId);
      if (overlay) {
        map.removeOverlay(overlay);
        const overlayElement = overlay.getElement();

        if (overlayElement && rootMapRef.current.has(overlayElement)) {
          rootMapRef.current.get(overlayElement)!.unmount();
          rootMapRef.current.delete(overlayElement);
        }
      }
    });
  }, [items, map, map.view, activeId, showLayer, fieldOfViewLayer]);


  useEffect(() => {
    MAP_EVENTS_LEADS_TO_UPDATE.forEach(eventType => {
      map.view.on(eventType, updateOverlays);
    });

    updateOverlays();

    return () => {
      MAP_EVENTS_LEADS_TO_UPDATE.forEach(eventType => {
        map.view.un(eventType, updateOverlays);
      });
    };
  }, [map.view, updateOverlays, fieldOfViewLayerStore.hiddenFieldOfViewList]);

  return <></>;
}

export default diInject(AssetsIcons, {
  showVideoStream: dep(DiKeys.showVideoStream),
  streams: dep(VideoStreamingAssetsService.diKey),
  fieldOfViewLayerStore: dep(FieldOfViewLayerStore.diKey),
  selectedAssetOnMap: dep(DiKeys.selectedAssetOnMap),
});
