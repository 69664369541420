export default {
  'incident.form.status': 'Status',
  'incident.form.relevance': 'Relevance',
  'incident.form.type': 'Type',
  'incident.form.targetCoordinates': 'Target coordinates',
  'incident.form.mapCenterCoordinates': 'Center coordinates',
  'incident.form.dateTime': 'Date and time',
  'incident.form.detectionMethod': 'Detection method',

  'incident.table.number': '№',
  'incident.table.source': 'Source',
  'incident.table.type': 'Type',
  'incident.table.detectionMethod': 'Method',
  'incident.table.vltStatusId': 'Relevance',
  'incident.table.status': 'Status',

  'incident.actions.load': 'Load',
  'incident.actions.download': 'Download',
  'incident.actions.delete': 'Delete',
  'incident.actions.save': 'Save',
  'incident.actions.reset': 'Reset',
  'incident.actions.toTheSource': 'To the source',

  'incident.delete.title': 'Delete incidents',
  'incident.delete.body': 'Are you sure you want to delete incidents?',

  'incident.tabs.map': 'Map',
  'incident.tabs.attachments': 'Attachments',
  'incident.tabs.comments': 'Comments',

  'incident.noPreviousUploadFound': 'Drag and drop JPG/PDF files here or click the button',
}
