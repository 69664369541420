/**
 * Статус загрузки файла
 */
export enum UploadingFileStates {
  Running,
  Failed,
  Queued,
  Completed,
  Error,
}

export interface FileUpload {
  readonly fileName: string;
  readonly state: UploadingFileStates;
  readonly error?: string;
  readonly size?: number;
  readonly startDate?: Date;
  readonly progress: number;
  readonly onClose?: () => void;
  readonly closeButtonTooltip?: string;
  readonly networkError?: string;
}

export interface UploadSession {
  readonly type: 'files' | 'usb';
  readonly id: string;
  readonly bytesToUpload: number;
  readonly bytesUploaded: number;
  readonly files: readonly FileUpload[];
  readonly isFinished: boolean;
  readonly isStarting: boolean;
  readonly requestErrorMessage: string | undefined;
  readonly onClose?: () => void;
}

