import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { getAuthToken } from '@/shared/utils/getAuthToken';
import { logger } from '@workspace/4Z1.ts.utils';

import type { HubConnection } from '@microsoft/signalr';

const log = logger('WEBSOCKETS:API');

// TODO нужно реализовать корректную обработку промежуточных состояний между Disconnected и Connected
// поведение при вызове методов start/stop в этих состояниях — неизвестно

export class WebsocketApi {

  private readonly connection: HubConnection;

  constructor(path: string) {
    this.connection = new HubConnectionBuilder()
      .withUrl(path, {accessTokenFactory: () => getAuthToken()})
      .build();
  }

  get isConnected() {
    return this.connection.state === HubConnectionState.Connected;
  }

  start(callbackAfterConnected?: () => void) {
    if (this.isConnected) {
      callbackAfterConnected?.();
      return;
    }

    this.connection.start().then(() => callbackAfterConnected?.()).catch((err: ErrorEvent) => log.error('error message', err.toString()));
  }

  stop() {
    this.connection.stop().catch((err: ErrorEvent) => log.error('error message', err.toString()));
  }

  sendNoAnswer(command: string, param: any) {
    this.connection.send(command, param);
  }

  send(command: string, param: any) {
    this.connection.invoke(command, param).catch((err: ErrorEvent) => log.error(err.toString()));
  }

  on(methodName: string, callback: (data: any) => void): void {
    this.connection.on(methodName, callback);
  }

  off(methodName: string, callback?: (data: any) => void): void {
    if (callback) {
      this.connection.off(methodName, callback);
      return;
    }

    this.connection.off(methodName);
  }
}
