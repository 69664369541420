import { FC, useCallback, useMemo, useState } from 'react';
import { Assets } from '@/entities/assets/model';
import { Button } from '@workspace/4Z1.uikit.react';
import { useIntl } from 'react-intl';
import { message as Message } from 'antd';
import { AssetCardProps } from '@/widgets';
import { RevokeStore } from '../model';
import { dep, diInject } from '@/HOC';
import { DiKeys } from '@/shared/di/global';
import { Tooltip } from '@workspace/4Z1.uikit.react';
import './styles.scss';

interface Props extends AssetCardProps {
  readonly onDeviceRevoke: (deviceId: string, isRevoked: boolean) => void;
}

const RevokeDevice: FC<Props> = ({ asset, onDeviceRevoke }) => {
  const intl = useIntl();
  const [store] = useState(() => new RevokeStore());
  const [isRevoked, setRevoked] = useState(asset.isRevoked);

  const handleShowSuccess = useCallback(() => {
    Message.success(
      intl.formatMessage({
        id: 'revoke-device.common.success',
        defaultMessage: 'Successfuly updated',
      }),
    );
  }, [intl]);

  const handleShowError = useCallback(() => {
    Message.error(
      intl.formatMessage({
        id: 'revoke-device.common.fail',
        defaultMessage: 'Try again!',
      }),
    );
  }, [intl]);

  const handleSubmit = useCallback(() => {
    setRevoked((state) => !state);
    const { publicAssetId } = asset;

    store
      .changeRevokeStatus({ publicAssetId, revoked: isRevoked })
      .then(handleShowSuccess)
      .catch(handleShowError)
      .finally(() => onDeviceRevoke(publicAssetId, isRevoked));
  }, [onDeviceRevoke, asset, isRevoked]);

  const message = useMemo(() => {
    return isRevoked
      ? {
          buttonText: 'online.assetCard.device.enable',
          tooltipText: 'online.assetCard.tooltip.enable',
        }
      : {
          buttonText: 'online.assetCard.device.revoke',
          tooltipText: 'online.assetCard.tooltip.revoke',
        };
  }, [isRevoked, intl]);

  if (!Assets.canUpdateCertificate(asset)) {
    return <></>;
  }

  return (
    <Tooltip fullWidth text={intl.formatMessage({ id: message.tooltipText })}>
      <Button
        className="revokeButton"
        type={isRevoked ? 'accent' : 'badge'}
        size={'small'}
        disabled={store.loading}
        onClick={handleSubmit}
        label={intl.formatMessage({ id: message.buttonText })}
      />
    </Tooltip>
  );
};

export default diInject(RevokeDevice, {
  onDeviceRevoke: dep(DiKeys.onDeviceRevoke),
});
