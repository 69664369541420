import { Container, interfaces } from 'inversify';
import { DiKeys } from '@/shared/di/global';
import { UserOptionsFromLocalStorage } from '@/entities/userOptions/UserOptionsFromLocalStorage';
import { UploaderStore } from '@/features/UploadFiles/store/uploadFiles.store';
import { MassloadApiImpl, TusApi, UploadService } from '@/entities/fileUpload';
import { UsbUploadApiImpl } from '@/entities/fileUpload/api/usb.upload.api';
import { PageLayoutStore } from '@/layouts/PageLayout/model/pageLayout.store';
import { LocaleStore } from '../store/language';
import { GLOBAL_OLD_FASHIONED_STORE } from './old.fashioned';
import { ArtilleryTriangleStore } from '@/features/map';
import { DownloadHistoryStoreImpl } from '@/pages/FlightCard/ui/DownloadHistory/model/downloadHistory.store';
import { FlightWorkflowService } from '@/entities/flight/model/flightWorkflow.service';
import { LoadFlightDataStore } from '@/features/LoadFlightData/model/LoadFlightData.store';

const container: interfaces.Container = new Container();
container.bind(PageLayoutStore.diKey).to(PageLayoutStore).inSingletonScope();
container.bind(DiKeys.userOptions).to(UserOptionsFromLocalStorage).inSingletonScope();
container.bind(UploaderStore.diKey).to(UploaderStore).inSingletonScope();
container.bind(TusApi.diKey).to(TusApi).inSingletonScope();
container.bind(UploadService.diKey).to(UploadService).inSingletonScope();
container.bind(DiKeys.api.massload).to(MassloadApiImpl).inSingletonScope();
container.bind(DiKeys.api.usbupload).to(UsbUploadApiImpl).inSingletonScope();
container.bind(ArtilleryTriangleStore.diKey).to(ArtilleryTriangleStore).inSingletonScope();
container.bind(DiKeys.downloadHistoryStore).to(DownloadHistoryStoreImpl).inSingletonScope();
container.bind(FlightWorkflowService.diKey).to(FlightWorkflowService).inSingletonScope();
container.bind<LocaleStore>(DiKeys.LocaleStore).toConstantValue(GLOBAL_OLD_FASHIONED_STORE.localeStore);
container
  .bind(LoadFlightDataStore.diKey)
  .to(LoadFlightDataStore)
  .inSingletonScope();


const GlobalContainer = container;
export default GlobalContainer;
