export enum FeaturesType {
  ONLINE = 'onlines',
  VIDEO = 'videos',
  PHOTO = 'photos',
  TRACK = 'track',
  LAYER_POINT = 'layer-point',
}

export const MEDIA_FEATURE_TYPES: Readonly<FeaturesType[]> = [
  FeaturesType.PHOTO,
  FeaturesType.VIDEO,
] as const;
