import { type FC } from 'react';
import { MarkersLayer, RoutesLayer } from '@/features/map';
import { OnlinesLayer } from '@/features/map/layers/onlinesLayer';
import { Maps, ONLINE_PAGE_LAYERS_ORDER } from '@/shared/map';
import { MapBaseProps } from './Base';
import { OnlinesLayerDataSource } from '@/features/map/layers/onlinesLayer';
import { TrafficLayer } from '@/features/map/layers/trafficLayer';
import { AirSpaceLayer } from '@/features/map/layers/airSpaceLayer';

interface Props extends MapBaseProps {
  readonly searchOnArea?: any;
  readonly showContextMenu?: boolean;
  readonly showAssetName?: boolean;
  readonly dataSource: OnlinesLayerDataSource,
}

export const Onlines: FC<Props> = (
  {
    id,
    center,
    mapTools,
    targetSideBar,
    zoomPanelPosition,
    showContextMenu,
    showCursorCoordinate,
    zoomPanelButtonSize,
    showAssetName,
    children,
    dataSource,
  }) => {
  return (
    <Maps.Base
      id={id}
      mapCenter={center}
      mapTools={mapTools}
      targetSideBar={targetSideBar}
      zoomPanelPosition={zoomPanelPosition}
      showContextMenu={showContextMenu}
      showCursorCoordinate={showCursorCoordinate}
      zoomPanelButtonSize={zoomPanelButtonSize}
      layerSortingTemplate={ONLINE_PAGE_LAYERS_ORDER}
    >

      {/* Подключение слоев на карту */}
      <RoutesLayer />
      <MarkersLayer />
      <AirSpaceLayer />
      <TrafficLayer />

      <OnlinesLayer
        dataSource={dataSource}
        showName={showAssetName}
      />

      {children}

    </Maps.Base>
  )
}
