import './styles.scss';

import { dep, diInject } from '@/HOC';
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { isNil } from 'lodash';
import {
  ButtonsGroup,
  ContentBar,
  UpLoadOutlineIcon,
} from '@workspace/4Z1.uikit.react';
import { UploaderStore } from '@/features/UploadFiles/store/uploadFiles.store';
import { ZalaType } from '@/entities/zalaType';
import { FlightContentBarStore } from '../model/FlightContentBar.store';
import { FlightContentBarButton } from '../model/interfaces';

interface Props {
  readonly withLoad?: boolean,
  readonly startButtons?: FlightContentBarButton[],
  readonly endButtons?: FlightContentBarButton[],
  readonly startContent?: ReactNode,
  readonly endContent?: ReactNode,
  readonly acceptFiles?: string[],
  readonly disabledLoad?: boolean,
  readonly uploadType?: ZalaType,

  readonly store: FlightContentBarStore,
  readonly uploaderStore: UploaderStore;
}

const FlightContentBar: FC<Props> = ({
    store,
    uploaderStore,
    startButtons,
    endButtons,
    withLoad = true,
    acceptFiles = [],
    disabledLoad = false,
    startContent,
    endContent,
    uploadType,
  }) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);

  const loadButton: FlightContentBarButton = {
    icon: <UpLoadOutlineIcon />,
    label: intl.formatMessage({id: 'incident.actions.load'}),
    iconPosition: 'left',
    type: 'accent',
    disabled: disabledLoad,
    onClick: () => inputRef.current?.click()
  }

  const defaultButtonStart = withLoad ? [loadButton] : [];

  const [startDefault, setStartDefault] = useState(defaultButtonStart);

  useEffect(() => {
    if (!isNil(startButtons)) {
      setStartDefault([...defaultButtonStart, ...startButtons])
    }
  }, [startButtons]);

  const handleFilesChange = useCallback((incomingFiles: File[]) => {
    if (incomingFiles.length === 0) {
      return;
    }
    uploaderStore.onFilesSelected(incomingFiles, store.flightId, uploadType);
  }, [uploaderStore]);

  return (
    <>
      <ContentBar>
        <div className='flightContentBar'>
          <div className='start'>
            <ButtonsGroup buttons={startDefault} gap={8} />
            { startContent }
          </div>
          <div className='end'>
            { endContent }
            <ButtonsGroup buttons={endButtons} gap={8} />
          </div>
        </div>
      </ContentBar>

      {/*Input для загрузки данных*/}
      <input
        type='file'
        ref={inputRef}
        hidden
        accept={acceptFiles.join(',')}
        multiple
        onChange={(e) => handleFilesChange([...e.target.files])}
      />
    </>
)
}

export default diInject(FlightContentBar, {
  store: dep(FlightContentBarStore),
  uploaderStore: dep(UploaderStore),
})
