import { AsyncData, asyncDataError, asyncDataLoading, asyncDataValue } from '@/shared/model';

interface requestFn<Data, RequestParams> {
  (params: RequestParams): Promise<Data>
}

export function asyncDataBind<Data, RequestParams>(
  request: requestFn<Data, RequestParams>,
  initialValue: Data,
  callback: (a: AsyncData<Data>) => void,
): AsyncDataBind<Data, RequestParams> {
  return new AsyncDataBind(request, initialValue, callback);
}

export class AsyncDataBind<Data, RequestParams> {

  private _data: AsyncData<Data>;
  private readonly request: (params: RequestParams) => Promise<Data>;
  private readonly callback: (a: AsyncData<Data>) => void;

  constructor(request: requestFn<Data, RequestParams>, initialValue: Data, callback: (a: AsyncData<Data>) => void) {
    this._data = asyncDataValue(initialValue);
    this.callback = callback;
    this.request = request;

    this.callback(this._data);
  }

  get data() {
    return this._data;
  }

  call(params: RequestParams): void {
    this._data = asyncDataLoading();
    this.callback(this._data);

    this.request(params).then(data => {
      this._data = asyncDataValue(data);
      this.callback(this._data);
    }).catch((error: Error) => {
      this._data = asyncDataError(error);
      this.callback(this._data);
    });
  }
}
