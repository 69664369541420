import useNavigation from '@/shared/hooks/useTabNavigation';
import { ReactNode, useEffect, useMemo, useState } from 'react';

type Tab<T> = T &{
  key?: string;
};

export const useTabs = <T>(tabs: Tab<T>[]) => {
  const { getLocationKey, onTabClick } = useNavigation();

  const location = getLocationKey();

  const [activeTabKey, setActiveTabKey] = useState(location);

  useEffect(() => {
    if (location !== activeTabKey) {
      setActiveTabKey(location);
    }
  }, [location, activeTabKey]);

  const activeTab = useMemo(() => {
    return tabs.find((tab) => tab.key === activeTabKey) ?? tabs[0];
  }, [activeTabKey]);

  return { onTabClick, activeTabKey, location, activeTab };
};
