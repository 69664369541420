import {
  FileUpload,
  UploadingFileStates,
  UploadSession
} from './interface';
import { makeAutoObservable, observable } from 'mobx';
import { v4 as generateId } from 'uuid';
import { logger } from '@workspace/4Z1.ts.utils';
import { TusApi } from '../api/tus.api';
import { MassloadApi } from '../api/Massload.api';

const log = logger('T:UPLD:S');

interface FileUploadStatus {
  readonly id: string;
  readonly file: File;
  readonly bytesTotal: number;
  readonly bytesUploaded: number;
  readonly error?: string;
  readonly success?: boolean;
  readonly isQueued?: boolean;
  readonly flightId?: {flightId: string | undefined} | undefined;
  readonly fileType?: string | undefined;
}

function uploadStatus(upload: FileUploadStatus): UploadingFileStates {
  if (upload.error !== undefined) {
    return UploadingFileStates.Failed;
  }

  if (upload.success) {
    return UploadingFileStates.Completed;
  }

  if (upload.isQueued) {
    return UploadingFileStates.Queued;
  }

  return UploadingFileStates.Running;
}

const MAX_CONCURRENT_UPLOADS = 10;

/**
 * Отвечает за статус загрузки пачки файлов закинутых одномоментно
 */
export class TusUploadSession implements UploadSession {
  public readonly type = 'files';
  private readonly uploads = observable.map<string, FileUploadStatus>();

  private _isStarting = false;
  private _activeUploadsCount = 0;

  private networkError: string | undefined;

  public readonly id: string = generateId();

  constructor(
    files: readonly File[],
    private readonly tus: TusApi,
    private readonly massload: MassloadApi,
    private readonly flightId?: {flightId: string | undefined} | undefined,
    private readonly fileType?: string | undefined,
  ) {
    makeAutoObservable(this);
    this.start(files);
  }

  private start(files: readonly File[]) {
    this._isStarting = true;
    const uploads = files.map(file => ({
      id: generateId(),
      file,
      bytesTotal: file.size,
      bytesUploaded: 0,
      isQueued: true,
      flightId: this.flightId
    }));
    uploads.forEach(upload => this.uploads.set(upload.id, upload));
    this.processQueue();
    this._isStarting = false;
  }

  private processQueue() {
    this.uploads.forEach((upload, id) => {
      if (upload.isQueued && this._activeUploadsCount < MAX_CONCURRENT_UPLOADS) {
        this.uploads.set(id, { ...upload, isQueued: false });
        this._activeUploadsCount++;
        this.startUpload([upload], this.uploads.size);
      }
    });
  }

  private startUpload(requests: readonly FileUploadStatus[], totalFiles: number) {
    this.tus.upload(
      this.id,
      requests,
      totalFiles,
      (id: string, bytesUploaded: number, bytesTotal: number) => {
        this.handleUpdates(id, { bytesUploaded, bytesTotal });
        this.networkError = undefined;
      },
      (id: string, error: Error) => {
        this.handleUpdates(id, { error: error.message ?? 'Upload failed' });
        this.moveUploadToFailed(id);
        this.processQueue();
      },
      (error: Error) => {
        this.networkError = error.message;
      },
      (id: string) => {
        this.handleUpdates(id, { success: true });
        this.moveUploadToCompleted(id);
        this.processQueue();
      },
      this.flightId,
      this.fileType
    );
  }

  public get requestErrorMessage() {
    return this.networkError;
  }

  public get bytesToUpload(): number {
    return [...this.uploads.values()]
      .filter(upload => !upload.error)
      .reduce((val, upload) => val + upload.file.size, 0);
  }
  
  public get bytesUploaded(): number {
    return [...this.uploads.values()]
      .filter(upload => !upload.error)
      .reduce((sum, upload) => sum + upload.bytesUploaded, 0);
  }

  public get files(): readonly FileUpload[] {
    return [...this.uploads.values()].map(upload => this.mapToFileUpload(upload)).filter(Boolean) as readonly FileUpload[];
  }

  private mapToFileUpload(upload: FileUploadStatus): FileUpload | undefined {
    const state = uploadStatus(upload);
    const progress = upload.bytesUploaded / upload.bytesTotal * 100;
    return {
      fileName: upload.file.name,
      state,
      error: upload.error,
      bytesUploaded: upload.bytesUploaded,
      size: upload.bytesTotal,
      progress: isNaN(progress) ? 0 : progress,
      onClose: () => this.abortUpload(upload),
    };
  }

  public get isFinished(): boolean {
    return [...this.uploads.values()].every(upload => upload.success || upload.error !== undefined);
  }

  public get isStarting(): boolean {
    return this._isStarting;
  }

  private handleUpdates(id: string, updates: Partial<FileUploadStatus>) {
    const upload = this.uploads.get(id);
    if (upload) {
      this.uploads.set(id, { ...upload, ...updates });
    }
  }

  private moveUploadToCompleted(id: string) {
    const upload = this.uploads.get(id);
    if (upload) {
      this.uploads.set(id, { ...upload, success: true });
      this._activeUploadsCount--;
    }
  }

  private moveUploadToFailed(id: string) {
    const upload = this.uploads.get(id);
    if (upload) {
      this.uploads.set(id, { ...upload, error: upload.error ?? 'Upload failed' });
      this._activeUploadsCount--;
    }
  }

  private abortUpload(upload: FileUploadStatus) {
    const isActiveUpload = this.uploads.has(upload.id);
    if (!upload.error && !upload.success) {
      this._activeUploadsCount--;
    }
    if (isActiveUpload) {
      this.uploads.delete(upload.id);
      this.tus.abortFile(upload.id);
      this.removeFromMassload(upload);
      this.processQueue();
    }
  }

  private removeFromMassload(upload: FileUploadStatus) {
    this.massload.getFiles(this.id).then(files => {
      const file = files.find(item => item.filename === upload.file.name);
      if (file) {
        this.massload.delete(file.id).catch(e => log.warn('Failed to delete file', upload.file.name, upload.id, file, e));
      }
    }).catch(e => log.warn('Failed to delete file - cannot get files in upload', upload.file.name, upload.id, e));
  }
}
