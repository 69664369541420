import React from 'react';
import { useEffect, useState } from 'react';
import { message } from 'antd';
import { useModel, useIntl } from 'umi';
import moment from 'moment';
import { useCookies } from 'react-cookie';

import {CurrentUserKey, CurrentOptionsKey, UserLoginPath} from "@/services/UrqlClientProvider";
import {CurrentOptions, CurrentUser, LicenseType} from '@/app';
import request from 'umi-request';

const url = API + '/user/license';

const License = ({ children }: any) => {
  const intl = useIntl();
  const { initialState, setInitialState } = useModel('@@initialState');
  const [cookies, setCookie] = useCookies(['show_license']);

  const date_range = [
    moment().subtract(1, 'month').format('DD.MM.YYYY'),
    moment().add(7, 'days').format('DD.MM.YYYY'),
  ];

  const getUser = (token: any) => {
    request(url, {
      method: 'get',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(function (response: LicenseType) {
        if(response.status === 10 || response.status === 11 || response.status === 0) {
          localStorage.removeItem(CurrentUserKey);
          if (window.location.pathname !== UserLoginPath) {
            window.location.pathname = UserLoginPath;
          }
          return null;
        }

        if(initialState?.currentUser && initialState?.currentOptions){
          const options: CurrentOptions = { 
            ...initialState?.currentOptions, 
            date_range: {...initialState?.currentOptions?.date_range, main: date_range} 
          };
          const user: CurrentUser = { ...initialState?.currentUser, license: response };
          setInitialState({
            ...initialState,
            currentUser: user,
            currentOptions: options,
          });

          localStorage.setItem(CurrentUserKey, JSON.stringify(user));
          localStorage.setItem(CurrentOptionsKey, JSON.stringify(options));
        }

        if(response.status === 1 && response.date){
          message.error(
            intl.formatMessage({
              id: 'license.active.expires',
              defaultMessage: 'License expires',
            })+' '+response.date,
          );
        }

      })
      .catch(function (error) {});
  };

  useEffect(() => {
    if (!cookies.show_license){
      setCookie('show_license', true, {path: '/', maxAge: 24*3600});
      getUser(initialState?.currentUser?.token);
    }
    
  }, [cookies.show_license]);
  
  return (
    <>{children}</>
  );
};

export default License;
