import { FC, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { mapCoordinates, implicitMapCheck, MapPluginProps } from '@/shared/map';
import { createRoot, Root } from 'react-dom/client';
import { Overlay } from 'ol';
import { FocusIcon, IconMap, UavtwoIcon } from '@workspace/4Z1.uikit.react';
import { curry, isNil } from 'lodash';
import { AssetTrackWaypoint } from '@/entities/assetTrack';
import { observer } from 'mobx-react-lite';
import { Coordinates } from '@workspace/4Z1.ts.utils';
import { TrackAssetProvider } from '@/shared/providers';

interface Props extends MapPluginProps {
  readonly trackAssetProvider?: TrackAssetProvider;
  readonly asset?: AssetTrackWaypoint;
  readonly icon?: JSX.Element;
}

/** Префикс Asset идентификатора для иконки */
const ASSET_ICON_ID_PREFIX: string = 'track-icon-overlay';

/** Префикс точки фокусировки камеры Asset'а */
const FOCUS_ICON_ID_PREFIX: string = 'focus-icon-overlay';

/** Наименования событий, которые приводят к изменениям */
const MAP_EVENTS_LEADS_TO_UPDATE = ['change:resolution', 'change:center'];

/** Метод формирования идентификатора overlay для Asset */
const getOverlayId = (id: string, prefix: string) => `${prefix}-${id}`;

const TrackIconOverlay: FC<Props> = observer(
  ({ map = implicitMapCheck(), trackAssetProvider, icon = <UavtwoIcon /> }) => {
    const asset = useMemo(() => {
      return trackAssetProvider?.trackAsset;
    }, [trackAssetProvider?.trackAsset]);

    /** Идентификатор выбранного Asset на карте */
    const rootMapRef = useRef<Map<HTMLElement, Root>>(new Map());

    const getCurrentOverlaysIds = (): ReadonlySet<string> =>
      new Set<string>(
        map
          .getOverlays()
          .getArray()
          .map((overlay: Overlay) => overlay.getId())
          .filter(
            (overlayId: string) =>
              overlayId.startsWith(ASSET_ICON_ID_PREFIX) ||
              overlayId.startsWith(FOCUS_ICON_ID_PREFIX),
          ),
      );

    /**
     * Отслеживаем изменение идентификатора ассета, чтобы выполнить первоначальное центрирование камеры
     */
    useEffect(() => {
      const overlays = getCurrentOverlaysIds();

      cleanUp(overlays);

      if (!isNil(asset)) {
        map.setCenter(asset.coordinates, true);
      }

      return () => {
        cleanUp(overlays);
      };
    }, [asset?.id]);

    const createOverlay = (
      item: AssetTrackWaypoint,
      prefix = ASSET_ICON_ID_PREFIX,
    ): Overlay => {
      const element = document.createElement('div');
      element.className = prefix;

      return new Overlay({
        id: getOverlayId(item.id, prefix),
        element: element,
        positioning: 'center-center',
        stopEvent: false,
      });
    };

    const getOrCreateOverlay = (
      item: AssetTrackWaypoint,
      prefix: string,
    ): Overlay => {
      const overlay = map.getOverlayById(getOverlayId(item.id, prefix));

      if (isNil(overlay)) {
        const assetOverlay = createOverlay(item, prefix);
        map.addOverlay(assetOverlay);

        return assetOverlay;
      }

      return overlay;
    };

    const cleanUp = (overlays: ReadonlySet<string>) => {
      overlays.forEach((overlayId) => {
        const overlay = map.getOverlayById(overlayId);
        if (overlay) {
          const overlayElement = overlay.getElement();
          map.removeOverlay(overlay);

          if (overlayElement && rootMapRef.current.has(overlayElement)) {
            rootMapRef.current.get(overlayElement)!.unmount();
            rootMapRef.current.delete(overlayElement);
          }
        }
      });
    };

    const setRootElement = (
      component: ReactNode,
      element?: HTMLElement,
    ): void => {
      if (isNil(element)) return;

      const root = rootMapRef.current.get(element) || createRoot(element);
      if (!rootMapRef.current.has(element)) {
        rootMapRef.current.set(element, root);
      }

      root.render(component);
    };

    const updateOverlay = (
      coordinates?: Coordinates,
      overlay?: Overlay,
      component?: ReactNode,
    ): void => {
      if (isNil(overlay)) return;

      const coordiantes = mapCoordinates(coordinates);

      setRootElement(component, overlay.getElement());

      overlay.setPosition(coordiantes);
    };

    const updateOverlays = useCallback(() => {
      const overlays = getCurrentOverlaysIds();

      if (isNil(asset)) {
        cleanUp(overlays);
        return;
      }

      const getOverlay = curry(getOrCreateOverlay)(asset);

      updateOverlay(
        asset.coordinates,
        getOverlay(ASSET_ICON_ID_PREFIX),
        <IconMap
          key={asset.id}
          course={asset.direction}
          view={map.view}
          icon={icon}
          zoom={map.zoom}
          isActive={true}
        />,
      );

      updateOverlay(
        asset.cameraCoordinates,
        getOverlay(FOCUS_ICON_ID_PREFIX),
        <FocusIcon />,
      );
    }, [asset, asset?.coordinates, asset?.cameraCoordinates, map, map.view]);

    useEffect(() => {
      MAP_EVENTS_LEADS_TO_UPDATE.forEach((eventType) => {
        map.view.on(eventType, updateOverlays);
      });

      updateOverlays();

      return () => {
        MAP_EVENTS_LEADS_TO_UPDATE.forEach((eventType) => {
          map.view.un(eventType, updateOverlays);
        });
      };
    }, [map.view, updateOverlays]);

    return <></>;
  },
);

export default TrackIconOverlay;
