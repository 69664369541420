export default {
  'video.start': 'Взлет',
  'video.noPreviousUploadFound': 'Видео ещё не загружены. Перетащите сюда AVI / MOV / TS-видеофайлы или нажмите на кнопку.',
  'video.end': 'Посадка',
  'video.miniap': 'Миникарта',
  'video.fullScreen': 'Полный экран',
  'video.close': 'Закрыть окно',
  'video.inverse': 'Инверсия камеры',
  'video.error': 'Трансляция прервалась. Идет процесс переподключения...',
  'video.loadingBroadcasts': 'Идёт загрузка трансляций...',
  'video.videoAvailability':'Для создания трека полёта, наличие видео обязательно.'
};
