import { makeAutoObservable } from 'mobx';
import { injectable } from 'inversify';
import { createOrtophotoApi, type OrtophotoApi } from '../api/ortophoto.api';
import { Ortophoto, OrtophotoComparee } from './interface';
import { logger } from '@workspace/4Z1.ts.utils';

const log = logger('Service:Ortophoto');

@injectable()
export class OrtophotoService {
  constructor(private readonly api: OrtophotoApi = createOrtophotoApi()) {
    makeAutoObservable(this);
  }

  public async fetchOrtophotoByFlightId(flightId: string): Promise<Ortophoto | undefined> {
    log.debug('retrieving ortophoto by flight id ::', flightId);

    return this.api.fetchOrtophotoByFlightId(flightId).catch(err => {
      log.error('An error occured during ortophoto retrieving', err, flightId);

      return undefined;
    });
  }

  public async fetchOrtophotoById(ortophotoId: string): Promise<Ortophoto | undefined> {
    log.debug('retrieving ortophoto by id ::', ortophotoId);

    return this.api.fetchOrtophotoById(ortophotoId).catch(err => {
      log.error('An error occured during ortophoto retrieving', err, ortophotoId);

      return undefined;
    });
  }

  public async fetchOrtophotoComparees(ortophotoId: string): Promise<readonly OrtophotoComparee[]> {
    log.debug('retrieving ortophoto comparees for id ::', ortophotoId);

    return this.api.fetchOrtophotoComparees(ortophotoId).catch(err => {
      log.error('An error occured during ortophoto comparees retrieving', err);

      return [];
    });
  }

  public async updateOrtophoto(ortophotoId: string, updatedOrtophoto: Partial<Ortophoto>): Promise<void> {
    return this.api.updateOrtophoto(ortophotoId, updatedOrtophoto);
  }

  public async deleteOrtophoto(ortophotoId: string): Promise<void> {
    return this.api.deleteOrtophoto(ortophotoId);
  }

  public async downloadOrtophoto(ortophotoId: string): Promise<void> {
    return this.api.downloadOrtophoto(ortophotoId);
  }
}
