import { UmiApiClient } from '@/shared/api/rest';
import request from 'umi-request';
import urlJoin from 'url-join';
import { injectable } from 'inversify';
import { MassLoadFile } from './interfaces';


export interface MassloadApi {
  getFiles(uploadId: string): Promise<readonly MassLoadFile[]>;
  delete(fileId: string): Promise<void>;
}

@injectable()
export class MassloadApiImpl implements MassloadApi {

  private static readonly API_BASE = API;

  private static readonly PATHS = {
    getFiles: urlJoin(MassloadApiImpl.API_BASE, 'files'),
    delete: urlJoin(MassloadApiImpl.API_BASE, 'files'),
  } as const;

  constructor(
    private readonly client = new UmiApiClient(request),
  ) {
  }

  getFiles(uploadId: string): Promise<MassLoadFile[]> {
    return this.client.get(MassloadApiImpl.PATHS.getFiles, { params: { LoadId: uploadId } })
      .then((response: any) => {
        const files = (response.data as any[]) ?? [];
        return files.map(file => {
          return {
            id: String(file.id),
            filename: file.filename,
            loadId: file.loadId,
            state: mapStatus(file.status),
          }
        })
      });
  }

  delete(fileId: string): Promise<void> {
    return this.client.delete(urlJoin(MassloadApiImpl.PATHS.delete, fileId));
  }

}

function mapStatus(status: MassloadImplFileStates): MassloadFileStates {
  switch (status) {
    case MassloadImplFileStates.CreateUpload:
    case MassloadImplFileStates.Uploading:
      return MassloadFileStates.Uploading;
    case MassloadImplFileStates.Uploaded:
    case MassloadImplFileStates.Stored:
    case MassloadImplFileStates.Processing:
      return MassloadFileStates.Uploaded;
    case MassloadImplFileStates.Finished:
    case MassloadImplFileStates.Terminated:
    case MassloadImplFileStates.Archived:
    case MassloadImplFileStates.Removed:
    case MassloadImplFileStates.Rejected:
      return MassloadFileStates.Done;
    default:
      return MassloadFileStates.Unknown
  }
}

enum MassloadImplFileStates {
  Unknown,
  CreateUpload,
  Uploading,
  Uploaded,
  Stored,
  Processing,
  Finished,
  Rejected,
  Terminated,
  Removed,
  Archived,
}
