import './styles.scss';

import { type FC, useEffect, useState } from 'react';
import { LayerOptions, MapPluginProps } from '@/shared/map/model/interfaces';
import { implicitMapCheck, Layers } from '@/shared/map';
import { useCookies } from 'react-cookie';
import { isNil } from 'lodash';
import { useTilesApiQuery } from '@/.graphql/graphql';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import { TileWMS, Vector as VectorSource, XYZ } from 'ol/source';
import KML from 'ol/format/KML';
import TileLayer from 'ol/layer/Tile';
import { TileLayerModal } from './TileslayerModal';
import { useIntl } from 'react-intl';
import {
  DataTreeTiles,
  Tile,
  TileGroups,
  TileLayerData,
} from '../model/interfaces';

const PREFIX_NAME = 't-';
const MAX_TILE_SIZE: number = 10;
// Типы слоев которые строятся на основе тайлов
const TILE_LAYER_TYPES = [1, 3];
// Типы слоев которые строятся на основе векторов
const VECTOR_LAYER_TYPE = [2];

export const TilesLayer: FC<MapPluginProps> = ({map = implicitMapCheck()}) => {
  const LAYER_NAME = Layers.Tiles;

  const intl = useIntl();
  const [cookies, setCookies] = useCookies(['layers', 'reprojection']);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalOptions, setModalOptions] = useState<TileLayerData[]>([]);

  const [query] = useTilesApiQuery();
  const data = query.data ? query.data.tile_groups : null;

  const getName = (id: string) => {
    return `${PREFIX_NAME}${id}`;
  }

  const setKml = (item: Tile) => {
    return new VectorSource({
      url: item.url,
      format: new KML({showPointNames: false}),
    });
  }

  const setLink = (item: Tile) => {
    return new TileWMS({
        url: item.url,
        params: {
          LAYERS: '33,32,31,30,29,28,27,25,24,23,22,21,20,19,18,16,15,14,12,11,10,9,8,6,5,4,3,2,1',
          TILED: true,
        },
      })
  }

  const setOfflineLink = (item: Tile) => {
    return new XYZ({url: item.url});
  }

  const getSourceTile = (item: Tile) => {
    switch (item.type) {
      case 1: return setLink(item);
      case 2: return setKml(item);
      case 3: return setOfflineLink(item);
    }
  }

  const generateVectorLayer = (item: Tile) => {
    const options: LayerOptions  = {
      name: getName(item.id),
      title: item.name,
      minZoom: item.minzoom,
      visible: cookies.layers[getName(item.id)] ?? item.visible
    }

    options.source = getSourceTile(item);

    if (TILE_LAYER_TYPES.includes(item.type)) {
      return new TileLayer(options)
    }

    if (VECTOR_LAYER_TYPE.includes(item.type)) {
      return new VectorLayer(options)
    }
  }

  const setTiles = (tiles: Tile[]) => {
    const layerTiles: VectorLayer<VectorSource>[] = [];

    tiles.map((item: Tile) => {

      if (item.size > 1024 * 1024 * MAX_TILE_SIZE
        && (cookies.layers[getName(item.id)] !== undefined ? cookies.layers[getName(item.id)] : item.visible)
      ) {
        setModalOptions((old) => [...old, {label: item.name, value: getName(item.id)}]);
        item.visible = 0;
        cookies.layers[getName(item.id)] = 0;
        setCookies('layers', cookies.layers);
      }

      const tile = generateVectorLayer(item);

      layerTiles.push(tile);
    });

    return layerTiles;
  }

  const createDataTree = (data: readonly TileGroups[]): readonly DataTreeTiles[] => {
    const hashTable = new Map<string, DataTreeTiles>();
    const dataTree: DataTreeTiles[] = [];

    data.forEach((group: TileGroups) => hashTable.set(group.id, {...group, children: []}));

    data.forEach((item: TileGroups) => {
      if (Number(item.parent_id) !== 0) {
        const parent = hashTable.get(item.parent_id);
        if (!isNil(parent)) {
          parent.children.push(hashTable.get(item.id)!);
        }
      } else {
          dataTree.push(hashTable.get(item.id));
      }
    });
    return dataTree;
  }

  const setVectorLayer = (layer: DataTreeTiles): LayerGroup => {
    const children: LayerGroup[] = layer.children.map(group => setVectorLayer(group));

    if (layer.children.length + layer.tiles.length > 0) {
      return new LayerGroup({
        title: layer.name,
        name: layer.name,
        className: layer.name,
        layers: children.concat(setTiles(layer.tiles)),
        zIndex: 4,
      } as LayerOptions);
    }

    return new LayerGroup();
  }

  const generateGroupLayers = (tree: readonly DataTreeTiles[]): LayerGroup[] => {
    return tree.flatMap(layer => (layer.children.length + layer.tiles.length > 0) ? [setVectorLayer(layer)] : []);
  }

  useEffect(() => {
    if (!isNil(data)) {
      // Чистим слой
      map.removeLayerByName(LAYER_NAME);
      // Формируем данные с бека для построения слоя
      const tree = createDataTree(data);
      // Генерация подслоев
      const groupsLayer = generateGroupLayers(tree);
      // Создание слоя с подслоями
      const groupTiles = new LayerGroup({
        title: intl.formatMessage({id: 'module.tiles'}),
        name: LAYER_NAME,
        layers: groupsLayer,
      } as LayerOptions);
      // Добавление слоя на карту
      map.addLayer(groupTiles);
    }

    return () => {
      map.removeLayerByName(LAYER_NAME);
    }
  }, [data]);

  useEffect(() => {
    if (modalOptions.length > 0) {
      setModalOpen(true)
    }
  }, [modalOptions]);

  return (
    <TileLayerModal
      map={map}
      options={modalOptions}
      open={modalOpen}
      onClose={() => setModalOpen(false)}
    />
  )
}
