import { inject, injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';
import { FlightService } from '@/entities/flight';

@injectable()
export class FlightContentBarStore {
  public static readonly diKey = Symbol.for('FlightContentBarStore');

  private flightService: FlightService;

  constructor(
    @inject(FlightService.diKey) flightService: FlightService,
  ) {
    this.flightService = flightService;
    makeAutoObservable(this);
  }

  get flightId() {
    return this.flightService.data.flight.flight_id || '';
  }
}
