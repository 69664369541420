import { Map } from 'ol';
import { MapEngine } from './interfaces';

/**
 * Метод-костыль
 *
 * В компонент в свойстве мап может приходить 2 варианта:
 * - ol/map может приходить напрямую
 * - приходит наша новая обертка (тогда достаем через приватное поле _map)
 *
 * @description Данный метод позволяет получить низкоуровневый объект для работы с ol/map
 * @usage Можно использовать только для быстрой переделки старых слоев для использования в качестве плагина нового компонента карты
 *
 * @param map
 * @returns Возвращает объект ol/map
 */
export const getOlMap = (map: MapEngine | Map): MapEngine | Map => {
  return (map as Map)?._map ?? map;
};
