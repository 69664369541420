import { type FC, ReactNode, type RefObject, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { observer } from 'mobx-react';
import { PortalStore } from '@/shared/utils/portal/Portal.store';

interface Props {
  readonly target?: RefObject<Element>;
  readonly children: ReactNode;
}

/**
 * Компонент хелпер.
 * Позволяющий внедрять элементы интерфейса в любую часть DOM дерева.
 * @param target - ссылка (useRef) на элемент в который необходимо внедрить компонент children
 * @param children - компонент который внедряется в указанный target (useRef)
 */
export const Portal: FC<Props> = observer(({target, children}) => {
  const [store] = useState(() => new PortalStore());

  useEffect(() => {
    if (target?.current) {
      store.target = target.current;
    }
  }, [target]);

  return store.target && createPortal(children, store.target);
})
