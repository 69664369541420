import { AssetTypes } from "@/.graphql/graphql";
import { IntlShape } from 'react-intl';
import { logger } from "@workspace/4Z1.ts.utils";

interface BaseLocalizeItem {
  label: string;
  translate: boolean;
  children?: BaseLocalizeItem[];
}

const log = logger('debug');

/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export const isUrl = (path: string): boolean => reg.test(path);

export const isDev = (): boolean => {
  try {
    return process.env.REACT_APP_ENV === 'dev';
  } catch (e) {
    log.error('isDev :: unexpected error, switch to default', e);
    return false;
  }

};

/**
 * Дополнительная инициализация при работе с дев-средой.
 * @param initFn Функция, которая выполняет инициализацию, вызовется только если вызов devDebugInit произошел в dev-среде
 */
export const devDebugInit = (initFn: () => void) => {
  if (isDev()) {
    initFn();
  }
}

export const isSslDisabled = (): boolean => {
  return window.location.protocol === 'http:'
}

export const getAssetTypeName = (assetType: string, intl: IntlShape) => {
  if (assetType === AssetTypes.Car) {
    return intl.formatMessage({
      id: 'asset.car',
      defaultMessage: 'Car'
    })
  } else if (assetType === AssetTypes.Uav) {
    return intl.formatMessage({
      id: 'asset.uav',
      defaultMessage: 'UAV'
    })
  } else if (assetType === AssetTypes.Gcs) {
    return intl.formatMessage({
      id: 'asset.gcs',
      defaultMessage: 'GCS'
    })
  } else if (assetType === AssetTypes.Beacon) {
    return intl.formatMessage({
      id: 'asset.beacon',
      defaultMessage: 'Beacon'
    })
  } else if (assetType === AssetTypes.LocalServer) {
    return intl.formatMessage({
      id: 'asset.4z1local',
      defaultMessage: '4z1 local'
    })
  } else if (assetType === AssetTypes.GeoGraphics
    || assetType === AssetTypes.GeoGorizontDesktop
    || assetType === AssetTypes.GeoGorizontService) {
    return intl.formatMessage({
      id: 'asset.geoGorizont',
      defaultMessage: 'GeoGorizont'
    })
  } else if (assetType === AssetTypes.GeoGorizontMobile) {
    return intl.formatMessage({
      id: 'asset.geoGorizontMobile',
      defaultMessage: 'GeoGorizont Mobile'
    })
  } else if (assetType === 'INCIDENT') {
    return intl.formatMessage({
      id: 'asset.incident',
      defaultMessage: 'Incident'
    })
  }

  return assetType;
};

export const localizeNestedLabels = <T extends BaseLocalizeItem>(
  item: T | T[],
  intl: IntlShape,
): T | T[] => {
  const formatLabel = (label: string, translate: boolean) => {
    if (!label) {
      return '';
    }

    if (!translate) {
      return label;
    }

    try {
      return intl.formatMessage({ id: label });
    } catch (error) {
      return '';
    }
  };

  if (Array.isArray(item)) {
    return item.map((child) => localizeNestedLabels(child, intl)) as T[];
  } else {
    const formattedItem = {
      ...item,
      label: formatLabel(item.label, item.translate),
    };

    if (item.children && item.children.length > 0) {
      formattedItem.children = localizeNestedLabels(item.children, intl) as T[];
    }

    return formattedItem;
  }
};

export const isNullOrWhiteSpace = (value: string | null | undefined): boolean => !value?.toString().trim();

export const listify = (obj: any, mapFn: Function) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    acc.push(mapFn(key, value));
    return acc;
  }, []);

export const isTouchDevice = (): boolean => matchMedia('(hover: none)').matches;
