import './styles.scss';

import { FC, ReactNode } from 'react';

import { Header } from '@workspace/4Z1.uikit.react';
import { isDemoMode } from '@/shared/utils/isDemoMode';
import { UserMenu } from '@/widgets/UserMenu';
import TotalPercentUpload from '@/widgets/TotalPercentUpload/TotalPercentUpload';
import classNames from 'classnames';
import { PageLayoutStore } from '../PageLayout/model/pageLayout.store';
import { dep, diInject } from '@/HOC';

interface Props {
  readonly children: ReactNode;
  readonly sidebarContent?: ReactNode;
  readonly headerLeft?: ReactNode;
  readonly headerRight?: ReactNode;
  readonly headerFixed?: boolean;
  readonly store: PageLayoutStore;

  /**
   * Временный флаг для установки скролла сайдбара на старых страницах
   */
  readonly scrollable?: boolean;
}

/**
 * Данный компонент подлежит переработке и используется для поддержки старых страниц
 * со старым компонентом списка и сайдбара
 *
 * @description
 * В новой версии лэйаута (см. ./PageLayout) сайдбар остается статичным и не скроллится
 * при появлении длинных списков, в то время как этот сохраняет возможность скролла сайдбара
 * 
 * Использовать в новых компонентах страниц - запрещено
 *
 * @see ./PageLayout.tsx
 */
const BaseAppLayout: FC<Props> = ({
  children,
  headerLeft,
  headerRight,
  headerFixed,
  sidebarContent,
  scrollable = true,
  store
}) => {
  const DEMO = isDemoMode();

  return (
    <>
      {DEMO ? (
        <div className="baseAppLayout">
          {sidebarContent && (
            <div className={classNames('sidebarLeft', { scrollable })}>
              {sidebarContent}
            </div>
          )}
          <div className="container">
            {store.shouldRenderHeader && (
              <Header
                className="header"
                fixed={headerFixed}
                childrenLeft={headerLeft}
                childrenRight={
                  <>
                    {headerRight}
                    <TotalPercentUpload />
                    <UserMenu />
                  </>
                }
              />
            )}
            {children}
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default diInject(BaseAppLayout, {
  store: dep(PageLayoutStore.diKey)
});
