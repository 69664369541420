import {
  FlightAttachmentsDocument,
  FlightAttachmentsQuery,
  Photo,
  Timing,
  Video,
} from '@/.graphql/graphql';
import {
  Attachment,
  attachmentIcons,
  AttachmentByType,
  GasAttachment,
  PhotoAttachment,
  VideoAttachment,
  VideoTiming,
} from '@/entities/attachment';
import { Gas } from '@/entities/gas';
import { AttachmentTypes } from '@/entities/attachment/model/utils';
import { GraphQlApi, REQUEST_POLICY_NETWORK } from '@/shared/api/graphql';
import { createRestApi, RestApi } from '@/shared/api/rest';
import { resolveLinkWithDomain } from '@/shared/utils';
import { Location } from '@workspace/4Z1.ts.utils';
import { chain, isArray, isNumber, isString, omit } from 'lodash';
import moment from 'moment';
import urlJoin from 'url-join';

export interface FlightAttachmentsApi {
  readonly fetchAttachments: (flightId: string) => Promise<readonly Attachment[]>;
  readonly getImageTile: (flightId: string) => Promise<readonly Attachment[]>;
  readonly getVideoFind: (flightId: string) => Promise<readonly Attachment[]>;
  readonly fetchGasesByFlight: (flightId: string) => Promise<readonly GasAttachment[] | undefined>;
}

class FlightAttachmentsImplApi implements FlightAttachmentsApi {

  private static readonly API_BASE = API;
  private readonly clientRest: RestApi = createRestApi();
  private static readonly PATHS = {
    gas: '/gas',
  };
  private readonly clientGraphQl = new GraphQlApi();

  async fetchAttachments(flightId: string): Promise<readonly Attachment[]> {
    // const requestResult: FlightAttachmentsQuery = await this.clientGraphQl.query<FlightAttachmentsQuery>(
    //   FlightAttachmentsDocument,
    //   { id: flightId },
    //   REQUEST_POLICY_NETWORK,
    // );

    const responsePhoto = await this.getImageTile(flightId)
    const responseVideo = await this.getVideoFind(flightId)
    // const flight = requestResult.flight;

    const typedVideos = responseVideo?.map(processAttachment(AttachmentTypes.Video)) ?? [];
    const typedPhotos = responsePhoto?.map(processAttachment(AttachmentTypes.Photo)) ?? [];

    return [...typedPhotos, ...typedVideos]

  }

  public async getImageTile(flightId?: string): Promise<any | undefined> {
    const path = urlJoin([API, '/imagetile/find?projectId=2',]);
    const resp = await this.clientRest.post(path,  {
      filter: [
      {
        field: "flightId",
        logic: "",
        filterValues: [
          {
            operator: "eq",
            value: flightId
          }
        ]
      }
      ],
      sort: [
        {
          field: "id",
          dir: "desc"
        }
      ],
      pagination: {
        offset: 0,
        limit: 100000 //TODO заменить на данные из tlm
      }
    }
  );


    const data = resp?.data;
    const filteredPhotos = data?.filter(photo => photo.file !== null && photo.imageBox !== null && photo.fileApiPath !== null) ?? [];
    return filteredPhotos;

  }
  public async getVideoFind(flightId: string): Promise<any | undefined> {
    const path = urlJoin([API, '/video/find?projectId=2',]);
    const resp = await this.clientRest.post(path, {
      filter: [
      {
        field: "flightId",
        logic: "",
        filterValues: [
          {
            operator: "eq",
            value: flightId
          }
        ]
      }
      ],
      sort: [
        {
          field: "id",
          dir: "desc"
        }
      ],
      pagination: {
        offset: 0,
        limit: 100000 //TODO заменить на данные из tlm
      }
    });

    const data = resp?.data;
    return data;
  }

  async fetchGasesByFlight(flightId: string): Promise<readonly GasAttachment[] | undefined> {
    const requestResult =  this.clientRest.get<Gas>(
      urlJoin(FlightAttachmentsImplApi.API_BASE, FlightAttachmentsImplApi.PATHS.gas),
      { flightId: flightId },
    ).then((data) => data.data).then((data) => {
      return chain(data)
      .castArray()
      .map<GasAttachment>(processAttachment(AttachmentTypes.Gas))
      .valueOf()
    }).catch((error) => {
      if (error.source && error.source.status === 404) {
        return undefined;
      } else {
        throw error;
      }
    })

    return requestResult
  }
}

export const createFlightAttachmentsApi = (): FlightAttachmentsApi => {
  return new FlightAttachmentsImplApi();
};

const transform = (value: string): readonly number[] => {
  return value.split(',').map((item) => {
    const parsedItem = Number(item);

    if (isNumber(parsedItem)) return parsedItem;

    throw new Error(
      `Cannot cast literal symbol to number - ${item} casted to ${parsedItem}`,
    );
  });
};

const processPhoto = (photo: Photo): PhotoAttachment => {
  if (!photo?.center || !photo?.center?.coordinates || !photo.imageBox) {
    throw new Error(
      'Cannot process a photo with missing properties of center, coordinates or imageBox',
    );
  }

  const resolutions = transform(photo.imageBox.resolutions);
  const bbox = transform(photo.imageBox.bbox);

  // Наша карта работает со значениями [lon, lat], а API возрващает [lat, lon]
  // Для корректной работы компонента трэк-мапы, необходимо развернуть координаты
  const location = Location.parse(photo.center.coordinates?.toReversed());

  if (!location) {
    throw new Error('Cannot get coordinates for location of unknown CRS');
  }

  return {
    ...omit(photo, 'thumb'),
    uniqueId: photo.id + AttachmentTypes.Photo,
    thumbUrl: resolveLinkWithDomain(photo.thumbApiPath),
    fileUrl: resolveLinkWithDomain(photo.fileApiPath),
    type: AttachmentTypes.Photo,
    center: location,
    geometry: photo.center.geom,
    placeholderIcon: attachmentIcons.Photo.placeholderComponent,
    icon: attachmentIcons.Photo.icon,
    imageBox: {
      ...photo.imageBox,
      tilecache: resolveLinkWithDomain(photo.imageBox.tilecache),
      resolutions: [...resolutions],
      bbox: [...bbox],
    },
  };
};


const parseTiming = (timing: any): readonly VideoTiming[] => {
  if (timing === undefined) return [];

  const data = isString(timing) ? JSON.parse(timing) : timing;

  if (!isArray(data)) {
    return [];
  }
  
  return data.map((timing: Timing) => ({
    direction: timing.dir,
    sec: timing.sec,
    droneCoordinates: GraphQlApi.parseCoordinates([timing.lon, timing.lat])!, // TODO избавится от trust-me-bro
    cameraCoordinates: GraphQlApi.parseCoordinates([
      timing.c_lon,
      timing.c_lat,
    ])!, // TODO избавится от trust-me-bro
  }));
}

const processVideo = (video: Video): VideoAttachment => {
  return {
    ...video,
    uniqueId: video.id + AttachmentTypes.Video,
    id: video.videoId,
    fileUrl: resolveLinkWithDomain(video.fileApiPath),
    placeholderIcon: attachmentIcons.Video.placeholderComponent,
    icon: attachmentIcons.Video.icon,
    type: AttachmentTypes.Video,
    timing: parseTiming(video?.timing),
    firstCoord: GraphQlApi.parseCoordinates(video.track?.first_coord),
  };
};
const processGas = (gas: Gas): GasAttachment => ({
  ...gas,
  uniqueId: gas.id.toString(),
  file: resolveLinkWithDomain(gas.fileFullPath),
  id: gas.id.toString(),
  name: gas.name ?? '',
  type: AttachmentTypes.Gas,
  kmlUrl: resolveLinkWithDomain(gas.fileFullPath),
  tlmUrl: resolveLinkWithDomain(gas.originFileFullPath)
});

const TYPES = {
  [AttachmentTypes.Photo]: {
    requiredFields: ['imageBox'],
    process: processPhoto,
  },
  [AttachmentTypes.Video]: {
    requiredFields: ['track'],
    process: processVideo,
  },
  [AttachmentTypes.Gas]: {
    requiredFields: ['fileFullPath'],
    process: processGas,
  },
} as const;

const processAttachment =
  <T extends AttachmentTypes>(type: T) =>
  (attachment: Photo | Video | Gas): AttachmentByType[T] => {
    const typeMeta = TYPES[type];

    if (!typeMeta.requiredFields.every(field => field in attachment)) {
      throw new Error(
        `Unexpected format: not enough required fields in object ${attachment} ${typeMeta.requiredFields}`,
      );
    }

    // TODO - На этом этапе мы уже точно знаем, с каким из объектов мы работаем, исправить ошибку Typescript'а
    return typeMeta.process(attachment);
  };
