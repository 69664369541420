import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { Item } from '../Item';
import { ContextMenuStore } from '../../model/ContextMenu.store';
import { implicitContextMenuCheck } from '../../model/implicitContextMenuCheck';

interface Props {
  readonly store?: ContextMenuStore;
  readonly className?: string;
}

export const AddViolation: FC<Props> = observer(({
  store = implicitContextMenuCheck(),
  className,
}) => {
  const intl = useIntl();

  const selectItem = () => {
    store.vltModalOpen = true;
    store.show = false;
  }

  return (
    <Item
      className={className}
      label={intl.formatMessage({id: 'violation.context.addVlt'})}
      action={() => selectItem()}
    />
  )
})
