import { FlightResponse, FtpStatus } from './interfaces';

interface InitialValuesResponseData {
  readonly flight_ftp: FlightResponse;
}

export const initialValuesParser = (
  params: [InitialValuesResponseData, string],
): FlightResponse => {
  const [flight, name] = params;

  const data = flight?.flight_ftp;
  const status = data?.status;

  switch (status) {
    case FtpStatus.Unknown:
      return {
        ...data,
        flight_name: name,
        companyCode: data.companyCode,
        status: data?.status,
      };
    default:
      return {
        ...data,
        flight_name: name,
      };
  }
};

export const isUploadToFtpCouldBeStarted = (
  state: number | undefined,
): boolean => {
  return state === FtpStatus.Unknown || state === FtpStatus.Failed;
};
