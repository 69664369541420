/**
 * Высота строки шрифта тултипа
 */
const LINE_HEIGHT = 10 * 1.5715;

/**
 * Отступ от верхнего края тултипа
 */
const TOP_MARGIN = 15;

/**
 * Функция для вычисления позиции курсора с учетом ширины тултипа и количества вложенных фичей.
 *
 * @param {number} x - Координата события наведения X
 * @param {number} y - Координата события наведения Y
 * @param {number} elementWidth - Ширина тултипа, который будет отображаться
 * @param {number} elementsCount - Количество элементов, которые нужно учесть при вычислении вертикальной позиции тултипа
 * @returns {{ x: number, y: number }} - Объект с обновленными координатами курсора, где x и y - это новые координаты
 */
export const getCursorPosition = (
  x: number,
  y: number,
  elementWidth: number,
  elementsCount: number,
): { x: number; y: number } => {
  return {
    x: x - elementWidth / 2,
    y: y - LINE_HEIGHT * elementsCount - TOP_MARGIN,
  };
};
