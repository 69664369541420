import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { AssetCardProps } from '@/widgets';
import { Assets } from "@/entities/assets/model";
import { Text } from "@workspace/4Z1.uikit.react";

/**
 * Дата и время последнего обновления объекта в AssetCard
 * @param asset
 */
export const LastUpdate: FC<AssetCardProps> = ({asset}) => {
  const intl = useIntl();

  if (Assets.lastUpdated(asset) === undefined) {
    return <></>;
  }

  return (
    <Text.Auto>
      {intl.formatMessage({id: 'online.assetCard.lastUpdated'})}: {Assets.lastUpdated(asset)}
    </Text.Auto>
  )
}
