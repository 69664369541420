import {Maps} from './maps';
import {Violations} from './violations';
import {TrackPointVideo} from './trackpoint_video';
import {Videos} from './videos';
import {Flights} from './flights';
import {TrackPointPhoto} from './trackpoint_photos';
import {OFPs} from './ofps';
import {Routes} from './routes';
import {Tiles} from './tiles';
import {SK42Grid} from './sk42grid';
import { Marks } from './Mark/mark';
import T_VPRF from './VPRF';
import T_Traffic from './Traffic';

let group = {
  Maps: Maps,
  Violations: Violations,
  Videos: Videos,
  Flights: Flights,
  TrackPointPhoto: TrackPointPhoto,
  TrackPointVideo: TrackPointVideo,
  OFPs: OFPs,
  Routes: Routes,
  Tiles: Tiles,
  SK42Grid: SK42Grid,
  Marks: Marks,
  T_VPRF: T_VPRF,
  T_Traffic: T_Traffic,
};

export {
  group,
};
