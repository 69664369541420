import './styles.scss';

import { type FC, CSSProperties, ReactNode } from 'react';
import { Button, ZoomInIcon, ZoomOutIcon } from '@workspace/4Z1.uikit.react';

import { MapPluginProps } from '@/shared/map/model/interfaces';
import { implicitMapCheck } from '@/shared/map';

import { ZoomPosition } from '../model/interfaces';
import classNames from 'classnames';
import { Tooltip } from '@workspace/4Z1.uikit.react';
import { useIntl } from 'react-intl';

interface Props extends MapPluginProps {
  readonly style?: CSSProperties;
  readonly position?: ZoomPosition;
  readonly children?: ReactNode;
  readonly buttonSize?: string;
}

export const ZoomPanel: FC<Props> = ({
    map = implicitMapCheck(),
    style,
    position = ZoomPosition.BaseLayoutTopRight,
    children,
    buttonSize = 'medium'
  }) => {

  const intl = useIntl()

  return (
    <div className={
      classNames('zoomPanel', {
        baseLayoutTopRight: position === ZoomPosition.BaseLayoutTopRight,
        baseLayoutTopLeft: position === ZoomPosition.BaseLayoutTopLeft,
        topRightSmall: position === ZoomPosition.TopRightSmall,
        topRight: position === ZoomPosition.TopRight,
        topLeft: position === ZoomPosition.TopLeft,
        }
      )}
         style={style}>
      <Tooltip text={intl.formatMessage({ id: 'map.zoomIn' })} direction='left-center'>
        <Button size={buttonSize} type='neutral' onlyIcon icon={<ZoomInIcon/>} onClick={() => map.zoomIn()}/>
      </Tooltip>
      <Tooltip text={intl.formatMessage({id: 'map.zoomOut'})} direction='left-center'>
        <Button size={buttonSize} type='neutral' onlyIcon icon={<ZoomOutIcon/>} onClick={() => map.zoomOut()}/>
      </Tooltip>
      {children}
    </div>
  )
}
