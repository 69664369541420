import { transformCoordFormat } from '@/components/Map/transformCoordinates';
import { implicitMapCheck } from '@/shared/map';
import { MapPluginProps } from '@/shared/map/model/interfaces';
import { FC } from 'react';
import { useCookies } from 'react-cookie';
import './styles.scss';
import { Coordinates } from '@workspace/4Z1.ts.utils';

interface Props extends MapPluginProps {
  readonly coordinates: Coordinates;
  readonly subtitle: string;
}

export const PhotoDescriptionPanel: FC<Props> = ({
  map = implicitMapCheck(),
  coordinates,
  subtitle,
}) => {
  const [cookies] = useCookies(['reprojection']);

  return (
    <div className="text-panel">
      {transformCoordFormat(
        [coordinates.lat, coordinates.lon],
        cookies.reprojection,
      )}
      <div>{subtitle}</div>
    </div>
  );
};
