import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withCookies } from 'react-cookie';
import request from 'umi-request';

import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { BaseLayerOptions } from 'ol-layerswitcher';
import { Feature, Map, TileCache } from 'ol';
import { GeoJSON } from 'ol/format';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { Coordinate } from 'ol/coordinate';

class VPRF_NOTAM extends Component<any, any> {
  cache    = new TileCache();
  map: Map = this.props.map;
  layer    = new VectorLayer();
  source   = new VectorSource();
  tag = 'NOTAM';
  minZoom  = 4;
  _tiles   = '8:4:4,9:4:4,10:4:4,11:4:4,12:4:4,13:4:4,8:5:4,9:5:4,10:5:4,11:5:4,12:5:4,13:5:4,7:3:4,8:3:4,9:3:4,10:3:4,11:3:4,12:3:4,13:3:4,14:3:4,7:4:4,14:4:4,7:5:4,14:5:4,7:6:4,8:6:4,9:6:4,10:6:4,11:6:4,12:6:4,13:6:4,14:6:4';
  urlInfo  = '/info';

  _feature: any = undefined;
  defaultStyle = new Style({
    image: new Circle({
      radius: 10,
      fill: new Fill({ color: [0, 255, 0, .5] }),
    }),
  });
  hoverStyle = new Style({
    image: new Circle({
      radius: 20,
      fill: new Fill({ color: [255, 0, 0, .5] }),
    }),
  });

  constructor(props: any) {
    super(props);

    this.state = {
      coord: [],
    };

    const { intl } = this.props;
    this.layer = new VectorLayer({
      name: 'notam',
      className: 'notam',
      title: intl.formatMessage({
        id: 'layer.vprf.notam',
        defaultMessage: 'NOTAM',
      }),
      source: this.source,
      visible: this.props.allCookies.layers.notam ? true : false,
      zIndex: 10,
      minZoom: this.minZoom,
      maxZoom: this.props.maxZoom,
    } as BaseLayerOptions);
  }

  componentDidMount() {
    this.source.setLoader(this.loader);
    this.props.addLayer(this.layer);

    this.map.on('pointermove', (e) => {
      const features = this.map.getFeaturesAtPixel(e.pixel);
      if (features.length && features[0].get('type') === 'NOTAM') {
        this._feature = features[0];
        this._feature.setStyle(this.hoverStyle);
      }else{        
        if (this._feature) {
          this._feature.setStyle(this.defaultStyle);
          this._feature = undefined;
        }
      }
    });
  }

  componentDidUpdate() {
    if (this.props.feature.get('type') === this.tag && this.props.feature.get('coord') !== this.state.coord){
      const id    = this.props.feature.getId();
      const coord = this.props.feature.get('coord');

      this.showPopup(coord, id);

      this.setState({coord: coord});
    }
  }

  componentWillUnmount() {
    this.map.removeLayer(this.layer);
  }

  showPopup = (coord: number[], id: any) => {
    this.cache.containsKey(id)
      ? this.setHTML(coord, this.cache.get(id)) 
      : this.getInfo(coord, id);
  }

  getInfo = (coord: number[], id: any) => {
    const url = this.props.urlApi+this.urlInfo+`/${this.tag}/`+id;
    request(url, {
      method: 'get',
      //headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.active) {
          this.cache.set(id, response);
          this.setHTML(coord, response);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setHTML = (coord: number[], props: any) => {
    let html = 
      `NOTAM <b>${props.name}</b><br/><br/>
      <b>Радиус:</b> ${props.radius} м<br/><br/>
      ${props.description?.replaceAll('\n', '<br/>')}`
    
    this.props.setHtml(coord, html);
  }

  loader = () => {
    const url = this.props.urlApi+'/'+this.tag+this.props.urlTiles+this._tiles;
    request(url, {
      method: 'get',
      //headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        this.setSource(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setSource = (data: any) => {
    if (data.length)
    {
      data.map((row: any) => {
        if (row.position.length && row.active) {
          const geom: Coordinate[] = [row.position[1], row.position[0]];
          const GeoJson = {
            "type": "Point",
            "coordinates": geom,
            "crs": {"type": "name","properties": {"name": "EPSG:4326"}}
          };
          const feature = new GeoJSON().readFeature(GeoJson, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          });
          feature.setId(row.id);
          feature.set('type', row.type);
          feature.set('typeClick', 'vprf');
          feature.setStyle(this.defaultStyle);
          
          this.source.addFeature(feature);
        }
      });
      this.layer.setSource(this.source);
    }
  }

  render() {
    return (
      <></>
    );
  }
}

export default injectIntl(withCookies(VPRF_NOTAM));
