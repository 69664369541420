import { Coordinates } from '@workspace/4Z1.ts.utils';

export interface TrackPoint {
  readonly timestamp: number;
  readonly date: Date;
  readonly location: Coordinates;
}

/**
 * Перечень типов точек маршрута
 */
export enum WaypointType {
  Start,
  End,
  Idle,
  Highlight,
}

/**
 * Перечень свойств для стилей точек маршрута
 */
export interface WaypointStyle {
  readonly color: string;
  readonly radius: number;
  readonly borderColor: string;
  readonly borderWidth: number;
}

/**
 * Перечень свойств необходимых для отрисовки иконки ассета в конкретной точке маршрута
 */
export interface AssetTrackWaypoint {
  readonly id: string;
  readonly coordinates: Coordinates;

  /**
   * Координаты точки фокусировки камеры (то, куда смотрит камера)
  */
  readonly cameraCoordinates?: Coordinates;

  /**
   * Направление БВС в точке маршрута (в градусах)
   */
  readonly direction: number;
}

/**
 * Тип функции, вызываемой для промотки видео по клику на трек
 */
export type TrackSeekCallback = (attachmentId: string, time: number) => void;