import {
  MenuDataItem,
  Settings as LayoutSettings,
} from '@ant-design/pro-layout';
import type { RunTimeLayoutConfig } from '@umijs/max';
import { addLocale, getLocale, history } from '@umijs/max';
import request from 'umi-request';
import {
  CurrentUserKey,
  UserLoginPath,
  CurrentOptionsKey,
} from '@/services/UrqlClientProvider';
import BottomMenu from '@/components/BottomMenu';
import ForbiddenPage from './pages/Exception/403';
import violationRA from '@/locales/ru-RU/violationRA';
import ruRU0 from 'antd/es/locale/ru_RU';
import Video from './types/video';
import { init } from "@/init";
import { isDemoMode } from './shared/utils/isDemoMode';
import { VideoCameraIcon } from '@workspace/4Z1.uikit.react';
import classNames from 'classnames';
import './global.scss';

const url = API + '/loggers';

/** место для инициализации кода, который вызывается единожды */
init();

const initialStateConfig = {
  video: new Video(),
};

export type LicenseType = {
  status: number;
  message: string;
  date?: string;
};

export type CurrentUser = {
  id: string;
  fio: string;
  token: string;
  access: string;
  permits: Array<{}>;
  version: string;
  license: LicenseType;
  ovenHost: string;
  AD: boolean;
  projectType: string;
};

/**
 * @deprecated перетаскиваем в UserOptions entities/userOptions/interface.ts
 */
export type CurrentOptions = {
  center?: Array<number>;
  date_range?: {
    main: Array<string>;
    stream?: Array<string>;
  };
  flight?: {
    user?: string | undefined;
    route?: Array<string> | undefined;
    violations?: boolean;
    videos?: boolean;
    photos?: boolean;
    gazes?: boolean;
  };
  violation?: {
    type?: Array<string> | undefined;
    status?: string;
    relevance?: string;
    dtype?: string;
    refresh?: boolean;
    null_range?: Array<string>;
  };
  mark?: {
    refresh?: boolean;
  };
  streamName?: string;
  searchArea?: object;
  isOnline?: boolean;
};

const DEMO = isDemoMode();

export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: CurrentUser;
  currentOptions?: CurrentOptions;
  fetchUserInfo?: () => Promise<CurrentUser | undefined>;
  fetchOptions?: () => Promise<CurrentOptions | undefined>;
  refreshInitialState?: () => void;
  video?: Video;
}> {

  //@deprecated перенесено в language.ts
  const fetchUserInfo = async () => {
    try {
      const currentUserJson = localStorage.getItem(CurrentUserKey);
      if (currentUserJson) {
        const user = JSON.parse(currentUserJson) as CurrentUser;

        if (user) {
          const locale = getLocale();
          if (user.projectType == '1' && locale === 'ru-RU') {
            addLocale(
              'ru-RU',
              { ...violationRA },
              {
                momentLocale: 'ru',
                antd: { ...ruRU0 },
              },
            );
          }
          return user;
        }
      }

      return undefined;
    } catch (error) {
      history.push(UserLoginPath);
    }
    return undefined;
  };

  const fetchOptions = async () => {
    let options: CurrentOptions = {};
    const currentOptionsJson = localStorage.getItem(CurrentOptionsKey);
    if (currentOptionsJson) {
      options = JSON.parse(currentOptionsJson) as CurrentOptions;
    }
    return options;
  };

  if (history.location.pathname !== UserLoginPath) {
    const currentUser = await fetchUserInfo();
    const currentOptions = await fetchOptions();
    return {
      fetchUserInfo,
      currentUser,
      fetchOptions,
      currentOptions,
      settings: {},
      video: initialStateConfig.video,
    };
  }

  return {
    fetchUserInfo,
    fetchOptions,
    settings: {},
    video: initialStateConfig.video,
  };
}

export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  return {
    rightContentRender: undefined,
    disableContentMargin: true,
    breakpoint: 'xl',
    collapsedButtonRender:false,
    menuRender: DEMO ? false : undefined, //Скрывает меню на страницах 404 и других
    disableMobile: true,
    onPageChange: (props: any) => {
      if (
        (!initialState?.currentUser || !initialState?.currentUser?.token) &&
        history.location.pathname != UserLoginPath &&
        history.location.pathname != '/' &&
        history.location.pathname != '/home'
      ) {
        history.push(UserLoginPath);
      }

      if (
        initialState?.currentUser &&
        !history.location.pathname.includes('photos')
      )
        setLogger(props, initialState?.currentUser?.token);
    },
    links: [
      <BottomMenu />,
      initialState?.currentUser?.license.message +
        ' v' +
        initialState?.currentUser?.version,
    ],
    unAccessible: <ForbiddenPage />,
    menuHeaderRender: () => (
      <div className="custom-sider-logo">
        <img src="/icons/logo_n_w.png" alt="logo-icon" />
      </div>
    ),
    //logo: '/icons/logo_n_w.png',
    ...initialState?.settings,
  };
};

const setLogger = (values: any, token: string) => {
  request(url, {
    method: 'post',
    data: values,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then(function () {})
    .catch(function () {});
};
