import { createRestApi, RestApi } from '@/shared/api/rest';
import { FlightWorkflowStatus } from '../model';
import urlJoin from 'url-join';

export interface FlightStatusesApi {
  fetchStatuses: () => Promise< readonly FlightWorkflowStatus[]>;
}

export const createFlightStatusesApi = (): FlightStatusesApi => {
  return new FlightStatusesApiImpl();
};

class FlightStatusesApiImpl implements FlightStatusesApi {
  private readonly client: RestApi = createRestApi();

  private static readonly API_BASE = API;

  public fetchStatuses(): Promise<readonly FlightWorkflowStatus[]> {
    // TODO небезопасно - возвращаем что пришло с сервера. но сигнатура позволяет )
    return this.client
      .get(urlJoin(FlightStatusesApiImpl.API_BASE, 'flights', 'workflow-statuses'))
      .then(resp => (resp.data as Array<any>).map(s => ({ id: String(s.id), name: s.name })));
  }
}
