import { urqlClient } from '@/services/UrqlClientProvider';
import { Coordinates, DateRange, Location } from '@workspace/4Z1.ts.utils';
import { DocumentNode } from 'graphql';
import moment from 'moment';

export const REQUEST_POLICY_NETWORK = { requestPolicy: 'network-only' };

const FORMAT_GRAPHQL_TIMESTAMP = 'YYYY-MM-DD HH:mm:ss'
const FORMAT_DEFAULT_DATE = 'DD.MM.YYYY'

function answerToResult<T>(answer: any): T {
  if (answer.error !== undefined) {
    throw answer.error;
  }

  return answer.data as T;
}


export class GraphQlApi {

  constructor(
    private readonly client = urqlClient,
  ) { }

  query<T>(query: DocumentNode, variables?: any, policies?: any): Promise<T> {
    return this.client
      .query(query, variables, policies)
      .toPromise()
      .then(answer => answerToResult(answer));
  }

  mutation<T>(query: DocumentNode, variables?: any, policies?: any): Promise<T> {
    return this.client
      .mutation(query, variables, policies)
      .toPromise()
      .then(answer => answerToResult(answer));
  }

  public static formatCurrentTimestamp(): string {
    return moment().format(FORMAT_GRAPHQL_TIMESTAMP);
  }

  public static timestampToApi(timestamp: number): number {
    return Math.round(timestamp / 1000);
  }

  public static timestampFromApi(timestamp: number): number {
    return timestamp * 1000;
  }

  public static parseCoordinates = (coordinates?: readonly number[]): Coordinates | undefined => {
    if (coordinates === undefined) return;

    return Location.parse(coordinates.toReversed());
  }

  public static formatDateRange = (dateRange: DateRange): string => {
    return `${moment(dateRange.startDate).format(FORMAT_DEFAULT_DATE)} - ${moment(dateRange.endDate).format(FORMAT_DEFAULT_DATE)}`
  };
}
