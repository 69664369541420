import { useEffect } from 'react';
import { useIntl } from 'umi';
import LayerGroup from 'ol/layer/Group';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import LayerSwitcher from 'ol-layerswitcher';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import KML from 'ol/format/KML';
import { Style, Stroke } from 'ol/style';

import { useRoutesApiQuery } from '@/.graphql/graphql';

export const dataRoutes = () => {
  const [query] = useRoutesApiQuery();
  return query.data?.routes;
};

export const Routes = (props: any) => {
  const data = dataRoutes();
  const { map, p_map } = props;
  const intl = useIntl();

  const _title = intl.formatMessage({
    id: 'module.routes',
    defaultMessage: 'Routes',
  });

  function setRoutes(data: any){
    const routes: any = [];
    data.map((route: any) => {
      const name = 'r-'+route.id;
      var layer =
        new VectorLayer({
          title: route.name,
          name: name,
          className: 'layer-'+name,
          visible: 
            props.initTiles[name] !== undefined
              ? props.initTiles[name]
              : false,
          zIndex: 3,
          source: new VectorSource({
            url: route.kml,
            format: new KML({ extractStyles: false }),
          }),
          style: new Style({
            stroke: new Stroke({ color: route.color, width: 3 }),
          }),
        } as BaseLayerOptions);
      routes.push(layer);
    });
    return routes;
  }

  useEffect(() => {
    if (data) {
      removeLayer('routes');
      const routes = setRoutes(data);
      var group_routes = new LayerGroup({
        title: _title,
        fold: 'close',
        name: 'routes',
        className: 'routes',
        layers: routes,
      } as GroupLayerOptions);
      map.addLayer(group_routes);

      if(p_map){
        removeLayer('p_routes');
        const p_routes = setRoutes(data);
        var group_p_routes = new LayerGroup({
          title: _title,
          fold: 'close',
          name: 'p_routes',
          className: 'p_routes',
          layers: p_routes,
        } as GroupLayerOptions);
        p_map.addLayer(group_p_routes);
        
        LayerSwitcher.forEachRecursive(group_routes, (layer) => {
          layer.on('change:visible', (e: any) => {
            LayerSwitcher.forEachRecursive(group_p_routes, (p_layer) => {
              if(p_layer.get('name') == layer.get('name')) p_layer.setVisible(layer.getVisible());
            });
          })
        });
      }
    }
  }, [data]);

  function removeLayer(layer_name: string) {
    map.getLayers().getArray().map((layer: any) => {
      if (layer.className_ === layer_name) map.removeLayer(layer);
    });
  };

  useEffect(() => {
    return () => {
      removeLayer('routes');
      //if(p_map) removeLayer('p_routes');
    };
  }, []);

  return <></>;
};
