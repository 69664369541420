import { type FC, RefObject, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Portal } from '@/shared/utils';
import { implicitMapCheck, MapPluginProps } from '@/shared/map';
import { AimIcon, Button } from '@workspace/4Z1.uikit.react';
import { Coordinates } from '@workspace/4Z1.ts.utils';
import { isNil } from 'lodash';
import { CoordinatesProvider } from '@/shared/providers';

interface Props extends MapPluginProps {
  readonly target?: RefObject<Element>;
  readonly coordsProvider?: CoordinatesProvider;
}

export const ViewportLockPlugin: FC<Props> = observer(
  ({ map = implicitMapCheck(), target, coordsProvider }) => {
    const [isLocked, setLocked] = useState<boolean>(false);

    useEffect(() => {
      const coords: Coordinates | undefined = coordsProvider?.coords;

      if (isNil(coords)) return;

      isLocked && map.setCenter(coords, true);
    }, [coordsProvider?.coords, isLocked]);

    return (
      <Portal target={target}>
        <Button
          type={'neutral'}
          size={'medium'}
          onlyIcon={true}
          active={isLocked}
          disabled={false}
          onClick={() => setLocked((locked) => !locked)}
          icon={<AimIcon.Small />}
        />
      </Portal>
    );
  },
);
