export const defaultReProjection: string = 'EPSG:4326';

export const defaultLayers: any = {
    maps: 0,
    flights: 1,
    videos: 1,
    violations: 1,
    onlines_global : 1,
    fieldOfViewLayer : 1,
    marks: 1,
}
