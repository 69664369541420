import { useEffect } from 'react';
import { useIntl, useModel } from 'umi';
import LayerGroup from 'ol/layer/Group';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON';

import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import {get as getProjection} from 'ol/proj';
import {getTopLeft, getWidth} from 'ol/extent';
import { Style, Icon } from 'ol/style';

import { useL_OfpsQuery } from '@/.graphql/graphql';

export const OFPs = (props: any) => {
  const { map } = props;
  const intl = useIntl();
  const { initialState } = useModel('@@initialState');
  const date_range = initialState?.currentOptions?.date_range?.main || [];

  const [query] = useL_OfpsQuery({
    variables: { 
      date_range: date_range.join(' - '),
    },
  });
  const data = query.data ? query.data.ofps : null;

  const _title = intl.formatMessage({
    id: 'module.ofps',
    defaultMessage: 'OFPs',
  });

  useEffect(() => {
    if (data) {
      removeLayer('ofps');
      const ofps: any = [];
      const sourceIcons = new VectorSource();

      data.map((ofp: any) => {
        const feature = new GeoJSON().readFeature(ofp.center.geom, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        });
        feature.set('className', 'layer-ofpicon-' + ofp.id);
        feature.set('name', ofp.name);
        feature.set('link', '/ofps?id=' + ofp.id);
        feature.setStyle(new Style({ image: new Icon({ src: ofp.icon }) }));
        sourceIcons.addFeature(feature);
      });

      ofps.push(
        new VectorLayer({
          name: 'ofpicons',
          title: intl.formatMessage({
            id: 'ofp.ofpicons',
            defaultMessage: 'OFP icons',
          }),
          source: sourceIcons,
          visible: props.initTiles['ofpicons'],
          zIndex: 1,
        } as BaseLayerOptions),
      );

      data.map((ofp: any, index: number) => {
        if(ofp.url || ofp.geoserver){
          const zIndex = 1 + ((data.length - index) / 100);
          ofps.push(
            setOfp(ofp, zIndex)
          );
        }
      });

      var group_ofps = new LayerGroup({
        title: _title,
        fold: 'close',
        name: 'ofps',
        className: 'ofps',
        layers: ofps,
      } as GroupLayerOptions);

      map.addLayer(group_ofps);
    }
  }, [data]);

  function removeLayer(layer_name: string) {
    map.getLayers().getArray().map((layer: any) => {
      if (layer.className_ === layer_name) map.removeLayer(layer);
    });
  };

  function setOfp(data: any, zIndex: number){
    let ofp = new TileLayer();
    const name = 'o-'+data.id;
    const visible = (props.initTiles[name] !== undefined) ? props.initTiles[name] : false;
    if(data.url){
      ofp = new TileLayer({
        name: name,
        title: data.name,
        visible: visible,
        zIndex: zIndex,
        source: new XYZ({
          url: data.url,
        }),
      } as BaseLayerOptions)
    }else if(data.geoserver){
      const geoserverParams = JSON.parse(data.geoserver);
      const projection = getProjection('EPSG:3857');
      if (projection) {
        const projectionExtent = projection.getExtent();
        const size = getWidth(projectionExtent) / 256;
        const resolutions = new Array(21);
        const matrixIds = new Array(21);
        for (let z = 0; z < 21; ++z) {
          resolutions[z] = size / Math.pow(2, z);
          matrixIds[z] = z;
        }

        ofp = new TileLayer({
          name: name,
          title: data.name,
          visible: visible,
          zIndex: zIndex,
          source: new WMTS({
            url: geoserverParams.url,
            layer: geoserverParams.layer,
            matrixSet: 'WebMercatorQuad',
            format: 'image/png',
            projection: projection,
            tileGrid: new WMTSTileGrid({
              origin: getTopLeft(projectionExtent),
              resolutions: resolutions,
              matrixIds: matrixIds,
            }),
            style: 'raster',
            wrapX: true,
          }),
        } as BaseLayerOptions);
      }
    }

    return ofp;
  }

  useEffect(() => {
    return () => {
      removeLayer('ofps');
    };
  }, []);

  return <></>;
};
