import { 
  AssetListDocument, 
  AssetTypes, 
  ChangeCameraCoordinatesDocument, 
  ChangeCameraCoordinatesMutation,  
  ChangeCameraDirectionDocument, 
  ChangeCameraDirectionMutation, 
  ChangeLocationCommandDocument, 
  ChangeLocationCommandInput, 
  GetOnlineVideoStreamingUavsDocument, 
  VideoStream, 
  VideoStreamListDocument 
} from "@/.graphql/graphql";
import { ALL_ASSET_TYPES, Asset, type AssetListFilter, Assets, UpdateCoordsReqParams, UpdateDirectionReqParams } from '../model';
import { GraphQlApi, REQUEST_POLICY_NETWORK } from "@/shared/api/graphql";
import _ from 'lodash';
import { calculateNorthDir, convertPtzAngleMeasureToDegrees } from '../model/utils';
import { v4 as uuidv4 } from 'uuid';
import { Loggers } from '@/debug/loggers';

export interface AssetsApi {
  assets(params: AssetListFilter): Promise<Asset[]>;
  videoStreamingAssets(): Promise<Asset[]>;
  videoStreams(): Promise<VideoStream[]>;
  updateAssetCoordinates(params: UpdateCoordsReqParams): Promise<ChangeCameraCoordinatesMutation>;
  updateAfuCoordinates(params: UpdateCoordsReqParams): Promise<void>;
  updateAssetDirection(params: UpdateDirectionReqParams): Promise<ChangeCameraDirectionMutation>;
}

const logger = Loggers.sova;

export const createAssetsApi = (): AssetsApi => {
  return new AssetsApiImpl();
};


/**
 * Значения AssetTypes и значения фильтров не совпадают.
 * Внутри приложения всегда нужно пользоваться AssetTypes, а мапить будем уже только тут.
 *
 * Следует удалить после того как разница между возвращаемым и запрашиваемыми типами будет ликвидирована на бекенде.
 */
const ASSET_TYPES_FOR_FILTER: ReadonlyMap<AssetTypes, string> = new Map([
  [AssetTypes.Beacon, 'Beacon'],
  [AssetTypes.Car, 'Car'],
  [AssetTypes.GeoGraphics, 'GeoGraphics'],
  [AssetTypes.Ptz, 'Ptz'],
  [AssetTypes.Gcs, 'Gcs'],
  [AssetTypes.Uav, 'Uav'],
  [AssetTypes.Afu, 'Afu'],
]);

/**
 * Костыль. Нужен для изоляции "странных" значений типов ассетов в фильтрах в запросах graphQl
 * См. описание к ASSET_TYPES_FOR_FILTER. Они должны быть удалены одновременно.
 */
function fixAssetTypesForQraphQl(types: readonly AssetTypes[]): readonly string[] {
  return _.compact(types.map(type => ASSET_TYPES_FOR_FILTER.get(type)));
}

function assetTypes(types: 'all' | readonly AssetTypes[]): readonly AssetTypes[] {
  return types === 'all'
    ? ALL_ASSET_TYPES
    : types;
}

class AssetsApiImpl implements AssetsApi {

  constructor(
    private readonly client = new GraphQlApi(),
  ) { }


  assets(params: AssetListFilter): Promise<Asset[]> {
    const vars = {
      assetTypesFilter: fixAssetTypesForQraphQl(assetTypes(params.types)),
      projectFilter: params.project ?? 'all',
      assetGroupFilter: [], // TODO
      showOnlyActiveFilter: params.onlyActive,
      showRevokedFilter: params.onlyRevoked
    };

    return this.client
      .query(AssetListDocument, vars, REQUEST_POLICY_NETWORK)
      .then(data => data.assets);
  }

  videoStreamingAssets(): Promise<Asset[]> {
    const vars = {
      assetTypesFilter: fixAssetTypesForQraphQl(Assets.streamingAssetTypes),
      projectFilter:  "all", // TODO фильтра по проекту нет - его не было в функционале страницы Onlines, которая была источником логики для данного стора
      assetGroupFilter: [],
    }
    return this.client
      .query(GetOnlineVideoStreamingUavsDocument, vars, REQUEST_POLICY_NETWORK)
      .then(data => data.onlineVideoStreamingUavs);
  }

  videoStreams(): Promise<VideoStream[]> {
    return this.client
      .query(VideoStreamListDocument, {}, REQUEST_POLICY_NETWORK)
      .then(data => data.videoStreams);
  }

  updateAssetDirection(
    params: UpdateDirectionReqParams,
  ): Promise<ChangeCameraDirectionMutation> {
    const { asset, direction } = params;

    const inverted = Assets.withInvertedControls(asset);
    const northDirection = calculateNorthDir(
      convertPtzAngleMeasureToDegrees(Assets.getNorthDirection(asset)),
      Assets.getCourse(asset),
      direction,
    );

    const requestParams = {
      // Инвертируем обе оси
      // Смотри withInvertedControls() - entities/assets/model/Assets.ts
      reversePanDirection: inverted,
      reverseTiltDirection: inverted,
      publicAssetId: asset.publicAssetId,
      timestamp: GraphQlApi.formatCurrentTimestamp(),
      northDirection,
    };

    logger.debug(`changing-camera-direction params-`, requestParams, asset);

    return this.client.mutation(
      ChangeCameraDirectionDocument,
      requestParams,
      REQUEST_POLICY_NETWORK,
    );
  }

  updateAssetCoordinates(
    params: UpdateCoordsReqParams,
  ): Promise<ChangeCameraCoordinatesMutation> {
    const {
      asset,
      coordinates: { lat, lon },
    } = params;

    return this.client.mutation(
      ChangeCameraCoordinatesDocument,
      {
        publicAssetId: asset.publicAssetId,
        latitudeDeg: lat,
        longitudeDeg: lon,
        // TODO - absoluteAltitudeM - это пока временное значение
        absoluteAltitudeM: -1,
        timestamp: GraphQlApi.formatCurrentTimestamp(),
      },
      REQUEST_POLICY_NETWORK,
    );
  }
  
  updateAfuCoordinates(
    params: UpdateCoordsReqParams,
  ): Promise<void> {
    const {
      asset,
      coordinates: { lat, lon },
    } = params;


    return this.client.mutation(
      ChangeLocationCommandDocument,
      {
        publicAssetId: asset.publicAssetId,
        clientMutationId: uuidv4(),
        lat: lat,
        lon: lon,
        timestamp: GraphQlApi.formatCurrentTimestamp(),
      },
      REQUEST_POLICY_NETWORK,
    );
  }

}
