import { useMemo, type FC } from 'react';
import { MapPluginProps } from '@/shared/map/model/interfaces';
import { getOlMap, implicitMapCheck } from '@/shared/map';
import { OFP } from '@/components/Map/Layers/ofp';
import { Ortophoto } from '@/entities/ortophotos';

interface Props extends MapPluginProps {
  readonly data?: Ortophoto;
}

/**
 * Компонент-обёртка для использования слоя ортофотоплана
 *
 * Предоставляет удобный интерфейс для передачи данных для старого слоя
 *
 * @see старая версия компонента - OFP.tsx
 */
export const OrtophotoLayer: FC<Props> = ({ map = implicitMapCheck(), data }) => {
  const olMap = useMemo(() => {
    return getOlMap(map);
  }, [map]);

  return <OFP data={data} map={olMap} />;
};
