import { makeAutoObservable } from 'mobx';

export class PortalStore {

  public target: Element | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }
}
