import type { FC, ReactNode } from "react";

interface Props {
  label?: string;
  action?: () => void;
  children?: ReactNode;
  className?: string;
}

export const Item: FC<Props> = ({label, action, children, className}) => {
  return (
    <div
      className={className}
      onClick={() => action?.()}
    >
      {label}
      {children}
    </div>
  )
}
