export default {
  'flight.name': 'Title',
  'flight.routes': 'All routes',
  'flight.users': 'All users',
  'flight.showAll': 'Show all',
  'flight.edit': 'Edit flight',
  'flight.NewFlight': 'New flight',
  'flight.list.empty':
    'There are currently no media attachments on this page',

  'flight.list.suggest':
    'You can view other attachments',

  'flight.delete.confirm.title': 'Attention',
  'flight.delete.confirm.body': 'Once a flight is deleted, all flight data will be lost and cannot be restored. Do you really want to delete the flight?',
  'flight.flights_delete_error': 'An error occured during deletion. Try again!',

  'flight.photo-viewer.title': 'Photo {title}',
  'flight.video-viewer.title': 'Video {title} ',

  'flight.redirect': 'open',
  'flight.downloadKmlTooltip': 'Download KML',
  'flight.missingKmlTooltip': 'KML is missing',

  'flight.flights_update_success': 'Update success',
  'flight.flights_update_error': 'Update error',
  'flight.empty_new_flight': 'New flight cannot be empty',

  'flight.NO_FLIGHT': 'Flight not found',
  'flight.Edit_Name_Flight': 'Edit flight\'s name',
  'flight.flights_delete_success': 'Flight deleted',

  'flight.readMore': 'Read more',
  'flight.redirectToPage': 'Go to flight page',
  'flight.returnToMap': 'Return to map',
  "flight.general": "General",
  "flight.trackFlight": "Flight Track",
  "flight.crew": "Crew",
  "flight.dateStart": "Start Date",
  "flight.dateEnd": "End Date",
  "flight.timeStart": "Start Time",
  "flight.timeEnd": "End Time",
  "flight.complex": "Complex",
  'flight.flightName': 'Flight Name',
  'flight.flightNumber': 'Flight Number',
  'flight.enterNumber': 'Enter Number',
  'flight.chooseRoute': 'Choose Route',
  'flight.enterFIO': 'Enter Full Name',
  'flight.board': 'Aircraft',
  'flight.chooseBoard': 'Choose Aircraft',
  'flight.create': 'Create flight',

  'flight.load.tlm': 'Upload telemetry (photo)',
  'flight.load.tlm.gas': 'Upload telemetry (gas)',
  'flight.load.photo': 'Upload photo',
  'flight.load.video': 'Upload video',
  'flight.load.incidents': 'Upload incident',
  'flight.load.ofp': 'Upload orthophoto plans',

  'flight.attachments.delete-success': 'Selected attachments has been deleted successfuly!',
  'flight.photo.delete': 'Are you sure you want to delete {count} photos?',
  'flight.video.delete': 'Are you sure you want to delete {count} videos?',
};
