const LOCAL_STORAGE_KEY = 'afu';
const VALUE_OFF = 'off';

/**
 * Включает изменение координат у неактивных антен.
 * В последствии нужно выпилить.
 *
 * Сейчас - включен по-умолчанию. Но его можно выключить значением 'off' в ключе afu
 */
export const isAfuMode = (): boolean => {
  const value = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (value === null) {
    return true;
  }
  return value !== VALUE_OFF;
}
