export enum Formats {
  TLM = 'tlm',
  KML = 'kml',
  ZIP = 'zip',
  FTP = 'ftp',
}

export enum AcceptFileFormats {
  TLM = '.tlm',
  JPG = '.jpg',
  TS = '.ts',
  AVI = '.avi',
  MOV = '.mov',
  PDF = '.pdf',
  TIF = '.tif',
  TIFF = '.tiff',
  GMT = '.gmt',
  ZIP = '.zip',
}
