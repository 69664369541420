import { inject, injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';

import {
  type UserOptions,
} from '@/entities/userOptions';
import { DiKeys } from '@/shared/di/global';

@injectable()
export class ArtilleryTriangleStore {
  public static readonly diKey = Symbol.for('ArtilleryTriangle');
  private _visibile: boolean = false;

  constructor(
    @inject(DiKeys.userOptions)
    private userOptions: UserOptions,
  ) {
    makeAutoObservable(this);
  }

  public toggleVisibility(): void {
    this._visibile ? this.hideLayer() : this.showLayer();
  }

  public showLayer(): void {
    this._visibile = true;
  }

  public hideLayer(): void {
    this._visibile = false;
  }

  public get visibility(): boolean {
    return this._visibile;
  }

  public get enabled(): boolean {
    return this.userOptions.projectType === '1';
  }
  
  public unmount(): void {
    this.hideLayer();
  }
}
