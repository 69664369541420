import './styles.scss';

import { FC } from 'react';
import { FieldOfViewToggle } from '@/entities/map/ui/FieldOfViewToggle';
import { AssetCardProps } from '@/widgets';
import { OpenDetailsPage } from './OpenDetailsPage';
import { AssetStatusConnection } from './AssetStatusConnection';
import { AssetCapabilities } from './AssetCapabilities';
import { ShowVideoStream } from '../ShowVideoStream';

import ShowOnMap from './ShowOnMap';
import { MovementTrack } from './MovementTrack';

/**
 * Тултипы для объекта в AssetCard
 * @param asset
 */
export const Tooltips: FC<AssetCardProps> = ({asset, onActivate}) => {

  return (
    <div className='assetCardTooltips'>
      <MovementTrack asset={asset} onActivate={onActivate}/>
      {/* Статус активности */}
      <AssetStatusConnection asset={asset} />

      {/* Возможности */}
      <AssetCapabilities asset={asset} />

      {/* Включение/выключение зон видимости */}
      <FieldOfViewToggle asset={asset} className='assetCardTooltip' />

      {/* Подробная информация */}
      <OpenDetailsPage asset={asset} />

      {/* Показывать видео */}
      <ShowVideoStream asset={asset} />

      {/* Показать на карте */}
      <ShowOnMap asset={asset} />
    </div>
  )
}
