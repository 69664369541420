import { Circle, Fill, Stroke, Style } from 'ol/style';
import { WaypointStyle, WaypointType } from './types';

/**
 * Стиль линии маршрута
 */
export const trackLineStyles = {
  // blue-900m - icon/accent
  color: '#177AE5',
  width: 2,
} as const;

/**
 * Изначальный стиль точки маршрута в состоянии 'покоя'
 */
export const idleWaypointStyle: Readonly<WaypointStyle> = {
  // blue-900m - icon/accent
  color: '#177AE5',
  // white-500 - border/primary
  borderColor: '#30373D',
  radius: 4,
  borderWidth: 1,
} as const;

/**
 * Стиль точки маршрута при ховере
 */
export const highlightedWaypointStyle: Readonly<WaypointStyle> = {
  // white-1200m - icon/primary
  color: '#ffffff',
  // white-500 - border/primary
  borderColor: '#30373D',
  radius: 6,
  borderWidth: 2,
} as const;

/**
 * Стиль первой и последней точки маршрута
 */
export const boundingWaypointStyle: Readonly<WaypointStyle> = {
  // green-900m - icon/success
  color: '#29CC51',
  // white-500 - border/primary
  borderColor: '#30373D',
  radius: 6,
  borderWidth: 2,
} as const;

/**
 * Утилита для получения объекта стиля точки маршрута по типу
 */
export const getWaypointCircleStyles = (
  type: WaypointType,
): Readonly<WaypointStyle> => {
  switch (type) {
    case WaypointType.Idle:
      return idleWaypointStyle;
    case WaypointType.Start:
      return boundingWaypointStyle;
    case WaypointType.End:
      return boundingWaypointStyle;
    case WaypointType.Highlight:
      return highlightedWaypointStyle;
    default:
      return idleWaypointStyle;
  }
};

/**
 * Утилита для получения стиля точки маршрута
 */
export const getWaypointStyle = (type: WaypointType): Style => {
  const styles = getWaypointCircleStyles(type);

  return new Style({
    zIndex: type === WaypointType.Idle ? 0 : 1,
    image: new Circle({
      radius: styles.radius,
      fill: new Fill({ color: styles.color }),
      stroke: new Stroke({
        color: styles.borderColor,
        width: styles.borderWidth,
      }),
    }),
  });
};

/**
 * Утилита для получения стиля линии-трека маршрута
 */
export const getTrackLineStyle = (): Style => {
  return new Style({
    // blue-900m - icon/accent
    stroke: new Stroke({ color: trackLineStyles.color, width: trackLineStyles.width }),
  });
};
