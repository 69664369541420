import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import KML from 'ol/format/KML';
import { Stroke, Style } from 'ol/style';
import { LayerOptions } from '@/shared/map';
import { Route } from './interfaces';

const PREFIX = 'r-';

const getLayerName = (id: string) => {
  return `${PREFIX}${id}`
}

export const getRoute = (route: Route, cookies: any): VectorLayer<VectorSource> => {
  return new VectorLayer({
    title: route.name,
    name: getLayerName(route.id),
    className: `layer-${route.id}`,
    visible: cookies.layers[getLayerName(route.id)] ?? true,
    zIndex: 3,
    source: new VectorSource({
      url: route.kml,
      format: new KML({ extractStyles: false }),
    }),
    style: new Style({
      stroke: new Stroke({ color: route.color, width: 3 }),
    }),
  } as LayerOptions);
}
