import './styles.scss';

import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from '@workspace/4Z1.uikit.react';
import { LayersPanelStore } from '../model/LayersPanel.store';
import { LayersPanelMaps } from './LayersPanelMaps';
import { LayersPanelList } from './LayersPanelList';
import { LayersPanelPosition } from '../model/interfaces';

interface Props {
  readonly store: LayersPanelStore;
  readonly parentTarget: HTMLElement;
  readonly position?: LayersPanelPosition;
}

export const LayersPanelDialog: FC<Props> = observer(
  ({ store}) => {
    return (
      <Modal isOpen={store.show} className={'layersPanel'} header={false} customPosition={{top: 46, right: 0}}>
    
        <div className="layersPanelContent">
          {/* Компонент выбора слоя с Картой */}
          <LayersPanelMaps store={store} />
          {/* Компонент списка соев на карте и управления ими */}
          <LayersPanelList store={store} />
        </div>
      </Modal>
    );
  },
);
