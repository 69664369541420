import { BeaconIcon, CameraIcon, CarIcon, GcsIcon, BusIcon, UavtwoIcon, UavIcon, Antenna_2Icon } from '@workspace/4Z1.uikit.react';
import { AssetTypes } from '@/entities/assets/model';
import type { ReactNode } from 'react';
import { AssetSubsetFragment } from "@/.graphql/graphql";
import {isDemoMode} from "@/shared/utils/isDemoMode";

const ICON_PATH = './icons/demoMapIcons';
const DEMO = isDemoMode();

export const getAssetIcon = (type: AssetTypes): ReactNode => {
  switch (type) {
    case AssetTypes.Car:
      return <CarIcon />;
    case AssetTypes.Gcs:
      return <GcsIcon />;
    case AssetTypes.Uav:
      return <UavIcon.Small />;
    case AssetTypes.Beacon:
      return <BeaconIcon />;
    case AssetTypes.Ptz:
      return <CameraIcon />;
    // TODO когда все иконки будут известны избавиться от default
    default:
      return <CameraIcon />;
  }
}

 
export const getAssetIconUav = (iconId: string, withStream: boolean): ReactNode => {
  return  DEMO ? <UavtwoIcon/> : iconId + (withStream ? 'plane_icon.svg' : 'plane_icon_yellow.svg')
}

export const getAssetIconDynamic = (item: AssetSubsetFragment, props?: any): ReactNode => {

  if (DEMO) {
    switch (item.assetType) {
      case AssetTypes.Car:
        return <BusIcon/>
      case AssetTypes.Ptz:
        return <CameraIcon/>
    }
  }

  let icon = '';
  if(item.assetType == AssetTypes.Ptz) icon = 'sova.svg';
  if(item.assetType == AssetTypes.Car) icon = 'grpc_car.svg';
  return item.iconId + icon;
}

export const getAssetIconStatic = (item: AssetSubsetFragment, props?: any): ReactNode => {
  if (DEMO) {
    switch (item.assetType) {
      case AssetTypes.Gcs:
        return <GcsIcon.Small/>
      case AssetTypes.Beacon:
        return <BeaconIcon/>
      case AssetTypes.Afu:
        return <Antenna_2Icon.Small/>
    }
  }

  let icon = '';
  switch (item.assetType) {
    case AssetTypes.Beacon:
      icon = 'beacon.svg';
      break;
    case AssetTypes.GeoGorizontService:
      icon = 'gg.svg';
      break;
    case AssetTypes.LocalServer:
      icon =  '4z1.svg';
      break;
    case AssetTypes.Ptz:
      icon =  'sova.svg';
      break;
    case AssetTypes.Gcs:
      icon =  'grpc_gcs.svg';
      break;
  }
  return item.iconId + icon;
}
