interface Props {
  components: React.JSXElementConstructor<React.PropsWithChildren<unknown>>[];
  children: React.ReactNode;
}

/**
 * Функция для удобного оборачивания компонентов в контексты
 *
 * @description
 * @param {components} - Список контекст-провайдеров
 * @param {children} - Оборачиваемый компонент
 *
 * @returns {JSX.Element} - Обернутый компонент
 * 
 * @example
 * 
 * Вместо написания длинных цепочек оберток теперь можно писать просто - 
 *
 * <Compose components={[MenuProvider, FlightProvider, MapProvider]}>
 *  <FlightPage/>
 * </Compose>
 */
export const Compose = (props: Props) => {
  const { components = [], children } = props;

  return (
    <>
      {components.reduceRight((acc, Comp) => {
        return <Comp>{acc}</Comp>;
      }, children)}
    </>
  );
};
