import { FC } from "react";
import { observer } from "mobx-react";

import { MapNavigateFn, useMapNavigation } from "@/entities/map/hooks/useMapNavigation";

interface Props {
  readonly onNavigate: MapNavigateFn;
  readonly id: string;
}

/**
 * Удобный способ вызова хука useMapNavigation из классовых компонент.
 */
export const MapNavigator: FC<Props> = observer(({ onNavigate, id }: Props) => {
  useMapNavigation(onNavigate, id);
  return (<></>);
});
