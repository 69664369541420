export const DiKeys = {
  userOptions: Symbol.for('userOptions'),
  assetFilter: Symbol.for('assetFilter'),
  onDeviceRevoke: Symbol.for('onDeviceRevoke'),
  assetOnMap: Symbol.for('assetOnMap'),
  onAssetDirectionChange: Symbol.for('onAssetDirectionChange'),
  onAssetCoordinatesChange: Symbol.for('onAssetCoordinatesChange'),
  showVideoStream: Symbol.for('showVideoStream'),
  flightOnMap: Symbol.for('flightOnMap'),
  updateGlobalDateRange: Symbol.for('updateGlobalDateRange'),
  selectedAssetOnMap: Symbol.for('selectedAssetOnMap'),
  LocaleStore: Symbol.for('LocaleStore'),
  DownloadFlight: Symbol.for('DownloadFlight'),
  flightRedirects: Symbol.for('FlightRedirects'),
  gasAnalyserStore: Symbol.for('GasAnalyserStore'),
  mediaAttachmentsProvider: Symbol.for('MediaAttachmentsProvider'),
  flightTelemetryStore: Symbol.for('FlightTelemetryStore'),
  photosStore: Symbol.for('PhotosStore'),
  ortophotoStore: Symbol.for('Ortophoto'),
  videosStore: Symbol.for('VideosStore'),
  downloadHistoryStore: Symbol.for('DownloadHistoryStore'),
  emptyListStore: Symbol.for('EmptyListStore'),
  ortophotoService: Symbol.for('OrtophotoService'),
  flightProvider: Symbol.for('FlightProvider'),
  gasProvider: Symbol.for('GasProvider'),
  onIncidentViewerClosed: Symbol.for('OnIncidentViewerClosed'),
  onSelectedIncident: Symbol.for('onSelectedIncident'),
  onSelectedTableElement: Symbol.for('onSelectedTableElement'),
  formHasChange: Symbol.for('formHasChange'),
  setIncidentCoordinates: Symbol.for('setIncidentCoordinates'),
  photosSevice: Symbol.for('ImageTilesService'),
  videosService: Symbol.for('VideosService'),
  deleteAttachmentsStore: Symbol.for('DeleteAttachmentsStore'),
  selectedAttachmentsProvider: Symbol.for('SelectedAttachmentsProvider'),
  onAttachmentsRefetchRequested: Symbol.for('onAttachmentsRefetchRequested'),
  downloadOrtophotoStore: Symbol.for('DownloadOrtophotoStore'),

  flightCard: {
    onVideoTrackSeek: Symbol.for('onVideoTrackSeek'),
    onVideoTimeChange: Symbol.for('onVideoTimeChange'),
    onViewtimeParamsChange: Symbol.for('onViewtimeParamsChange'),
    onWorkflowStatusChanged: Symbol.for('onWorkflowStatusChanged'),
  } as const,
  createFlight: Symbol.for('createFlight'),
  api: {
    massload: Symbol.for('massload'),
    usbupload: Symbol.for('usbupload'),
  } as const,
} as const;
