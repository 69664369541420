import { FC, useEffect } from 'react';
import { implicitMapCheck, Layers, MapPluginProps } from '@/shared/map';
import { Coordinates } from '@workspace/4Z1.ts.utils';
import { isNil } from 'lodash';
import { Point } from 'ol/geom';
import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import { getWaypointStyle, WaypointType } from '@/entities/assetTrack';


interface Props extends MapPluginProps {
  readonly coordinates: Coordinates | undefined;
}

export const SinglePointLayer: FC<Props> = ({coordinates, map = implicitMapCheck()}) => {

  const LAYER_NAME = Layers.SinglePoint;

  useEffect(() => {
    if (isNil(coordinates)) {
      map.removeLayerByName(LAYER_NAME)
      return;
    }

    const feature = new Feature({
      geometry: new Point(fromLonLat([coordinates.lon, coordinates.lat])),
    })

    feature.setStyle(getWaypointStyle(WaypointType.Highlight));

    const source = new VectorSource({
      features: [feature]
    })

    const layer = new VectorLayer({
      source,
    })

    layer.set('name', LAYER_NAME);

    map.addLayer(layer);
    map.setCenter(coordinates)

  }, [coordinates]);

  useEffect(() => {
    return () => map.removeLayerByName(LAYER_NAME);
  }, [map]);
  return <></>
}
