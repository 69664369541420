import { PtzActions, PTZBaseParams, PtzCommandParams } from './interfaces';
import { v4 as uuidv4 } from 'uuid';

const DEG = 0.1;
const DEG_S = 0.5;

// TODO надо разобраться с типами, это работает но TS ругается
const actionsMap: ReadonlyMap<PtzActions, (k: number) => Partial<Omit<PtzCommandParams, 'Uuid' | 'IsAbsolute'>>> = new Map([
  [ PtzActions.Up, (k) => { return { PitchDeg: k, PitchRateDegS: DEG_S} }],
  [ PtzActions.Down, (k) => { return { PitchDeg: -k, PitchRateDegS: DEG_S} }],
  [ PtzActions.Left, (k) => { return { YawDeg: -k, YawRateDegS: DEG_S} }],
  [ PtzActions.Right, (k) => { return { YawDeg: k, YawRateDegS: DEG_S} }],
  [ PtzActions.ZoomIn, (k) => { return { Zoom: k, ZoomRateDegS: DEG_S} }],
  [ PtzActions.ZoomOut, (k) => { return { Zoom: -k, ZoomRateDegS: DEG_S} }],
]);


export const prepareBaseParams = (CameraUuid: string): PTZBaseParams  => {
  return { CameraUuid, CommandUuid: uuidv4() }
}

export const prepareCommand = (CameraUuid: string, command: PtzActions): PtzCommandParams  => {
  const baseParams: PtzCommandParams = { ...prepareBaseParams(CameraUuid), IsAbsolute: false };
  const actionParams = actionsMap.get(command)?.(DEG);

  return {...baseParams, ...actionParams}
}
