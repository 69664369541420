import { CurrentOptionsKey, CurrentUserKey } from '@/services/UrqlClientProvider';
import type { CurrentOptions, CurrentUser } from '@/app';

export class ContextApp {

  getCurrentOptions(): CurrentOptions {
    return JSON.parse(localStorage.getItem(CurrentOptionsKey));
  }

  getCurrentUser(): CurrentUser {
    return JSON.parse(localStorage.getItem(CurrentUserKey));
  }
}
