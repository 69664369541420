import { action, makeAutoObservable } from 'mobx';
import { addHistory, search } from '@/features/geosearch/api';
import { SearchResultItem } from '@/features/geosearch/model/inteface';
import { logger } from "@workspace/4Z1.ts.utils";

const SEARCHED_ITEMS_COUNT = 5;

const log = logger('GSRC:ST');

export class GeoSearchStore {

  private _query: string = '';
  private _results: SearchResultItem[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get query(): string {
    return this._query;
  }

  get results(): SearchResultItem[] {
    return this._results;
  }

  setQuery(querySearch: string) {
    this._query = querySearch;

    const data = {
      query: querySearch,
      size: SEARCHED_ITEMS_COUNT,
    };

    search(data)
      .then(data => this.setResults(data))
      .catch(error => log.error('Request failed', error, data));
  }

  @action
  setResults(results: readonly SearchResultItem[]) {
    this._results = [...results];
  }

  saveToHistory(id: number) {
    if (this.query !== '' && id) {
      addHistory(id, this.query);
    }
  }

  selectItem(id: number, title: string) {
    this.saveToHistory(id);
    this.setQuery(title);
  }

  /**
   * @deprecated
   */
  showHistory() {
    if(this.query === '') {
      this.setQuery('');
    }
  }

  clear() {
    this.setQuery('');
  }

}

