export default {
  'ftp.hint.unknown': 'Data could be uploaded to FTP',
  'ftp.hint.in_progress': 'Uploading to FTP is in progress',
  'ftp.hint.done': 'Data is already uploaded to FTP',
  'ftp.hint.failed': 'Failed to upload data to FTP',

  'ftp.userLogin': 'User Login GCS',
  'ftp.companyCode': 'Company Code',
  'ftp.viewShot': 'View Shot',
  'ftp.flightDate': 'Flight date',
  'ftp.numberRoute': 'Route №',
  'ftp.numberFlight': 'Flight №',
  'ftp.numberTeam': 'Team Id',
  'ftp.cameraModel': 'Camera model',
  'ftp.focus': 'Focal length (mm)',
  'ftp.send_to_ftp': 'Send to FTP',
  'ftp.send_to_ftp.title': 'Upload to FTP',
  'ftp.submit.error': 'Request failed. Check entered data and try once again',
  'ftp.submit.success': 'Success! Upload to FTP will start soon',

  'ftp.userLogin.helper': 'Enter your username to work with GCS',
  'ftp.companyCode.helper': 'Enter the code of the subsidiary',
  'ftp.viewShot.helper': 'Select the device from which you filmed your flight',
  'ftp.flightDate.helper': 'Specify your flight date',
  'ftp.numberRoute.helper': 'Enter the flight route number',
  'ftp.numberFlight.helper': 'Enter the flight number',
  'ftp.numberTeam.helper': 'Enter the brigade number',
  'ftp.cameraModel.helper ': 'Enter the camera model',
  'ftp.focus.helper': 'Enter the focal length',
  'ftp.inputDir.helper': 'An error occured during file upload',

  'ftp.error.userlogin.not-found': 'User not found',
  'ftp.error.flightId.incorrect-id': ' Invalid flight ID is specified',
  'ftp.error.inputDir.no-telemetry-found': 'The directory with the telemetry file was not found',
  'ftp.error.viewShot.incorrect-type': 'An incorrect type of shooting is specified',
  'ftp.error.numberRoute.incorrect-route': 'An incorrect route number is specified',
  'ftp.error.numberFlight.incorrect-flight-number': 'The flight sequence number is incorrect',
  'ftp.error.numberTeam.incorrect-team-number': 'Incorrect brigade number',
  'ftp.error.cameraModel.incorrect-camera-model': 'Incorrect camera model',
  'ftp.error.focus.incorrect-focal-length': 'Incorrect camera focal length',

  'ftp.validation.required_value': 'Please, enter a value',
  'ftp.validation.required_file': 'Please, choose file',
  'ftp.validation.max_length': 'Too many characters entered. Max - ',
  'ftp.validation.permitted_chars':
    'Only latin characters, digits, dash and dots allowed',
  'ftp.validation.only_numbers_permitted': 'Only digits, dash and dots allowed',

  'ftp.error.no_flight': 'Something went wrong. Flight not found',

  'ftp.form.telemetryTitle': 'Loading telemetry',
  'ftp.form.uploadFile': 'upload file',
  'ftp.form.send': 'Send telemetry file',
  'ftp.mm': 'mm',
};
