import { type FC } from 'react';
import { LayersPanelStore } from '../model/LayersPanel.store';
import { useIntl } from 'react-intl';
import { Tree } from '@workspace/4Z1.uikit.react';
import { observer } from 'mobx-react';
import { useVisibleLayer } from '@/shared/ui/ol_map/useVisibleLayer';
import type BaseLayer from 'ol/layer/Base';
import { ItemLayer } from '../model/getLayersMap';

interface Props {
  store: LayersPanelStore;
}

export const LayersPanelList: FC<Props> = observer(({store}) => {
  const intl = useIntl();
  const { setVisibleLayer } = useVisibleLayer();

  const onSelected = (item: ItemLayer<BaseLayer>, selected: boolean) => {
    // Получаем слой из элемента
    const data = item.data;

    // Проверяем его наличие
    if (data === undefined) {
      return;
    }

    // Если это был родительский слой, выключаем его "нейтральное" состояние
    if (item.neutral) {
      item.neutral = false;
    }

    // Если это был родительский слой, получаем дочерние и переключаем их в актуальное состояние
    if (item.children && item.children?.length > 0) {
      item.children.forEach(i => {
        // Переключили видимость слоя на карте
        i.data?.setVisible(selected);
        // Сохранили в куки актуальное состояние видимости карты
        setVisibleLayer(i.data);
        i.checked = selected;
      })
    } else {
      // TODO особенность использования старых слоев на новой карте
      // Если в слое нет дочерних элементов, но у слоя есть подслои, то их тоже необходимо включить
      item.data?.getLayersArray()?.forEach(layer => layer.setVisible(selected));
    }

    // Меняем состояние чекбокса
    item.checked = selected;

    // Переключаем видимость слоя на карте
    data.setVisible(selected);
    // Записываем изменения в куки
    setVisibleLayer(data);


    // Проверяем, является ли слой родительским
    const isParentLayer = store.layers.find(layer => layer.key === item.key);

    // Если нет, проводим настройки родительского слоя
    if (!isParentLayer) {
      // Ищем родительский слой
      let parent = store.layers.find(layer =>
        layer.children?.some(child => child.key === item.key)
      );

      // Если слой найден
      if (parent) {
        // Переключаем состояние дочернего слоя, чтобы родительский знал об актуальной информации о дочернем
        const childLayer = parent.children.find(layer => layer.key === item.key);
        if (childLayer) {
          childLayer.checked = selected;
        }

        // Статусы состояния родительского слоя
        const childStates = parent.children.map(layer => layer.checked);
        const allSelected = childStates.every(state => state);
        const allUnselected = childStates.every(state => !state);
        const hasSelected = childStates.some(state => state);

        // Если все выключены, отключаем слой
        if (allUnselected) {
          parent.data.setVisible(false);
          parent.neutral = false;
          parent.checked = false;
          return;
        }

        parent.data.setVisible(true);
        // Если все включены, включаем в состояния выбраны все
        if (allSelected) {
          parent.neutral = false;
          parent.checked = true;
          return;
        }

        // Если слои частично выбраны, ставим состояние нейтральное
        if (hasSelected) {
          parent.checked = true;
          parent.neutral = true;
        }
      }
    }
  }

  return (<>
    {
      store.layers && <div className='layersPanelList'>
        <div className='layersPanelListTitle'>
          {intl.formatMessage({id: 'map.Tiles'})}
        </div>
        <div className='layersPanelListContainer'>
          <Tree
            data={store.layers}
            withCheckbox={true}
            onSelected={(item, checked) => onSelected(item, checked)}
          />
        </div>
      </div>
    }
  </>)
})
