import { useIntl } from 'react-intl';
import { FC } from 'react';
import _ from 'lodash';
import { AssetCardProps } from '@/widgets';
import { ViolationIcon, Tooltip, Text } from '@workspace/4Z1.uikit.react';
import { dep, diInject } from '@/HOC';
import { Assets, VideoStreamingAssetsService } from '@/entities/assets/model';

interface Props extends AssetCardProps {
  readonly streams: VideoStreamingAssetsService;
}

const UavInfo: FC<Props> = ({asset, streams}) => {

  if (!Assets.isUav(asset)) {
    return <></>
  }

  const intl = useIntl();

  return (
    <>
        {
          !_.isNil(asset.state.uavState?.diagnosticsFlags) && asset.state.uavState.diagnosticsFlags.some((flag: boolean) => flag) &&
          <div className='diagnosticAuvList'>
            {intl.formatMessage({id: 'online.assetCard.warnings', defaultMessage: 'Warnings'})}:
            {asset.state.uavState.diagnosticsFlags?.map((flag: boolean, index: number) => {
                return flag
                  ? <Tooltip key={index} title={intl.formatMessage({id: `online.assetCard.diagnosticFlag.${index}`}) ?? index}>
                    <ViolationIcon.XSmall />
                  </Tooltip>
                  : null
              }
            )}
          </div>
        }

      {
        streams.getRealStreamingAsset(asset)?.name &&
        <Text.Ellipsis>
          {intl.formatMessage({id: 'online.assetCard.gcs', defaultMessage: 'GCS'})}:
          {streams.getRealStreamingAsset(asset)?.name ?? 'N/A'}
        </Text.Ellipsis>
      }


      {
        !_.isNil(asset.state.uavState?.inAir) &&
          <Text.Ellipsis>
            {intl.formatMessage({id: 'online.assetCard.state', defaultMessage: 'State'})}: &nbsp;
            {asset.state.uavState.inAir
              ? intl.formatMessage({id: 'online.assetCard.inAir', defaultMessage: 'In Air'})
              : intl.formatMessage({id: 'online.assetCard.grounded', defaultMessage: 'Grounded'})
            }
          </Text.Ellipsis>
      }

      {
        !_.isNil(asset.state.uavState?.pilotingMode) &&
          <Text.Ellipsis>
            {intl.formatMessage({id: 'online.assetCard.mode', defaultMessage: 'Mode'})}:
            {asset.state.uavState.pilotingMode}
          </Text.Ellipsis>
      }


      {
        !_.isNil(asset.state.uavState?.engineTemp) &&
          <Text.Ellipsis>
            {intl.formatMessage({id: 'online.assetCard.engineTemp'})}: {asset.state.uavState.engineTemp}
          </Text.Ellipsis>
      }
    </>
  )
}

export default diInject(UavInfo, {
  streams: dep(VideoStreamingAssetsService),
});
