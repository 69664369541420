import { Coordinates, Location } from '@workspace/4Z1.ts.utils';
import { makeAutoObservable } from 'mobx';

import RequestHandler from '@/shared/utils/requestHandler';

import { Asset, AssetTypes } from '@/entities/assets/model';
import { createAssetsApi } from '@/entities/assets/api';

interface UpdateCoordsParams {
  readonly asset: Asset;
  readonly coordinates: string;
}

export class CoordinatesStore {
  constructor(
    /** 
     * TODO - Исправить
     * 
     * Мы не должны брать API из ассетов, но должны инжектить сюда сервис и взаимодействовать с ним
    */
    private readonly api = createAssetsApi(),
    private readonly requestHandler = new RequestHandler(),
  ) {
    makeAutoObservable(this);
  }

  public async onFormSubmit(
    params: UpdateCoordsParams,
  ): Promise<Coordinates> {
    const coordinates = Location.parse(params.coordinates);
    
    if (coordinates === undefined) {
      throw new Error('Incorrect coordinates received');
    }

    if(params.asset.assetType === AssetTypes.Afu) {
      return this.requestHandler
      .handleRequest(() => this.api.updateAfuCoordinates({ ...params, coordinates }))
      .then(() => coordinates);
    } else {
      return this.requestHandler
      .handleRequest(() => this.api.updateAssetCoordinates({ ...params, coordinates }))
      .then(() => coordinates);
    }
  }

  public validate(coordinates: string): boolean {
    const withCoordinates = Location.parse(coordinates);

    return withCoordinates !== undefined;
  }

  public get isLoading(): boolean {
    return this.requestHandler.isLoading;
  }
}
