import { AssetDirectionStore } from '../model';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { dep, diInject } from '@/HOC';
import { Button } from '@workspace/4Z1.uikit.react';
import { AssetCardProps } from '@/widgets';
import { DiKeys } from '@/shared/di/global';
import { Assets } from '@/entities/assets/model';

interface Props extends AssetCardProps {
  readonly assetDirectionStore: AssetDirectionStore;
  readonly assetOnMap: (assetId: string) => void;
}

const ChangeAssetDirection: FC<Props> = ({
  asset,
  assetDirectionStore,
  assetOnMap,
}) => {
  const intl = useIntl();

  if (!Assets.isActive(asset) || !Assets.canSetDirection(asset)) {
    return undefined;
  }

  const onDirectionUpdateClick = () => {
    assetOnMap(asset.id);
    assetDirectionStore.showAssetCompass(asset);
  };

  return (
    <Button
      className='fullWidth'
      type='accent'
      size='small'
      label={intl.formatMessage({ id: 'change-dir.update' })}
      onClick={onDirectionUpdateClick}
    />
  );
};

export default diInject(ChangeAssetDirection, {
  assetDirectionStore: dep(AssetDirectionStore.diKey),
  assetOnMap: dep(DiKeys.assetOnMap),
});
