import './styles.scss';

import { type FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Geometry } from 'ol/geom';
import { Button, CloseIcon, DateRangerPicker, List } from '@workspace/4Z1.uikit.react';
import { DateRange } from '@workspace/4Z1.ts.utils';
import { useAppContext } from '@/shared/store/context';
import { DATE_TEMPLATE, SearchOnAreaItem } from '../model/interfaces';
import { FlightsByDateAndAreaStore } from '../model/FlightsByDateAndArea.store';

interface Props {
  readonly area?: Geometry;
  readonly close?: () => void;
}

export const FlightsByDateAndArea: FC<Props> = observer(({area, close}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  // TODO избавиться useAppContext - когда карта будет работать на DI (брать из UserOptionsFromLocalStorage)
  const {appContext} = useAppContext();
  const initialState = appContext.getCurrentOptions();
  const date_range = initialState?.date_range?.main || [];

  const initialRangeDate = {
    startDate: moment(date_range[0], DATE_TEMPLATE).toDate(),
    endDate: moment(date_range[1], DATE_TEMPLATE).toDate(),
  }

  const [store] = useState(() => new FlightsByDateAndAreaStore());

  useEffect(() => {
    store.area = area;
    store.querySearch(initialRangeDate);
  }, [area]);


  const openFlight = (id: number) => {
    navigate(`/flight/${id}`);
  }

  const dateFilter = useMemo(() => {
    return <DateRangerPicker
      numberOfMonths={2}
      initialRange={initialRangeDate}
      onRangeChange={(dateRange: DateRange) => store.querySearch(dateRange)}
    />
  }, [store])

  return (
    <div className='FlightsByDateAndArea'>

      <div className='FlightsByDateAndAreaHeader'>
        <div>{intl.formatMessage({id: 'map.SearchOnArea'})}</div>
        <Button
          type='badge'
          size='small'
          onlyIcon={true}
          icon={<CloseIcon/>}
          onClick={() => close?.()}
        />
      </div>

      {dateFilter}

      <List
        className='FlightsByDateAndAreaList'
        activeParam='flight_id'
        width='100%'
        emptyMessage={intl.formatMessage({id: 'assets.historicalData.na'})}
        dataSource={store.flightsSearchOnArea}
        onClick={(item: SearchOnAreaItem) => openFlight(item.flightId)}
        renderItem={(item: SearchOnAreaItem) => <List.Item title={item.title}
        />}
      />
    </div>
  )
})
