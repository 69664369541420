import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { SidebarMenuProps } from '../model/types';
import SidebarMenu from './SidebarMenu/SidebarMenu';
import './styles.scss';
import menuFromUmiRoutes from '../config/menuFromUmiRoutes';
import { useAccess } from '@umijs/max';
import { normalizePath } from '@workspace/4Z1.ts.utils';

const Sidebar: FC<SidebarMenuProps> = ({ routes, footer }) => {
  const access = useAccess();
  const location = useLocation();

  const shouldShowSidebar = (() => {
    const currentPath = normalizePath(location.pathname);
    const route = routes.find((r) => normalizePath(r.path) === currentPath);
    if (route === undefined) {
      return true;
    }
    return route.menuRender === undefined;
  })();

  return (
    <>
      {shouldShowSidebar && (
        <div className="sidebarContainer">
          <SidebarMenu
            routes={menuFromUmiRoutes(routes, access)}
            footer={footer}
          />
        </div>
      )}
    </>
  );
};

export default Sidebar;
