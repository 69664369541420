import { useEffect } from 'react';
import { useModel, history } from 'umi';
import { UserLoginPath } from '@/services/UrqlClientProvider';
import { useGetOnlineVideoStreamingUavsQuery } from '@/.graphql/graphql';
import { setLocalStorageItems } from '@/shared/utils/localStorage';
import { LocalStorageKeys } from '@/shared/model';

//const url: string = API + '/onlines/is-online';
const seconds: number = 30;

const OnlineNotification = (props: any) => {
  const { initialState, setInitialState, refresh } = useModel('@@initialState');
  const token = initialState?.currentUser?.token;
  const options = initialState?.currentOptions;

  const [videoStreamingUavs, executeVideoStreamingUavs] = useGetOnlineVideoStreamingUavsQuery({
    variables: {
      assetTypesFilter: ["Uav"],
      projectFilter: "all",
      assetGroupFilter: []
    },
    pause: token === undefined
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (history.location.pathname !== UserLoginPath) executeVideoStreamingUavs({ requestPolicy: 'network-only' });
    }, 1000 * seconds);
    return () => clearInterval(interval);
  }, [token]);

  useEffect(() => {
    if (videoStreamingUavs.data?.onlineVideoStreamingUavs) {
      const response = videoStreamingUavs.data?.onlineVideoStreamingUavs.length > 0 ? true : false;
      if(response !== initialState?.currentOptions?.isOnline){
        if(options) {
          options.isOnline = response;
          refresh();
          setLocalStorageItems(LocalStorageKeys.CurrentOptions, options);
          setInitialState({...initialState, currentOptions: options});
        }
      }
    }
  }, [videoStreamingUavs.data?.onlineVideoStreamingUavs]);

  /*const OnlineRequest = () => {
    request(url, {
      method: 'get',
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response: boolean) {
      if(response !== initialState?.currentOptions?.isOnline){
        if(options) options.isOnline = response;
        setInitialState({
          ...initialState,
          currentOptions: options,
        });
      }
    })
    .catch(function (error) {console.log(error);});
  }

  useEffect(() => {
    if (history.location.pathname !== UserLoginPath) OnlineRequest();
    const interval = setInterval(() => {
      if (history.location.pathname !== UserLoginPath) OnlineRequest();
    }, 1000 * seconds);
    return () => clearInterval(interval);
  }, [token]);
  */

  return (
    <>
      {props.children}
    </>
  );
};

export default OnlineNotification;
