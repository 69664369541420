import { Coordinates } from '@workspace/4Z1.ts.utils';
import { fromLonLat } from 'ol/proj';
import { Coordinate } from 'ol/coordinate';
import _ from 'lodash';

export const mapCoordinates = (coordinates: Coordinates | undefined): Coordinate | undefined => {
  if (_.isNil(coordinates) || (_.isNil(coordinates.lon) || _.isNil(coordinates.lat))) {
    return undefined;
  }

  return fromLonLat([coordinates.lon, coordinates.lat]);
}
