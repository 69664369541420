export enum FlightStatuses {
  Deleted = 0,
  Active = 1,
  Inactive = 2,
}

export enum FlightTypes {
  Video = 1,
  Photo = 2,
  Violation = 3,
  Gaz = 4,
}

/** 
  @deprecated
*/
export enum FlightWorkflowStatuses {
  Draft = 0,
  SentForReview = 1,
  InProcessing = 2,
  Processed = 3,
}

export interface FlightWorkflowStatus {
  readonly id: string;
  readonly name: string;
}

export enum RouteStatuses {
  Deleted = 0,
  Active = 1,
}