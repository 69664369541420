import { logger } from '@workspace/4Z1.ts.utils';
import { LocalStorageKeys } from './interfaces';

const log = logger('LOC');

//Нужно для локализации uikit
//TODO требует рефача, возможно можно заменить и как-то по другому передавать locale

export const getLocale = () => {
    const storedLocale = localStorage.getItem(LocalStorageKeys.ClientLocale);

    if (storedLocale) {
        try {
            const parsedLocale = JSON.parse(storedLocale);
            return parsedLocale;
        } catch (error) {
            log.error('parse error locale', error);;
        }
    }
    return 'ru-RU';
};