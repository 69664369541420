// Временное решение. Идентификаторы приходят с бека. Решение костыльное. Надо обсудить логику с Ильей.
import { OSM_MAP_ID } from "@/shared/map";

export const getPathPreviewMap = (id: number | string): string => {
  switch (id.toString()) {
    case OSM_MAP_ID.toString():
      return '/icons/preview-map/osm_map.png';
    case '6':
      return '/icons/preview-map/google_hybrid_map.png';
    default:
      return '/icons/preview-map/osm_map.png';
  }
}
