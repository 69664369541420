import moment from 'moment';

/**
 * TODO - Убрать any и избавиться от моковых данных, когда появится апишка
 */
type GetFilesResponse = readonly any[];

export const mockData: GetFilesResponse = [
  {
    id: 1,
    type: 'Ортофотопланы',
    fileName: 'Много фото.rar',
    fileSize: 123453444455,
    status: 'loaded',
    createdAt: moment().unix(),
  },
  {
    id: 2,
    type: 'Видео',
    fileName: 'Мало фото.rar',
    fileSize: 24235423,
    status: 'loading',
    createdAt: moment().unix(),
  },
  {
    id: 3,
    type: 'Фото',
    fileName: 'Мало фото.rar',
    fileSize: 1234555,
    status: 'loaded',
    createdAt: 1654775675,
  },
  {
    id: 4,
    type: 'Фото',
    fileName: 'Много фото.rar',
    fileSize: 12345332455,
    status: 'loaded',
    createdAt: moment().unix(),
  },
  {
    id: 5,
    type: 'Видео',
    fileName: 'Архив.rar',
    fileSize: 123453455,
    status: 'error',
    createdAt: moment().unix(),
  },
];

export const mockDataList: any[] = [
  {
    id: 443,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'загруженное.svg',
    fileSize: 11223,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:50.113912+00:00',
    finishedAt: '2024-12-13T12:45:50.12507+00:00',
  },
  {
    id: 442,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'zoo.svg',
    fileSize: 8311,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:50.113375+00:00',
    finishedAt: '2024-12-13T12:45:50.124891+00:00',
  },
  {
    id: 441,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'zamazuchennost.svg',
    fileSize: 1855,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:50.107759+00:00',
    finishedAt: '2024-12-13T12:45:50.122259+00:00',
  },
  {
    id: 440,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'wave.svg',
    fileSize: 1633,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:50.088498+00:00',
    finishedAt: '2024-12-13T12:45:50.09833+00:00',
  },
  {
    id: 438,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'video.svg',
    fileSize: 466,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:50.072653+00:00',
    finishedAt: '2024-12-13T12:45:50.082769+00:00',
  },
  {
    id: 439,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'vyhodtruby.svg',
    fileSize: 885,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:50.072121+00:00',
    finishedAt: '2024-12-13T12:45:50.083098+00:00',
  },
  {
    id: 437,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'truck.svg',
    fileSize: 2380,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:50.067834+00:00',
    finishedAt: '2024-12-13T12:45:50.079761+00:00',
  },
  {
    id: 436,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'truby.svg',
    fileSize: 2809,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:50.05428+00:00',
    finishedAt: '2024-12-13T12:45:50.066112+00:00',
  },
  {
    id: 435,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'truboprovod.svg',
    fileSize: 885,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:50.050499+00:00',
    finishedAt: '2024-12-13T12:45:50.063905+00:00',
  },
  {
    id: 434,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'truba.svg',
    fileSize: 1163,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:50.047647+00:00',
    finishedAt: '2024-12-13T12:45:50.062237+00:00',
  },
  {
    id: 433,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'town.svg',
    fileSize: 2350,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:50.037186+00:00',
    finishedAt: '2024-12-13T12:45:50.049188+00:00',
  },
  {
    id: 431,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'tn.svg',
    fileSize: 600,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:50.027432+00:00',
    finishedAt: '2024-12-13T12:45:50.039399+00:00',
  },
  {
    id: 432,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'tent.svg',
    fileSize: 2872,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:50.027356+00:00',
    finishedAt: '2024-12-13T12:45:50.039921+00:00',
  },
  {
    id: 430,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'tehnicheskiepostroyki.svg',
    fileSize: 807,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:50.007098+00:00',
    finishedAt: '2024-12-13T12:45:50.022214+00:00',
  },
  {
    id: 429,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'target.svg',
    fileSize: 2713,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.990471+00:00',
    finishedAt: '2024-12-13T12:45:50.002986+00:00',
  },
  {
    id: 428,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'svish.svg',
    fileSize: 976,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.982694+00:00',
    finishedAt: '2024-12-13T12:45:49.993997+00:00',
  },
  {
    id: 427,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'tank.svg',
    fileSize: 914,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.980137+00:00',
    finishedAt: '2024-12-13T12:45:49.991817+00:00',
  },
  {
    id: 426,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'svalka.svg',
    fileSize: 1472,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.970107+00:00',
    finishedAt: '2024-12-13T12:45:49.983+00:00',
  },
  {
    id: 424,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'study.svg',
    fileSize: 2741,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.962661+00:00',
    finishedAt: '2024-12-13T12:45:49.977351+00:00',
  },
  {
    id: 425,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'structure.svg',
    fileSize: 1893,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.962323+00:00',
    finishedAt: '2024-12-13T12:45:49.978046+00:00',
  },
  {
    id: 423,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'sova.svg',
    fileSize: 3761,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.946322+00:00',
    finishedAt: '2024-12-13T12:45:49.957198+00:00',
  },
  {
    id: 422,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'stroitelnayatehnika.svg',
    fileSize: 2834,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.944929+00:00',
    finishedAt: '2024-12-13T12:45:49.956558+00:00',
  },
  {
    id: 421,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'service.svg',
    fileSize: 4991,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.931554+00:00',
    finishedAt: '2024-12-13T12:45:49.943977+00:00',
  },
  {
    id: 420,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'razliv.svg',
    fileSize: 717,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.924908+00:00',
    finishedAt: '2024-12-13T12:45:49.936395+00:00',
  },
  {
    id: 419,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'rainbow.svg',
    fileSize: 4552,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.900081+00:00',
    finishedAt: '2024-12-13T12:45:49.910772+00:00',
  },
  {
    id: 418,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'point_20.svg',
    fileSize: 565,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.897168+00:00',
    finishedAt: '2024-12-13T12:45:49.908715+00:00',
  },
  {
    id: 417,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'point.svg',
    fileSize: 565,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.892398+00:00',
    finishedAt: '2024-12-13T12:45:49.905536+00:00',
  },
  {
    id: 416,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'plane_icon_yellow.svg',
    fileSize: 14184,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.879592+00:00',
    finishedAt: '2024-12-13T12:45:49.895873+00:00',
  },
  {
    id: 415,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'plane_icon.svg',
    fileSize: 14179,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.878061+00:00',
    finishedAt: '2024-12-13T12:45:49.891752+00:00',
  },
  {
    id: 414,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'people.svg',
    fileSize: 1126,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.868185+00:00',
    finishedAt: '2024-12-13T12:45:49.88656+00:00',
  },
  {
    id: 412,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'oilpump.svg',
    fileSize: 1662,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.86103+00:00',
    finishedAt: '2024-12-13T12:45:49.873852+00:00',
  },
  {
    id: 413,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'nefteskvajina.svg',
    fileSize: 3739,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.856773+00:00',
    finishedAt: '2024-12-13T12:45:49.87443+00:00',
  },
  {
    id: 411,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'mazut.svg',
    fileSize: 2083,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.846351+00:00',
    finishedAt: '2024-12-13T12:45:49.858445+00:00',
  },
  {
    id: 410,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'materials.svg',
    fileSize: 2991,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.842867+00:00',
    finishedAt: '2024-12-13T12:45:49.855511+00:00',
  },
  {
    id: 409,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'mark.svg',
    fileSize: 1960,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.813462+00:00',
    finishedAt: '2024-12-13T12:45:49.822774+00:00',
  },
  {
    id: 408,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'lodka.svg',
    fileSize: 749,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.808959+00:00',
    finishedAt: '2024-12-13T12:45:49.818933+00:00',
  },
  {
    id: 407,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'machinery.svg',
    fileSize: 3016,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.808435+00:00',
    finishedAt: '2024-12-13T12:45:49.818563+00:00',
  },
  {
    id: 406,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'Lancet yellow.svg',
    fileSize: 9416,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.788883+00:00',
    finishedAt: '2024-12-13T12:45:49.799925+00:00',
  },
  {
    id: 405,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'Lancet green.svg',
    fileSize: 9415,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.776431+00:00',
    finishedAt: '2024-12-13T12:45:49.7862+00:00',
  },
  {
    id: 404,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'kranovyyuzel.svg',
    fileSize: 783,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.77377+00:00',
    finishedAt: '2024-12-13T12:45:49.784962+00:00',
  },
  {
    id: 403,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'jbi.svg',
    fileSize: 1507,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.7661+00:00',
    finishedAt: '2024-12-13T12:45:49.781655+00:00',
  },
  {
    id: 402,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'hozyaystvennayapostroykasaraybudka.svg',
    fileSize: 1666,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.755913+00:00',
    finishedAt: '2024-12-13T12:45:49.773843+00:00',
  },
  {
    id: 401,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'incident.svg',
    fileSize: 2940,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.753252+00:00',
    finishedAt: '2024-12-13T12:45:49.772863+00:00',
  },
  {
    id: 400,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'home.svg',
    fileSize: 2372,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.745592+00:00',
    finishedAt: '2024-12-13T12:45:49.769585+00:00',
  },
  {
    id: 399,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'gruzovik.svg',
    fileSize: 2174,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.731037+00:00',
    finishedAt: '2024-12-13T12:45:49.743746+00:00',
  },
  {
    id: 398,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'grpc_car.svg',
    fileSize: 6000,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.728157+00:00',
    finishedAt: '2024-12-13T12:45:49.74082+00:00',
  },
  {
    id: 397,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'grpc_gcs.svg',
    fileSize: 5498,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.727106+00:00',
    finishedAt: '2024-12-13T12:45:49.740195+00:00',
  },
  {
    id: 396,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'gg.svg',
    fileSize: 3832,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.689602+00:00',
    finishedAt: '2024-12-13T12:45:49.698096+00:00',
  },
  {
    id: 395,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'forest.svg',
    fileSize: 1739,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.68759+00:00',
    finishedAt: '2024-12-13T12:45:49.697651+00:00',
  },
  {
    id: 394,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'fish.svg',
    fileSize: 8988,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.675015+00:00',
    finishedAt: '2024-12-13T12:45:49.684369+00:00',
  },
  {
    id: 393,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'fire.svg',
    fileSize: 1571,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.670524+00:00',
    finishedAt: '2024-12-13T12:45:49.682473+00:00',
  },
  {
    id: 391,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'exavator.svg',
    fileSize: 2770,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.65534+00:00',
    finishedAt: '2024-12-13T12:45:49.673153+00:00',
  },
  {
    id: 392,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'fakel.svg',
    fileSize: 1156,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.655301+00:00',
    finishedAt: '2024-12-13T12:45:49.674339+00:00',
  },
  {
    id: 390,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'drevesnyestroymaterialy.svg',
    fileSize: 2503,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.649651+00:00',
    finishedAt: '2024-12-13T12:45:49.668676+00:00',
  },
  {
    id: 388,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'electric-tower.svg',
    fileSize: 4306,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.640992+00:00',
    finishedAt: '2024-12-13T12:45:49.659741+00:00',
  },
  {
    id: 389,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'ecology.svg',
    fileSize: 1124,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.639081+00:00',
    finishedAt: '2024-12-13T12:45:49.664756+00:00',
  },
  {
    id: 387,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'cross.svg',
    fileSize: 1038,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.633545+00:00',
    finishedAt: '2024-12-13T12:45:49.65804+00:00',
  },
  {
    id: 386,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'crane.svg',
    fileSize: 1113,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.61054+00:00',
    finishedAt: '2024-12-13T12:45:49.61948+00:00',
  },
  {
    id: 385,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'cisternavertikalnaya.svg',
    fileSize: 1592,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.6086+00:00',
    finishedAt: '2024-12-13T12:45:49.61842+00:00',
  },
  {
    id: 384,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'cisternagorizontalnaya.svg',
    fileSize: 642,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.586957+00:00',
    finishedAt: '2024-12-13T12:45:49.597619+00:00',
  },
  {
    id: 383,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'chastnyydomkottedj.svg',
    fileSize: 2219,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.581614+00:00',
    finishedAt: '2024-12-13T12:45:49.59109+00:00',
  },
  {
    id: 382,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'car.svg',
    fileSize: 2264,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.578115+00:00',
    finishedAt: '2024-12-13T12:45:49.588994+00:00',
  },
  {
    id: 381,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'camera.svg',
    fileSize: 3458,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.528505+00:00',
    finishedAt: '2024-12-13T12:45:49.540915+00:00',
  },
  {
    id: 377,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'buldozer.svg',
    fileSize: 2018,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.516408+00:00',
    finishedAt: '2024-12-13T12:45:49.536081+00:00',
  },
  {
    id: 376,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'beacon.svg',
    fileSize: 9056,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.512881+00:00',
    finishedAt: '2024-12-13T12:45:49.534798+00:00',
  },
  {
    id: 378,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'bochka.svg',
    fileSize: 2987,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.512325+00:00',
    finishedAt: '2024-12-13T12:45:49.537951+00:00',
  },
  {
    id: 375,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'autokran.svg',
    fileSize: 3226,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.498068+00:00',
    finishedAt: '2024-12-13T12:45:49.535604+00:00',
  },
  {
    id: 380,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'atom.svg',
    fileSize: 1411,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.494264+00:00',
    finishedAt: '2024-12-13T12:45:49.539894+00:00',
  },
  {
    id: 379,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'avtomobil.svg',
    fileSize: 2236,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.490388+00:00',
    finishedAt: '2024-12-13T12:45:49.539186+00:00',
  },
  {
    id: 373,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: '4z1.svg',
    fileSize: 4148,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.475444+00:00',
    finishedAt: '2024-12-13T12:45:49.507247+00:00',
  },
  {
    id: 374,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'autogruz.svg',
    fileSize: 2512,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.472521+00:00',
    finishedAt: '2024-12-13T12:45:49.512065+00:00',
  },
  {
    id: 372,
    flightId: '',
    fileType: 'image/svg+xml',
    zalaFileType: '',
    fileName: 'avto.svg',
    fileSize: 2412,
    offset: 0,
    status: 'Error',
    username: 'admin_server',
    createdAt: '2024-12-13T12:45:49.472049+00:00',
    finishedAt: '2024-12-13T12:45:49.503996+00:00',
  },
  {
    id: 371,
    flightId: '',
    fileType: 'application/zip',
    zalaFileType: '',
    fileName: 'VSCode-darwin-universal 1.zip',
    fileSize: 229482716,
    offset: 0,
    status: 'Terminated',
    username: 'admin_server',
    createdAt: '2024-12-13T08:44:34.675318+00:00',
    finishedAt: '0001-01-01T00:00:00+00:00',
  },
  {
    id: 370,
    flightId: '',
    fileType: 'application/zip',
    zalaFileType: '',
    fileName: 'VSCode-darwin-universal 1.zip',
    fileSize: 229482716,
    offset: 0,
    status: 'Terminated',
    username: 'admin_server',
    createdAt: '2024-12-13T08:15:19.241118+00:00',
    finishedAt: '0001-01-01T00:00:00+00:00',
  },
  {
    id: 369,
    flightId: '',
    fileType: 'application/zip',
    zalaFileType: '',
    fileName: 'VSCode-darwin-universal.zip',
    fileSize: 229482716,
    offset: 0,
    status: 'Removed',
    username: 'admin_server',
    createdAt: '2024-12-13T08:14:13.628815+00:00',
    finishedAt: '2024-12-13T08:14:13.637952+00:00',
  },
  {
    id: 368,
    flightId: '',
    fileType: 'application/zip',
    zalaFileType: '',
    fileName: 'VSCode-darwin-universal.zip',
    fileSize: 229482716,
    offset: 0,
    status: 'Removed',
    username: 'admin_server',
    createdAt: '2024-12-13T08:13:40.101628+00:00',
    finishedAt: '2024-12-13T08:13:40.348108+00:00',
  },
  {
    id: 367,
    flightId: '',
    fileType: '',
    zalaFileType: '',
    fileName: '10040319-5.rar',
    fileSize: 11813651451,
    offset: 11813651451,
    status: 'Stored',
    username: 'admin_server',
    createdAt: '2024-12-13T07:35:10.659128+00:00',
    finishedAt: '2024-12-13T07:38:52.532342+00:00',
  },
  {
    id: 366,
    flightId: '',
    fileType: 'application/zip',
    zalaFileType: '',
    fileName: 'VSCode-darwin-universal.zip',
    fileSize: 229482716,
    offset: 229482716,
    status: 'Stored',
    username: 'admin_server',
    createdAt: '2024-12-12T14:44:21.01521+00:00',
    finishedAt: '2024-12-12T14:44:29.788987+00:00',
  },
  {
    id: 365,
    flightId: '',
    fileType: '',
    zalaFileType: '',
    fileName: '10040319-3.rar',
    fileSize: 11813651451,
    offset: 0,
    status: 'Uploading',
    username: 'admin_server',
    createdAt: '2024-12-12T13:40:30.555621+00:00',
    finishedAt: '0001-01-01T00:00:00+00:00',
  },
  {
    id: 364,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'test17.ts',
    fileSize: 40705196,
    offset: 0,
    status: 'Error',
    username: 'root',
    createdAt: '2024-12-11T10:26:55.206667+00:00',
    finishedAt: '2024-12-11T10:26:55.213967+00:00',
  },
  {
    id: 363,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'test17 — копия.ts',
    fileSize: 40705196,
    offset: 0,
    status: 'Error',
    username: 'root',
    createdAt: '2024-12-11T10:26:55.197761+00:00',
    finishedAt: '2024-12-11T10:26:55.207061+00:00',
  },
  {
    id: 362,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'test17 — копия (7).ts',
    fileSize: 40705196,
    offset: 0,
    status: 'Error',
    username: 'root',
    createdAt: '2024-12-11T10:26:55.191426+00:00',
    finishedAt: '2024-12-11T10:26:55.199624+00:00',
  },
  {
    id: 361,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'test17 — копия (6).ts',
    fileSize: 40705196,
    offset: 0,
    status: 'Error',
    username: 'root',
    createdAt: '2024-12-11T10:26:55.18692+00:00',
    finishedAt: '2024-12-11T10:26:55.196299+00:00',
  },
  {
    id: 360,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'test17 — копия (5).ts',
    fileSize: 40705196,
    offset: 0,
    status: 'Error',
    username: 'root',
    createdAt: '2024-12-11T10:26:55.149326+00:00',
    finishedAt: '2024-12-11T10:26:55.157385+00:00',
  },
  {
    id: 358,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'test17 — копия (3).ts',
    fileSize: 40705196,
    offset: 0,
    status: 'Error',
    username: 'root',
    createdAt: '2024-12-11T10:26:55.130093+00:00',
    finishedAt: '2024-12-11T10:26:55.137388+00:00',
  },
  {
    id: 359,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'test17 — копия (2).ts',
    fileSize: 40705196,
    offset: 0,
    status: 'Error',
    username: 'root',
    createdAt: '2024-12-11T10:26:55.12907+00:00',
    finishedAt: '2024-12-11T10:26:55.137611+00:00',
  },
  {
    id: 357,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'test17 — копия (4).ts',
    fileSize: 40705196,
    offset: 0,
    status: 'Error',
    username: 'root',
    createdAt: '2024-12-11T10:26:55.128573+00:00',
    finishedAt: '2024-12-11T10:26:55.136936+00:00',
  },
  {
    id: 356,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'sample1.ts',
    fileSize: 739548948,
    offset: 0,
    status: 'Error',
    username: 'root',
    createdAt: '2024-12-11T10:26:55.109635+00:00',
    finishedAt: '2024-12-11T10:26:55.121259+00:00',
  },
  {
    id: 354,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'sample1 — копия (7).ts',
    fileSize: 739548948,
    offset: 0,
    status: 'Terminated',
    username: 'root',
    createdAt: '2024-12-11T10:26:55.109206+00:00',
    finishedAt: '0001-01-01T00:00:00+00:00',
  },
  {
    id: 355,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'sample1 — копия.ts',
    fileSize: 739548948,
    offset: 0,
    status: 'Terminated',
    username: 'root',
    createdAt: '2024-12-11T10:26:55.109151+00:00',
    finishedAt: '0001-01-01T00:00:00+00:00',
  },
  {
    id: 352,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'sample1 — копия (6).ts',
    fileSize: 739548948,
    offset: 0,
    status: 'Terminated',
    username: 'root',
    createdAt: '2024-12-11T10:26:55.091369+00:00',
    finishedAt: '0001-01-01T00:00:00+00:00',
  },
  {
    id: 353,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'sample1 — копия (5).ts',
    fileSize: 739548948,
    offset: 0,
    status: 'Terminated',
    username: 'root',
    createdAt: '2024-12-11T10:26:55.089198+00:00',
    finishedAt: '0001-01-01T00:00:00+00:00',
  },
  {
    id: 351,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'sample1 — копия (4).ts',
    fileSize: 739548948,
    offset: 0,
    status: 'Terminated',
    username: 'root',
    createdAt: '2024-12-11T10:26:55.083399+00:00',
    finishedAt: '0001-01-01T00:00:00+00:00',
  },
  {
    id: 350,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'sample1 — копия (3).ts',
    fileSize: 739548948,
    offset: 0,
    status: 'Error',
    username: 'root',
    createdAt: '2024-12-11T10:26:55.071659+00:00',
    finishedAt: '2024-12-11T10:26:55.083595+00:00',
  },
  {
    id: 349,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'sample1 — копия (2).ts',
    fileSize: 739548948,
    offset: 0,
    status: 'Error',
    username: 'root',
    createdAt: '2024-12-11T10:26:55.066886+00:00',
    finishedAt: '2024-12-11T10:26:55.080414+00:00',
  },
  {
    id: 348,
    flightId: '',
    fileType: '',
    zalaFileType: '',
    fileName: 'Полет от 15.10.2020 08_06_34.kml',
    fileSize: 78221,
    offset: 0,
    status: 'Error',
    username: 'root',
    createdAt: '2024-12-11T10:25:50.633501+00:00',
    finishedAt: '2024-12-11T10:25:50.645762+00:00',
  },
  {
    id: 347,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'test17.ts',
    fileSize: 40705196,
    offset: 0,
    status: 'Error',
    username: 'root',
    createdAt: '2024-12-11T10:25:37.232211+00:00',
    finishedAt: '2024-12-11T10:25:37.238087+00:00',
  },
  {
    id: 346,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'test17 — копия.ts',
    fileSize: 40705196,
    offset: 0,
    status: 'Error',
    username: 'root',
    createdAt: '2024-12-11T10:25:37.226794+00:00',
    finishedAt: '2024-12-11T10:25:37.233699+00:00',
  },
  {
    id: 345,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'test17 — копия (6).ts',
    fileSize: 40705196,
    offset: 0,
    status: 'Error',
    username: 'root',
    createdAt: '2024-12-11T10:25:37.218625+00:00',
    finishedAt: '2024-12-11T10:25:37.2254+00:00',
  },
  {
    id: 344,
    flightId: '',
    fileType: 'video/vnd.dlna.mpeg-tts',
    zalaFileType: '',
    fileName: 'test17 — копия (7).ts',
    fileSize: 40705196,
    offset: 0,
    status: 'Error',
    username: 'root',
    createdAt: '2024-12-11T10:25:37.215227+00:00',
    finishedAt: '2024-12-11T10:25:37.224448+00:00',
  },
];
