import { AsyncData } from '@/shared/model';
import { State } from '@/shared/utils';
import { FlightResponse } from '@/entities/ftp';

/**
 * Тип формы, статус
 */
export enum FormType {
  Disabled = 'disabled',
  DataUpload = 'dataUpload',
  DataWithFileUpload = 'dataWithFileUpload',
}

export enum StatusSubmit {
  Success = 'success',
  Error = 'error',
}

export interface FormValues {
  readonly userLogin: string;
  readonly viewShot: string;
  readonly numberRoute: string;
  readonly numberFlight: string;
  readonly numberTeam: string;
  readonly cameraModel: string;
  readonly focus: string;
  readonly file?: any;
}

export interface StoreState {
  readonly status: StatusSubmit | undefined;
}

export interface Store {
  readonly onSubmitForm: (values: FormValues) => void;
  readonly formType: FormType;
  readonly disabledField?: ReadonlyArray<keyof FormValues>;
  readonly acceptFiles?: readonly string[];
  readonly getFieldErrorLocale: (key: keyof FormValues) => undefined | string;
  readonly formErrors: boolean;
  readonly state?: State<StoreState>;
  readonly fieldHasError: (key: keyof FormValues) => undefined | boolean;
}

export interface FtpStore extends Store {
  readonly disabled?: boolean;
  readonly requestData?: () => void;
  readonly initialValues?: AsyncData<FlightResponse>;
}
