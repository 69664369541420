import './styles.scss';

import { FC, ReactNode } from 'react';

import { Header } from '@workspace/4Z1.uikit.react';
import { UserMenu } from '@/widgets/UserMenu';
import TotalPercentUpload from '@/widgets/TotalPercentUpload/TotalPercentUpload';
import { PageLayoutStore } from './model/pageLayout.store';
import { dep, diInject } from '@/HOC';

interface Props {
  readonly children: ReactNode;
  readonly sidebarContent?: ReactNode;
  readonly headerLeft?: ReactNode;
  readonly headerRight?: ReactNode;
  readonly headerFixed?: boolean;
  readonly store: PageLayoutStore;
}

/**
 * Лэйаут для страниц, поддерживающий возможность скрытия хедера и сайдбара в зависимости от того, авторизовался пользователь или нет
 */
const PublicPageLayout: FC<Props> = ({
  children,
  headerLeft,
  headerRight,
  headerFixed,
  sidebarContent,
  store,
}) => {
  return (
    <div className="pageLayout">
      {sidebarContent && (
        <div className={'sidebarLeft'}> {sidebarContent} </div>
      )}
      <div className="container">
        {store.shouldRenderHeader && (
          <Header
            className="header"
            fixed={headerFixed}
            childrenLeft={headerLeft}
            childrenRight={
              <>
                {headerRight}
                <TotalPercentUpload />
                <UserMenu />
              </>
            }
          />
        )}
        {children}
      </div>
    </div>
  );
};

export default diInject(PublicPageLayout, {
  store: dep(PageLayoutStore.diKey),
});
