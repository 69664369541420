export enum MassloadFileStates {
  // пока не знаю как точно будет использоваться, потому такая версия маппинга
  Unknown,
  Uploading, //  CreateUpload,
  Uploaded, // Stored, Processing,
  Done, //Finished, Terminated, Archived, Removed, Rejected,
  Error,
}

export interface MassLoadFile {
  readonly id: string;
  readonly filename: string;
  readonly loadId: string;
  readonly state: MassloadFileStates;
}
