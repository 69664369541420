import { DiKeys } from '@/shared/di/global';
import { Container, interfaces } from 'inversify';
import { OrtophotoStoreImpl } from './model/ortophoto.store';

const container: interfaces.Container = new Container();

container
  .bind(DiKeys.emptyListStore)
  .to(OrtophotoStoreImpl)
  .inSingletonScope();

const OrtophotoModule = container;
export default OrtophotoModule;
