import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'umi';
import { useCookies } from 'react-cookie';
import LayerSwitcher from 'ol-layerswitcher';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import TileLayer from 'ol/layer/Tile';
import { Vector as VectorLayer, Group as LayerGroup, } from 'ol/layer';
import { Vector as VectorSource, TileWMS, XYZ } from 'ol/source';
import KML from 'ol/format/KML';

import { useTilesApiQuery } from '@/.graphql/graphql';
import { Button, Checkbox, Modal } from 'antd';

const maxTileSize: number = 10;

/**
 * @deprecated - новый слой расположен в @features/map/layers/tilesLayer/ui/TilesLayer.tsx
 */
export const Tiles = (props: any) => {
  const [query] = useTilesApiQuery();
  const data = query.data ? query.data.tile_groups : null;
  const { map, p_map } = props;
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalOptions, setModalOptions] = useState<{label: string, value: string}[]>([]);
  const [buttonTitle, setButtonTitle] = useState(<FormattedMessage id="tile.Modal.OK" />);
  const [values, setValues] = useState<string[]>([]);
  const [cookies, setCookie] = useCookies(['layers', 'reprojection']);

  const _title = intl.formatMessage({
    id: 'module.tiles',
    defaultMessage: 'Tiles',
  });

  function setKml(item: any) {
    const name = 't-'+item.id;
    const source = new VectorSource({
      url: item.url,
      format: new KML({ showPointNames: false }),
    });

    return new VectorLayer({
      name: name,
      title: item.name,
      active: item.visible,
      visible:
        props.initTiles[name] !== undefined
          ? props.initTiles[name]
          : item.visible,
      source: source,
      minZoom: item.minzoom,
    } as BaseLayerOptions);
  }

  function setLink(item: any) {
    const name = 't-'+item.id;
    return new TileLayer({
      name: name,
      title: item.name,
      active: item.visible,
      minZoom: item.minzoom,
      visible:
        props.initTiles[name] !== undefined
          ? props.initTiles[name]
          : item.visible,
      source: new TileWMS({
        url: item.url,
        params: {
          LAYERS:
            '33,32,31,30,29,28,27,25,24,23,22,21,20,19,18,16,15,14,12,11,10,9,8,6,5,4,3,2,1',
          TILED: true,
        },
      }),
    } as BaseLayerOptions);
  }

  function setOfflineLink(item: any) {
    const name = 't-'+item.id;
    return new TileLayer({
      name: name,
      title: item.name,
      active: item.visible,
      minZoom: item.minzoom,
      visible:
        props.initTiles[name] !== undefined
          ? props.initTiles[name]
          : item.visible,
      source: new XYZ({
        url: item.url,
      }),
    } as BaseLayerOptions);
  }

  function setTiles(tiles: any) {
    const layer_tiles: any = [];
    const maxSizeArr: any = [];
    tiles.map((item: any) => {
      const name = 't-'+item.id;

      if (item.size > 1024 * 1024 * maxTileSize
        && (props.initTiles[name] !== undefined ? props.initTiles[name] : item.visible)
      ) {
        maxSizeArr.push({ label: item.name, value: name });
        item.visible = 0;
        props.initTiles[name] = 0;
        setCookie('layers', props.initTiles);
      }

      let tile: any = new VectorLayer();
      if (item.type == 1) tile = setLink(item);
      if (item.type == 2) tile = setKml(item);
      if (item.type == 3) tile = setOfflineLink(item);
      layer_tiles.push(tile);
    });

    if (maxSizeArr.length) {
      setModalOptions((old) => [...old, ...maxSizeArr]);
      setModalOpen(true);
    }

    return layer_tiles;
  }

  function setGroup(group: any) {
    let layers: any,
      tiles: any,
      childs: any = [];

    let count = group.childs.length + group.tiles.length;
    tiles = setTiles(group.tiles);
    group.childs.map((group: any) => {
      childs.push(setGroup(group));
    });
    layers = childs.concat(tiles);

    return count > 0
      ? new LayerGroup({
          title: group.name,
          fold: 'close',
          className: group.name,
          layers: layers,
          zIndex: 4,
        } as GroupLayerOptions)
      : new LayerGroup();
  }

  function buildTree(tree: any) {
    let arr_layers: any = [];
    tree.map((brach: any) => {
      let count = brach.childs.length + brach.tiles.length;
      if (count > 0) {
        const group = setGroup(brach);
        arr_layers.push(group);
      }
    });
    return arr_layers;
  }

  function createDataTree(dataset: any) {
    const hashTable = Object.create(null);
    dataset.forEach(
      (aData: any) => (hashTable[aData.id] = { ...aData, childs: [] }),
    );
    const dataTree: any = [];
    dataset.forEach((aData: any) => {
      if (aData.parent_id != 0)
        hashTable[aData.parent_id].childs.push(hashTable[aData.id]);
      else dataTree.push(hashTable[aData.id]);
    });

    return dataTree;
  }

  useEffect(() => {
    if (data)  {
      removeLayer('tiles');
      const tree = createDataTree(data);
      const layerTiles = buildTree(tree);

      if (layerTiles.length > 0) {
        const group_tiles = new LayerGroup({
          title: _title,
          fold: 'open',
          name: 'tiles',
          className: 'tiles',
          layers: layerTiles,
        } as GroupLayerOptions);
        map.addLayer(group_tiles);

        if(p_map){
          const p_tree = createDataTree(data);
          const p_tiles = buildTree(p_tree);
          const group_p_tiles = new LayerGroup({
            title: _title,
            fold: 'close',
            name: 'p_tiles',
            className: 'p_tiles',
            layers: p_tiles,
          } as GroupLayerOptions);
          p_map.addLayer(group_p_tiles);

          LayerSwitcher.forEachRecursive(group_tiles, (layer) => {
            layer.on('change:visible', (e: any) => {
              LayerSwitcher.forEachRecursive(group_p_tiles, (p_layer) => {
                if(p_layer.get('name') == layer.get('name')) p_layer.setVisible(layer.getVisible());
              });
            })
          });
        }
      }
    }
  }, [data]);

  function removeLayer(layer_name: string) {
    map.getLayers().getArray().map((layer: any) => {
      if (layer.className_ === layer_name) map.removeLayer(layer);
    });
  };

  const titleModal = <>{intl.formatMessage({id: 'tile.Modal.Title'})}<p style={{fontSize: '14px', margin: 0, fontWeight: '400'}}>*{intl.formatMessage({id: 'tile.Modal.SubTitle'})}</p></>;

  const modalCancel = () => {
    saveChoice();
    offModal();
  };

  const modalOK = async () => {
    saveChoice();
    offModal();
  };

  const saveChoice = () => {
    const arr = modalOptions.map((option: any) => option.value);
    let diff = arr.filter(x => !values.includes(x));

    LayerSwitcher.forEachRecursive(map.getLayerGroup(), (lyr) => {
      if (values.includes(lyr.get('name'))) {
        lyr.setVisible(true);
        props.onSetVisible(lyr);
      }
      if (diff.includes(lyr.get('name'))) {
        lyr.setVisible(false);
        props.onSetVisible(lyr);
      }
    });
  }

  const onChange = (values: any[]) => {
    setValues(values);
    if (values.length > 0) {
      setButtonTitle(<FormattedMessage id="tile.Modal.ON" />);
    }else{
      setButtonTitle(<FormattedMessage id="tile.Modal.OK" />);
    }
  };

  const offModal= () => {
    setModalOpen(false);
    setModalOptions([]);
    setButtonTitle(<></>);
  }

  useEffect(() => {
    return () => {
      removeLayer('tiles');
      offModal();
    };
  }, []);

  return (
    <Modal
      centered
      title={titleModal}
      width={600}
      open={modalOpen}
      onOk={modalOK}
      onCancel={modalCancel}
      footer={[
        <Button key="submit" type="primary" onClick={modalOK}>
          {buttonTitle}
        </Button>,
      ]}
    >
      <Checkbox.Group options={modalOptions} onChange={onChange} />

    </Modal>
  );
};
