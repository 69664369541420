import { Angles }  from '@workspace/4Z1.ts.utils';

export const convertPtzAngleMeasureToDegrees = (ptzAngle: number): number => {
  return Angles.normalizeDegrees(ptzAngle * 180);
};

/**
 * @internal экспортируется только для тестов
 */
export const convertDegreesToPtzAngleMeasure = (angle: number): number => {
  const normal = Angles.normalizeDegrees(angle);
  return normal / 180;
};

/**
 * Функция для расчета направления камеры на север
 *
 * @param {number} currentNorthDirDegrees - Текущее направление камеры на север
 * @param {number} currentCourseDegrees - Текущее направление камеры
 * @param {number} newCourseDegrees - Новое направление камеры в градусах
 *
 * @description
 * Расчет делаем из того, что угол между севером и текущим направлением у нас не меняется.
 * C0 - N0 = C1 - N1, где С - азимут курса, N - азимут севера
 *
 * Тогда получается что
 * N1 = C1 - C0 + N0
 */
export const calculateNorthDir = (
  currentNorthDirDegrees: number,
  currentCourseDegrees: number,
  newCourseDegrees: number,
) => {
  const newNorth = Angles.normalizeDegrees(
    currentNorthDirDegrees + newCourseDegrees - currentCourseDegrees,
  );

  return convertDegreesToPtzAngleMeasure(newNorth);
};
