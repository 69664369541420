import { ZalaType } from '@/entities/zalaType';

export enum UploadHistoryFileStatuses {
  Loaded = 'Loaded',
  Processing = 'Processing',
  Loading = 'Loading',
  Error = 'Error',
}

export enum UploadStatus {
  Unknown = 'Unknown',
  CreateUpload = 'CreateUpload',
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  Stored = 'Stored',
  Processing = 'Processing',
  Finished = 'Finished',
  Error = 'Error',
  Rejected = 'Rejected',
  Terminated = 'Terminated',
  Removed = 'Removed',
  Archived = 'Archived',
}

export interface UploadHistoryFile {
  readonly id: string;
  readonly fileName: string;
  readonly fileSize: number;
  readonly progress?: number;
  readonly uploadedSize?: number;
  readonly zalaType: ZalaType;
  readonly status: UploadHistoryFileStatuses;
  readonly createdAt: string;
}
