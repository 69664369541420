import { useEffect, useState, type FC } from 'react';
import { MapPluginProps } from '@/shared/map/model/interfaces';
import { implicitMapCheck } from '@/shared/map';
import { group } from '@/components/Map/Layers';
import { useCookies } from 'react-cookie';
import { defaultLayers } from '@/components/Map/mapSettings';

export const OfpLayer: FC<MapPluginProps> = ({ map = implicitMapCheck() }) => {
  const [cookies, setCookie] = useCookies(['layers', 'reprojection']);
  if(cookies['layers'] === undefined || Object.keys(cookies['layers']).length === 0)
    cookies.layers = defaultLayers;

  return <group.OFPs map={map._map} initTiles={cookies.layers}/>
};
