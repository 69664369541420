import { Button, ClipIcon, GalleryIcon } from '@workspace/4Z1.uikit.react';
import './styles.scss';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { ChangeEvent, DragEvent, FC, InputHTMLAttributes, ReactElement, useRef, useState } from 'react';
import React from 'react';

interface Props {
  readonly text?: string;
  readonly disabled?: boolean;
  readonly type: 'text' | 'button' | 'image' | 'update';
  readonly error?: string;
  readonly size?: 'small' | 'big' | undefined;
  readonly buttonType?: 'badge' | 'accent';
  readonly transformText?: boolean;
  readonly buttonText?: string;
  readonly disabledBgType?: 'transparent' | 'highlight' | 'disableBg' | 'opacity';
  readonly buttonIcon?: ReactElement;
  readonly icon?: ReactElement;
  readonly accept?: string[];
  readonly onChange: (files: File[]) => void;
  readonly additionalButtons?: ReactElement;
  readonly directory?: boolean;
  readonly title?: string;
  readonly className?: string;
  readonly visibleAcceptText?: boolean;
}

//Небольшое расширение возможностей инпута
//Нужно чтобы не падала сборка
//Используется для загрузки директорий
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  readonly webkitdirectory?: string;
}

const Dropzone: FC<Props> = React.memo(({
  disabled = false,
  size,
  error,
  text,
  type,
  icon,
  accept,
  onChange,
  additionalButtons,
  directory = true,
  title,
  className,
  buttonText,
  transformText = true,
  disabledBgType = 'highlight',
  buttonIcon = <ClipIcon.Small/> ,
  buttonType = 'badge',
  visibleAcceptText = true,
}) => {
  const intl = useIntl();

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const addFiles = (newFiles: File[]) => {
    onChange(newFiles);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    const newFiles = getFilesFromEvent(event);
    addFiles(newFiles);

    event.dataTransfer.clearData();
  };

  const getFilesFromEvent = (event: DragEvent<HTMLDivElement>) => {
    const dataTransfer = event.dataTransfer;
    const fileList: File[] = [];

    if (dataTransfer.items) {
      for (const item of dataTransfer.items) {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file) fileList.push(file);
        }
      }
    } else {
      fileList.push(...dataTransfer.files);
    }

    return fileList;
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputFiles = event.target.files ? [...event.target.files] : [];
    addFiles(inputFiles);
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const renderDropzoneContent = () => {
    if (isDragging && type !== 'update') {
      return <h4 className="dropzoneTitle">{intl.formatMessage({id: 'massload.dndFiles'})}</h4>;
    }

    const acceptText = accept && visibleAcceptText ? accept.join(', ') : '';

    return (
      <>
        {icon}
        {type === 'image' && <GalleryIcon.Large />}
        <h4 className="dropzoneTitle">
          {title ? (
            title
          ) : type === 'button' ? (
            <>{intl.formatMessage({ id: 'massload.dndFilesOnButton' })}</>
          ) : (
            <>
              {intl.formatMessage({ id: 'massload.dndFilesOr' })}
              <span className='dropzoneTitleSpan'>
                {intl.formatMessage({ id: 'massload.upload' })}
              </span>
            </>
          )}
        </h4>
        {text && <p className='dropzoneText'>{text}</p>}
        {acceptText &&
          <p className='dropzoneText'>
          {`${intl.formatMessage({id: 'massload.supportedFormats'})} ${acceptText}`}
          </p>
        }
        {type === 'button' && (
          <div className='dropzoneButtonContainer'>
            {additionalButtons}
            <Button
              disabled={ disabled }
              type={ buttonType }
              size='small'
              icon={ buttonIcon }
              iconPosition='left'
              label={ buttonText ?? intl.formatMessage({ id: 'massload.selectFile' }) }
            />
          </div>
        )}
      </>
    );
  };

  const InputFile: FC<InputProps> = (props) => {
    return (
      <input
        type="file"
        webkitdirectory={directory ? 'true' : undefined}
        onChange={handleInputChange}
        multiple
        ref={fileInputRef}
        accept={accept ? accept.join(',') : undefined}
        hidden
        {...props}
      />
    );
  };

  return (
    <div
      className={classNames('dropzoneContainer', className, disabledBgType, { disabled, transformText, dragging: isDragging, [`size-${size}`]: size })}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onClick={handleClick}
      role="button"
      aria-disabled={disabled}
      aria-describedby={error ? 'error-tooltip' : undefined}
    >
        <InputFile/>
        {renderDropzoneContent()}
    </div>
  );
});

export default Dropzone;