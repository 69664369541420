export default {
  'ftp.hint.unknown': 'Данные о полёте ещё не загружались на FTP-сервер',
  'ftp.hint.in_progress': 'Идёт процесс загрузки данных на FTP-сервер',
  'ftp.hint.done': 'Данные успешно загружены на FTP-сервер',
  'ftp.hint.failed': 'В процессе загрузки данных произошла ошибка',

  'ftp.userLogin': 'Логин пользователя GCS',
  'ftp.companyCode': 'Код дочернего общества',
  'ftp.viewShot': 'Вид съёмки',
  'ftp.flightDate': 'Дата полёта',
  'ftp.numberRoute': 'Номер маршрута',
  'ftp.numberFlight': 'Порядковый номер полёта',
  'ftp.numberTeam': 'Номер бригады',
  'ftp.cameraModel': 'Модель фотокамеры',
  'ftp.focus': 'Фокусное расстояние (мм)',
  'ftp.send_to_ftp': 'Отправить на FTP',
  'ftp.send_to_ftp.title': 'Загрузка на FTP',
  'ftp.submit.error':
    'Произошла ошибка. Проверьте введенные данные и попробуйте еще раз',
  'ftp.submit.success': 'Успех! Загрузка на FTP начнется в ближайшее время',

  'ftp.userLogin.helper': 'Введите логин для работы с GCS',
  'ftp.companyCode.helper': 'Введите код дочернего общества',
  'ftp.viewShot.helper':
    'Выберите устройство, с которого Вы снимали свой полёт',
  'ftp.flightDate.helper': 'Укажите дату своего полёта',
  'ftp.numberRoute.helper': 'Введите номер маршрута полёта',
  'ftp.numberFlight.helper': 'Введите порядковый номер полёта',
  'ftp.numberTeam.helper': 'Введите номер бригады',
  'ftp.cameraModel.helper': 'Введите модель камеры',
  'ftp.focus.helper': 'Введите фокусное расстояние',

  'ftp.error.userlogin.not-found': 'Пользователь не найден',
  'ftp.error.flightId.incorrect-id': 'Указан неверный идентификатор полета',
  'ftp.error.inputDir.no-telemetry-found': 'Каталог с файлом телеметрии не найден',
  'ftp.error.viewShot.incorrect-type': 'Указан неверный тип съемки',
  'ftp.error.numberRoute.incorrect-route': 'Указан неверный номер маршрута',
  'ftp.error.numberFlight.incorrect-flight-number': 'Указан неверный порядковый номер полета',
  'ftp.error.numberTeam.incorrect-team-number': 'Неверный номер бригады',
  'ftp.error.cameraModel.incorrect-camera-model': 'Неверная модель камеры',
  'ftp.error.focus.incorrect-focal-length': 'Неверное фокусное расстояние камеры',

  'ftp.validation.required_value': 'Пожалуйста, введите значение',
  'ftp.validation.required_file': 'Пожалуйста, выберите файл',
  'ftp.validation.max_length':
    'Превышено допустимое максммальное количество символов - ',
  'ftp.validation.permitted_chars':
    'Допустимы только символы латинского алфавита, цифры, дефис и точка',
  'ftp.validation.only_numbers_permitted':
    'Допустимы только цифры, дефис и точка',

  'ftp.error.no_flight': 'Что-то пошло не так. Полет не найден.',

  'ftp.form.telemetryTitle': 'Загрузка телеметрии',
  'ftp.form.uploadFile': 'Выбрать файл',
  'ftp.form.send': 'Отправить файл телеметрии',
  'ftp.mm': 'мм',

  'ftm.sendToCustomer': 'Отправить заказчику',
  'ftm.sendForProcessing': 'Отправить на обработку',
};
