import { SearchResultItem } from '@/features/geosearch';
import { ClockIcon, DataSourceItem } from '@workspace/4Z1.uikit.react';
import { ReactElement } from 'react';
import { MapMarkersTypes } from '@/entities/map';
import { LAYER_NAME } from '../ui/SearchPlugin';
import { MapMarker } from '@/shared/map';

const setImage = (type: string): ReactElement | undefined => {
  if (type === 'History') {
    return <ClockIcon.Small />
  }
  return undefined;
}

export const resultsMapping = (results: readonly SearchResultItem[]): DataSourceItem[] => {
  const options: DataSourceItem[] = [];

  results.forEach(item => {
    const optionsItem = {
      id: item.id,
      title: item.title.text,
      description: item.subTitle.text,
      location: {
        lon: item.location.longitude,
        lat: item.location.latitude,
      },
      image: setImage(item.type)
    }
    options.push(optionsItem);
  })

  return options;
}

export const searchMarkerMapping = (item: DataSourceItem): MapMarker => {
  return {
    id: String(item.id),
    location: item.location,
    type: MapMarkersTypes.Default,
    layerId: LAYER_NAME,
  }
}
