import request from 'umi-request';

import { UmiApiClient } from '@/shared/api/rest';
import { SearchResultItem } from "@/features/geosearch/model";
import urlJoin from 'url-join';

const BASE_PATH = API_ROOT;
const API_PATH = urlJoin(BASE_PATH, 'geocoder');

const API = new UmiApiClient(request);


const path = (method: string): string => {
  return urlJoin(API_PATH, method);
}

const METHODS = {
  search: path('search'),
  addHistory: path('add-history'),
} as const;


interface GeosearchRequest {
  readonly query: string;
  readonly size: number;
}

export const search = (data: GeosearchRequest): Promise<readonly SearchResultItem[]> => {
  return API.post(METHODS.search, data).then(data => data.results);
}

export const addHistory = (searchedDocumentId: number, usedQuery: string): Promise<void> => {
  return API.post(METHODS.addHistory, {searchedDocumentId, usedQuery}).then(data => data.results);
}
