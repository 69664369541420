import { makeAutoObservable } from 'mobx';
import {
  SendFtpRequestParams,
  FlightStatusResponse,
  CreatedFileResponse,
  FlightResponse,
} from './interfaces';
import { createFtpApi } from '../api/ftp.api';
import { injectable } from 'inversify';
import { initialValuesParser } from './utils';
import { createFlightApi } from '@/entities/flight/api/flight.api';

@injectable()
export class FtpService {
  public static readonly diKey = Symbol.for('FtpService');

  constructor(
    private readonly api = createFtpApi(),
    private readonly flightApi = createFlightApi(),
  ) {
    makeAutoObservable(this);
  }

  public async sendFtp(data: SendFtpRequestParams): Promise<FlightStatusResponse> {
    return this.api.sendFtp(data);
  }
  
  public async flight(flightId: string): Promise<FlightResponse> {
    return Promise.all([
      this.api.flightFtp(flightId),
      this.flightApi.flightName(flightId),
    ]).then((data) => initialValuesParser(data));
  }
  
  public async createTml(data: SendFtpRequestParams): Promise<CreatedFileResponse> {
    return this.api.createTml(data);
  }
}
