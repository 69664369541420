import { makeAutoObservable } from 'mobx';
import { inject, injectable } from 'inversify';
import { logger } from '@workspace/4Z1.ts.utils';
import { DiKeys } from '@/shared/di/global';
import { UsbFileUpload, type UsbUploadApi } from '../api/usb.upload.api';
import { v4 as generateId } from 'uuid';
import { FileUpload, UploadingFileStates, UploadSession } from './interface';
import { parseErrorKey } from '../utils/parseErrorKey';
import { getLocalizationId } from '../utils/getLocalizationId';
import { ApiError, NetworkError, UnclassifiedError } from '@/shared/errors';
import { isArray } from 'lodash';
import { MassloadFileStates } from '../api/interfaces';

const log = logger('USB:UD:SRV');

const REFRESH_INTERVAL = 1 /* secs */ * 1000;

function isFileUploaded(upload: UsbFileUpload): boolean {
  return upload.size === upload.bytesUploaded;
}

function uploadStatus(upload: UsbFileUpload): UploadingFileStates {
  if (isFileUploaded(upload)) {
    return UploadingFileStates.Completed;
  }

  return UploadingFileStates.Running;
}

@injectable()
export class UsbUploadSession implements UploadSession {
  public static readonly diKey = Symbol.for('UsbUploadSession');

  public readonly type = 'usb';

  public readonly id: string = generateId();

  private readonly api: UsbUploadApi;

  private timeout: number = 0;
  private isWatching = false;
  private starting = false;

  private uploads: readonly UsbFileUpload[] = [];

  private filesError: readonly FileUpload[] = [];
  private errorMessage: string;

  constructor(
    @inject(DiKeys.api.massload) api: UsbUploadApi,
    private readonly refreshInterval = REFRESH_INTERVAL,
  ) {
    this.api = api;
    makeAutoObservable(this);
    this.start();
  }

  public get bytesToUpload(): number {
    return this.uploads
      .reduce((val, file) => val + file.size, 0);
  }
  public get bytesUploaded(): number {
    return this.uploads
      .reduce((val, file) => val + file.bytesUploaded, 0);
  }

  public get isFinished(): boolean {
    return this.uploads.every(file => isFileUploaded(file));
  }

  public get isStarting(): boolean {
    return this.starting;
  }

  public start(): void {
    this.starting = true;
    log.info('init new upload from usb', this.id);

    this.api.start(this.id)
      .then(() => {
        log.info('upload started', this.id);
        this.startWatching();
        this.starting = false;
      })
      .catch(e => {
        log.error('upload failed to start', this.id, e);
        this.starting = false;
        this.handleError(e);
      });
  }

  private parseFilesFromError(details: ApiError[]): readonly FileUpload[] {
    return details.map(errFile => {
      const errorCode = parseErrorKey(`${errFile.code}`);        
      const localizationId = getLocalizationId(errorCode);
      
      return {
        fileName: errFile.pointer,
        state: 0,
        error: localizationId,
        bytesUploaded: 0,
        size: 0,
        progress: 0,
      };
    });
  }

  public get files(): readonly FileUpload[] {
    log.debug('uploads');

    const normalFiles = this.uploads.map(upload => {
      const errorCode = parseErrorKey(upload.message);
      const localizationId = getLocalizationId(errorCode);
      
      return {
        fileName: upload.name,
        state: uploadStatus(upload),
        error: upload.state === MassloadFileStates.Error ? localizationId : undefined,
        bytesUploaded: upload.bytesUploaded,
        size: upload.size,
        progress: upload.bytesUploaded / upload.size * 100,
      };
    });

    const allFiles = [...normalFiles, ...this.filesError];

    if (this.filesError.length === 0) {
      return normalFiles.sort((a, b) => a.fileName.localeCompare(b.fileName));
    }

    return allFiles.sort((a, b) => a.fileName.localeCompare(b.fileName));
  }

  /**
   * Общий процент по всем загрузкам
   */
  public get percentage() {
    const { uploaded, total } = this.uploads.reduce((acc, file) => {
      acc.total += file.size;
      acc.uploaded += file.bytesUploaded;
      return acc;
    }, { uploaded: 0, total: 0 });

    return total === 0 ? 0 : uploaded / total * 100;
  }

  public get requestErrorMessage() {
    return this.errorMessage;
  }

  private startWatching() {
    log.debug('start watching', this.id);
    this.isWatching = true;
    this.scheduleFetch();
  }

  private scheduleFetch() {
    this.timeout = setTimeout(() => this.fetch(), this.refreshInterval);
  }

  private stopWatching() {
    this.isWatching = false;
    clearTimeout(this.timeout);
    this.timeout = 0;
  }

  private fetch() {
    log.debug('fetching status for session', this.id);
    this.api.updateStatus(this.id)
      .then(files => {
        log.debug('status updated successfully', this.id, files);
        this.uploads = files;
        this.errorMessage = '';
        this.checkFinished();
      })
      .catch(e => {
        log.warn('failed to update status', this.id, e);
        this.handleError(e);
      });
  }

  private handleError(error: ApiError | NetworkError | UnclassifiedError) {

    if (error instanceof ApiError) {

      if (error.code === 400) {
        const conflictDetails = error.details.filter(f => f.code === 'Conflict');
  
        if (conflictDetails.length > 0) {
          this.filesError = this.parseFilesFromError(conflictDetails);
          return;
        }
      }
  
      this.errorMessage = 'massload.error.unknownError';
      return;
    }

    if(error instanceof UnclassifiedError) {
       if (error.code === 500) {
        const detailMessage = error.message;
        this.handleErrorMessage({ ...error, message: detailMessage });
        return;
      }
    }
  
    if (error instanceof NetworkError) {
      log.error('Network error', error);
      this.handleErrorMessage(error);
      this.scheduleFetch();
      return;
    }
  
    this.handleErrorMessage(error);
  }

  private handleErrorMessage(e: Error | NetworkError) {
      const errorCode = e.message ? parseErrorKey(e.message) : undefined;
      const localizationId = errorCode ? getLocalizationId(errorCode) : undefined;
      this.errorMessage = localizationId || 'massload.error.unknownError';
  }

  private checkFinished() {
    log.debug('check is finished', this.id, this.files);
    const allUploadsDone = this.isFinished;

    if (allUploadsDone) {
      log.info('files uploaded, stop watching', this.id, this.files);
      this.stopWatching();
    } else {
      this.scheduleFetch();
    }
  }
}