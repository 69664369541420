import { getArea, getLength } from 'ol/sphere';
import type Geometry from 'ol/geom/Geometry';

const convertToKm = (length: number) => Math.round((length / 1000) * 100) / 100;
const convertToMeter = (length: number) => Math.round((length / 1000) * 100) / 100;

const convertToAreaKm = (area: number) => Math.round((area / 1000000) * 100) / 100;
const convertToAreaMeter = (area: number) => Math.round(area * 100) / 100;

export const formatLength = (line: Geometry) => {
  const length = getLength(line);

  let output;
  if (length > 100) {
    output = `${convertToKm(length)} km `;
  } else {
    output = `${convertToMeter(length)} m `;
  }
  return output;
};

export const formatArea = (polygon: Geometry) => {
  const area = getArea(polygon);

  let output;
  if (area > 10000) {
    output =  `${convertToAreaKm(area)} rm `;
  } else {
    output = `${convertToAreaMeter(area)} m `;
  }
  return output;
};