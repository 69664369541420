import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import React, { useState } from 'react';
import { FormattedMessage, useIntl, useModel } from 'umi';

const url: string = API + '/vltimages/pic';

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const UploadImage = (props: any) => {
  const { initialState } = useModel('@@initialState');
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const intl = useIntl();

  const beforeUpload = (file: RcFile) => {
    const fileExt = file.name.split('.').pop();
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || fileExt === 'jpg' || fileExt === 'png' || fileExt === 'JPG' || fileExt === 'PNG';
    if (!isJpgOrPng) {
      message.error(intl.formatMessage({id: 'violation.errorimage.type'}));
    }
    return isJpgOrPng;
  };

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done' && info.file.response) {
      if (info.file.response.filePath) {
        props.getDataUploadImage(info.file.response);
        getBase64(info.file.originFileObj as RcFile, url => {
          setImageUrl(url);
        });
      }
      if (info.file.response.error) message.error(info.file.response.error);
      setLoading(false);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}><FormattedMessage id="button.UploadImage" /></div>
    </div>
  );

  return (
    <Upload
      name="pic"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      action={url}
      accept={['.png','.PNG','.jpg','.JPG']}
      headers={{"Authorization": `Bearer ${initialState?.currentUser?.token}`}}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {imageUrl ? <img src={imageUrl} style={{ width: '100%' }} /> : uploadButton}
    </Upload>
  );
};

export default UploadImage;