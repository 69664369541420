import { useState, useEffect } from 'react';
import { useIntl } from 'umi';
import { Map } from 'ol';
import { useCookies } from 'react-cookie';
import { Coordinate } from 'ol/coordinate';
import { Button, FixZoomIcon } from '@workspace/4Z1.uikit.react';
import { Tooltip } from '@workspace/4Z1.uikit.react';

interface Component {
  map: Map;
  rightPosition?: boolean;
}

/**
 * @deprecated
 * 
 * Компонент подлежит рефакторингу. Вместо этого компонента используй holdScalePlugin.tsx
 * 
 * Необходимо:
 *  1. Получать карту из пропсов, как это делают сейчас плагины
 *  2. Исправить небезопасный JSON.parse
 *  3. Убрать лишние useEffect'ы
*/
const HoldPosition = (props: Component) => {
  const intl = useIntl();
  const { map, rightPosition } = props;
  const view = map.getView();
  const [cookies, setCookie] = useCookies(['hold_position']);
  const [pressed, setPressed] = useState<boolean>(cookies.hold_position ? true : false);

  const handleHold = () => {
    setPressed(!pressed);
    if(!cookies.hold_position){
      const center = view.getCenter();
      const zoom = view.getZoom();
      const pos: string = JSON.stringify(center) +';'+ JSON.stringify(zoom);

      setCookie('hold_position', pos, {path: '/'});
    }
  }

  useEffect(() => {
    if (pressed)
    {
      if (cookies.hold_position != '') {
        let arr = cookies.hold_position.split(";");
        let center: Coordinate = JSON.parse(arr[0]);
        let zoom: number = JSON.parse(arr[1]);
        view.setCenter(center);
        view.setZoom(zoom);
      }
    }else{
      setCookie('hold_position', '', {path: '/'});
    }
  }, [pressed]);

  useEffect(() => {
    
  }, []);

  return (
    <Tooltip text={intl.formatMessage({ id: 'holdposition' })} direction={rightPosition ? 'bottom-right' : 'bottom-center'}>
      <Button active={pressed} type={'neutral'} className="holdposition" title={intl.formatMessage({id: 'holdposition'})} onClick={handleHold} onlyIcon icon={<FixZoomIcon />}></Button>
    </Tooltip>
  );
};

export default HoldPosition;