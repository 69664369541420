interface FieldOfViewLayerApi {
  getData(): readonly string[];
  updateData(value: string): void;
}

const HIDDEN_FIELD_OF_VIEW_KEY = 'hiddenFieldOfView';

export class FieldOfViewStorageApi implements FieldOfViewLayerApi {


  constructor() {}

  getData(): string[] {
    const data = localStorage.getItem(HIDDEN_FIELD_OF_VIEW_KEY);
    return data ? JSON.parse(data) : [];
  }

  updateData(value: string): void {
    const data = this.getData();

    data.includes(value)
      ? data.removeItem(value)
      : data.push(value);

    localStorage.setItem(HIDDEN_FIELD_OF_VIEW_KEY, JSON.stringify(data));
  }
}
