import { type FC } from 'react';
import { Asset, Assets } from '@/entities/assets/model';
import { Button, EyeCloseIcon, EyeIcon, Tooltip } from '@workspace/4Z1.uikit.react';
import { useStore } from '@/shared/store/context';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';

interface Props {
  readonly asset: Asset;
  readonly className?: string;
}

export const FieldOfViewToggle: FC<Props> = observer(({asset, className}) => {

  if (!Assets.hasKnownFieldOfView(asset)) {
    return;
  }

  const intl = useIntl();
  const {fieldOfViewLayer} = useStore();

  return <Tooltip
    className={className}
    title={fieldOfViewLayer.canShow(asset)
      ? intl.formatMessage({
        id: 'online.fieldOfViewToggle.show',
        defaultMessage: 'Show'
      })
      : intl.formatMessage({
        id: 'online.fieldOfViewToggle.hide',
        defaultMessage: 'Hide'
      })
    }
    >
    <Button
      fullWidth={true}
      type='badge'
      size='small'
      icon={fieldOfViewLayer.canShow(asset) ? <EyeIcon.Small/> : <EyeCloseIcon.Small/>}
      onlyIcon={true}
      onClick={() => fieldOfViewLayer.toggleShow(asset)}
    />
  </Tooltip>
})
