import { getLocalStorageItems } from './localStorage';
import { LocalStorageKeys } from '../model';

/**
 * Функция для получения идентификатора текущего пользователя.
 * 
 * @returns {string | undefined} Идентификатор пользователя или undefined, если пользователь не найден.
 */
export const getUserId = (): string | undefined => {
  const user = getLocalStorageItems(LocalStorageKeys.CurrentUser);

  return user?.id;
};
