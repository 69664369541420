import { FC, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'umi';
import LayerGroup from 'ol/layer/Group';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import Feature from 'ol/Feature';
import { Style } from 'ol/style';
import { getAttachmentQueryParams, PhotoAttachment } from '@/entities/attachment';
import { Flight } from '@/.graphql/graphql';
import BaseLayer from 'ol/layer/Base';
import { implicitMapCheck, MapPluginProps } from '@/shared/map';
import { isNil, cond } from 'lodash';
import {
  getTrackLineStyle,
  getWaypointStyle,
  WaypointType,
} from '@/entities/assetTrack';
import { Geometry, Point } from 'ol/geom';
import { FeaturesType } from '@/entities/features';
import { fromLonLat } from 'ol/proj';

interface Props extends MapPluginProps {
  readonly photo?: PhotoAttachment;
  readonly flight: Flight;
  readonly data: readonly PhotoAttachment[];
  readonly searcharealink?: string;
  readonly highlightedPhoto?: PhotoAttachment;

  // TODO - Понять, что это за тип
  readonly searchArea?: object | string;
}

enum ExtentPaddingSizes {
  Top = 80,
  Right = 50,
  Bottom = 50,
  Left = 80,
}

const TRACK_EXTENT_PADDINGS: Readonly<ExtentPaddingSizes[]> = [
  ExtentPaddingSizes.Top,
  ExtentPaddingSizes.Right,
  ExtentPaddingSizes.Bottom,
  ExtentPaddingSizes.Left,
] as const;

export const FlightTrackLayer: FC<Props> = ({
  map = implicitMapCheck(),
  data,
  flight,
  photo,
  highlightedPhoto,
  ...props
}) => {
  const intl = useIntl();
  const [cookies] = useCookies(['layers']);
  const lineFeature = useRef<Feature<Geometry>>();
  const sourceRef = useRef<VectorSource>();
  const hoveredFeatures = useRef<Feature[]>([]);

  const _title = intl.formatMessage({
    id: 'module.imagetiles',
    defaultMessage: 'Photos',
  });

  const setWaypointFeature = (
    feature: Feature,
    photo: PhotoAttachment,
    type: WaypointType,
  ): void => {
    let waypoint: Style = getWaypointStyle(WaypointType.Idle);
    let text: string = photo.name;

    switch (type) {
      case WaypointType.Idle:
        waypoint = getWaypointStyle(WaypointType.Idle);
        break;
      case WaypointType.Highlight:
        waypoint = getWaypointStyle(WaypointType.Highlight);
        break;
      case WaypointType.Start:
        waypoint = getWaypointStyle(WaypointType.Start);
        text = intl.formatMessage({
          id: 'photo.start',
          defaultMessage: 'Start',
        });
        break;
      case WaypointType.End:
        waypoint = getWaypointStyle(WaypointType.End);
        text = intl.formatMessage({ id: 'photo.end', defaultMessage: 'End' });
        break;
    }

    feature.setStyle(waypoint);
    feature.set('initialStyles', waypoint);
    feature.set('name', text);
  };

  useEffect(() => {
    // TODO - Добавить чистку слоев, когда вложение не пришло
    if (data && flight?.geometry?.geom) {
      // TODO - Добавить очистку единичных элементов, а не перебирать все слои
      removeLayer('photos');

      const line = new GeoJSON()?.readFeature(flight.geometry.geom, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      }) as Feature;

      line.setStyle(getTrackLineStyle());

      const source = new VectorSource({
        features: [line],
      });

      if (props.searchArea && props.searchArea.type) {
        const searchArea = new GeoJSON().readFeature(props.searchArea, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        }) as Feature;

        source.addFeature(searchArea);
      }

      const lastIndex = data.length - 1;
      
      data.forEach(photo => {
        if (!photo?.center) return;
        const isFirst = data[0].id == photo.id && !props.searcharealink;
        const isLast = data[lastIndex].id == photo.id && !props.searcharealink;
        const isHighlighted = highlightedPhoto?.id === photo?.id;
        // const feature = new GeoJSON().readFeature(photo.geometry, {
        //   dataProjection: 'EPSG:4326',
        //   featureProjection: 'EPSG:3857',
        // }) as Feature;
        const feature: Feature = new Feature({
          geometry: new Point(fromLonLat([photo.center.lon, photo.center.lat]))
        });

        feature.setId(photo.id);
        feature.set('singleTab', true);
        feature.set('type', FeaturesType.LAYER_POINT);
        feature.set('className', 'layer-photo-' + photo.id);

        feature.set(
          'link',
          getAttachmentQueryParams(photo.id),
        );

        const waypointType = cond([
          [() => isHighlighted, () => WaypointType.Highlight],
          [() => isFirst, () => WaypointType.Start],
          [() => isLast, () => WaypointType.End],
          [() => !isLast && !isFirst, () => WaypointType.Idle],
        ]);

        setWaypointFeature(feature, photo, waypointType());
        source.addFeature(feature);
      });

      const layer = new VectorLayer({
        source: source,
        visible: cookies.layers.flights ? true : false,
        zIndex: 2,
      } as BaseLayerOptions);

      const group_photos = new LayerGroup({
        title: _title,
        //fold: 'close',
        name: 'photos',
        className: 'photos',
        layers: [layer],
      } as GroupLayerOptions);

      map.addLayer(group_photos);

      sourceRef.current = source;
      lineFeature.current = line;
    }
  }, [data, photo]);

  const cleanUpHoveredFeatures = () => {
    hoveredFeatures.current.forEach((feature) => {
      feature.setStyle(feature.get('initialStyles'));
    });
    hoveredFeatures.current = [];
  };

  useEffect(() => {
    cleanUpHoveredFeatures();

    if (isNil(highlightedPhoto)) {
      return;
    }
  
    const feature = sourceRef.current?.getFeatures()
      .find((feature) => feature.getId() === highlightedPhoto?.id);

    if (feature?.get('type') !== FeaturesType.LAYER_POINT) return;

    feature.setStyle(getWaypointStyle(WaypointType.Highlight));

    hoveredFeatures.current.push(feature);
  }, [highlightedPhoto?.id]);

  // TODO - Часто повторяется, убрать
  function removeLayer(layer_name: string) {
    map.getLayers().map((layer: BaseLayer) => {
      if (layer.getClassName() === layer_name)
        map.removeLayerByName(layer_name);
    });
  }

  useEffect(() => {
    const event = map.view.once('change', () => {
      if (isNil(lineFeature.current)) return;

      map.fitMapToFeature(lineFeature.current, [...TRACK_EXTENT_PADDINGS]);
    });

    return () => {
      map.view.un('change', event.listener);
      removeLayer('photos');
      removeLayer('drone');
    };
  }, []);

  return <></>;
};
