import { injectable } from 'inversify';
import { type AssetTrackApi, createAssetTrackApi } from '../api';
import { Asset } from '@/entities/assets/model';
import { TrackPoint } from './types';

@injectable()
export class AssetTrackService {
  public static readonly diKey = Symbol.for('AssetTrackService');

  constructor(
    private readonly api: AssetTrackApi = createAssetTrackApi(),
  ) {
  }

  public requestTrack(asset: Asset, lastHours: number): Promise<readonly TrackPoint[]> {
    return this.api.getTrackForLastHours(asset, lastHours)
      .then(data => {
        return data.filter(point => {
          return !_.isNil(point.location);
          //  TODO нужно понять насколько акутальны проверки в закомментировано коде ниже и какой вообще у них смысл
          /*

             if (assetStateForTimeRange !== undefined && assetStateForTimeRange.length > 0 && trackState?.publicAssetId) {
      const locs = assetStateForTimeRange
        .flatMap((t: any) => t.location != null ? t.location : [])
        .filter((l: any) => l.lat !== 0 && l.lon !== 0 && (l.lat >= -90 && l.lat <= 90) && (l.lon >= -180 && l.lon <= 180));

      const avgLat = locs.reduce((a: any, l: any) => a + l.lat, 0) / locs.length;
      const avgLon = locs.reduce((a: any, l: any) => a + l.lon, 0) / locs.length;

      const points = assetStateForTimeRange.flatMap((p: any) => {
        if (!p.location) {
          return [];
        }

        if (p.location.lon === 0 || p.location.lat === 0) {
          return [];
        }

        if (Math.abs(p.location.lat - avgLat) >= 5 || Math.abs(p.location.lon - avgLon) >= 5) {
          return [];
        }
           */
        }).sort((a, b) => a.timestamp - b.timestamp);
      });
  }
}
