import { format } from 'ol/coordinate';
import { sk42ToWgs84, wgs84ToSk42 } from './sk42grid';
import { sk42ToWM } from './sk42_3857';
import { fromLonLat, toLonLat } from 'ol/proj';

const formatCoordinate: string = '{y} {x}';
const formatCoordinateBack: string = '{x}, {y}';

export function transformCoordFormat(coordinates: number[], projection: string = 'EPSG:4326') 
{
  switch (projection) {
    case 'pulkovo1942':
      return format(wgs84ToSk42(coordinates), formatCoordinate);
    default:
      return format(coordinates, formatCoordinate, 5);
  }
}

export function transformCoord(coordinates: number[], projection: string = 'EPSG:4326') 
{
  switch (projection) {
    case 'pulkovo1942':
      return sk42ToWM(coordinates);
    case 'EPSG:4326':
      return fromLonLat(coordinates);
    default:
      return coordinates;
  }
}

export function transformCoord_4326(coordinates: number[], projection: string = 'EPSG:3857') 
{
  switch (projection) {
    case 'pulkovo1942':
      return sk42ToWgs84(coordinates);
    case 'EPSG:3857':
      return toLonLat(coordinates);
    default:
      return coordinates;
  }
}

export function transformCoordToArray(coordinates: string, type: string = 'xy'): number[]
{
  const arrayCoordinates: string[] = coordinates.trim().split(" ");
  const coord: number[] = [parseFloat(arrayCoordinates[0]), parseFloat(arrayCoordinates[1])];
  return (type == 'xy') ? [coord[1], coord[0]] : coord;
}
