import { IconByName } from '@/shared/lib/icons';
import classNames from 'classnames';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { SidebarMenuItemProps } from '../../model/types';
import './styles.scss';

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({ route, isActive }) => {
  const intl = useIntl();
  const renderIcon = () => {
    if (route.icon) {
      return IconByName(route.icon, 24, {
        className: 'navContainer__link-icon',
      });
    }

    return;
  };

  return (
    <Link
      to={route.path}
      className={classNames('navContainer__link', { active: isActive })}
    >
      {renderIcon()}
      {route.name && (
        <span className="navContainer__link-text">
          {intl.formatMessage({ id: `menu.${route.name}` })}
        </span>
      )}
    </Link>
  );
};

export default SidebarMenuItem;
