import { LogoIcon } from '@workspace/4Z1.uikit.react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { RouteItem, SidebarMenuProps } from '../../model/types';
import SidebarMenuItem from '../SidebarMenuItem/SidebarMenuItem';
import './styles.scss';

const SidebarMenu: FC<SidebarMenuProps> = ({ routes, footer }) => {
  return (
    <div className="sidebarMenu__container">
      <div className="sidebarMenu__upContainer">
        <Link to={'/'}>
          <LogoIcon.Large />
        </Link>

        <nav className="sidebarMenu__navContainer">
          {routes.map((route: RouteItem) => {
            return (
              <SidebarMenuItem key={route.path} route={route} isActive={route?.active} oldVersion />
            );
          })}
        </nav>
      </div>
      {footer !== undefined && (
        <div className="sidebarMenu__footer">{footer}</div>
      )}
    </div>
  );
};

export default SidebarMenu;
