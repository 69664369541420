import { MapNavigationStore, SearchMarkerStore } from '@/entities/map';
import { GeoSearchStore } from '@/features/geosearch/model';
import { PtzActionsStore } from '@/features/ptzActions';
import { FieldOfViewLayerStore } from '@/entities/map/stores/FieldOfViewLayer.store';
import { LocaleStore } from './language';
import { EtherStore } from './ether';

export class RootStore {
  readonly searchMarkerStore = new SearchMarkerStore();
  readonly geoSearchStore = new GeoSearchStore();
  readonly mapNavigation = new MapNavigationStore();
  readonly ptzActionsStore = new PtzActionsStore();
  readonly fieldOfViewLayer = new FieldOfViewLayerStore();
  readonly localeStore = new LocaleStore();
  readonly ether = new EtherStore();
}
