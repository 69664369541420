import { type FC, useEffect } from 'react';
import { implicitMapCheck, MapPluginProps, Layers } from '@/shared/map';
import { useCookies } from 'react-cookie';
import { getRoute } from '../model/getRoute';
import { useIntl } from 'react-intl';
import { useRoutesApiQuery } from '@/.graphql/graphql';
import { Route } from '../model/interfaces';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';


export const RoutesLayer: FC<MapPluginProps> = ({map = implicitMapCheck()}) => {

  const LAYER_NAME = Layers.Routes;

  // Получение данных с бэка
  const [query] = useRoutesApiQuery();
  const data: Route[] = query?.data?.routes;

  const intl = useIntl();
  // TODO избавиться от useCookies (umi tools), когда будет свой инструмент
  const [cookies] = useCookies(['layers', 'reprojection']);

  useEffect(() => {
    if (data === undefined) return;

    // очищаем слой, если появились новые данные
    map.removeLayerByName(LAYER_NAME);

    // подготовка базовых данных
    const routes: VectorLayer<VectorSource>[] = [];

    // Формирование слоев пришедших с бэка
    data.forEach((route: Route) => routes.push(getRoute(route, cookies)));

    // Добавление всех слоев на карту
    map.addLayerGroups(routes, {title: intl.formatMessage({id: 'module.routes'}), name: LAYER_NAME, visible: cookies.layers[LAYER_NAME]});
  }, [data]);

  useEffect(() => {
    return () => map.removeLayerByName(LAYER_NAME);
    }, [map]);

  return (<></>)
}
