/**
 * Данная структура часто встречается в uploadHistoryFile.service и даже в пермишенах
 *
 * Перенесена сюда, пока не уточним, что означают эти типы
 */
export enum ZalaType {
  Gas = 'zalagaz',
  Incident = 'zalaviolation',
  Ortophoto = 'zalaortophoto',
  ImageTile = 'zalaimagetile',
  Video = 'zalavideo',
}
