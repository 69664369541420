import { makeAutoObservable } from 'mobx';
import { Flight } from '@/.graphql/graphql';
import {
  downloadFile,
  State,
  StateHandler,
  StateHandlerStatus,
} from '@/shared/utils';
import { PhotoAttachment } from '@/entities/attachment';
import { inject, injectable } from 'inversify';
import { FtpFormStore } from '@/features/ftpForm/model/ftpForm.store';
import {
  createFlightApi,
  DownloadKmlParams,
  type FlightApi,
} from '../api/flight.api';
import RequestHandler from '@/shared/utils/requestHandler';
import { isNil } from 'lodash';
import urlJoin from 'url-join';
import { downloadFileBlob } from '@/shared/utils/downloadFile';

export interface FlightServiceState {
  readonly flight: Flight;
  readonly isFtpEnabled: boolean;
}

const defaultLinkCreator = (url: string): string => urlJoin(API, url);

@injectable()
export class FlightService {
  public static readonly diKey = Symbol.for('FlightService');

  private ftpService: FtpFormStore;

  constructor(
    /**
     * TODO - Исправить
     *
     * Ниже перед нами никакой не ftpService, а обычный стор FTP формы
     * Необходимо отделить логику формы от сервиса и инжектить сюда именно сервис FTP
     */

    @inject(FtpFormStore.diKey) ftpService: FtpFormStore,
    private readonly api: FlightApi = createFlightApi(),
    private readonly requestHandler = new RequestHandler<Flight | undefined>(),
    private readonly downloadLinkCreator = defaultLinkCreator
  ) {
    this.ftpService = ftpService;
    makeAutoObservable(this);
  }

  /**
   * TODO - Исправить
   *
   * @deprecated - брать данные напрямую из state
   */
  public get data(): FlightServiceState {
    if (this.state.status !== StateHandlerStatus.Ready) {
      throw new Error('Cannot access data before initialization');
    }

    return this.state.data;
  }

  public get state(): State<FlightServiceState, unknown> {
    const { data, isLoading, error } = this.requestHandler;

    if (isLoading) {
      return StateHandler.loading();
    }

    if (!isNil(error) || isNil(data)) {
      return StateHandler.error(error);
    }

    if (StateHandler.isReady(this.ftpService.state) && !isNil(data)) {
      return StateHandler.ready({
        flight: data,
        isFtpEnabled: this.ftpService.state.data?.isFtpEnabled ?? false,
      });
    }

    return StateHandler.loading();
  }

  private get flight(): Flight | undefined {
    if (!StateHandler.isReady(this.state)) return;

    return this.state.data.flight;
  }

  public async fetchFlight(flightId: string): Promise<Flight | undefined> {
    if (isNil(flightId)) {
      throw new Error('Cannot view flight without a flight id');
    }

    return this.requestHandler
      .handleRequest(() => this.api.fetchFlight(flightId))
      .then(flight => {
        this.requestFtp(flight);
        return flight;
      });
  }

  public async deleteFlight(): Promise<unknown> {
    if (this.flight === undefined) return;

    return this.api.deleteFlight(this.flight.flight_id);
  }

  private requestFtp(flight: Flight | undefined): void {
    if (flight?.flight_id) {
      this.ftpService.setFlightId(flight?.flight_id);
      this.ftpService.requestData();
    }
  }

  public async downloadPhotoAttachments(
    photos: readonly PhotoAttachment[],
  ): Promise<unknown> {
    if (isNil(this.flight)) return;

    const { flight_id, flight_name, project_id } = this.flight;

    return this.api
      .downloadPhotoAttachments({
        flightId: flight_id,
        projectId: project_id,
        flightName: flight_name,
        files: photos,
      })
  }

  public async downloadKmlAttachments(
    flight: DownloadKmlParams,
  ): Promise<unknown> {
    return this.api
      .downloadKmlAttachments(flight)
      .then(data => downloadFile(data.url, data.fileName));
  }

  public async downloadFlight(
    flightId: string,
  ): Promise<void> {
    return this.api.downloadFlight(flightId)
  }

  public async downloadVideoAttachments(): Promise<void> {
    if (isNil(this.flight)) return;

    const { flight_id, flight_name, project_id } = this.flight;

    return this.api.downloadVideoAttachments({
      flightId: flight_id,
      projectId: project_id,
      flightName: flight_name,
    })
  }

  public async downloadFlightTlm(
    flight: Flight,
  ): Promise<unknown> {
    return this.api.downloadFlightTlm(flight.flight_id, flight.project_id);
  }
}
