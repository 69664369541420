export default {
  'map.Tiles': 'Слои',
  'map.OSM': 'Карта открытого доступа',
  'map.Measure.length': 'Расстояние',
  'map.Measure.area': 'Площадь',
  'map.Measure.clear': 'Очистить',
  'map.Measure.triangle': 'Артиллерийский треугольник',
  'map.SearchOnArea': 'Поиск по выделенной области',
  'map.SearchResults': 'Результат поиска',
  'map.SearchByCoordinates': 'Поиск по координатам',
  'map.Search': 'Поиск',
  'map.SearchEmptyMessage': 'Ничего не найдено',
  'map.SearchLengthErrorMessage': 'Превышено максимальное количество символов',
  'map.zoomIn': 'Приблизить',
  'map.zoomOut': 'Отдалить',
  'map.layersPanel.downloadedMaps': 'Выгруженные карты',
  'map.layersPanel.maps': 'Карты',
}
