import { type FC } from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { Item } from '../Item';
import { ContextMenuStore } from '../../model/ContextMenu.store';
import { implicitContextMenuCheck } from '../../model/implicitContextMenuCheck';
import { MenuItem } from '../../model/interfaces';

interface Props {
  readonly store?: ContextMenuStore;
  readonly className?: string;
}

export const RemoveMark: FC<Props> = observer(({
  store = implicitContextMenuCheck(),
  className,
}) => {
  const intl = useIntl();

  const selectItem = () => {
    store.markModalOpen = !store.markModalOpen;
    store.deleteMark = true;
  }

  return (
    <Item
      className={className}
      action={() => selectItem()}
      label={intl.formatMessage({id: 'mark.context.delMark'})}
    />
  )
})

export const cmiRemoveMark: (isEnabledCallback?: () => boolean) => MenuItem = (isEnabledCallback) => {
  return {
    render: <RemoveMark/>,
    isEnabled: (store: ContextMenuStore) => Boolean(store.markId) && (isEnabledCallback === undefined ? true : isEnabledCallback()),
  }
}

