export default {
  violation: 'Событие',
  violations: 'События',
  'menu.violations': 'События',
  'menu.vlt_view': 'Событие',
  'menu.vlt_list': 'Список событий',
  'module.violations': 'События',
  'report.Violations': 'События',
  'violation.vlt_types': 'Все типы событий',
  'violation.list': 'Список событий',
  'violation.heatmap-type-vlt': 'События',
  'violation.context.addVlt': 'Добавить событие',
  'violation.search_results_count': 'Событий, удовлетворяющих фильтру',
  'violation.context.create.success': 'Событие создано!',
  'violation.zviolations': 'События онлайн',
};
