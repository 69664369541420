export default {
  'ofp.name': 'Наименование',
  'ofp.title': 'Ортофотоплан',
  'ofp.noPreviousUploadFound': 'Ортофотоплан не загружен. Перетащите сюда TIF / TIFF / ZIP файлы или нажмите на кнопку',
  'ofp.datetime': 'Дата',
  'ofp.ofpicons': 'Метки',
  'ofp.Compare': 'Сравнить',
  'ofp.Action': 'Действия',
  'ofp.delete.confirm': 'Удаление ортофотоплана приведёт к безвозвратной потере всех связанных данных. Вы уверены, что хотите продолжить?',
  'ofp.delete.error': 'Произошла ошибка во время удаления ортофотоплана. Попробуйте ещё раз!',
  'ofp.delete.success': 'Ортофотоплан успешно удален!',
  'ofp.OFP_MISSING': 'Ортофотоплан удален',
  'ofp.Cdreport.button': 'ГеоДинамика',
  'ofp.Cdreport.SearchArea': 'Область отчёта',
  'ofp.Cdreport.Classes': 'Классы',
  'ofp.Cdreport.Segments': 'Сегментация',
  'ofp.Cdreport.Open': 'PDF',
  'ofp.cdreport.name': 'Наименование',
  'ofp.cdreport.ofp_id_1': 'Левое окно ГД',
  'ofp.cdreport.ofp_id_2': 'Правое окно ГД',
  'ofp.сdreport.Confirm update': 'Уверены в выбранных ортофотопланах?',
  'ofp.сdreport.VIEW_PDF': 'Просмотр PDF',
  'ofp.сdreport.VIEW_OFP': 'Просмотр на ортофотопланах',
  'ofp.Orthomosaic processing': 'Обработка GeoTIFF',
};
