//import { useEffect, useState } from "react";

export const I4z1 = (props: any) => {
  /*const [svg, setSvg] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrored, setIsErrored] = useState(false);

  useEffect(() => {
    fetch(props.image)
        .then(res => res.text())
        .then(setSvg)
        .catch(setIsErrored)
        .then(() => setIsLoaded(true))
  }, [props.url]);*/

  return(
    //<span dangerouslySetInnerHTML={{__html: props.image}}  style={{width: '100%'}}  />
    <img src={'data:image/svg+xml;utf8,' + props.image} width={props.width} />
    /*<div
      style={{scale: .8}}
      className={`svg svg-${isLoaded ? 'loaded' : 'loading'} ${isErrored ? 'svgInline--errored' : ''}`}
      dangerouslySetInnerHTML={{ __html: svg }}
    />*/
  );
};
