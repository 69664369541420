import { makeAutoObservable } from 'mobx';
import { injectable } from 'inversify';
import { Asset, Assets } from '@/entities/assets/model';
import { FieldOfViewStorageApi } from '../api/FieldOfViewLayer.api';

@injectable()
export class FieldOfViewLayerStore {
  public static readonly diKey = Symbol.for('FieldOfViewLayerStore');
  private _list: Asset[] = [];
  private _assets: Asset[] = [];
  private _hiddenFieldOfViewList: string[] = [];

  constructor(
    private readonly api = new FieldOfViewStorageApi(),
  ) {
    makeAutoObservable(this);
  }

  get list() {
    return this._list;
  }

  get hiddenFieldOfViewList(): readonly string[] {
    return this._hiddenFieldOfViewList;
  }

  public setAssets(list: readonly Asset[]): void {
    this._assets = list.filter(asset => Assets.hasKnownFieldOfView(asset));
    this.update();
  }

  public update(): void {
    this._hiddenFieldOfViewList = this.api.getData();
    this._list = this._assets.filter(asset => !this.canShow(asset));
  }

  async toggleShow(asset: Asset): Promise<void> {
    await this.api.updateData(asset.publicAssetId);
    this.update();
  }

  public canShow(asset: Asset): boolean {
    return this._hiddenFieldOfViewList.includes(asset.publicAssetId);
  }
}
