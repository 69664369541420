import {
  ChangeCameraDirectionDocument,
  ChangeCameraDirectionMutation,
} from '@/.graphql/graphql';
import { GraphQlApi, REQUEST_POLICY_NETWORK } from '@/shared/api/graphql';

import { Asset, Assets } from '@/entities/assets/model';
import {
  calculateNorthDir,
  convertPtzAngleMeasureToDegrees,
} from '../../../../entities/assets/model/utils';
import { Loggers } from '@/debug/loggers';

interface UpdateDirectionRequestParams {
  readonly asset: Asset;
  readonly direction: number;
}

const logger = Loggers.sova;

interface ChangeDirectionApi {
  setDirection: (
    params: UpdateDirectionRequestParams,
  ) => Promise<ChangeCameraDirectionMutation>;
}

export class ChangeDirectionApiImpl implements ChangeDirectionApi {
  private readonly clientGraphQl = new GraphQlApi();

  async setDirection(
    params: UpdateDirectionRequestParams,
  ): Promise<ChangeCameraDirectionMutation> {
    const { asset, direction } = params;

    const inverted = Assets.withInvertedControls(asset);
    const northDirection = calculateNorthDir(
      convertPtzAngleMeasureToDegrees(Assets.getNorthDirection(asset)),
      Assets.getCourse(asset),
      direction,
    );

    const requestParams = {
      // Инвертируем обе оси
      // Смотри withInvertedControls() - entities/assets/model/Assets.ts
      reversePanDirection: inverted,
      reverseTiltDirection: inverted,
      publicAssetId: asset.publicAssetId,
      timestamp: GraphQlApi.formatCurrentTimestamp(),
      northDirection,
    };

    logger.debug(`changing-camera-direction params-`, requestParams, asset);

    return this.clientGraphQl.mutation(
      ChangeCameraDirectionDocument,
      requestParams,
      REQUEST_POLICY_NETWORK,
    );
  }
}

export const createChangeCoordinatesApi = () => {
  return new ChangeDirectionApiImpl();
};
