export default {
    'tabs.historicalDownloads':'История загрузок',
    'tabs.massLoadFiles':'История загрузок',
    'tabs.geodinamics': "ГеоДинамика",
    'tabs.ofp': "Ортофотопланы",
    'tabs.downloads': "Загрузка",
    'tabs.attachments': 'Вложения',
    'tabs.history': 'История изменений',
    'tabs.move': 'Переместить',
}
