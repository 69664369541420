import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl, useModel } from 'umi';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import { Vector as VectorSource, Cluster } from 'ol/source';
import { Vector as VectorLayer, Group as LayerGroup, } from 'ol/layer';
import GeoJSON from 'ol/format/GeoJSON';
import Feature from 'ol/Feature';
import { Style, Icon, Circle as CircleStyle, Text, Stroke, Fill } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import { LayersFlags } from '@/shared/map';

// @deprecated packages/app/src/features/map/layers/violationsLayer/ui/violationsLayer.tsx
export const Violations = (props: any) => {
  const { map, data, vlt, vlt_types } = props;
  const intl = useIntl();
  const [cookies] = useCookies(['layers']);
  const [cluster, setCluster] = useState<any>();
  const [clusters, setClusters] = useState<Cluster>();

  const defautlScale: number = .8;
  const _title = intl.formatMessage({
    id: 'module.violations',
    defaultMessage: 'Violations',
  });

  const white_circle = new Style({
    image: new CircleStyle({
      radius: 18,
      stroke: new Stroke({
        color: "#fff",
        width: 2
      }),
      fill: new Fill({
        color: 'transparent',
      }),
    })
  });

  function singleStyle(vlt: any, scale: number = defautlScale) {
    return new Style({
      image: new Icon({
        src: 'data:image/svg+xml;utf8,' + vlt.vlt_type.image,
        size: [50,50],
        //color: vlt.vlt_type.color,
        scale: scale
      }),
    });
  }

  function clusterStyle(size: any) {
    return [
      new Style({
        image: new CircleStyle({
          radius: 20,
          stroke: new Stroke({
            color: "#000",
            width: 2
          }),
          fill: new Fill({
            color: 'transparent',
          }),
        }),
        text: new Text({
          text: size.toString(),
          font: 'bold 18px Segoe UI',
          fill: new Fill({
            color: "#000",
          }),
          stroke: new Stroke({
            color: 'white',
            width: 2,
          }),
        }),
      }),
      white_circle
    ];
  }

  function setDefaultCluster() {
    const size = cluster.get('features').length;
    if (size == 1) {
      var _style = cluster.getStyle().clone();
      _style.getImage().setScale(defautlScale);
    }
    const newStyle = (size > 1) ? clusterStyle(size) : _style;
    cluster.setStyle(newStyle);
    setCluster(undefined);
  }

  function animateCluster(vlt: any) {
    if (vlt && clusters) {
      if (cluster) setDefaultCluster();
      var _cluster = clusters.getClosestFeatureToCoordinate(fromLonLat(vlt.target.coordinates));
      
      if (_cluster) {
        _cluster.setStyle(singleStyle(vlt, 1.2));
        setCluster(_cluster);
      }
    }
  }

  useEffect(() => {
    if (!vlt && cluster) {
      setDefaultCluster();
    }
    animateCluster(vlt);
  }, [vlt]);

  useEffect(() => {
    removeLayer('violations');
    if (data && data.length && vlt_types.length) {
      const types: any = [];
      if (vlt_types.length) {
        types[0] = vlt_types[0];
        vlt_types.map((type: any) => {
            let id = parseInt(type.id);
            types[id] = type;
        });
      }
      const source = new VectorSource();
      data.map((violation: any, feature: Feature) => {
        violation.vlt_type = (!types[violation.vlt_type_id]) ? types[0] : types[violation.vlt_type_id];
        
        var feature = new GeoJSON().readFeature(violation.target.geom, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        });
        feature.setId(violation.id);
        feature.set('className', 'layer-violation-' + violation.id);
        feature.set('name', violation.name + ' - ' + violation.vlt_type.name);
        feature.set('link', '/violations/view/' + violation.id);
        feature.set('type', violation.type);
        if (violation.type_id == 5) feature.set('pic', violation.pic);

        feature.setStyle(
          singleStyle(violation)
        );
        source.addFeature(feature);
      });

      const clusterSource = new Cluster({
        distance: 40,
        minDistance: 10,
        source: source
      });
      
      const styleCache: any = {};
      const layer = new VectorLayer({
        source: clusterSource,
        visible: cookies.layers.violations ? true : false,
        zIndex: 7,
        style: function (feature: Feature) {
          const size = feature.get('features').length;
          if (size == 1) return feature.get('features')[0].getStyle();
          let style = styleCache[size];
          if (!style) {
            style = clusterStyle(size);
            styleCache[size] = style;
          }
          return style;
        },
      } as BaseLayerOptions);

      var group_violations = new LayerGroup({
        title: _title,
        //fold: 'close',
        name: 'violations',
        className: 'violations',
        layers: [layer],
      } as GroupLayerOptions);

      group_violations.set(LayersFlags.DisableChildrenVisibilityChange, true);

      map.addLayer(group_violations);
      setClusters(clusterSource);
    }
  }, [data, vlt_types]);

  function getLayer(layer_name: string) {
    return map.getLayers().getArray().find((layer: any) => layer.className_ == layer_name);
  };

  function removeLayer(layer_name: string) {
    var layer = getLayer(layer_name);
    if (layer) map.removeLayer(layer);
  };

  useEffect(() => {
    return () => {
      removeLayer('violations');
    };
  }, []);

  return <></>;
};
