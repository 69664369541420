import { GraphQlApi, REQUEST_POLICY_NETWORK } from '@/shared/api/graphql';
import {
  UpdateAssetCertificateEnabledDocument,
  UpdateAssetCertificateEnabledMutation,
} from '@/.graphql/graphql';
import { ChangeRevokeStatusParams } from '../model';

export class DeviceRevokeApi {
  private readonly clientGraphQl = new GraphQlApi();

  async updateAssetCertificate(
    params: ChangeRevokeStatusParams,
  ): Promise<UpdateAssetCertificateEnabledMutation> {
    return this.clientGraphQl.mutation(
      UpdateAssetCertificateEnabledDocument,
      {
        enabled: params.revoked,
        assetPublicId: params.publicAssetId,
      },
      REQUEST_POLICY_NETWORK,
    );
  }
}
