import { useCallback, useEffect, useMemo, useRef, useState, type FC } from 'react';
import { MapPluginProps } from '@/shared/map/model/interfaces';
import HeatOlLayer, { Options } from 'ol/layer/Heatmap';
import { KML } from 'ol/format';
import VectorSource from 'ol/source/Vector';
import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import { compact, isEmpty, isNil } from 'lodash';
import { getOlMap, implicitMapCheck } from '@/shared/map';
import { GasAttachment } from '@/entities/attachment';
import { useIntl } from 'react-intl';
import './old_styles.scss';
import './styles.scss';
import { Button, Tooltip } from '@workspace/4Z1.uikit.react';
import ClusterMap from '@/components/Map/Layers/clustermap';
import { TemperatureIcon } from '@workspace/4Z1.uikit.react';

interface Props extends MapPluginProps {
  readonly tracks: readonly GasAttachment[];
  readonly layerNameCreator?: (track: GasAttachment) => string;
  readonly tooltipTextFormatter?: (weight: number) => string;
}

const DEFAULTS = {
  BLUR: 5,
  RADIUS: 5,
  ZINDEX: 5,
  THRESHOLD: 5,
  LAYER_NAME: 'heat-layer',
  ELEMENT_SUFFIX: 'heat-layer-element',
} as const;

const READ_OPTIONS = {
  extractStyles: false,
} as const;

const defaultLayerNameCreator = (track: GasAttachment): string => {
  return `${track.id}-${track.name}-${DEFAULTS.ELEMENT_SUFFIX}`;
};

const defaultTooltipTextFormatter = (weight: number): string => {
  return `${weight} ppm`;
};

export const HeatLayer: FC<Props> = ({
  map = implicitMapCheck(),
  tracks,
  layerNameCreator = defaultLayerNameCreator,
  tooltipTextFormatter = defaultTooltipTextFormatter,
}) => {
  const intl = useIntl();
  const layersRef = useRef<readonly HeatOlLayer[]>();
  const [blur, setBlur] = useState<number>(DEFAULTS.BLUR);
  const [radius, setRadius] = useState<number>(DEFAULTS.RADIUS);
  const [isThermal, setThermal] = useState<boolean>(false);
  const [threshold, setThreshold] = useState<number>(DEFAULTS.THRESHOLD);

  const renderClusterMap = useCallback(() => {
    if (isThermal || isEmpty(tracks)) return;

    const clusterLayers = tracks.map(track => (
      <ClusterMap key={track.id} shouldCenterOnRender={false} map={getOlMap(map)} data={track} />
    ));

    return clusterLayers;
  }, [isThermal, tracks, map]);

  /** Текст тултипа кнопки переключения режима отображения карты */
  const tooltipText = useMemo(() => {
    return isThermal
      ? intl.formatMessage({ id: 'gaz.enableNumericDisplay' })
      : intl.formatMessage({ id: 'gaz.enableThermalMap' });
  }, [isThermal]);

  /** Смена вида отображения карты на тепловую/числовую */
  const toggleThermalDispalyMode = (): void => {
    setThermal(isThermal => !isThermal);
  };

  /** Создание KML-формата */
  const createKML = (): KML => {
    return new KML(READ_OPTIONS);
  };

  /** Создание источника слоя */
  const createSource = (track: GasAttachment): VectorSource => {
    return new VectorSource({ url: track.file, format: createKML() });
  };

  /** Вычисление веса фичи */
  const calculateFeatureWeight = (feature: Feature<Geometry>): number => {
    const concentration = parseFloat(feature.get('name'));

    /**
     * TODO - Добавить отображение количества элементов поблизости, которое было реализовано в старой версии слоя
     *
     * @see - heatmaps.tsx (метод getFeaturesByRadius)
     */
    feature.set('text', tooltipTextFormatter(concentration));
    feature.set('prefix', intl.formatMessage({id: 'gasConcentration'}));

    return (concentration - threshold) / 1000;
  };

  /** Создание конфигурации слоя */
  const createLayerConfig = (track: GasAttachment): Options => ({
    source: createSource(track),
    weight: calculateFeatureWeight,
    blur,
    radius,
    zIndex: DEFAULTS.ZINDEX,
    className: DEFAULTS.LAYER_NAME,
  });

  /** Создание слоя и добавление атрибутов */
  const createLayer = (track: GasAttachment): HeatOlLayer => {
    const layer = new HeatOlLayer(createLayerConfig(track));
    layer.set('name', layerNameCreator(track));

    map.addLayer(layer);

    return layer;
  };

  /** Создание слоя и добавление атрибутов */
  const updateLayersAttributes = (layers: readonly HeatOlLayer[], blur: number, radius: number): void => {
    layers.map(layer => {
      layer.setBlur(blur);
      layer.setRadius(radius);
      layer.weightFunction_ = calculateFeatureWeight;

      /** Императивно заставляем источник слоя подтянуть изменения размера и сглаживания */
      layer.getSource()?.changed();
    });
  };

  /** Получение существующего или нового слоя */
  const getOrCreateLayer = (track: GasAttachment): HeatOlLayer => {
    const layerName = layerNameCreator(track);
    const existingLayer = map.getLayerByName(layerName);

    return existingLayer instanceof HeatOlLayer ? existingLayer : createLayer(track);
  };

  /** Удаление слоёв с карты */
  const removeLayers = (layers: readonly HeatOlLayer[]): void => {
    layers.forEach(layer => map.removeLayerByName(layer.get('name')));
  };

  /** Добавление слоёв на карту */
  const addLayers = (tracks: readonly GasAttachment[]): readonly HeatOlLayer[] => {
    const layers = compact(tracks.map(getOrCreateLayer));

    return layers;
  };

  useEffect(() => {
    const layers = addLayers(tracks);
    layersRef.current = [...layers];

    return () => {
      removeLayers(layers);
      layersRef.current = undefined;
    };
  }, [tracks, isThermal]);

  useEffect(() => {
    if (isNil(layersRef.current)) return;

    updateLayersAttributes([...layersRef.current], blur, radius);
  }, [blur, threshold, radius]);

  useEffect(() => {
    const layers = layersRef.current;

    if (!isThermal && !isNil(layers)) {
      removeLayers(layers);
    }
  }, [isThermal]);

  return (
    <div className='heat-layer-tools'>
      <Tooltip title={tooltipText} direction='bottom-left'>
        <Button.Switch
          value={isThermal}
          onClick={toggleThermalDispalyMode}
          onlyIcon
          icon={<TemperatureIcon />}
          type='badge'
        />
      </Tooltip>

      <div className='heat-layer-tools__control-list'>
        <div className='heat-layer-tools__control'>
          <label className='heat-layer-tools__label'>{intl.formatMessage({ id: 'gaz.radius size' })}</label>
          <input
            id='radius'
            type='range'
            min='1'
            max='10'
            step='1'
            value={radius}
            onChange={e => setRadius(Number(e.target.value))}
          />
        </div>

        <div className='heat-layer-tools__control'>
          <label className='heat-layer-tools__label'>{intl.formatMessage({ id: 'gaz.blur size' })}</label>
          <input
            id='blur'
            type='range'
            min='1'
            max='20'
            step='1'
            value={blur}
            onChange={e => setBlur(Number(e.target.value))}
          />
        </div>

        <div className='heat-layer-tools__control'>
          <label className='heat-layer-tools__label'>{intl.formatMessage({ id: 'gaz.threshold' })}</label>
          <input
            className='filter'
            id='threshold'
            type='range'
            min='0'
            max='1000'
            step='1'
            value={threshold}
            onChange={e => setThreshold(Number(e.target.value))}
          />
          <span className='heat-layer-tools__label heat-layer-tools__label--fixed'>{threshold} ppm</span>
        </div>
      </div>
      {renderClusterMap()}
    </div>
  );
};
