export default {
  'online.layers': 'Online marks',
  'online.height': 'Height',
  'online.wind_ms': 'Wind speed',
  'online.wind_dir': 'Wind direction',
  'online.metr': 'm',
  'online.metr_seconds': 'm/s',

  'online.MultiStream': 'MultiStream',

  'online.assetsFilter.onlyActive': 'Show only active Assets',
  'online.assetsFilter.onlyStreaming': 'Show only video streaming Assets',
  'online.assetsFilter.onlyRevoked': 'Show only revoked Assets',
  'online.assetsFilter.assetType': 'Filter by asset type',

  'online.assetTypes.allAssetTypes': 'All types',
  'online.assetTypes.car.shortname': 'Car',
  'online.assetTypes.car.fullname': 'Car',
  'online.assetTypes.uav.shortname': 'Uav',
  'online.assetTypes.uav.fullname': 'Unmanned Aerial Vehicle (UAV)',
  'online.assetTypes.gcs.shortname': 'Gcs',
  'online.assetTypes.gcs.fullname': 'Ground control station (GCS)',
  'online.assetTypes.incident.shortname': 'Marker',
  'online.assetTypes.incident.fullname': 'Marker',
  'online.assetTypes.beacon.shortname': 'Beacon',
  'online.assetTypes.beacon.fullname': 'Beacon',
  'online.assetTypes.geographics.shortname': 'Geographics',
  'online.assetTypes.geographics.fullname': 'Geographics',
  'online.assetTypes.localServers': '4Z1',
  'online.assetTypes.ptz.fullname': 'Camera',
  'online.assetTypes.afu.fullname': 'AFU',

  'online.assetCard.connected': 'Connected',
  'online.assetCard.disconnected': 'Disconnected',
  'online.assetCard.canSendGPS': 'Can send GPS',
  'online.assetCard.canStreamVideo': 'Can stream video',
  'online.assetCard.canExecuteCommands': 'Can execute commands',
  'online.assetCard.hardwareID': 'Hardware ID',
  'online.assetCard.warnings': 'Warnings',
  'online.assetCard.gcs': 'GCS',
  'online.assetCard.state': 'State',
  'online.assetCard.inAir': 'In Air',
  'online.assetCard.grounded': 'Grounded',
  'online.assetCard.mode': 'Mode',
  'online.assetCard.engineTemp': 'Engine Temp',
  'online.assetCard.activeUAV': 'Active UAV',
  'online.assetCard.сonnectedUAVs': 'Connected UAVs',
  'online.assetCard.speed': 'Speed',
  'online.assetCard.kmh': 'km/h',
  'online.assetCard.course': 'Course',
  'online.assetCard.location': 'Location',
  'online.assetCard.coordinates': 'Coordinates',
  'online.assetCard.tooltip.coordinates': 'Change coordinates',
  'online.assetCard.altitude': 'Altitude',
  'online.assetCard.lastUpdated': 'Last Updated',
  'online.assetCard.last1h': 'Last 1 hour',
  'online.assetCard.last8h': 'Last 8 hour',
  'online.assetCard.last24h': 'Last 24 hour',
  'online.assetCard.showTrack': 'Show Track',
  'online.assetCard.showVideoStream': 'Show Video Stream',
  'online.assetCard.changeCoordinates': 'Change coordinates',
  'online.assetCard.showVideoStreamFromGCS': 'Show Video Stream from GCS',
  'online.assetCard.openDetailsPage': 'Open Details page',
  'online.assetCard.showOnMap': 'Show on map',
  'online.assetCard.device.revoke': 'Revoke',
  'online.assetCard.device.enable': 'Enable',
  'online.assetCard.tooltip.revoke': 'Revoke certificate',
  'online.assetCard.tooltip.enable': 'Restore certificate',
  'online.multiVideoPlayers.actions.zoomIn': "zoom in",
  'online.multiVideoPlayers.actions.zoomOut': "zoom out",
  'online.fieldOfView': 'Camera visibility area',
  'online.fieldOfViewToggle.show': 'Show camera area',
  'online.fieldOfViewToggle.hide': 'Hide camera area',
  'online.assetCard.locality': 'Locality',

  // Перевод от Google Translate. Необходима задача на обновления переводов по статусам диагностики БВС.
  'online.assetCard.diagnosticFlag.0': 'Magnetometer failure',
  'online.assetCard.diagnosticFlag.1': 'No connection with the SNS receiver',
  'online.assetCard.diagnosticFlag.2': 'Warming up the SPG',
  'online.assetCard.diagnosticFlag.3': 'Height failure',
  'online.assetCard.diagnosticFlag.4': 'Critical speed or error ПВД >6',
  'online.assetCard.diagnosticFlag.5': 'No coordinates from СНС',
  'online.assetCard.diagnosticFlag.6': 'Probability of icing',
  'online.assetCard.diagnosticFlag.7': 'Loss of traction',
  'online.assetCard.diagnosticFlag.8': 'The release is not connected',
  'online.assetCard.diagnosticFlag.9': 'No AC (inverse)',
  'online.assetCard.diagnosticFlag.10': 'Propulsion failure',
  'online.assetCard.diagnosticFlag.11': 'No connection with the propulsion system',
  'online.assetCard.diagnosticFlag.12': 'Servo overload',
  'online.assetCard.diagnosticFlag.13': 'Entry in tegru',
  'online.assetCard.diagnosticFlag.14': 'Network address match',
  'online.assetCard.diagnosticFlag.15': 'No chain platoon',
  'online.assetCard.diagnosticFlag.16': 'No chain detonation',
  'online.assetCard.diagnosticFlag.17': 'Control of ПЗ for БСС ',
  'online.assetCard.diagnosticFlag.18': 'Failed pre-flight checks',
  'online.assetCard.diagnosticFlag.19': 'External influence',
  'online.assetCard.diagnosticFlag.20': 'Airplane in flight',
};
