import { Coordinates } from '@workspace/4Z1.ts.utils';

export interface License {
  readonly status: LicenseStatuses;
  readonly message: string;
  readonly date: string;
}

/**
 * Настройки из контекста пользователя
 */
export interface UserOptions {
  readonly center: Coordinates | undefined;
  readonly mainDateRange: [Date, Date] | undefined;
  readonly setMainDateRange: (start: Date, end: Date) => void;
  readonly ovenHost: string | undefined;
  readonly isLoggedIn: boolean;
  readonly projectType: string | undefined;
  readonly license: License;

  // тут должны быть ваши настройки
  // старый интерфейс настроек описан тут https://gitlab.eds.loc/4z1-x/frontend/redrock.frontend/-/blob/master/packages/app/src/app.tsx#L52 (см. тип CurrentOptions)
}

export enum LicenseStatuses {
  Deleted = 0,
  Active = 1,
  Inactive = 2,
  Invalid = 10,
  Empty = 11,
}