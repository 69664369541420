import './styles.scss';

import { formatDateTime } from '@workspace/4Z1.ts.utils';
import { ProgressBar } from '@workspace/4Z1.uikit.react';
import { FC } from 'react';
import { useIntl } from  'react-intl'

interface Props {
  readonly progress?: number; 
  readonly fileSize?: string; 
  readonly startDate?: string; 
}

const FileProgress: FC<Props> = ({ progress = 0, fileSize, startDate }) => {
  const intl = useIntl();
  const boundedProgress = Math.max(0, Math.min(progress, 100));
  const roundedProgress = Math.round(boundedProgress);
  const isProgressVisible = roundedProgress !== 0 && roundedProgress !== 100;
  const shouldShowDot = fileSize || startDate;

  return (
    <div className="fileProgressContainer">
      <ProgressBar progress={roundedProgress}/>
      <p className='progressInfo'>
        {isProgressVisible && intl.formatMessage({id: 'massload.loading'},{progress: roundedProgress})}
        {isProgressVisible && shouldShowDot ? " • " : ''}
        {fileSize && `${fileSize}`}
        {startDate && `${formatDateTime(startDate)}`}
      </p>
    </div>
  );
};

export default FileProgress;
