import { makeAutoObservable } from 'mobx';
import { injectable } from 'inversify';
import { ZalaType } from '@/entities/zalaType';

@injectable()
export class LoadFlightDataStore {
  public static readonly diKey = Symbol.for('LoadFlightDataStore');

  private _showPopup: boolean = false;
  private _zalaType: ZalaType | undefined;

  constructor() {
    makeAutoObservable(this)
  }

  get zalaType(): ZalaType | undefined {
    return this._zalaType;
  }

  get showPopup() {
    return this._showPopup;
  }

  setZalaType(zalaType: ZalaType): void {
    this._zalaType = zalaType;
  }

  setShowPopup(show: boolean): void {
    this._showPopup = show;
  }
}
