export enum StateHandlerStatus {
  Loading = 'Loading',
  Error = 'Error',
  Ready = 'Ready',
}

export type State<T, K = string | Error> =
  | { status: StateHandlerStatus.Loading }
  | { status: StateHandlerStatus.Error; error: K }
  | { status: StateHandlerStatus.Ready; data: T };

export class StateHandler<T, K = string | Error> {
  private components: Map<
    StateHandlerStatus | string,
    (state?: State<T, K>) => JSX.Element | undefined
  > = new Map();

  static ready<T, K>(data: T): State<T, K> {
    return { status: StateHandlerStatus.Ready, data };
  }

  static error<T, K>(error: K): State<T, K> {
    return { status: StateHandlerStatus.Error, error };
  }

  static loading<T, K>(): State<T, K> {
    return { status: StateHandlerStatus.Loading };
  }

  static isReady<T extends State<unknown, unknown>>(
    state: T,
  ): state is T & { status: StateHandlerStatus.Ready } {
    return state.status === StateHandlerStatus.Ready;
  }

  static isLoading<T extends State<unknown, unknown>>(
    state: T,
  ): state is T & { status: StateHandlerStatus.Loading } {
    return state.status === StateHandlerStatus.Loading;
  }

  static isError<T extends State<unknown, unknown>>(
    state: T,
  ): state is T & { status: StateHandlerStatus.Error } {
    return state.status === StateHandlerStatus.Error;
  }

  loading(fn: () => JSX.Element): this {
    this.components.set(StateHandlerStatus.Loading, () => fn());
    return this;
  }

  error(fn: (error: K) => JSX.Element): this {
    this.components.set(StateHandlerStatus.Error, (state) => {
      if (state?.status === StateHandlerStatus.Error) return fn(state.error);
    });
    return this;
  }

  ready(fn: (state: T) => JSX.Element): this {
    this.components.set(StateHandlerStatus.Ready, (state) => {
      if (state?.status === StateHandlerStatus.Ready) return fn(state.data);
    });
    return this;
  }

  render(state: State<T, K>): JSX.Element | undefined {
    const component = this.components.get(state.status);

    if (component !== undefined) return component(state);

    return this.defaultRender();
  }

  default(fn: () => JSX.Element): this {
    this.components.set('default', () => fn());
    return this;
  }

  private defaultRender(): JSX.Element | undefined {
    const defaultComponent = this.components.get('default');

    if (defaultComponent !== undefined) {
      return defaultComponent();
    }

    throw new Error(`Cannot render component of unknown status`);
  }
}
