import { makeAutoObservable } from 'mobx';

import RequestHandler from '@/shared/utils/requestHandler';

import { createChangeCoordinatesApi } from '../api';
import { Asset } from '@/entities/assets/model';

interface UpdateDirectionParams {
  readonly asset: Asset;
  readonly direction: number;
}

export class DirectionStore {
  constructor(
    private readonly api = createChangeCoordinatesApi(),
    private readonly requestHandler = new RequestHandler(),
  ) {
    makeAutoObservable(this);
  }

  public async updateDirection(params: UpdateDirectionParams) {
    return await this.requestHandler.handleRequest(() =>
      this.api.setDirection(params),
    );
  }

  get isLoading() {
    return this.requestHandler.isLoading;
  }
}
