import * as Icons from '@workspace/4Z1.uikit.react';

import React from 'react';

export const IconByName = (
  iconName: string,
  size?: number,
  props?: React.CSSProperties & { className?: string },
) => {
  const IconComponent = (Icons as any)[iconName];
  return IconComponent ? (
    <IconComponent size={size} style={props} className={props?.className} />
  ) : undefined;
};
