import { ActionConfirmModal } from '@/components/ActionConfirmModal';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { ActionsViewModel } from '../model/ortophoto.store';

interface Props {
  readonly viewModel: ActionsViewModel;
}

export const ConfirmOrtophotoDeletion: FC<Props> = ({
  viewModel: { onDeletionConfirm, onDeletionCancel, isConfirmModalOpen },
}) => {
  const intl = useIntl();

  return (
    <ActionConfirmModal
      confirmText={intl.formatMessage({ id: 'button.Delete' })}
      onConfirm={onDeletionConfirm}
      onCancel={onDeletionCancel}
      title={intl.formatMessage({ id: 'flight.delete.confirm.title' })}
      isOpen={isConfirmModalOpen}
    >
      {intl.formatMessage({ id: 'ofp.delete.confirm' })}
    </ActionConfirmModal>
  );
};
