import './styles.scss';

import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FileList } from '@/components/FileList';
import { useIntl } from 'react-intl';
import { Button, PercentIndicator, ModalPopup } from '@workspace/4Z1.uikit.react';
import { dep, diInject } from '@/HOC';
import { UploaderStore } from '@/features/UploadFiles/store/uploadFiles.store';

interface Props {
  readonly uploaderStore: UploaderStore;
}

const PATHNAME_DOWNLODS = '/downloads/upload';

const TotalPercentUpload: FC<Props> = ({ uploaderStore: uploadStore }) => {
  const intl = useIntl();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isOnUploadPage = location.pathname === PATHNAME_DOWNLODS;

  const fileItems = uploadStore?.sessions?.reduce((acc, session) => {
    return acc.concat(session.files);
  }, []);

  const handleToUploads = () => {
    setIsOpenModal(false);
    navigate(PATHNAME_DOWNLODS, { replace: true });
  };

  return (
    uploadStore.uploadsTotalPercentage !== 0 && (
      <div className="totalPercentUploadContainer">
        <Button
          onClick={() => setIsOpenModal(!isOpenModal)}
          active={isOpenModal}
          disable={fileItems?.length === 0}
        >
          <PercentIndicator value={uploadStore.uploadsTotalPercentage} />
        </Button>
        <ModalPopup
          onClose={() => setIsOpenModal(false)}
          title=""
          customPosition={{ top: 48, right: 0 }}
          footer={false}
          header={false}
          isOpen={isOpenModal}
          className="uploadPercentListContainer"
          isOutsideClick
        >
          <div className="modalPopupPercentContainer">
            {fileItems && fileItems?.length > 0 && <FileList files={fileItems} size="small" />}
            {!isOnUploadPage && (
              <div className="uploadPercentModalContainerButton">
                <Button
                  label={intl.formatMessage({ id: 'massload.goToUploads' })}
                  size="small"
                  className="uploadPercentModalButton"
                  type="badge"
                  onClick={() => handleToUploads()}
                />
              </div>
            )}
          </div>
        </ModalPopup>
      </div>
    )
  );
};

export default diInject(TotalPercentUpload, {
  uploaderStore: dep(UploaderStore),
});