import { makeAutoObservable } from 'mobx';
import { MapEngine } from '@/shared/map/model/interfaces';
import { LayerType, MAP_LAYERS, OSM_MAP_ID } from '@/shared/map';
import { getBaseLayersMap, getLayersMap } from '@/features/map/LayersPanel/model/getLayersMap';
import { TreeData } from '@workspace/4Z1.uikit.react';
import BaseLayer from 'ol/layer/Base';

export class LayersPanelStore {

  show: boolean = false;

  /** Идентификатор активной карты */
  private _activeMapId: number | string = OSM_MAP_ID;

  /** Список карт */
  private _maps: BaseLayer[] = [];

  /** Список слоев */
  private _layers: TreeData[] = [];

  /** Сортировка слоев */
  private _layerSortingTemplate: LayerType[] = [];

  // TODO !!! Нарушение зависимостей. Store зависит от Ui. !!!
  constructor(private readonly map: MapEngine) {
    makeAutoObservable(this);
  }

  get maps() {
    return this._maps;
  }

  get layers() {
    return this._layers;
  }

  get activeMapId() {
    return this._activeMapId;
  }

  public setLayerSortingTemplate(template: LayerType[]) {
    this._layerSortingTemplate = template;
  }

  public setActiveMapId(id: number | string) {
    this._activeMapId = id;
  }

  public mount() {
    if (!this.show || (this._layers.length > 0 && this._maps.length > 0)) return;

    this._layers = getLayersMap(this.map, MAP_LAYERS, this._layerSortingTemplate);
    this._maps = getBaseLayersMap(this.map);
  }

  public selectMap(selectedMap: BaseLayer) {
    this._activeMapId = selectedMap.get('map_id');
    this.map.setBaseMapLayer(selectedMap);
  }

}
