export default {
  'tile.Tiles': 'Слои',
  'tile.Title': 'Название',
  'tile.Title.new': 'Название нового слоя',
  'tile.Title.empty': 'Введите название',
  'tile.Title.Group': 'Название группы',
  'tile.Title.Tile': 'Название слоя',
  'tile.AddGroup': 'Добавить группу',
  'tile.AddTile': 'Добавить слой',
  'tile.Title.visible': 'Видимость',
  'tile.error.nottype': 'Файл имеет неверный формат.',
  'tile.Sort': 'Сортировка',
  'tile.minzoom': 'Мин. zoom',
  'tile.Modal.OK': 'OK',
  'tile.Modal.ON': 'Включить',
  'tile.Modal.Title': 'Эти слои автоматически отключены',
  'tile.Modal.SubTitle': 'чтобы включить слой, выделите нужный и нажмите Включить',
};
