import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl, useModel } from 'umi';
import LayerGroup from 'ol/layer/Group';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import Feature from 'ol/Feature';
import { Style, Stroke } from 'ol/style';

export const Videos = (props: any) => {
  const { map, data } = props;
  const intl = useIntl();
  const [cookies] = useCookies(['layers']);
  
  const _title = intl.formatMessage({
    id: 'module.videos',
    defaultMessage: 'Videos',
  });

  useEffect(() => {
    if (data && data.length) {
      map
        .getLayers()
        .getArray()
        .map((layer: any) => {
          if (layer.className_ == 'videos') map.removeLayer(layer);
        });

      const source = new VectorSource();
      data.map((video: any, feature: Feature) => {
        if (video.track) {
          var feature = new GeoJSON().readFeature(video.track.geom, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          });
          feature.set('className', 'layer-video-' + video.id);
          feature.set('name', video.name);
          feature.set('link', '/videos/' + video.flight_id);
          feature.set('type', 'videos');
          feature.setStyle(
            new Style({ stroke: new Stroke({ color: video.color, width: 3 }) }),
          );
          source.addFeature(feature);
        }
      });

      const layer = new VectorLayer({
        source: source,
        visible: cookies.layers.videos ? true : false,
        zIndex: 5,
      } as BaseLayerOptions);

      var group_videos = new LayerGroup({
        title: _title,
        //fold: 'close',
        name: 'videos',
        className: 'videos',
        layers: [layer],
      } as GroupLayerOptions);

      map.addLayer(group_videos);
    }
  }, [data]);

  return <></>;
};
