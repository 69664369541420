import { type FC } from 'react';
import { MapPluginProps } from '@/shared/map/model/interfaces';
import { implicitMapCheck } from '@/shared/map';
import { group } from '@/components/Map/Layers';

/** TODO Копипаст старого слоя.
 * Переписать используя возможности MapEngin!
 * исправить в https://jira.eds.loc/browse/REDR-1064
 */
export const MarkersLayer: FC<MapPluginProps> = ({map = implicitMapCheck()}) => {
  return <group.Marks map={map._map} />
}
