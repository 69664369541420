import './styles.scss';

import { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '@/shared/store/context';
import { PtzActions as Actions } from '../model/interfaces';
import { Button } from '@workspace/4Z1.uikit.react';
import { ptzActionsList } from '../model/PtzActionsList';

interface Props {
  publicAssetId: string;
}

export const PtzActions: FC<Props> = observer(({publicAssetId}) => {
  const { ptzActionsStore } = useStore();

  useEffect (() => {
    ptzActionsStore.connect(publicAssetId);

    return () => {
      ptzActionsStore.disconnect();
    };
  }, [ptzActionsStore]);

  const sendPtz = useCallback( (action: Actions) => {
    ptzActionsStore.sendCommand(publicAssetId, action);
  }, [ptzActionsStore]);

  return (
    <div className='ptzActions'>
      {ptzActionsList.map(action =>
        <Button className="ptzActionsButton" type='badge'
                size="small"
                fullWidth={true}
                onlyIcon={true}
                icon={action.icon}
                onClick={() => sendPtz(action.type)}
        />
      )}
    </div>
  )
})


