import { useState, useEffect } from 'react';
import { useIntl, FormattedMessage, Link, useModel } from 'umi';
import { Select, Modal, Form, Input, Divider, message, Button, SelectProps } from 'antd';
import { useCookies } from 'react-cookie';
import { dataVltTypes, TypeVltType } from '@/components/VltTypes';
import { useViolationCreateMutation } from '@/.graphql/graphql';
import { I4z1 } from '@/components/Icons';
import UploadImage from './uploadImage';
import { transformCoordToArray, transformCoord_4326 } from '../transformCoordinates';
import { useStore } from "@/shared/store/context";

type TypeVltCreate = {
  name: string,
  coord: string,
  vlt_type_id: string,
  comment: string,
  pic: string,
  massload_id: string,
}

type DataUpload = {
  filePath: string,
  massload_id: string
}

const emptydataUpload: DataUpload = {filePath: '', massload_id: ''};

const VltModal = (props: any) => {
  const { modalOpen, coord } = props;
  const { ether } = useStore();
  const [cookies] = useCookies(['reprojection', 'blank']);
  const intl = useIntl();
  const [form] = Form.useForm();
  const vlt_types = dataVltTypes();
  const [createResult, CreateViolation] = useViolationCreateMutation();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<string>('1');
  const [dataUploadImage, setDataUploadImage] = useState<DataUpload>(emptydataUpload);

  const modalCancel = () => {
    props.onModalChange(false);
    setSelected('1');
    setDataUploadImage(emptydataUpload);
    form.resetFields();
  };

  const getDataUploadImage = (dataUpload: DataUpload) => {
    setDataUploadImage(dataUpload);
    form.setFieldValue('pic', dataUpload.filePath);
  };

  const modalOK = async () => {
    const row: TypeVltCreate = (await form.validateFields());
    row.massload_id = dataUploadImage.massload_id;
    row.coord = JSON.stringify(transformCoord_4326(transformCoordToArray(coord), cookies.reprojection));

    if (row.pic && row.coord) {
      setLoading(true);
      CreateViolation(row);
      form.resetFields();
      setDataUploadImage(emptydataUpload);
    }else{
      message.error(intl.formatMessage({id: 'violation.context.create.pic.required'}));
    }
  };

  useEffect(() => {
    if (createResult.data) {
      setLoading(false);
      message.success(intl.formatMessage({id: 'violation.context.create.success'}));
      props.onModalChange(false);

      ether.refreshIncidents();
    }
  }, [createResult]);

  useEffect(() => {
    if (vlt_types.length) {
      setSelected(vlt_types[0].id);
    }
  }, [vlt_types]);

  useEffect(() => {
    return () => {
      //props.onModalChange(false);
    };
  }, []);

  const options: SelectProps['options'] = [];
  vlt_types.map((type: TypeVltType) => (
    options.push({value: type.id, label: (<><I4z1 image={type.image} width={20} />{" "} {type.name}</>), name: type.name })
  ))

  return (
    <Modal
      centered
      title={intl.formatMessage({id: 'violation'}) + ` (${coord})`}
      width={600}
      open={modalOpen}
      onOk={modalOK}
      onCancel={modalCancel}
      footer={[
        <Button key="back" onClick={modalCancel}>
          <FormattedMessage id="violation.context.add.Cancel" />
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={modalOK}>
          <FormattedMessage id="violation.context.add.OK" />
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="new_vlt"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={{
          vlt_type_id: selected,
          name: '',
          pic: '',
          comment: ''
        }}
      >

        <Form.Item
          name="name"
          label={<FormattedMessage id="violation.name" />}
          //rules={[{ required: true, message: <FormattedMessage id="violation.required.name" /> }]}
        >
          <Input />
        </Form.Item>
        <Divider />
        <Form.Item
          name="pic"
          label={<FormattedMessage id="violation.pic" />}
          rules={[{ required: true, message: <FormattedMessage id="violation.context.create.pic.required" /> }]}
        >
          <UploadImage getDataUploadImage={getDataUploadImage} />
        </Form.Item>
        <Divider />
        <Form.Item
          name="vlt_type_id"
          label={<FormattedMessage id="violation.type" />}
        >
          <Select
            options={options}
            showSearch
            filterOption={(input, option) =>
              (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Divider />
        <Form.Item
          name="comment"
          label={<FormattedMessage id="violation.comment" />}
          className="vlt-comment"
        >
          <Input.TextArea rows={2} showCount={true} maxLength={255} />
        </Form.Item>

      </Form>

    </Modal>
  );
};

export default VltModal;
