import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useIntl } from 'umi';
import LayerGroup from 'ol/layer/Group';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import Feature from 'ol/Feature';
import { Style, Stroke, Circle, Fill, Icon } from 'ol/style';

/**
 * @deprecated - Используется только для совместимости со старой страницей галереи и фото
 * 
 * Вместо этого компонента стоит использовать новый  - flightTrackLayer
 * 
 * @see - features/map/flightTrackLayer
 */
export const TrackPointPhoto = (props: any) => {
  const { map, data, flight, photo } = props;

  const intl = useIntl();
  const [cookies] = useCookies(['layers']);

  const _title = intl.formatMessage({
    id: 'module.imagetiles',
    defaultMessage: 'Photos',
  });

  useEffect(() => {
    if (data && flight.geometry.geom) {
      removeLayer('photos');
      var line = new GeoJSON()?.readFeature(flight.geometry.geom, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      });
      line.setStyle(
        new Style({
          stroke: new Stroke({ color: 'rgb(62,44,193,.8)', width: 3 }),
        }),
      );

      const source = new VectorSource({
        features: [line],
      });

      if (props.searchArea && props.searchArea.type) {
        var searchArea = new GeoJSON().readFeature(props.searchArea, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        });
        source.addFeature(searchArea);
      }

      const lastIndex = data.length - 1;
      data.map((photo: any, feature: Feature) => {
        if (photo.center) {
          var feature = new GeoJSON().readFeature(photo.center.geom, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          });
          feature.set('type', 'layer-point');
          feature.set('className', 'layer-photo-' + photo.id);
          feature.set('link', '/photos/' + photo.flight_id + '?id=' + photo.id + props.searcharealink);
          if (data[0].id == photo.id && !props.searcharealink) {
            feature.setStyle(
              new Style({
                image: new Circle({
                  radius: 6,
                  fill: new Fill({ color: '#dc3545' }),
                }),
              }),
            );
            feature.set(
              'name',
              intl.formatMessage({id: 'photo.start', defaultMessage: 'Start'}),
            );
          } else if (data[lastIndex].id == photo.id && !props.searcharealink) {
            feature.setStyle(
              new Style({
                image: new Circle({
                  radius: 6,
                  fill: new Fill({ color: '#dc3545' }),
                }),
              }),
            );
            feature.set(
              'name',
              intl.formatMessage({id: 'photo.end', defaultMessage: 'End'}),
            );
          } else {
            feature.set('name', photo.name);
            feature.setStyle(
              new Style({
                image: new Circle({
                  radius: 3,
                  fill: new Fill({ color: '#fff' }),
                  stroke: new Stroke({ color: '#319FD3', width: 1 }),
                }),
              }),
            );
          }
          source.addFeature(feature);
        }
      });

      const layer = new VectorLayer({
        source: source,
        visible: cookies.layers.flights ? true : false,
        zIndex: 2,
      } as BaseLayerOptions);

      const group_photos = new LayerGroup({
        title: _title,
        //fold: 'close',
        name: 'photos',
        className: 'photos',
        layers: [layer],
      } as GroupLayerOptions);

      map.addLayer(group_photos);
    }
  }, [data]);

  useEffect(() => {
    if (photo) {
      removeLayer('drone');
      var drone = new VectorLayer({
        source: new VectorSource({
          features: [
            new GeoJSON().readFeature(photo.center.geom, {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:3857',
            }),
          ],
        }),
        zIndex: 10,
        className: 'drone',
        style: new Style({
          image: new Icon({
            src: flight.icon_drone,
            rotation: (photo.dir * Math.PI) / 180,
          }),
        }),
      });
      map.addLayer(drone);
    }
  }, [photo]);

  function removeLayer(layer_name: string) {
    map.getLayers().getArray().map((layer: any) => {
      if (layer.className_ === layer_name) map.removeLayer(layer);
    });
  };

  useEffect(() => {
    return () => {
      removeLayer('photos');
      removeLayer('drone');
    };
  }, []);

  return <></>;
};