import { useEffect } from 'react';
import { useIntl } from 'umi';
import LayerGroup from 'ol/layer/Group';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';

import { useMapsQuery } from '@/.graphql/graphql';

export const Maps = (props: any) => {
  const [queryMaps] = useMapsQuery();
  const data = queryMaps.data ? queryMaps.data.maps : null;
  const { map } = props;
  const intl = useIntl();

  const _title = intl.formatMessage({
    id: 'module.maps',
    defaultMessage: 'Maps',
  });

  const _title_osm = intl.formatMessage({
    id: 'map.OSM',
    defaultMessage: 'OSM',
  });

  useEffect(() => {
    if (data) {
      removeLayer('maps');
      const osm = new TileLayer({
        title: _title_osm,
        map_id: -1,
        type: 'base',
        visible: props.initmap === -1 || !props.initmap,
        source: new OSM(),
      } as BaseLayerOptions);

      const maps = [osm];
      data.map((map: any) => {
        if(props.initmap === map.id || (!props.initmap && map.active)) osm.setVisible(false);
        maps.push(
          new TileLayer({
            title: map.name,
            map_id: map.id,
            type: 'base',
            visible: props.initmap === map.id || (!props.initmap && map.active),
            source: new XYZ({
              url: map.url,
            }),
          } as BaseLayerOptions),
        );
      });

      var group_maps = new LayerGroup({
        title: _title,
        fold: 'close',
        name: 'maps',
        className: 'maps',
        layers: maps,
      } as GroupLayerOptions);

      map.addLayer(group_maps);
    }
  }, [data]);

  function removeLayer(layer_name: string) {
    map.getLayers().getArray().map((layer: any) => {
      if (layer.className_ === layer_name) map.removeLayer(layer);
    });
  };

  useEffect(() => {
    return () => {
      removeLayer('maps');
    };
  }, []);

  return <></>;
};
