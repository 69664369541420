import { isArray, isNumber, isString } from 'lodash';
import { ApiError, DetailedError } from './errors';
import { isNil, chain, transform } from 'lodash';

enum DetailedParserKeys {
  Detail = 'detail',
  Code = 'code',
  Pointer = 'pointer',
}

enum ErrorParserKeys {
  Detail = 'detail',
  Status = 'status',
  Errors = 'errors',
}

const detailedParserKeys: ReadonlyArray<DetailedParserKeys> = [
  DetailedParserKeys.Pointer,
  DetailedParserKeys.Detail,
  DetailedParserKeys.Code,
] as const;

const errorParserKeys: ReadonlyArray<ErrorParserKeys> = [
  ErrorParserKeys.Detail,
  ErrorParserKeys.Errors,
  ErrorParserKeys.Status,
] as const;

interface ErrorCandidate {
  readonly detail: unknown;
  readonly status: unknown;
  readonly errors: readonly unknown[];
}

interface DetailedErrorCandidate {
  readonly detail: unknown;
  readonly pointer: unknown;
  readonly code: unknown;
}

export class ErrorsParser {
  private static parseDetailedError<T>(candidate: T): DetailedError | undefined {
    if (isNil(candidate)) return;

    const nonNullableData = candidate as NonNullable<T>;
    const lowercased = transform(nonNullableData, (res, val, key) => res[key.toLowerCase()] = val);

    const errorCandidate: DetailedErrorCandidate = chain(lowercased)
      .pick(detailedParserKeys)
      .toPlainObject()
      .value();

    const hasCorrectTypes =
      isString(errorCandidate.detail) &&
      isString(errorCandidate.pointer) &&
      isString(errorCandidate.code);

    if (hasCorrectTypes) {
      return {
        message: errorCandidate.detail,
        code: errorCandidate.code,
        pointer: errorCandidate.pointer,
      };
    }
  }

  public static parse<T>(candidate: T, source: unknown): ApiError | undefined {
    if (isNil(candidate)) return;

    const nonNullableData = candidate as NonNullable<T>;
    const lowercased = transform(nonNullableData, (res, val, key) => res[key.toLowerCase()] = val);

    const errorCandidate: ErrorCandidate = chain(lowercased)
      .pick(errorParserKeys)
      .toPlainObject()
      .value();

    const hasCorrectTypes =
      isString(errorCandidate.detail) &&
      isNumber(errorCandidate.status) &&
      isArray(errorCandidate.errors);

    if (hasCorrectTypes) {
      return new ApiError(
        errorCandidate.detail,
        errorCandidate.status,
        ErrorsParser.transformErrors(errorCandidate.errors),
        source,
      );
    }
  }

  private static transformErrors<T>(
    errors: readonly T[],
  ): readonly DetailedError[] {
    return errors
      .map((error) => ErrorsParser.parseDetailedError(error))
      .filter((error) => error !== undefined);
  }
}
