import { logger } from '@workspace/4Z1.ts.utils';

const log = logger('DownloadFile')

/**
 * Метод ниже были написаны в спешке
 * 
 * Необходимо их пересмотреть, т.к по большей части они делают одно и тоже (парсят респонс в блобу и эмулируют скачивание)
 */

/**
 * Утилита для скачивания файлов с веб-ресурсов
 *
 * Позволяет скачивать файлы находящиеся вне текущего домена.
 *
 * @param {string} fileName - Имя, которое будет присвоено файлу при загрузке
 * @param {string} url - URL для скачивания файла
 */
export const downloadFile = async (url: string, fileName: string): Promise<void> => {
  return await fetch(url)
    .then(response => response.blob())
    .then(blob => emulateDownloadOnClick(blob, fileName));
};

/**
 * Скачивает файл из бинарного ответа API.
 *
 * @param {string} url - URL эндпоинта.
 * @param {object} body - Тело запроса.
 * @param {string} fileName - Имя файла для сохранения.
 */
export const downloadFileFromStream = async (url: string, body: object, fileName: string): Promise<void> => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    // Получаем бинарные данные
    const blob = await response.blob();

    emulateDownloadOnClick(blob, fileName);
  } catch (error) {
    log.error('An error occured during file download', error);
  }
};


/**
 * Создаёт Blob из строки и инициирует скачивание файла.
 *
 * Используется для загрузки файлов, передаваемых как поток данных (например, текстовые файлы).
 *
 * @param {string} stream - Содержимое файла в виде строки
 * @param {string} fileName - Имя файла для сохранения
 */
export const downloadFileBlob = (stream: string, fileName: string): void => {
  const blob = new Blob([stream], { type: 'application/octet-stream' });

  emulateDownloadOnClick(blob, fileName);
};

/**
 * Эмулирует нажатие на ссылку для скачивания файла.
 *
 * Создаёт временный URL для Blob, инициирует скачивание и освобождает URL после завершения.
 *
 * @param {Blob} blob - Blob-объект с данными для скачивания
 * @param {string} fileName - Имя файла, которое будет присвоено при сохранении
 */
export const emulateDownloadOnClick = (blob: Blob, fileName: string): void => {
  const url = URL.createObjectURL(blob);
  emulateDownload(url, fileName);
};

/**
 * Эмулирует нажатие на ссылку для скачивания файла.
 *
 * Создаёт временный URL для Blob, инициирует скачивание и освобождает URL после завершения.
 *
 * @param {string} url - Ссылка до файла
 * @param {string} fileName - Имя файла, которое будет присвоено при сохранении
 */
export const emulateDownload = (url: string, fileName?: string): void => {
  const a = document.createElement('a');
  a.href = url;

  if (fileName) {
    a.download = fileName;
  }

  a.click();
  URL.revokeObjectURL(url);
  a.remove();
};

