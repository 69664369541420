import { makeAutoObservable } from 'mobx';
import { DeviceRevokeApi } from '../api';
import RequestHandler from '@/shared/utils/requestHandler';
import { ChangeRevokeStatusParams } from './interface';

export class RevokeStore {
  constructor(
    private readonly api = new DeviceRevokeApi(),
    private readonly requestHandler = new RequestHandler(),
  ) {
    makeAutoObservable(this);
  }

  public async changeRevokeStatus(
    params: ChangeRevokeStatusParams,
  ): Promise<unknown> {
    return await this.requestHandler.handleRequest(() => {
      return this.api.updateAssetCertificate(params);
    });
  }

  public get loading(): boolean {
    return this.requestHandler.isLoading;
  }
}
