import { Form, Spin } from 'antd';
import { useIntl } from 'react-intl';
import { CoordinatesStore, type CoordFormData } from '../model';
import { FC, useState } from 'react';
import { Asset } from '@/entities/assets/model';
import { dep, diInject } from '@/HOC';
import { ModalPopup } from '@workspace/4Z1.uikit.react';
import { LoadingOutlined } from '@ant-design/icons';
import { Input } from '@workspace/4Z1.uikit.react';
import { DiKeys } from '@/shared/di/global';
import { Coordinates } from '@workspace/4Z1.ts.utils';

import './styles.scss';

interface CoordFormData {
  readonly coordinates: string;
}

interface Props {
  readonly visible: boolean;
  readonly asset: Asset;
  readonly onClose: () => void;
  readonly onAssetCoordinatesChange: (
    assetId: string,
    coordinates: Coordinates,
  ) => void;
}

const ChangeAssetCoordinates: FC<Props> = ({
  onAssetCoordinatesChange,
  asset,
  visible,
  onClose,
}) => {
  const [coordinatesStore] = useState(() => new CoordinatesStore());
  const [form] = Form.useForm<CoordFormData>();

  const intl = useIntl();

  const handleValidate = (value: string) => {
    return coordinatesStore.validate(value)
      ? Promise.resolve({})
      : Promise.reject({ message: 'coords.input.prompt' });
  };

  const handleFormError = () => {
    form.setFields([
      {
        name: 'coordinates',
        errors: [
          intl.formatMessage({
            id: 'coords.submit.error',
            defaultMessage: 'Try again!',
          }),
        ],
      },
    ]);
  };

  const handleFormSubmit = () => {
    coordinatesStore
      .onFormSubmit({
        asset,
        coordinates: form.getFieldValue('coordinates'),
      })
      .then((coordinates) => {
        onClose();
        onAssetCoordinatesChange(asset.publicAssetId, coordinates);
        form.resetFields();
      })
      .catch(handleFormError);
  };

  return (
    <ModalPopup
      isOpen={visible}
      title={intl.formatMessage({ id: 'online.assetCard.coordinates' })}
      onClose={onClose}
      buttons={[
        {
          label: intl.formatMessage({
            id: 'button.Cancel',
            defaultMessage: 'Cancel',
          }),
          type: 'badge',
          onClick: onClose,
        },
        {
          label: intl.formatMessage({
            id: 'button.Save',
            defaultMessage: 'Save',
          }),
          type: 'accent',
          onClick: handleFormSubmit,
        },
      ]}
    >
      <Spin
        spinning={coordinatesStore.isLoading}
        indicator={<LoadingOutlined spin />}
        tip={intl.formatMessage({
          id: 'asset.details.loading',
          defaultMessage: 'Loading',
        })}
      >
        <Form
          className="changeCoordinatesForm"
          onFinish={handleFormSubmit}
          disabled={coordinatesStore.isLoading}
          form={form}
        >
          <Form.Item
            name="current-coordinates"
            rules={[
              {
                required: true,
                validator(_, value) {
                  return handleValidate(value);
                },
              },
            ]}
          >
            <div className="changeCoordinatesForm__form-item">
              <label
                htmlFor="current-coordinates"
                className="changeCoordinatesForm__label"
              >
                {intl.formatMessage({
                  id: 'coords.label.current',
                  defaultMessage: 'Current coordinates',
                })}
              </label>
              <Input
                id="current-coordinates"
                disabled
                placeholder={asset.state.location ? `${asset.state.location?.lat?.toFixed(5)} ${asset.state.location?.lon?.toFixed(5)}` : intl.formatMessage({
                  id: 'coords.notSet'
                })}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="coordinates"
            rules={[
              {
                required: true,
                validator(_, value) {
                  return handleValidate(value);
                },
                message: intl.formatMessage({
                  id: 'coords.input.prompt',
                  defaultMessage: 'Enter coordinates',
                }),
              },
            ]}
          >
            <div className="changeCoordinatesForm__form-item">
              <label
                htmlFor="coordinates"
                className="changeCoordinatesForm__label"
              >
                {intl.formatMessage({
                  id: 'coords.label.changeTo',
                  defaultMessage: 'Change to',
                })}
              </label>
              <Input
                onChange={(text: string) =>
                  form.setFieldValue('coordinates', text)
                }
                placeholder={intl.formatMessage({
                  id: 'coords.input.placeholder',
                  defaultMessage: 'Enter coordinates',
                })}
              />
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </ModalPopup>
  );
};

export default diInject(ChangeAssetCoordinates, {
  onAssetCoordinatesChange: dep(DiKeys.onAssetCoordinatesChange),
});
