import { makeAutoObservable } from 'mobx';
import { AsyncData } from '@/shared/model';
import { asyncDataBind } from '@/shared/utils/asyncDataBind';
import { FormType, StatusSubmit } from './interfaces';

import type { FormValues, FtpStore, Store } from './interfaces';
import { inject, injectable } from 'inversify';
import { FtpService, FtpStatus } from '@/entities/ftp';
import { logger } from '@workspace/4Z1.ts.utils';
import { State, StateHandler } from '@/shared/utils';
import RequestHandler from '@/shared/utils/requestHandler';
import { FormHelper } from './form.store';
import { FlightResponse } from '@/entities/ftp';
import { getStatus } from './utils';

const log = logger('FTP');

export interface FtpState {
  readonly status: StatusSubmit | undefined;
  readonly ftp_status: FtpStatus | undefined;
  readonly isFtpEnabled: boolean | undefined;
}

@injectable()
export class FtpFormStore implements Store, FtpStore {
  public static readonly diKey = Symbol.for('FtpFormStore');

  private ftpRequest = asyncDataBind<FlightResponse, string>(
    (flightId: string) => this.ftpService.flight(flightId),
    {},
    result => this.initValues = result,
  );

  private ftpService: FtpService;
  private formHelper: FormHelper;
  private initValues!: AsyncData<FlightResponse>;
  private flightID: string | undefined;

  private statusSubmit: StatusSubmit | undefined = undefined;

  constructor(
    @inject(FormHelper.diKey) formHelper: FormHelper,
    @inject(FtpService.diKey) ftpService: FtpService,
    private readonly requestHandler = new RequestHandler(),
  ) {
    makeAutoObservable(this);
    this.ftpService = ftpService;
    this.formHelper = formHelper;
  }

  public get initialValues(): AsyncData<FlightResponse> {
    return this.initValues;
  }

  public get formType(): FormType {
    return getStatus(this.initValues?.data?.status);
  }

  public setFlightId(flightId: string): void {
    this.flightID = flightId;
  }

  public requestData(): void {
    if (!this.flightID) return;

    this.ftpRequest.call(this.flightID);
  }

  public onSubmitForm(values: FormValues): void {
    log.debug('attempting to submit FTP ->', values);

    this.formHelper.resetError();
    this.statusSubmit = undefined;

    this.requestHandler
      .handleRequest(() =>
        this.ftpService.sendFtp({ id: this.flightID, ...values }),
      )
      .then(() => {
        this.statusSubmit = StatusSubmit.Success;

        log.info('FTP has been submited successfully');
      })
      .catch((errors) => {
        this.formHelper.setError(errors.graphQLErrors);
        this.statusSubmit = StatusSubmit.Error;

        log.error('An error occured during submition of FTP form', errors);
      });
  }

  public get state(): State<FtpState> {
    const withRequestFinished = !this.requestHandler.isLoading;

    if (withRequestFinished) {
      return StateHandler.ready({
        status: this.statusSubmit,
        ftp_status: this.initialValues?.data?.status,
        isFtpEnabled: this.initialValues?.data?.status !== undefined,
      });
    }

    return StateHandler.loading();
  }

  public getFieldErrorLocale(key: keyof FormValues): undefined | string {
    return this.formHelper.getFieldErrorLocale(key);
  }

  public fieldHasError(key: keyof FormValues): boolean {
    return this.formHelper.fieldHasError(key);
  }

  public get formErrors(): boolean {
    return this.formHelper.hasErrors;
  }
}
