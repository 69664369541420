import { FC } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { Text } from "@workspace/4Z1.uikit.react";
import { dep, diInject } from '@/HOC';
import { AssetCardProps } from '@/widgets';
import { Assets, VideoStreamingAssetsService } from '@/entities/assets/model';

interface Props extends AssetCardProps {
  readonly streams: VideoStreamingAssetsService;
}

const GcsInfo: FC<Props> = ({asset, streams}) => {

  if (!Assets.isGcs(asset)) {
    return <></>
  }

  const intl = useIntl();

  const connectedUavNames = (asset.state.gcsState)
    ? streams.namesFor(asset.state.gcsState.connectedAssetIds.concat(asset.state.gcsState.activeUavAssetId) ?? [])
    : {};

  return (
    <>
      {
        !_.isNil(asset.state?.gcsState?.activeUavAssetId) && connectedUavNames[asset.state.gcsState.activeUavAssetId] &&
        <Text.Ellipsis>
          {intl.formatMessage({id: 'online.assetCard.activeUAV', defaultMessage: 'Active UAV'})}:&nbsp;
          {connectedUavNames[asset.state.gcsState.activeUavAssetId]}
        </Text.Ellipsis>
      }

      {
        _.isNil(asset.state?.gcsState?.connectedAssetIds) && asset.state.gcsState.connectedAssetIds?.length > 0  &&
        <Text.Ellipsis>
          {intl.formatMessage({id: 'online.assetCard.сonnectedUAVs', defaultMessage: 'Connected UAVs'})}:&nbsp;
          {asset.state.gcsState.connectedAssetIds?.map((c: any) => <>{connectedUavNames[c]} </>)}
        </Text.Ellipsis>
      }
    </>
  )
}

export default diInject(GcsInfo, {
  streams: dep(VideoStreamingAssetsService),
});
