import { type FC } from 'react';
import { getOlMap, implicitMapCheck, MapPluginProps } from '@/shared/map';
import Traffic from '@/components/Map/Layers/Traffic';
import { useAccess } from 'umi';
import { useCookies } from 'react-cookie';
import { useVisibleLayer } from '@/shared/ui/ol_map/useVisibleLayer';

export const TrafficLayer: FC<MapPluginProps> = ({map = implicitMapCheck()}) => {
  const access = useAccess();
  const [cookies] = useCookies(['layers', 'reprojection']);
  const { setVisibleLayer } = useVisibleLayer();

  return access['traffic'] && <Traffic map={getOlMap(map)} initTiles={cookies.layers} onSetVisible={setVisibleLayer} />
}
