import { Languages, Locale, LocalStorageKeys } from '../model';
import { LOCALES } from '../utils/locales';
import antdRu from 'antd/lib/locale/ru_RU';
import antdEn from 'antd/lib/locale/en_US';
import ruRU from '@/locales/ru-RU';
import enUS from '@/locales/en-US';
import violationRA from '@/locales/ru-RU/violationRA';
import { makeAutoObservable } from 'mobx';
import {
  getLocalStorageItems,
  setLocalStorageItems,
} from '../utils/localStorage';
import { DiKeys } from '../di/global';
import { UserOptionsFromLocalStorage } from '@/entities/userOptions/UserOptionsFromLocalStorage';
import { inject, injectable } from 'inversify';

@injectable()
export class LocaleStore {
  locale: Locale = LOCALES[Languages.RU];
  updatedRuRU: { [key: string]: string } | undefined = undefined;

  constructor(
    @inject(DiKeys.userOptions) private userOptions: UserOptionsFromLocalStorage = new UserOptionsFromLocalStorage(),
  ) {
    makeAutoObservable(this);
    this.preloadLocale();
    this.fetchUserLocale();
  }

  setLocale(locale: Locale) {
    this.locale = locale;
    setLocalStorageItems(LocalStorageKeys.ClientLocale, this.locale.code);
  }

  private preloadLocale() {
    const cachedLocale = this.findLocale(this.cachedLocale);
    if (cachedLocale) {
      this.locale = cachedLocale;
    }
  }

  private findLocale(key?: Languages): Locale | undefined {
    const locales = Object.values(LOCALES);
    return locales.find((locale) => locale.code === key);
  }

  private get cachedLocale() {
    return getLocalStorageItems(LocalStorageKeys.ClientLocale);
  }
  //Функция по изменению проишествий на события
  public fetchUserLocale() {
    if (!this.userOptions?.isLoggedIn) {
      return;
    }
    if (this.locale.code === Languages.RU && this.userOptions?.projectType === '1') {
      this.updatedRuRU = {
        ...ruRU,
        ...violationRA,
      };
    }
  }

  get libLocales() {
    switch (this.locale.code) {
      case Languages.RU:
        return {
          antd: antdRu,
          intl: this.updatedRuRU || ruRU,
        };
      case Languages.EN:
        return {
          antd: antdEn,
          intl: enUS,
        };

      default:
        break;    
    }
  }
}