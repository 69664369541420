import { dep, diInject } from '@/HOC';
import { FC, useEffect } from 'react';
import { DiKeys } from '@/shared/di/global';
import { Button, CloudRetrieveIcon } from '@workspace/4Z1.uikit.react';
import { DownloadOrtophotoStore } from '../model/downloadOrtophoto.store';
import { BaseStoreEffect } from '@/shared/model';
import { useIntl } from 'react-intl';
import { message } from 'antd';

interface Props {
  readonly ortophotoId: string;
  readonly store: DownloadOrtophotoStore;
}

const DownloadOrtophoto: FC<Props> = ({ store, ortophotoId }) => {
  const intl = useIntl();

  const effects: BaseStoreEffect = {
    onRequestError: () => message.error(intl.formatMessage({ id: 'Error' })),
    onRequestSuccess: () => message.success(intl.formatMessage({ id: 'button.downloadStartSoon' })),
  };

  useEffect(() => {
    store.mount(ortophotoId, effects);

    return () => {
      store.unmount();
    };
  }, [ortophotoId]);

  return (
    <Button
      onlyIcon
      type='badge'
      size='small'
      icon={<CloudRetrieveIcon.Small />}
      onClick={() => store.buttonView.onClick()}
    />
  );
};

export default diInject(DownloadOrtophoto, {
  store: dep(DiKeys.downloadOrtophotoStore),
});
