import { type FC } from 'react';
import { AssetCardProps } from '@/widgets';
import { Asset, Assets } from '@/entities/assets/model';
import { ConnectIcon, OnlineBroadcastIcon, Tooltip } from '@workspace/4Z1.uikit.react';
import { useIntl } from 'react-intl';

export const AssetStatusConnection: FC<AssetCardProps> = ({asset}) => {
  const intl = useIntl();

  return (
      Assets.isActive(asset as unknown as Asset)
        ? <Tooltip className='assetCardTooltip assetCardTooltipInfo' title={intl.formatMessage({id: 'online.assetCard.connected'})}>
          <OnlineBroadcastIcon.Small/>
        </Tooltip>
        : <Tooltip className='assetCardTooltip assetCardTooltipInfo' title={intl.formatMessage({id: 'online.assetCard.disconnected'})}>
          <ConnectIcon.Small/>
        </Tooltip>
  )
}
