import { inject, injectable } from 'inversify';
import { createAssetsApi } from '@/entities/assets/api';
import { makeObservable, observable } from 'mobx';
import { Asset, AssetFilterParam, type AssetListFilter } from '@/entities/assets/model/types';
import { logger } from '@workspace/4Z1.ts.utils';
import _ from 'lodash';
import { DiKeys } from '@/shared/di/global';

const REFRESH_INTERVAL = 5 /* secs */ * 1000;

const log = logger('AST:SRV');

@injectable()
export class AssetsService {
  public static readonly diKey = Symbol.for('AssetsService');

  private _list: Asset[] = [];

  private interval: number = 0;

  private _filter: AssetListFilter;

  constructor(
    @inject(DiKeys.assetFilter) filter: AssetListFilter,
    protected readonly api = createAssetsApi(),
    protected readonly refreshInterval = REFRESH_INTERVAL,
  ) {
    this._filter = filter;
    makeObservable(this, {
      _list: observable,
      _filter: observable,
    });
  }

  /**
   * Список ассетов в соответствии с фильтрами.
   * Обновляется с периодичностью REFRESH_INTERVAL
   */
  public get list(): readonly Asset[] {
    return this._list;
  }

  public get filter(): AssetListFilter {
    return this._filter;
  }

  public startWatching() {
    log.debug('start watching', this._filter);
    if (this.refreshInterval <= 0) {
      throw new Error('IllegalStateException: can not start watching without REFRESH INTERVAL');
    }
    if (this.isWatching) {
      log.warn('trying to start watching once again, skipped');
      return;
    }
    setTimeout(() => this.fetch(), 0);
    this.interval = setInterval(() => this.fetch(), this.refreshInterval);
  }

  public stopWatching() {
    if (this.isWatching) {
      clearInterval(this.interval);
      this.interval = 0;
    }
  }

  public forceUpdate() {
    this.stopWatching();
    this.startWatching();
  }

  private get isWatching(): boolean {
    return this.interval > 0;
  }

  public setFilter(filter: AssetListFilter): void {
    const needToStart = this.isWatching;
    this.stopWatching();
    this._filter = filter;
    this.fetch();
    if (needToStart) {
      this.startWatching();
    }
  }

  public updateFilter(filter: AssetFilterParam): void {
    log.debug('new filter', {...this._filter, ...filter});
    this.setFilter({...this._filter, ...filter} as AssetListFilter);
  }

  protected fetch() {
    log.debug('assets filter params: ', this._filter);

    this.api.assets(this._filter)
      .then(result => {
        log.debug('data updated', result);
        this._list = result;
      })
      .catch(error => {
        log.error('failed to fetch data', error, this._filter);
      });
  }

  // TODO нужно пересмотреть логику: использование подобного словаря (publicAssetId:name) - выглядит очень странно
  /**
   * Возвращает словарь имен ассетов по их publicAssetId.
   * Ключ словарая - publicAssetId, значение — имя ассета
   * @param publicAssetIds
   */
  public namesFor(publicAssetIds: string[]): Record<string, string> {
    return _(publicAssetIds)
      .map(id => this.findAssetByPublicId(id))
      .compact()
      .reduce((aggregate, asset) => ({ ...aggregate, [asset.publicAssetId]: asset.name }), {});
  }

  protected findAssetByPublicId(id: string): Asset | undefined {
    return this.list.find(a => a.publicAssetId === id);
  }

}
