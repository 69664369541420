import { type FC } from 'react';
import {
  LastUpdate,
  Location,
  MovementTrack,
  Project,
  Tooltips,
  Comment,
  ChangeAssetDirection,
  RevokeDevice,
  ChangeCoordinates,
  GcsInfo,
  UavInfo
} from '@/features/assetCard';
import { Preview } from '@workspace/4Z1.uikit.react';
import { Asset, Assets } from '@/entities/assets/model';

export interface AssetCardProps {
  readonly asset: Asset;
  readonly actions?: {
    readonly onMovementTrackRequested?: (asset: Asset, period: number) => void,
  };
}

/**
 * Компонент карточки Онлайн трансляций
 * @param asset
 */
export const AssetCard: FC<AssetCardProps> = ({ asset, actions }) => {

  return (
    <Preview title={asset.name} subTitle={asset.assetType}>

      <Comment asset={asset} />

      <Project asset={asset} />

      <GcsInfo asset={asset} />

      <UavInfo asset={asset} />

      <Location asset={asset}/>

      <LastUpdate asset={asset} />

      <Tooltips asset={asset} onActivate={actions?.onMovementTrackRequested}/>

      <ChangeAssetDirection asset={asset} />

      {Assets.withPtzActions(asset) && (
        <div className="ptzActions">
          <RevokeDevice asset={asset} />
          <ChangeCoordinates asset={asset} />
        </div>
      )}

    </Preview>
  )
}
